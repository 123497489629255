import { FC } from "react";

import "./DowWindmill.scss";

export const DotWindmill: FC = () => {
  return (
    <div style={{ padding: "12px 0" }}>
      <div className="dot-windmill" />
    </div>
  );
};
