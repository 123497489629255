import trashIcon from "assets/moodboard/trash.svg";
import { IconButtonWrapper } from "shared/ui";

export const TrashIcon = () => {
  return (
    <IconButtonWrapper>
      <img style={{ width: 16, height: 16 }} src={trashIcon} alt="delete" />
    </IconButtonWrapper>
  );
};
