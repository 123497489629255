import { useGoogleLogin } from "@react-oauth/google";
import { useAction, useAtom } from "@reatom/npm-react";
import { captureException } from "@sentry/react";
import { useDebounceFn } from "ahooks";
import { Form } from "antd";
import { TRegisterCredentials } from "@/shared/api/auth";
import {
  errorRegisterMessageAtom,
  googleRegisterAction,
  onRegisterGoogleLoginAction,
  regularRegisterAction,
} from "./register-model";

export const useBehavior = () => {
  const [form] = Form.useForm();
  const password = Form.useWatch("password", { form, preserve: true });

  const regularRegister = useAction(regularRegisterAction);
  const googleRegister = useAction(googleRegisterAction);

  const [regularRegisterStatuses] = useAtom(regularRegisterAction.statusesAtom);
  const [googleRegisterStatuses] = useAtom(googleRegisterAction.statusesAtom);
  const [onRegisterGoogleLoginStatuses] = useAtom(onRegisterGoogleLoginAction.statusesAtom);

  const [errorMessage, setErrorMessage] = useAtom(errorRegisterMessageAtom);

  const { run: onRegister } = useDebounceFn(
    (params: TRegisterCredentials) => {
      regularRegister(params);
    },
    { wait: 500 },
  );

  const runGoogleRegister = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      googleRegister(tokenResponse);
    },
    onError: (err) => {
      setErrorMessage("Oops, something went wrong");
      captureException(err, {
        tags: {
          feature: "google-register",
        },
        level: "warning",
      });
    },
  });

  const { run: onGoogleRegister } = useDebounceFn(runGoogleRegister, { wait: 500 });

  return {
    onRegister,
    errorMessage,
    onGoogleRegister,
    loading:
      googleRegisterStatuses.isPending ||
      regularRegisterStatuses.isPending ||
      onRegisterGoogleLoginStatuses.isPending,
    form,
    password,
  };
};
