import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect, useState } from "react";

import { initResetPasswordResource } from "@/shared/api/auth";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { GRAYSCALE_TOKEN } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { EnterTheCodePath } from "shared/types/routes.ts";

type Props = {
  error: string | null;
};

export const CountDownResendCode: FC<Props> = ({ error }) => {
  const { email } = useExtractParams<EnterTheCodePath>();
  const [timeLeft, setTimeLeft] = useState(60);

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const handleResendCode = async () => {
    if (email) {
      await initResetPasswordResource(email);
      setTimeLeft(60);
    }
  };

  if (timeLeft === 0) {
    return (
      <Flex vertical gap={8}>
        {error && (
          <Typography.Text className={cn(TEXT_CLASSNAMES.Body20)} type="danger">
            {error}
          </Typography.Text>
        )}
        <Typography.Link
          onClick={handleResendCode}
          type="secondary"
          underline
          className={cn(TEXT_CLASSNAMES.Body20)}
        >
          Resend code
        </Typography.Link>
      </Flex>
    );
  }

  return (
    <Flex vertical gap={8}>
      {error && (
        <Typography.Text className={cn(TEXT_CLASSNAMES.Body20)} type="danger">
          {error}
        </Typography.Text>
      )}
      <Typography.Text
        className={cn(TEXT_CLASSNAMES.Body20, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
      >
        If you didn’t receive the code, click Resend code in {timeLeft}s.
      </Typography.Text>
    </Flex>
  );
};
