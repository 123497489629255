import { useAction } from "@reatom/npm-react";
import { FC, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { TSettingsResult } from "@/shared/api/settings";
import { ROUTES } from "shared/const/routes.ts";
import { useOpenModal } from "shared/hooks";
import { NavigationTopBar } from "shared/ui";
import { logoutAction } from "@/entities/viewer";
import { FeedbackModal } from "../../feedback-modal";

type Props = {
  account: TSettingsResult;
  loading: boolean;
};

export const HeaderContent: FC<PropsWithChildren<Props>> = ({ account, loading, children }) => {
  const navigate = useNavigate();
  const [feedbackModal, toggleFeedbackModal] = useOpenModal();
  const logout = useAction(logoutAction);

  const onSettings = () => {
    navigate(ROUTES.SETTINGS_ACCOUNT.fullPath);
  };

  const onLogout = () => {
    logout();
  };

  const avatar = {
    role: account.role ?? "",
    username: account.username ?? "",
    image: account.avatar ?? "",
    placeholder: account.username?.[0] ?? "",
  };

  return (
    <>
      <NavigationTopBar
        avatarLoading={loading}
        onFeedback={toggleFeedbackModal}
        onSettings={onSettings}
        avatar={avatar}
        onLogout={onLogout}
      >
        {children}
      </NavigationTopBar>
      <FeedbackModal toggleModal={toggleFeedbackModal} isOpen={feedbackModal} />
    </>
  );
};
