import { api } from "@/shared/api";
import {
  TChangePasswordDTO,
  TSettingsDTO,
  TSettingsPreferencesDTO,
  TSettingsPreferencesResult,
  TSettingsResult,
} from "./types.ts";
import { TResponse } from "@/vite-env";

export const getSettingsProfileResource = (
  isAvatar: boolean,
  controller?: AbortController,
): TResponse<TSettingsResult> =>
  api.get("/settings/profile", {
    signal: controller?.signal,
    params: {
      get_avatar: isAvatar,
    },
  });

export const updateSettingsProfileResource = (
  data: TSettingsDTO,
  controller: AbortController,
): TResponse<unknown> => api.patch("/settings/profile", data, { signal: controller.signal });

export const getSettingsPreferencesResource = (
  controller: AbortController,
): TResponse<TSettingsPreferencesResult> =>
  api.get("/settings/preferences", { signal: controller.signal });

export const updateSettingsPreferencesResource = (
  data: TSettingsPreferencesDTO,
  controller: AbortController,
): TResponse<unknown> => api.patch("/settings/preferences", data, { signal: controller.signal });

export const changePasswordResource = (
  data: TChangePasswordDTO,
  controller: AbortController,
): TResponse<unknown> => api.post("/settings/change_password", data, { signal: controller.signal });
