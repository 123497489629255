import { Collapse, ConfigProvider, Flex } from "antd";
import { FC } from "react";
import { COLOR_NAMES } from "shared/const/color-names.ts";
import { SceneHeader, TStoryboardScene, useStoryboardType } from "@/entities/storyboard";
import { StoryboardGrid } from "./StoryboardGrid";
import { StoryboardList } from "./StoryboardList";

type Props = {
  scene: TStoryboardScene;
};

export const CollapsedScene: FC<Props> = ({ scene }) => {
  const { storyboardType } = useStoryboardType();

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            fontSizeIcon: 18,
            headerBg: COLOR_NAMES.White100,
            contentBg: COLOR_NAMES.White100,
            colorBorder: COLOR_NAMES.MetalGray50,
            headerPadding: "16px",
            contentPadding: 16,
            borderRadiusLG: 16,
          },
        },
      }}
    >
      <Collapse
        expandIconPosition={"end"}
        items={[
          {
            key: scene.id,
            label: (
              <SceneHeader
                sceneId={scene.id}
                scenesOrder={scene.scenesOrder}
                shotsOrder={scene.shots.map((shot) => shot.id)}
                locations={scene.locations}
                idx={scene.idx}
                title={scene.title}
                shotLength={scene.shots.length}
                activeLocation={scene.selectedLocation}
                projectLocation={scene.projectLocation}
              />
            ),
            children: (
              <Flex vertical className="gap-xs">
                {storyboardType === "grid" && (
                  <StoryboardGrid sceneId={scene.id} shots={scene.shots} />
                )}
                {storyboardType === "list" && (
                  <StoryboardList sceneId={scene.id} shots={scene.shots} />
                )}
              </Flex>
            ),
          },
        ]}
        defaultActiveKey={[scene.id]}
      />
    </ConfigProvider>
  );
};
