import { useAtom } from "@reatom/npm-react";
import {Button, Flex, Form, Input, Typography, Upload} from "antd";
import cn from "classnames";
import { FC, useMemo } from "react";

import { TNewProject } from "@/entities/projects";
import { viewerModel } from "@/entities/viewer";
import uploadImage from "assets/shared/plus_load.svg";
import { useBehavior } from "../model";

import {useExtractParams} from "@/shared/hooks/use-extract-params.ts";
import {ScriptPath} from "shared/types/routes.ts";
import {TEXT_CLASSNAMES} from "shared/const/text-classnames.ts";
import {COLOR_CLASSNAMES, GRAYSCALE_TOKEN} from "shared/const/color-names.ts";
import {NOT_RELEASE} from "shared/const/release.ts";
import {useNavigate} from "react-router-dom";
import TagManager from "react-gtm-module";
import {generateUrl} from "shared/methods";
import {ROUTES} from "shared/const/routes.ts";
import {getFileType} from "@/shared/methods/get-file-type.ts";
import {captureException} from "@sentry/react";

import "./ThirdStepForm.scss";

type TFiled = Pick<TNewProject, "script" | "briefing" | "country" | "city">;

interface IThirdStepForm {
  closeModal: () => void;
  isBriefOpen: boolean;
}

export const ThirdStepForm: FC<IThirdStepForm> = ({ closeModal, isBriefOpen }) => {
  const { id } = useExtractParams<ScriptPath>();
  const navigate = useNavigate();
  const [subscription] = useAtom(viewerModel.viewerSubscription.dataAtom);

  const {
    onChange,
    newProject,
    onRemove,
    form,
    beforeUpload,
    isEmptyFields,
    analyzeStatuses,
    uploadStatus,
    uploadScript,
    createProject,
    createProjectStatuses,
    resetData
  } = useBehavior();

  const onFinish = async () => {
    try {
      const file = newProject.script;

      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const fileType = getFileType(file);

        if (id) {
          uploadScript({
            projectKey: id,
            formData,
            fileType
          });
        } else {
          const project = await createProject(newProject);

          uploadScript({
            projectKey: project.data.project_key,
            formData,
            fileType
          });

          if (isBriefOpen) {
            navigate(generateUrl<ScriptPath>(ROUTES.SCRIPT.fullPath, {id: project.data.project_key}));
          }
        }

        TagManager.dataLayer({
          dataLayer: {
            event: "create_project",
            projectName: newProject.name,
          },
        });
      }
    } catch (e) {
      captureException(e, {
        tags: {
          feature: "create-project",
        },
        level: "error",
      })
    } finally {
      closeModal();
      resetData();
    }
  };

  const fields = useMemo(() => {
    const result = [];
    result[0] = { name: "script", value: newProject.script };
    result[1] = { name: "briefing", value: newProject.briefing };

    return result;
  }, []);

  return (
    <Form fields={fields} onFinish={onFinish} form={form}>
      <Flex vertical className="gap-s">
        <Flex vertical>
          <Typography.Text className={TEXT_CLASSNAMES.Title50}>Adding a script</Typography.Text>
          <Typography.Text className={cn(TEXT_CLASSNAMES.Body20, COLOR_CLASSNAMES.TextSecondary)}>
            Upload the script ({subscription?.num_symbols} characters max)
          </Typography.Text>
        </Flex>
        <Flex vertical className="gap-xs">
          <Form.Item<TFiled> name="script" rules={[{ required: isEmptyFields }]}>
            <Upload.Dragger
              fileList={newProject.script ? [{
                ...newProject.script,
                status: uploadStatus,
                name: uploadStatus === "error" ? "You’ve reached symbols limit" : newProject.script.name,
                response: "You’ve reached symbols limit"
              }] : []}
              maxCount={1}
              onRemove={onRemove}
              beforeUpload={beforeUpload}
              accept=".pdf,.txt,.docx"
              multiple={false}
              className={cn("add-image-moodboard__upload upload-list-inline")}
              onChange={onChange}
              listType="picture"
              disabled={analyzeStatuses.isPending}
            >
              <p className="ant-upload-drag-icon">
                <img src={uploadImage} alt="uplaod image" />
              </p>
              <Typography.Text className={TEXT_CLASSNAMES.Body20}>
                Drag and drop to upload, or{" "}
                <Typography.Text
                  className={cn(TEXT_CLASSNAMES.Body20, COLOR_CLASSNAMES.BrandGreen600)}
                >
                  browse
                </Typography.Text>{" "}
                <br />
                <Typography.Text
                  className={cn(
                    TEXT_CLASSNAMES.Body20,
                    GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className,
                  )}
                >
                  Supports: PDF, TXT, DOC
                </Typography.Text>
              </Typography.Text>
            </Upload.Dragger>
          </Form.Item>
        </Flex>
        {NOT_RELEASE && (
          <>
            {isBriefOpen && (
              <>
                <Flex justify="center" align="center">
                  <Typography.Text
                    className={cn(TEXT_CLASSNAMES.LgBold, COLOR_CLASSNAMES.TextSecondary)}
                  >
                    or
                  </Typography.Text>
                </Flex>
                <Flex vertical className="gap-xs">
                  <Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>
                    Describe your project brief
                  </Typography.Text>
                  <Form.Item<TFiled>
                    rules={[
                      { required: isEmptyFields, message: "Please enter briefing or upload file" },
                    ]}
                    name="briefing"
                  >
                    <Input.TextArea
                      disabled={analyzeStatuses.isPending}
                      rows={7}
                      placeholder="Example: Set in a cozy vintage café, 'Brewing Love' is a short film that celebrates the romance between a coffee enthusiast and their favorite brew.
With a warm color palette and soft lighting, the film captures close-ups of swirling coffee and the satisfying clink of porcelain cups"
                    />
                  </Form.Item>
                </Flex>
              </>
            )}
          </>
        )}
        <Button
          type="primary"
          disabled={analyzeStatuses.isPending || isEmptyFields || uploadStatus !== "done"}
          loading={analyzeStatuses.isPending || createProjectStatuses.isPending}
          size="large"
          htmlType="submit"
          onSubmitCapture={(event) => event.preventDefault()}
        >
          {analyzeStatuses.isPending ? (
            <span className="thirdForm_loading-dots">Processing the script</span>
          ) : (
            "Start creating"
          )}
        </Button>
      </Flex>
    </Form>
  );
};
