import { Flex, Typography } from "antd";
import logo from "assets/viewer/logo.svg";
import cn from "classnames";
import { FC, HTMLAttributes, PropsWithChildren } from "react";

import { GRAYSCALE_TOKEN } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { useMediaQueries } from "shared/hooks/use-media-queries";

import "./LoginWrapper.scss";

type Props = {
  title: string;
  questionText: string | (string | JSX.Element)[];
  actionText: string;
  action: () => void;
  isChildrenFullHeight?: boolean;
  isChildrenFullWidth?: boolean;
  containerClassName?: string;
} & HTMLAttributes<HTMLDivElement>;

export const LoginWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  title,
  questionText,
  actionText,
  action,
  isChildrenFullHeight,
  isChildrenFullWidth,
  containerClassName,
  ...props
}) => {
  const { isMobileL } = useMediaQueries();

  return (
    <Flex className={cn("login-wrapper full-width full-height", props.className)} {...props}>
      <Flex flex={1} justify="center" align="center" className="login-wrapper__content full-width">
        <Flex
          vertical
          justify={isChildrenFullHeight ? (isMobileL ? "flex-start" : "center") : "initial"}
          className={cn(containerClassName, {
            "full-height": isChildrenFullHeight,
            "height-scroll": isChildrenFullHeight,
            "full-width": isChildrenFullWidth,
          })}
        >
          <Typography.Text className={cn(TEXT_CLASSNAMES.Display70)}>{title}</Typography.Text>
          <Flex align="center">
            <Typography.Text
              className={cn(
                TEXT_CLASSNAMES.Subtitle30,
                GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className,
              )}
            >
              {questionText}
            </Typography.Text>
            {!!actionText && (
              <>
                &nbsp;
                <Typography.Link
                  onClick={action}
                  className={TEXT_CLASSNAMES.Link30}
                  type="secondary"
                  underline
                >
                  {actionText}
                </Typography.Link>
              </>
            )}
          </Flex>
          {children}
        </Flex>
      </Flex>
      <Flex vertical justify="space-between" flex={1} className="login-wrapper__space">
        <Flex align="center" justify="center" flex={1}>
          <div className="login-wrapper__space__img">
            <img className="image-contain" src={logo} alt="logo" />
          </div>
        </Flex>
        <Flex flex={0} justify="flex-end">
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
            © Blooper 2025. All rights reserved.
          </Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
