import { Button, Flex } from "antd";
import { FC } from "react";

type Props = {
  disabled: boolean;
  onSave: () => void;
};

export const Save: FC<Props> = ({ disabled, onSave }) => {
  return (
    <Flex style={{ padding: 16 }} className="full-width">
      <Button
        disabled={disabled}
        type="primary"
        className="flex-ctr-ctr full-width"
        onClick={onSave}
        size="large"
      >
        Save
      </Button>
    </Flex>
  );
};
