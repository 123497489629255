import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, HTMLAttributes } from "react";
import { useNavigate } from "react-router-dom";

import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";

interface IDropdownContent extends HTMLAttributes<HTMLDivElement> {
  link: string;
  onClose: () => void;
  img: string | null;
  description: string;
}

export const DropdownContent: FC<IDropdownContent> = ({ link, onClose, description, ...props }) => {
  const navigate = useNavigate();
  const onRedirect = () => {
    navigate(link);
    onClose();
  };

  return (
    <Flex
      {...props}
      role="button"
      onClick={onRedirect}
      align="center"
      justify="space-between"
      className={cn("header-dropdown__block gap-s pointer", props.className)}
    >
      <Flex align="center" style={{ marginLeft: 24 }} className="gap-xxs">
        <Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.MetalGray300)}>
          {description}
        </Typography.Text>
      </Flex>
    </Flex>
  );
};
