import { useAction, useAtom } from "@reatom/npm-react";
import { useHover } from "ahooks";
import { Button, Typography } from "antd";
import { useRef } from "react";
import { ThumbnailImageProps } from "react-grid-gallery";
import { changeMainImageResource, UploadImageResult } from "@/shared/api/storyboard";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { ShotEditPath } from "shared/types/routes.ts";
import { shotEditingImageUploadLoadingAtom, updateShotAction } from "@/entities/shot-edit";

export const DraggableOverlay = (props: ThumbnailImageProps) => {
  const { shotId, sceneId, id } = useExtractParams<ShotEditPath>();

  const updateShot = useAction(updateShotAction);
  const [loading, setLoading] = useAtom(shotEditingImageUploadLoadingAtom);
  const ref = useRef(null);
  const isHover = useHover(ref);

  const setMain = async () => {
    try {
      setLoading(true);

      await changeMainImageResource({
        shotId,
        projectKey: id,
        sceneId,
        imageId: Number(props.imageProps.alt),
      });

      const params: UploadImageResult = {
        bw_shot_image: props.imageProps.src,
        bw_shot_image_compressed: props.imageProps.src,
        bw_shot_image_watermarked: props.imageProps.src,
        bw_shot_image_watermarked_compressed: props.imageProps.src,
        id: Number(props.imageProps.alt),
      };

      updateShot(params);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <img {...props.imageProps} title={props.imageProps.title ?? ""} alt={props.imageProps.alt} />
      <div
        style={{
          position: "absolute",
          left: 0,
          bottom: 0,
          marginLeft: 5,
          marginBottom: 5,
          cursor: "pointer",
          zIndex: 10,
        }}
      >
        <Button
          style={{ opacity: isHover ? 1 : 0 }}
          loading={loading}
          onClick={setMain}
          type="primary"
          size="small"
        >
          <Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>Set as main</Typography.Text>
        </Button>
      </div>
    </div>
  );
};
