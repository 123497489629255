import { FC } from "react";
import redoIcon from "assets/character-info/redo.svg";
import { IconButtonWrapper } from "shared/ui";

export const RedoIcon: FC = () => {
  return (
    <IconButtonWrapper>
      <img src={redoIcon} alt="redo" />
    </IconButtonWrapper>
  );
};
