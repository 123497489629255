import { Flex } from "antd";
import { FC, PropsWithChildren } from "react";
import { COLOR_NAMES } from "../../const/color-names.ts";
import { useMediaQueries } from "../../hooks/use-media-queries.ts";

export const PageContainer: FC<PropsWithChildren> = ({ children }) => {
  const { isLaptopS, isTablet } = useMediaQueries();
  return (
    <Flex
      style={{
        padding: isLaptopS ? "32px 0" : 32,
        width: "100%",
        boxSizing: "border-box",
        background: COLOR_NAMES.BackgroundLightGray,
      }}
    >
      <Flex
        vertical
        gap={24}
        style={{
          background: COLOR_NAMES.BackgroundWhite,
          width: "100%",
          padding: 32,
          boxSizing: "border-box",
          borderRadius: isTablet ? 0 : 16,
          maxWidth: 1280,
          margin: "0 auto",
        }}
      >
        {children}
      </Flex>
    </Flex>
  );
};
