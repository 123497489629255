import { action, atom } from "@reatom/core";
import { AxiosResponse } from "axios";

import { getAllProjectsResource } from "@/shared/api/project";
import {
  createTeamResource,
  DeleteTeamMemberDTO,
  deleteTeamMemberResource,
  getTeamsResource,
  inviteToTeamResource,
  Member,
  TeamResult,
} from "@/shared/api/teams";
import { TTabView } from "shared/types/common";
import { isObjectEmpty } from "shared/methods";
import { accountWithAvatarModel } from "@/entities/account";
import { callErrorAction, callSuccessNotificationAction } from "@/entities/notification";
import { getTeamId, toTeamMemberList, TTeamMember } from "../lib";

export const searchTeamMemberAtom = atom("", "searchTeamMemberAtom");

export const teamMemberViewAtom = atom<TTabView>("grid", "teamMemberViewAtom");

export const teamListAtom = atom<TTeamMember[]>([], "teamListAtom");

export const teamListLoadingAtom = atom(false, "teamListLoading");

export const teamIdAtom = atom("", "teamIdAtom");

export const initTeamListAction = action(async (ctx) => {
  try {
    teamListLoadingAtom(ctx, true);
    const teams = await getTeamsResource();
    const projects = await getAllProjectsResource();

    if (!isObjectEmpty(teams.data)) {
      const teamId = getTeamId(teams.data);

      teamIdAtom(ctx, teamId);
      teamListAtom(ctx, toTeamMemberList(teams.data, projects.data.names.length));
      // temporalTeamListAtom(ctx, toTeamMemberList(teams.data, projects.data.names.length));
    }
  } catch (err) {
    callErrorAction(ctx, err as AxiosResponse);
  } finally {
    teamListLoadingAtom(ctx, false);
  }
});

export const inviteTeamMemberAction = action(async (ctx, member: Member) => {
  try {
    const teams = await getTeamsResource();
    const projects = await getAllProjectsResource();
    const account = ctx.get(accountWithAvatarModel.resource.dataAtom);

    if (isObjectEmpty(teams.data) && account.email) {
      const owner: Member = { email: account.email, role: "admin", position: "line_producer" };

      const newTeams = await createTeamResource({ members: [owner] });
      const teamId = getTeamId(newTeams.data);

      await inviteToTeamResource(teamId, { members: [member] });

      const teamResult: TeamResult = {
        [teamId]: [
          // {
          // 	[member.email]: {
          // 		user_role: member.role,
          // 		user_position: member.position
          // 	}
          // },
          {
            [owner.email]: {
              user_role: owner.role,
              user_position: owner.position,
            },
          },
        ],
      };

      teamIdAtom(ctx, teamId);
      teamListAtom(ctx, toTeamMemberList(teamResult, projects.data.names.length));
      callSuccessNotificationAction(ctx, "Invite sent successfully");
    } else {
      const teamId = getTeamId(teams.data);

      await inviteToTeamResource(teamId, { members: [member] });

      // const teamResult: TeamResult = {
      // 	[teamId]: [
      // 		...teams.data[teamId],
      // 		{
      // 			[member.email]: {
      // 				user_role: member.role,
      // 				user_position: member.position
      // 			}
      // 		}
      // 	]
      // };

      teamIdAtom(ctx, teamId);
      // teamListAtom(ctx, toTeamMemberList(teamResult, projects.data.names.length));
      callSuccessNotificationAction(ctx, "Invite sent successfully");
    }
  } catch (err) {
    callErrorAction(ctx, err as AxiosResponse);
  }
});

export const deleteTeamMemberAction = action(async (ctx, member: DeleteTeamMemberDTO) => {
  try {
    await deleteTeamMemberResource(member);
    teamListAtom(
      ctx,
      ctx.get(teamListAtom).filter((self) => self.email !== member.email),
    );
  } catch (e) {
    callErrorAction(ctx, e as AxiosResponse);
  }
});
