import { Flex } from "antd";
import { FC } from "react";
import ReactGA from "react-ga4";

import blooperExamplePDF from "assets/blooper-example.pdf";

import arrow1 from "assets/product/arrow-1.svg";
import arrow2 from "assets/product/arrow-2.svg";
import arrow3 from "assets/product/arrow-3.svg";
import arrow4 from "assets/product/arrow-4.svg";
import arrow5 from "assets/product/arrow-5.svg";

import num1 from "assets/product/num-1.svg";
import num2 from "assets/product/num-2.svg";
import num3 from "assets/product/num-3.svg";
import num4 from "assets/product/num-4.svg";
import num5 from "assets/product/num-5.svg";
import num6 from "assets/product/num-6.svg";

import step1 from "assets/product/step-1.png";
import step2 from "assets/product/step-2.png";
import step3 from "assets/product/step-3.png";
import step4 from "assets/product/step-4.png";
import step5 from "assets/product/step-5.png";
import step6 from "assets/product/step-6.png";
import { TrialButton } from "shared/ui";
import { Step } from "./Step.tsx";

const onButtonClick = () => {
  const link = document.createElement("a");
  link.href = blooperExamplePDF;
  link.download = "Blooper example.pdf";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  ReactGA.event({
    category: "pdf download",
    action: "download pdf from use case storyboard",
  });
};

const steps = [
  {
    img: (
      <Flex align="center" justify="center" className="product-step__content" flex={3}>
        <img
          src={step1}
          alt="Effortless script breakdowns made simple"
          className="product-step__preview"
        />
      </Flex>
    ),
    num: 1,
    title: "Effortless script breakdowns made simple",
    description:
      "Streamline your script breakdowns into detailed shot lists and production schedules, making your shoot planning smooth and easy.",
    numImg: num1,
    arrowImg: arrow1,
  },
  {
    img: (
      <Flex align="center" justify="center" className="product-step__content" flex={3}>
        <img
          src={step2}
          alt="Moodboards That Bring Ideas to Life"
          className="product-step__preview"
        />
      </Flex>
    ),
    num: 2,
    title: "Moodboards That Bring Ideas to Life",
    description:
      "Save time and energy by turning your creative vision into a shareable moodboard in minutes. Define the tone, style, and visual direction of your project to ensure everyone on your team is on the same page.",
    numImg: num2,
    arrowImg: arrow2,
  },
  {
    img: (
      <Flex align="center" justify="center" className="product-step__content" flex={3}>
        <img src={step3} alt="Scene-Specific Venue Suggestions" className="product-step__preview" />
      </Flex>
    ),
    num: 3,
    title: "Scene-Specific Venue Suggestions",
    description:
      "Receive tailored recommendations for shooting venues that match the mood and requirements of each scene in your project.",
    numImg: num3,
    arrowImg: arrow3,
  },
  {
    img: (
      <Flex align="center" justify="center" className="product-step__content" flex={3}>
        <img
          src={step4}
          alt="Instantly transform scripts into storyboards with AI"
          className="product-step__preview"
        />
      </Flex>
    ),
    num: 4,
    title: "Instantly transform scripts into storyboards with AI",
    description:
      "Turn your script into a visual storyboard in minutes. Blooper helps you quickly see how \n" +
      "your video will look before \n" +
      "you start filming.",
    numImg: num4,
    arrowImg: arrow4,
  },
  {
    img: (
      <Flex
        align="center"
        justify="center"
        className="product-step__content"
        flex={3}
        style={{ position: "relative" }}
      >
        <img src={step5} alt="Generate your pitch deck" className="product-step__preview" />
        <TrialButton
          onClick={onButtonClick}
          text="Download pitch deck"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </Flex>
    ),
    num: 5,
    title: "Generate \n" + "your pitch deck",
    description:
      "Effortlessly craft a tailored pitch deck for ads, commercials, \n" +
      "and campaigns. Fine-tune \n" +
      "the design, content, and layout to showcase your vision \n" +
      "with clarity.",
    numImg: num5,
    arrowImg: arrow5,
  },
  {
    img: (
      <Flex align="center" justify="center" className="product-step__content" flex={3}>
        <img src={step6} alt="Easy share your projects" className="product-step__preview" />
      </Flex>
    ),
    num: 6,
    title: "Easy share your projects",
    description:
      "Simplify teamwork with Blooper: give projects link to your clients, give feedback, and ensure everyone stays aligned effortlessly—all in one tool!",
    numImg: num6,
    arrowImg: "",
  },
];

export const ProductSteps: FC = () => {
  return (
    <Flex vertical className="product-step">
      {steps.map((step, idx) => (
        <Step {...step} key={idx.toString(36)} />
      ))}
    </Flex>
  );
};
