import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Popover } from "antd";
import cn from "classnames";
import { FC, useState } from "react";

import { ShotColor as TShotColor } from "@/shared/api/script";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ScriptPath } from "shared/types/routes.ts";
import { useProjectType } from "@/entities/projects";
import {
  ShotColor,
  styleSettingsOutlineAtom,
  updateShotColorAction,
} from "@/entities/script";

import "./ColorShotSelection.scss";

interface IColorShotSelection {
  defaultColor: TShotColor;
  placement: "top" | "left";
  shotId: string;
  sceneId: string;
}

export const ColorShotSelection: FC<IColorShotSelection> = ({
  defaultColor,
  placement,
  shotId,
  sceneId,
}) => {
  const { id } = useExtractParams<ScriptPath>();
  const updateShotColor = useAction(updateShotColorAction);
  const [activeColor, setActiveColor] = useState(defaultColor);
  const { isShare } = useProjectType();
  const shotColors: TShotColor[] = ["green", "red", "blue", "violet", "orange"];
  const [outlined] = useAtom(styleSettingsOutlineAtom);

  const isOutlined = outlined[id];

  const handleChangeColor = (color: TShotColor) => {
    updateShotColor({
      projectKey: id,
      color,
      shotId,
      sceneId,
    });
    setActiveColor(color);
  };

  return (
    <Popover
      // @ts-ignore
      trigger={isShare || !isOutlined ? "" : "hover"}
      placement={placement}
      content={
        <Flex
          align="center"
          justify="center"
          className={cn("color-shot-selection cubic-animation")}
        >
          {shotColors.map((color) => (
            <div key={color} className="color-shot-selection__shot">
              <ShotColor
                className="pointer"
                // @ts-ignore
                color={color}
                onClick={() => handleChangeColor(color)}
              />
            </div>
          ))}
        </Flex>
      }
    >
      <ShotColor
        // @ts-ignore
        color={isOutlined ? activeColor : "gray"}
      />
    </Popover>
  );
};
