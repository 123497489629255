import { Flex, Typography } from "antd";
import { FC } from "react";

import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import image1 from "assets/news-pages/wn-1.svg";
import image2 from "assets/news-pages/wn-2.svg";
import image3 from "assets/news-pages/wn-3.svg";
import image4 from "assets/news-pages/wn-4.svg";
import image5 from "assets/news-pages/wn-5.svg";
import image6 from "assets/news-pages/wn-6.svg";
import { TrialButton } from "shared/ui";

type CardProps = {
  title: string;
  description: string;
  img: string;
};

const Card: FC<CardProps> = ({ title, img, description }) => (
  <Flex vertical gap={4} style={{ maxWidth: 360, width: "100%" }}>
    <img height={260} src={img} alt={title} />
    <Typography.Text className={TEXT_CLASSNAMES.Link30}>{title}</Typography.Text>
    <Typography.Text className={TEXT_CLASSNAMES.Subtitle30}>{description}</Typography.Text>
  </Flex>
);

export const WhatsNewInBlooper: FC = () => {
  return (
    <Flex vertical>
      <Typography.Text className={TEXT_CLASSNAMES.SmRegular}>
        Big news! We’ve made huge improvements to Blooper—enhancing speed, stability, AI accuracy,
        and collaboration tools. Whether you’re scripting, storyboarding, or refining visuals,
        Blooper is now smoother, faster, and smarter than ever.
      </Typography.Text>
      <Flex style={{ margin: "24px 0" }}>
        <Typography.Text className={TEXT_CLASSNAMES.Display60}>
          What’s New in Blooper?
        </Typography.Text>
      </Flex>
      <Flex wrap="wrap" gap={16}>
        <Card
          title="Enhanced Script & Storyboard Generation"
          description="Blooper now generates scripts and storyboards faster, with improved structure and storytelling accuracy. Plus, all storyboard images now have a consistent hand-drawn pencil sketch style, making them visually appealing and professional."
          img={image1}
        />
        <Card
          title="Faster AI & Smarter Image Generation"
          description="AI-generated images are now quicker and more accurate, and when you make shot edits, regenerated images will automatically follow your changes. Combined with increased app stability and performance optimizations, everything runs more smoothly."
          img={image2}
        />
        <Card
          title="New Image Editing with Inpainting (Beta)"
          description="Want to tweak specific areas of an AI-generated image? Our new inpainting tool lets you refine details without regenerating the entire shot."
          img={image3}
        />
        <Card
          title="Location Prediction for Scenes"
          description="Blooper now helps predict and suggest scene locations based on your script, streamlining the pre-production process."
          img={image4}
        />
        <Card
          title="Project Sharing & Guest Access"
          description="You can now share projects with your team or clients—even if they don’t have a Blooper account."
          img={image5}
        />
        <Card
          title="Enhanced Security, Bug Fixes & Stability"
          description="We’ve fixed multiple bugs, boosted security, and improved overall app stability for a more reliable experience."
          img={image6}
        />
      </Flex>
      <Flex align='flex-start' vertical gap={16} style={{ marginTop: "24px" }}>
        <Typography.Text className={TEXT_CLASSNAMES.Display60}>
          Try out the new features now!
        </Typography.Text>
        <TrialButton text="Try it out"/>
      </Flex>
    </Flex>
  );
};
