import { Flex } from "antd";
import { FC } from "react";
import { EditInput } from "./EditInput.tsx";

export const ShotCameraMovementEdit: FC = () => {
  return (
    <>
      <div className="shot-details-info__divider" />
      <Flex vertical wrap="wrap" className="shot-details-info__row gap-xxs">
        <EditInput title="Camera Movement" editKey="camera_movement" />
      </Flex>
    </>
  );
};
