import { useAtom } from "@reatom/npm-react";
import { Flex, notification, Typography } from "antd";
import { FC } from "react";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import plus from "assets/shared/plus_square.svg";
import { ToggleModal } from "shared/hooks";
import { TProject } from "@/entities/projects";
import { isGuestAtom, viewerModel } from "@/entities/viewer";
import { NotificationError } from "@/assets/components/NotificationError.tsx";

import "./NewProjectCard.scss";

type Props = {
  projects: TProject[];
  toggleUpgradeModalModal: ToggleModal;
  toggleModal: ToggleModal;
};

export const NewProjectCard: FC<Props> = ({ projects, toggleModal, toggleUpgradeModalModal }) => {
  const [api, contextHolder] = notification.useNotification();
  const [viewerSubscription] = useAtom(viewerModel.viewerSubscription.dataAtom);
  const [isGuest] = useAtom(isGuestAtom);

  const createNewProject = () => {
    if (isGuest) {
      return toggleUpgradeModalModal(true);
    }

    const ownProjectsCount = projects.filter((project) => project.projectType === "own").length;

    if (viewerSubscription?.num_projects && ownProjectsCount >= viewerSubscription.num_projects) {
      api.error({
        message: (
          <Typography.Text className={TEXT_CLASSNAMES.SmSemibold}>
            Exceeded number of projects created
          </Typography.Text>
        ),
        description: (
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
            To continue creating new projects, you will need to upgrade your plan or archive/delete
            an existing project.
          </Typography.Text>
        ),
        placement: "top",
        closeIcon: null,
        icon: <NotificationError />,
        style: { width: 460 },
      });
    } else {
      toggleModal(true);
    }
  };

  return (
    <>
      {contextHolder}
      <Flex
        onClick={createNewProject}
        role="button"
        vertical
        align="center"
        justify="center"
        className="new-project-card pointer"
      >
        <img src={plus} alt="plus" />
        <Typography.Text className={TEXT_CLASSNAMES.Title30}>New Project</Typography.Text>
      </Flex>
    </>
  );
};
