import { useAction, useAtom } from "@reatom/npm-react";
import { captureException, setUser } from "@sentry/react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import success from "assets/shared/success.svg";
import { mobileCheck } from "shared/methods";
import {
  BackgroundPreLogin,
  LoginWrapper,
  sessionAuthAction,
  viewerAtom,
  viewerModel,
} from "@/entities/viewer";

export const PaymentSuccess: FC = () => {
  const navigate = useNavigate();
  const sesssionAuth = useAction(sessionAuthAction);
  const getSubscription = useAction(viewerModel.viewerSubscription);
  const isMobile = mobileCheck();
  const [loading, setLoading] = useState(false);
  const [viewer] = useAtom(viewerAtom);

  const start = async () => {
    try {
      setLoading(true);

      await getSubscription();

      sesssionAuth({ isGuest: false, email: "" });
      navigate("/projects");
    } catch (e) {
      setUser({
        email: viewer.email,
      });
      captureException(e, {
        tags: {
          feature: "start-using-blooper",
        },
        level: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <BackgroundPreLogin>
      <LoginWrapper title="" questionText="" actionText="" action={() => {}}>
        <Flex className="text-center" vertical gap={24}>
          <img src={success} alt="success" style={{ maxWidth: 200, margin: "0 auto" }} />
          <Flex style={{ maxWidth: 390 }} vertical gap={8}>
            <Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>
              You’re ready!
            </Typography.Text>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}
            >
              We have sent you a payment receipt by email
            </Typography.Text>
          </Flex>
          {isMobile ? (
            <Button size="large" loading={loading} style={{ height: 60 }} onClick={start}>
              Start using the app <br />
              from the desktop
            </Button>
          ) : (
            <Button size="large" loading={loading} type="primary" onClick={start}>
              Start using
            </Button>
          )}
        </Flex>
      </LoginWrapper>
    </BackgroundPreLogin>
  );
};
