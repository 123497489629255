import { useGoogleLogin } from "@react-oauth/google";
import { useAction, useAtom } from "@reatom/npm-react";
import { captureException } from "@sentry/react";
import { useCountDown, useDebounceFn } from "ahooks";
import { Form } from "antd";
import { useEffect, useState } from "react";
import { resendLinkAction } from "@/entities/viewer";
import { LoginFormType } from "../lib";
import {
  errorLoginMessageAtom,
  googleLoginAction,
  isUserVerifiedAtom,
  onLoginGoogleRegisterAction,
  regularLoginAction,
} from "./login-model";

export const useBehaviour = () => {
  const regularLogin = useAction(regularLoginAction);
  const googleLogin = useAction(googleLoginAction);
  const resendLink = useAction(resendLinkAction);

  const [googleRegisterStatuses] = useAtom(onLoginGoogleRegisterAction.statusesAtom);
  const [googleLoginStatuses] = useAtom(googleLoginAction.statusesAtom);
  const [regularLoginStatuses] = useAtom(regularLoginAction.statusesAtom);

  const [errorMessage, setErrorMessage] = useAtom(errorLoginMessageAtom);
  const [isUserShowLink, toggleUserShowLink] = useAtom(isUserVerifiedAtom);

  const [targetDate, setTargetDate] = useState<number>();
  const [linkCountdown] = useCountDown({
    targetDate,
  });

  const [form] = Form.useForm();
  const email = Form.useWatch("email", { form, preserve: true });

  const { run: onResendLink } = useDebounceFn(
    () => {
      resendLink(email);
      setTargetDate(Date.now() + 60 * 1000);
    },
    {
      wait: 500,
    },
  );

  const { run: onLogin } = useDebounceFn(
    (data: LoginFormType) => {
      regularLogin(data);
    },
    { wait: 500 },
  );

  const runGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      googleLogin(tokenResponse);
    },
    onError: (data) => {
      setErrorMessage("Oops, something went wrong");
      captureException(data, {
        tags: {
          feature: "google-login",
        },
        level: "warning",
      });
    },
  });

  const { run: onGoogleLogin } = useDebounceFn(runGoogleLogin, { wait: 500 });

  useEffect(() => {
    return () => {
      toggleUserShowLink(false);
    };
  }, []);

  return {
    onLogin,
    onGoogleLogin,
    onResendLink,
    form,
    errorMessage,
    isUserShowLink,
    linkCountdown,
    loading:
      googleRegisterStatuses.isPending ||
      googleLoginStatuses.isPending ||
      regularLoginStatuses.isPending,
  };
};
