import { FC } from "react";
import { Flex, Typography } from "antd";
import { useAtom } from "@reatom/npm-react";
import emptyShot from "assets/storyboard/empty-shot.svg";
import refresh from "assets/storyboard/refresh.svg";
import cn from "classnames";

import { ImageHoverAction } from "@/entities/storyboard/ui/image-hover-action";
import { Spinner } from "shared/ui";
import { useProjectType } from "@/entities/projects";
import { generateImageAtom, regenerateImageAtom } from "@/entities/storyboard";
import { useShotStoryboard } from "@/entities/storyboard";
import { SceneShotImageProps } from "../model";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { UpdateShotInfo } from "@/shared/api/storyboard";

export const SceneShotImage: FC<SceneShotImageProps> = ({
  id,
  image,
  sceneId,
  isShowDetails,
  renderType,
  imageStyle,
  imageContainerStyle,
  ...rest
}) => {
  const [generateImage] = useAtom(generateImageAtom);
  const [regenerateImage] = useAtom(regenerateImageAtom);
  const { isShare } = useProjectType();
  const { onGenerate, onRegenerate, imageRef, isHoverImage } = useShotStoryboard();

  const pending = regenerateImage[id]?.pending ?? generateImage[id]?.pending;
  const error = regenerateImage[id]?.error ?? generateImage[id]?.error;
  const imageGenerated = regenerateImage[id]?.image ?? generateImage[id]?.image;
  const currentImage = imageGenerated ?? image;

  const updateShotInfo: UpdateShotInfo = {
    title: rest.title,
    shot_description: rest.shot_description,
    location: rest.location,
    dialog: rest.dialogue,
    time: rest.time,
    camera_movement: rest.cameraMovement,
    camera_angle: rest.cameraAngle,
    props: rest.props,
  };

  return (
    <Flex
      ref={imageRef}
      align="center"
      justify="center"
      style={renderType === "export" ? imageContainerStyle : undefined}
      className="scene-shot-grid__img"
    >
      {pending && (
        <Flex align="center" justify="center" className="scene-shot-grid__overlay">
          <Spinner />
        </Flex>
      )}
      {error ? (
        <Flex vertical className="gap-xxs error-card">
          <Flex vertical align="center" className="gap-4">
            <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>
              Something went wrong
            </Typography.Text>
            <Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>
              Please try again
            </Typography.Text>
          </Flex>
          <Flex
            role="button"
            className={cn("scene-shot-grid__error-regenerate", {
              pointer: !isShare,
              "non-pointer-events": isShare,
            })}
            onClick={() => onRegenerate({
              shotId: id,
              sceneId,
              updateShotsOrder: rest.shotsOrder,
              updateShotInfo,
            })}
            align="center"
            justify="center"
            gap={4}
          >
            <img src={refresh} alt="refresh" />
            <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextWhitePrimary)}>
              Generate
            </Typography.Text>
          </Flex>
        </Flex>
      ) : (
        <>
          <img
            className={cn({
              ["scene-shot-grid__img__full"]: currentImage,
              ["scene-shot-grid__img__default"]: !currentImage,
            })}
            src={currentImage || emptyShot}
            alt="shot image"
            style={renderType === "export" ? imageStyle : undefined}
          />
          <ImageHoverAction
            image={currentImage}
            onGenerate={() => onGenerate({ shotId: id, sceneId })}
            isHoverImage={isHoverImage}
            isShare={isShare}
          />
        </>
      )}
    </Flex>
  );
};
