import {
  Character,
  CharacterImageNumber,
  CharacterNumber,
  CHARACTERS,
} from "@/shared/const/characters.ts";

export const toCharacter = (character: string | null, imageNumber: CharacterImageNumber) => {
  const characters = character?.split("_");

  const characterName = characters?.[0] as unknown as Character;
  const characterNumber = characters?.[1] as unknown as CharacterNumber;

  return CHARACTERS[characterName][characterNumber][imageNumber];
};

export const toCharacterInfo = (character: string) => {
  const characters = character.split("_");

  const characterName = characters[0] as unknown as Character;
  const characterNumber = characters[1] as unknown as CharacterNumber;

  return CHARACTERS[characterName][characterNumber];
};
