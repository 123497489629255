import {FC} from "react";
import {Flex, Typography} from "antd";
import cn from "classnames";
import {TEXT_CLASSNAMES} from "shared/const/text-classnames.ts";
import {GRAYSCALE_TOKEN} from "shared/const/color-names.ts";
import {PARTNERSHIP} from "shared/const/partnership.ts";
import spaceCowboys from "assets/partnership/spacecowboys.svg";
import {useMediaQueries} from "shared/hooks/use-media-queries.ts";

export const TrustedBy: FC = () => {
	const { isTablet } = useMediaQueries();
	return (
		<Flex style={{ width: "100%", maxWidth: 800, margin: "0 auto", padding: "48px 0" }} vertical justify="center" align="center" className="gap-s">
			<Typography.Text className={cn(TEXT_CLASSNAMES.Display70, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}>Trusted by</Typography.Text>
			<Flex vertical={isTablet} gap={24} className="full-width" justify="space-between">
				<a className="flex-ctr-ctr" target="_blank" href="https://www.spacecowboys.studio/">
					<img src={spaceCowboys} alt="space cowboys"/>
				</a>
				<a className="flex-ctr-ctr" target="_blank" href={PARTNERSHIP[3].link}>
					<img width={155} height={80} src={PARTNERSHIP[3].img} alt={PARTNERSHIP[4].title}/>
				</a>
				<a className="flex-ctr-ctr" target="_blank" href={PARTNERSHIP[4].link}>
					<img width={140} height={40} src={PARTNERSHIP[4].img} alt={PARTNERSHIP[4].title}/>
				</a>
			</Flex>
		</Flex>
	);
};
