import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Dropdown, Flex, MenuProps, Switch, Typography } from "antd";
import cn from "classnames";
import { FC, KeyboardEvent, MouseEvent } from "react";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { NOT_RELEASE } from "shared/const/release.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { ScriptPath } from "shared/types/routes";
import {
  backAction,
  descriptionStatusAtom,
  forwardAction,
  styleSettingsOutlineAtom,
} from "@/entities/script";
import { RedoIcon, UndoIcon, BrushIcon, SettingsIcon } from "./icons";

export const ScriptSettings: FC = () => {
  const { id } = useExtractParams<ScriptPath>();
  const [descriptionStatus] = useAtom(descriptionStatusAtom);
  const historyBack = useAction(backAction);
  const historyForward = useAction(forwardAction);
  const [outlined, setOutlined] = useAtom(styleSettingsOutlineAtom);

  const undoAction = () => {
    historyBack(id);
  };

  const redoAction = () => {
    historyForward(id);
  };

  const onChangeOutlined = (
    value: boolean,
    event: KeyboardEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setOutlined({
      ...outlined,
      [id]: value
    });
  };

  const sharedItems: MenuProps["items"] = [
    {
      key: "outline",
      label: (
        <Flex align="center" justify="space-between" gap={16}>
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.Subtitle10, COLOR_CLASSNAMES.BackgroundBlack)}
          >
            Shot outline
          </Typography.Text>
          <Switch
            checked={outlined[id]}
            onChange={(checked, event) => onChangeOutlined(checked, event)}
          />
        </Flex>
      ),
    },
  ];

  return (
    <Flex gap={8}>
      {descriptionStatus === "full" && (
        <>
          <Button onClick={undoAction} size="small" icon={<UndoIcon />}>
            Undo
          </Button>
          <Button onClick={redoAction} size="small" icon={<RedoIcon />}>
            Redo
          </Button>
        </>
      )}
      <Dropdown trigger={["click"]} menu={{ items: sharedItems }}>
        <Button size="small" icon={<BrushIcon />}>
          Style settings
        </Button>
      </Dropdown>
      {NOT_RELEASE && (
        <Button size="small" icon={<SettingsIcon />}>
          Breakdown settings
        </Button>
      )}
    </Flex>
  );
};
