import { useAction } from "@reatom/npm-react";
import { Button, Flex, Modal, Typography } from "antd";
import { FC } from "react";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { StoryboardSidebarPath } from "shared/types/routes";
import { IOpenModal } from "shared/hooks";
import { deleteCommentAction } from "../../model";

interface Props extends IOpenModal {
  commentId: string;
}

export const DeleteMessageModal: FC<Props> = ({ isOpen, commentId, toggleModal }) => {
  const { id } = useExtractParams<StoryboardSidebarPath>();

  const deleteComment = useAction(deleteCommentAction);
  const onClose = () => {
    toggleModal(false);
  };

  const handleDeleteComment = async () => {
    await deleteComment({ project_id: id, comment_id: commentId });
    onClose();
  };

  return (
    <Modal
      width={390}
      open={isOpen}
      onCancel={onClose}
      className="delete-comment-modal-root"
      footer={[
        <Flex
          style={{
            marginTop: 16,
          }}
          className="gap-xs"
          justify="flex-end"
        >
          <Button size="large" className="" onClick={onClose}>
            Cancel
          </Button>
          <Button size="large" type="primary" onClick={handleDeleteComment}>
            Yes, delete it
          </Button>
        </Flex>,
      ]}
    >
      <Flex vertical className="delete-comment-modal gap-xxs">
        <Typography.Text className={TEXT_CLASSNAMES.MdBold}>Delete message</Typography.Text>
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
          Are you sure you want to delete this message? This cannot be undone.
        </Typography.Text>
      </Flex>
    </Modal>
  );
};
