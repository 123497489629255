import { FC, useRef, useState } from "react";
import { useClickAway } from "ahooks";
import { Flex } from "antd";

import { SceneShotImage } from "./ui/SceneShotImage";
import { SceneShotDetails } from "./ui/SceneShotDetails";
import { SceneShotTags } from "./ui/SceneShotTags";
import { SceneShotDescription } from "./ui/SceneShotDescription";
import { SceneShotDialogue } from "./ui/SceneShotDialogue";
import { SceneShotCharacters } from "./ui/SceneShotCharacters";
import { SceneShotGridProps } from "./model";

import "./SceneShotGrid.scss";

export const SceneShotGrid: FC<SceneShotGridProps> = (props) => {
  const [isShowDetails, setIsShowDetails] = useState(false);
  const ref = useRef(null);
  const isExport = props.renderType === "export";

  useClickAway(() => setIsShowDetails(false), ref, ["click", "contextmenu"]);

  return (
    <Flex
      className="scene-shot-grid"
      style={isExport ? props.style : undefined}
      vertical
      ref={ref}
    >
      {isShowDetails && <div className="scene-shot-grid__overlay" />}

      <SceneShotImage {...props} isShowDetails={isShowDetails} />
      <SceneShotDetails {...props} isShowDetails={isShowDetails} setIsShowDetails={setIsShowDetails} />
      <SceneShotTags {...props} />
      <SceneShotDescription {...props} />
      <SceneShotDialogue {...props} />

      {isExport && (
        <>
          {!!props.props?.split(",")?.filter(el => el).length && (
            <SceneShotTags {...props} type="props" />
          )}
          {(!!props.shotSettings.length || !!props.cameraAngle) && (
            <SceneShotTags {...props} type="settings" />
          )}
          {!!props.cameraMovement && (
            <SceneShotTags {...props} type="movement" />
          )}
        </>
      )}

      <SceneShotCharacters {...props} />
    </Flex>
  );
};
