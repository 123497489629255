import { useAction } from "@reatom/npm-react";
import { captureException } from "@sentry/react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { ChangeEvent, FC, useState } from "react";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import info from "assets/projects/info.svg";
import {SelectTabImage, uploadThumbnailAction} from "@/entities/projects";

import "./SelectTab.scss";

type Props = {
  images: SelectTabImage[];
  onClose: () => void;
  projectKey: string;
};

export const SelectTab: FC<Props> = ({ images, onClose, projectKey }) => {
  const [activeImageIdx, setActiveImageIdx] = useState("");
  const uploadThumbnail = useAction(uploadThumbnailAction);
  const [loading, setLoading] = useState(false);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setActiveImageIdx(value);
  };

  async function getFileFromUrl(url: string, name: string, defaultType = "image/jpeg") {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }

  const onSave = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      const image = images.find((el) => el.id === activeImageIdx);

      if (image) {
        const file = await getFileFromUrl(image.img.bw_image_url_compressed, image.id);
        formData.append("thumbnail", file);
        uploadThumbnail(projectKey, formData);
      }

      onClose();
    } catch (e) {
      captureException(e, {
        tags: {
          feature: "save-thumbnail",
        },
        level: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (!images.length) {
    return (
      <Flex vertical className="gap-m">
        <Flex className="select-tab gap-xxs">
          <img style={{ width: 24, height: 24 }} src={info} alt="info" />
          <Flex vertical className="gap-4">
            <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>
              Empty storyboard
            </Typography.Text>
            <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
              There are no pictures in the storyboard. To select a cover for a project, first
              generate a storyboard.
            </Typography.Text>
          </Flex>
        </Flex>
        <Button loading={loading} disabled type="primary">
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Save</Typography.Text>
        </Button>
      </Flex>
    );
  }

  return (
    <Flex vertical className="gap-m">
      <Flex rootClassName="select-tab__content" className="gap-s" wrap="wrap">
        {images.map(({ img, id }, index) => (
          <div
            key={index.toString(36)}
            className={cn("select-tab__thumbnail", {
              "select-tab__thumbnail--active": id === activeImageIdx,
            })}
          >
            <div
              className={cn("select-tab__thumbnail__radio", {
                "select-tab__thumbnail__radio--active": id === activeImageIdx,
              })}
            />
            <input
              onChange={onChange}
              className="select-tab__thumbnail__radio--input"
              name="image"
              type="radio"
              value={id}
            />
            <img
              className="select-tab__thumbnail--image"
              alt="image"
              src={img.bw_image_url_compressed}
            />
          </div>
        ))}
      </Flex>
      <Button onClick={onSave} disabled={activeImageIdx === ""} type="primary" loading={loading}>
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Save</Typography.Text>
      </Button>
    </Flex>
  );
};
