import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Modal, Tabs, TabsProps, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";

import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { IOpenModal } from "shared/hooks";
import { Spinner } from "shared/ui";
import { getProjectStoryboardImagesActions } from "../../index";
import { SelectTab } from "./select-tab";
import { UploadTab } from "./upload-tab";

interface Props extends IOpenModal {
  projectKey: string;
}

export const ThumbnailProjectModal: FC<Props> = ({ isOpen, toggleModal, projectKey }) => {
  const [allImages] = useAtom(getProjectStoryboardImagesActions.dataAtom);
  const [statuses] = useAtom(getProjectStoryboardImagesActions.statusesAtom);
  const getImages = useAction(getProjectStoryboardImagesActions);

  const images = allImages.filter((el) => !!el.img.bw_image_url_compressed);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Select",
      // @ts-ignore
      children: (
        <SelectTab projectKey={projectKey} onClose={() => toggleModal(false)} images={images} />
      ),
    },
    {
      key: "2",
      label: "Upload",
      children: <UploadTab projectKey={projectKey} onClose={() => toggleModal(false)} />,
    },
  ];

  useEffect(() => {
    getImages(projectKey);
  }, []);

  return (
    <Modal footer={[]} open={isOpen} onCancel={() => toggleModal(false)}>
      <Flex vertical>
        <Flex vertical className="gap-xs">
          <Flex vertical>
            <Typography.Text className={TEXT_CLASSNAMES.Title50}>Set a thumbnail</Typography.Text>
            <Typography.Text className={cn(TEXT_CLASSNAMES.Body20, COLOR_CLASSNAMES.TextSecondary)}>
              Select a thumbnail for your project or upload it from your computer
            </Typography.Text>
          </Flex>
          {statuses.isPending ? <Spinner /> : <Tabs defaultActiveKey="1" items={items} />}
        </Flex>
      </Flex>
    </Modal>
  );
};
