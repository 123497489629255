import { Currency, Interval } from "@/shared/api/payment";

export const CURRENCY: Record<Currency, string> = {
  usd: "$",
  eur: "€",
};

export const INTERVAL: Record<Interval, string> = {
  year: "yr.",
  month: "mo.",
};
