import { useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC } from "react";

import { ActionRestrictor } from "shared/ui";
import { useAccountWithAvatar } from "@/entities/account";
import { isGuestAtom } from "@/entities/viewer";
import { AccountSettingsForm } from "./AccountSettingsForm";
import { AccountSettingsSkeleton } from "./AccountSettingsSkeleton";
import { DeleteAccountForm } from "./DeleteAccountForm";
import { UpdatePasswordForm } from "./UpdatePasswordForm";

export const SettingsAccount: FC = () => {
  const { loading } = useAccountWithAvatar();
  const [isGuest] = useAtom(isGuestAtom);

  return (
    <Flex vertical className="gap-xs">
      <Flex className="gap-xs">
        <Flex vertical className="gap-xs">
          <ActionRestrictor show={!isGuest}>
            {loading ? <AccountSettingsSkeleton /> : <AccountSettingsForm />}
          </ActionRestrictor>
          <Flex className="gap-xs full-width">
            <DeleteAccountForm />
          </Flex>
        </Flex>
        <UpdatePasswordForm />
      </Flex>
    </Flex>
  );
};
