import { Flex } from "antd";
import userPic from "assets/projects/user.svg";
import cn from "classnames";
import { FC } from "react";

import "./UsersWithShared.scss";

interface IUsersWithShared {
  amount: number;
  onClick?: () => void;
}

export const UsersWithShared: FC<IUsersWithShared> = ({ amount, onClick }) => {
  if (amount > 3) {
    return (
      <Flex
        onClick={onClick}
        className={cn({
          pointer: !!onClick,
        })}
      >
        {[...Array(3)].map((_user: number, i) => (
          <div
            key={i.toString(36)}
            style={{ zIndex: i + 1, left: i === 0 ? 0 : -i * 5 }}
            className="user"
          >
            <img src={userPic} alt="user" />
          </div>
        ))}
        <div style={{ zIndex: amount + 1, left: -15 }} className="user">
          +{amount - 3}
        </div>
      </Flex>
    );
  }
  return (
    <Flex
      onClick={onClick}
      className={cn({
        pointer: !!onClick,
      })}
    >
      {[...Array(amount)].map((_user: number, i) => (
        <div
          key={i.toString(36)}
          style={{ zIndex: i + 1, left: i === 0 ? 0 : -i * 5 }}
          className="user"
        >
          <img src={userPic} alt="user" />
        </div>
      ))}
    </Flex>
  );
};
