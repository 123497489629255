import axios from "axios";
import { api } from "@/shared/api";
import {
  CreateGuestUserParams,
  InvitationVerifyParams,
  LoginResponse,
  Permission,
  TAuthCode,
  TCredentials,
  TRegisterCredentials,
} from "./types";
import { TResponse } from "@/vite-env";

export const loginResource = async (
  credentials: TCredentials,
  abortController?: AbortController,
): TResponse<LoginResponse> =>
  axios.post(
    "/api/auth/login",
    {
      ...credentials,
      email: credentials.email.toLowerCase(),
    },
    {
      signal: abortController?.signal,
    },
  );

export const createUserResource = (
  credentials: TRegisterCredentials,
  abortController?: AbortController,
) =>
  axios.post(
    "/api/auth/add_user",
    {
      ...credentials,
      email: credentials.email.toLowerCase(),
    },
    {
      signal: abortController?.signal,
    },
  );

export const createUserGoogleResource = (credentials: TAuthCode, controller?: AbortController) =>
  axios.post("/api/auth/add_user/google", credentials, { signal: controller?.signal });

export const loginUserGoogleResource = (credentials: TAuthCode, controller?: AbortController) =>
  axios.post("/api/auth/login/google", credentials, { signal: controller?.signal });

export const deleteUserResource = () => api.delete("/auth");

export const verifyEmailResource = (key: string) =>
  api({
    method: "PATCH",
    url: "/auth/verify",
    params: {
      verification_code: key,
    },
  });

export const linkProfileGoogleResource = (token: string) =>
  api.post("/auth/link_profile/google", {
    access_token: token,
  });

export const verifyInvitationResource = (params: InvitationVerifyParams) =>
  api.post("/auth/add_user/invitation", {
    ...params,
    email: params.email.toLowerCase(),
  });

export const initResetPasswordResource = (email: string) =>
  api.post("/settings/reset_password/init", {
    email: email.toLowerCase(),
  });

export const validateResetPasswordResource = (email: string, code: string) =>
  api.post(`/settings/reset_password/validate?code=${code}`, {
    email: email.toLowerCase(),
  });

export const resetPasswordResource = (password: string) =>
  api.put("/settings/reset_password/update", { password });

export const refreshTokenResource = () => axios.post("/auth/refresh");

export const createGuestUserResource = (params: CreateGuestUserParams) =>
  api.post("/auth/guest_sign_up", {
    ...params,
    email: params.email.toLowerCase(),
  });

export const getUserPermissionResource = (controller?: AbortController): TResponse<Permission> =>
  api.get("/auth/permissions", { signal: controller?.signal });

export const logoutResource = () => api.delete("/auth/logout");

export const resendVerifyResource = (email: string, controller?: AbortController) =>
  api.post("/auth/verify/resend", { email: email.toLowerCase() }, { signal: controller?.signal });
