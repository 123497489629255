import { api } from "@/shared/api";
import {
  HistorySubscription,
  MoveFromTrialParams,
  PaymentCardDetails,
  PaymentCredentials,
  Promo,
  SetupParams,
  SetupResult,
  SubscribeCustomer,
  Subscription,
  SubscriptionSuccess,
  UpdateTrialPaymentDetailsParams,
  UserSubscription,
} from "./types.ts";
import { TResponse } from "@/vite-env";

export const getPossibleSubscriptionsResource = (): TResponse<Subscription> =>
  api.get("/payments/subscriptions");

export const subscribeCustomerResource = (
  params: SubscribeCustomer,
): TResponse<SubscriptionSuccess> =>
  api.post("/payments/subscription", {
    blooper_subscription_id: params.productId,
    interval: params.interval,
    promocode: params.promoCode,
  });

export const subscribeTrialCustomerResource = (): TResponse<unknown> =>
  api.post("/payments/subscription/trial");

export const getCustomerSubscriptionResource = (
  controller?: AbortController,
): TResponse<UserSubscription> => api.get("/payments/subscription", { signal: controller?.signal });

export const checkPromoCodeResource = (promo: string, subscriptionId: string): TResponse<Promo> =>
  api.get("/payments/subscription/promo", {
    params: {
      promocode: promo,
      blooper_subscription_id: subscriptionId,
    },
  });

export const getPaymentCredentialsResource = (): TResponse<PaymentCredentials> =>
  api.get("/payments/subscription/payment_credentials");

export const applyPromoCodeResource = (code: string) =>
  api.patch(
    "/payments/subscription/apply_promocode",
    {},
    {
      params: {
        promocode: code,
      },
    },
  );

export const createSetupIntentResource = (params: SetupParams): TResponse<SetupResult> =>
  api.post("/payments/subscription/create_setup_intent", params);

export const getSubscriptionPaymentHistoryResource = (): TResponse<HistorySubscription[]> =>
  api.get("/payments/subscription/payment_history");

export const cancelSubscriptionResource = () => api.delete("/payments/subscription/");

export const renewSubscriptionResource = () => api.patch("/payments/subscription/renew/");

export const getPaymentDetails = (): TResponse<PaymentCardDetails> =>
  api.get("/payments/subscription/payment_details/");

export const updatePaymentDetails = (id: string) =>
  api.patch("/payments/subscription/payment_details/", { payment_method_id: id });

export const moveFromTrialResource = (params: MoveFromTrialParams): TResponse<unknown> =>
  api.post("/payments/subscription/from_trial", params);

export const updateTrialPaymentDetails = (
  params: UpdateTrialPaymentDetailsParams,
): TResponse<unknown> =>
  api.patch("/payments/subscription/payment_details", {
    stripe_session_id: params.sessionId,
  });
