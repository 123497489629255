import { REG_EXP } from "../const/reg-exp";

export const validatePasswordReg = (password?: string) => ({
  // lowerCaseLetter: REG_EXP.lowerCaseLetter.test(password ?? ""),
  upperCaseLetter: REG_EXP.upperCaseLetter.test(password ?? ""),
  anySymbol: password ? password.length > 0 : false,
  // oneNumber: REG_EXP.oneNumber.test(password ?? ""),
  eightCharacters: password ? password.length >= 8 : false,
});

export const validatePassword = (password?: string) => ({
  lowerCaseLetter: REG_EXP.lowerCaseLetter.test(password ?? ""),
  upperCaseLetter: REG_EXP.upperCaseLetter.test(password ?? ""),
  specialSymbol: REG_EXP.specialSymbol.test(password ?? ""),
  oneNumber: REG_EXP.oneNumber.test(password ?? ""),
  eightCharacters: password ? password.length >= 8 : false,
});
