import { UploadFile } from "antd";

export const getFileType = (file: UploadFile<any>) => {
	if (file.type === "application/pdf") {
		return "pdf";
	}

	if (file.type === "text/plain") {
		return "txt";
	}

	if (file.type === "application/msword") {
		return "doc";
	}

	if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
		return "docx";
	}

	return "doc";
};
