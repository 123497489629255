import { reatomAsync, withDataAtom, withErrorAtom, withStatusesAtom } from "@reatom/async";
import { atom } from "@reatom/core";
import { callErrorAction } from "@/entities/notification";

import {
  removeGuestResource,
  ChangePermission,
  changePermissionResource,
} from "@/shared/api/guests";
import { getProjectResource } from "@/shared/api/project";
import { GetGuestParams, guestMapper } from "../lib";

export const showGuestInvitationToProjectAtom = atom(false, "showGuestInvitationToProjectAtom");

export const getGuestsAction = reatomAsync(async (_, params: GetGuestParams) => {
  const { data: project } = await getProjectResource(params.projectKey);

  return {
    sharedAccess: project.shared_access,
  };
}).pipe(
  withDataAtom([], (_ctx, { sharedAccess }) => {
    return sharedAccess.map((access) => guestMapper(access));
  }),
  withErrorAtom(callErrorAction),
  withStatusesAtom(),
);

export const removeGuestAction = reatomAsync((ctx, { projectKey, userEmail }) => {
  const guests = ctx.get(getGuestsAction.dataAtom);
  const newGuests = guests.filter((guest) => guest.email !== userEmail);
  getGuestsAction.dataAtom(ctx, newGuests);

  return removeGuestResource(projectKey, userEmail);
}).pipe(withErrorAtom(callErrorAction));

export const changePermissionAction = reatomAsync((ctx, params: ChangePermission) => {
  const guests = ctx.get(getGuestsAction.dataAtom);

  const newGuests = guests.map((guest) =>
    guest.email === params.user_email
      ? { ...guest, permissionGroup: params.permission_group_name }
      : guest,
  );

  getGuestsAction.dataAtom(ctx, newGuests);
  return changePermissionResource(params);
}).pipe(withErrorAtom(callErrorAction));
