import { drawDOM, pdf } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import { useAction, useAtom } from "@reatom/npm-react";
import { captureException } from "@sentry/react";
import { MouseEvent, useCallback, useState } from "react";
import { generateStoryboardShotResource, regenerateImageResource } from "@/shared/api/storyboard";
import { useOpenModal } from "shared/hooks";
import {
  getStoryboardAction,
  updateGenerateImageAction,
  useStoryboardType,
} from "@/entities/storyboard";
import { Generate, GenerateOrRegenerate } from "../../../../../features/storyboard-view/utils";

type Params = {
  projectKey: string;
};

export const useBehavior = ({ projectKey }: Params) => {
  const { storyboardType, setStoryboardType } = useStoryboardType();
  const [isOpenExport, toggleOpenExport] = useOpenModal();
  const [isOpenRegenerate, toggleRegenerate] = useOpenModal();
  const [isSaving, setIsSaving] = useState(false);
  const [getStatuses] = useAtom(getStoryboardAction.statusesAtom);

  const updateGenerateImage = useAction(updateGenerateImageAction);
  const [storyboardList] = useAtom(getStoryboardAction.dataAtom);
  const shots: Generate[] = storyboardList
    .map((scene) =>
      scene.shots.map((shot) => ({
        shotId: shot.id,
        isRegenerate: !!shot.image,
        sceneId: shot.id,
      })),
    )
    .flat();

  const onRegenerate = () => {
    toggleRegenerate(true);
  };

  const toPDF = async () => {
    setIsSaving(true);
    const table = document.getElementById("virtual-grid-storyboard");
    if (table) {
      const temporaryCards = table.querySelectorAll(".scene-shot-grid");
      const cards = table.getElementsByClassName("scene-shot-grid");
      const blocks = table.getElementsByClassName("scene-shot-grid__line");
      const actions = table.getElementsByClassName("scene-shot-grid__line__actions");
      const buttonsDetails = table.getElementsByClassName("scene-shot-grid__details");
      const buttonsEdit = table.getElementsByClassName("storyboard-edit-button");
      const selectors = table.getElementsByClassName("map-selector");
      const errors = table.getElementsByClassName("error-card");

      for (let i = 0; i < errors.length; i++) {
        // @ts-ignore
        errors[i].style.display = "none";
      }

      for (let i = 0; i < selectors.length; i++) {
        // @ts-ignore
        selectors[i].style.display = "none";
      }

      for (let i = 0; i < buttonsDetails.length; i++) {
        // @ts-ignore
        buttonsDetails[i].style.display = "none";
      }

      for (let i = 0; i < buttonsEdit.length; i++) {
        // @ts-ignore
        buttonsEdit[i].style.display = "none";
      }

      for (let i = 0; i < actions.length; i++) {
        // @ts-ignore
        actions[i].style.display = "none";
      }

      for (let i = 0; i < blocks.length; i++) {
        // @ts-ignore
        blocks[i].style.height = "auto";
      }

      let cardMaxHeight = 0;
      temporaryCards.forEach((card) => {
        const maxHeight = card.clientHeight + 2;
        if (maxHeight > cardMaxHeight) {
          cardMaxHeight = maxHeight;
        }
      });

      for (let i = 0; i < cards.length; i++) {
        // @ts-ignore
        cards[i].style.height = `${cardMaxHeight}px`;
      }

      const height = cardMaxHeight + 32 + 72 + 10;
      const paperSize = [1000, height];
      const options = { paperSize, landscape: false };
      const group = await drawDOM(table, {
        // @ts-ignore
        paperSize: options.paperSize,
        landscape: options.landscape,
        repeatHeaders: true,
        scale: {
          x: 1,
          y: 1,
        },
      });

      pdf
        // @ts-ignore
        .exportPDF(group, options)
        .then((data) => {
          saveAs(data, `${projectKey}.pdf`);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const callFunctionOnEachArrayElementInBatches = async (
    array: Generate[],
    callback: (projectId: string) => (shot: Generate) => Promise<GenerateOrRegenerate>,
  ) => {
    const notGeneratedShots = array.filter((shot) => !shot.isRegenerate);
    notGeneratedShots.forEach((shot) => {
      updateGenerateImage({ shotId: shot.shotId, loading: true, image: null, error: false });
    });

    while (notGeneratedShots.length > 0) {
      const chunkSize = 5;
      const batch = notGeneratedShots.splice(0, chunkSize);

      await Promise.allSettled(batch.map(callback(projectKey)));
    }
  };

  const generateOrRegenerateImage =
    (projectKey: string) =>
    async (shot: Generate): Promise<GenerateOrRegenerate> => {
      let image = null;
      let error = false;

      try {
        if (shot.isRegenerate) {
          const shotInfo = await regenerateImageResource({
            shotId: shot.shotId,
            sceneId: shot.sceneId,
            projectKey,
          });
          image = shotInfo.data.image_url ?? "";

          return {
            image,
            shotId: shot.shotId,
            sceneId: shot.sceneId,
          };
        } else {
          const shotInfo = await generateStoryboardShotResource({
            shotId: shot.shotId,
            sceneId: shot.sceneId,
            projectKey,
          });
          image = shotInfo.data.image_url ?? "";

          return {
            image,
            shotId: shot.shotId,
            sceneId: shot.sceneId,
          };
        }
      } catch (e) {
        error = true;
        captureException(e, {
          tags: {
            feature: "generate or regenerate image",
          },
          level: "log",
        });
        return {
          image: "",
          shotId: shot.shotId,
          sceneId: shot.sceneId,
        };
      } finally {
        updateGenerateImage({ shotId: shot.shotId, loading: false, image, error });
      }
    };

  const onGenerateImages = useCallback((shots: Generate[]) => {
    return async (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      await callFunctionOnEachArrayElementInBatches(shots, generateOrRegenerateImage);
    };
  }, []);

  const isRegenerate = !!storyboardList
    .map((scene) => scene.shots.filter((shot) => !!shot.image))
    .flat().length;

  const disabled = getStatuses.isPending;

  return {
    storyboardType,
    setStoryboardType,
    isOpenExport,
    toggleOpenExport,
    isOpenRegenerate,
    toggleRegenerate,
    isSaving,
    toPDF,
    onGenerateImages,
    shots,
    onRegenerate,
    isRegenerate,
    disabled,
  };
};
