import { useAction, useAtom } from "@reatom/npm-react";
import { useInterval, useSize } from "ahooks";
import { Flex } from "antd";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { Box } from "konva/lib/shapes/Transformer";
import { FC, useEffect, useRef, useState } from "react";
import { Layer, Stage, Transformer } from "react-konva";

import { useParams } from "react-router-dom";
import {
  IMAGE_PADDING,
  MAX_HEIGHT_MOODBOARD,
  MAX_WIDTH_IMAGE,
  MIN_WIDTH_IMAGE,
  REFRESH_TIME_SAVE,
  toCanvasImage,
} from "@/features/full-moodboard";
import { CustomKonvaImage } from "@/features/full-moodboard/ui/CustomKonvaImage";
import { DRAGGABLE_IMAGE_ID } from "@/entities/inspiration-board";
import {
  dropImageFromSidebarsAction,
  getMoodboardAction,
  toggleMoodboardButtonActiveAction,
} from "@/entities/moodboard";

import { addMoodboardsImagesCoordinates } from "@/shared/api/moodboard";
import { COLOR_NAMES } from "shared/const/color-names";
import { ShadowButtons } from "./ShadowButtons";

export const KonvaMoodboard: FC = () => {
  const params = useParams();
  const dropImageFromSidebars = useAction(dropImageFromSidebarsAction);
  const [dropId, setDropId] = useState<null | string>(null);

  const [moodBoardList] = useAtom(getMoodboardAction.dataAtom);
  const transformerRef = useRef<null | Konva.Transformer>(null);
  const [bounceLocation] = useState({
    x: 0,
    y: 0,
  });
  const ref = useRef(null);
  const size = useSize(ref);
  const stageRef = useRef<null | Konva.Stage>(null);
  const layerRef = useRef<null | Konva.Layer>(null);

  const toggleButtonActive = useAction(toggleMoodboardButtonActiveAction);
  const [hoveredId, setHoveredId] = useState<null | string>(null);
  const [isSelected, setSelected] = useState(false);

  const checkDeselect = (e: KonvaEventObject<MouseEvent>) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      if (hoveredId) {
        toggleButtonActive(hoveredId, false);
      }
      setHoveredId(null);
      setSelected(false);
      transformerRef.current?.nodes([]);
    }
  };

  useEffect(() => {
    transformerRef.current?.nodes([]);
  }, [moodBoardList.length]);

  useEffect(() => {
    if (stageRef.current) {
      const container = stageRef.current?.container();

      container.addEventListener("dragover", function (e) {
        e.preventDefault(); // !important
      });

      container.addEventListener("drop", (e) => {
        e.preventDefault();

        stageRef.current?.setPointersPositions(e);

        const id = e.dataTransfer?.getData(DRAGGABLE_IMAGE_ID);
        setDropId(id ?? null);
      });
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      if (dropId && params.id) {
        await dropImageFromSidebars({
          dropId,
          id: params.id,
          position: stageRef.current?.getPointerPosition(),
        });
      }
    };

    init();
  }, [dropId]);

  useInterval(async () => {
    if (params.id) {
      await addMoodboardsImagesCoordinates(
        params.id,
        moodBoardList.map((el) => ({ ...el.coordinates, id: el.id })),
      );
    }
  }, REFRESH_TIME_SAVE);

  return (
    <Flex
      style={{
        overflow: "auto",
        position: "relative",
        height: MAX_HEIGHT_MOODBOARD,
      }}
      id="moodboard"
      ref={ref}
      className="full-width"
    >
      <ShadowButtons />
      <Stage
        width={size?.width}
        height={MAX_HEIGHT_MOODBOARD}
        ref={stageRef}
        contaoner="moodboard"
        onClick={checkDeselect}
      >
        <Layer ref={layerRef}>
          {moodBoardList.map((el) => (
            <CustomKonvaImage
              key={el.id}
              transformerRef={transformerRef}
              x={el.coordinates.x}
              y={el.coordinates.y}
              width={el.coordinates.w}
              height={el.coordinates.h}
              zIndex={el.coordinates.minW}
              isSelected={isSelected}
              isHovered={el.id === hoveredId}
              onSelect={setSelected}
              onHover={(e, id) => {
                if (e.target.parent?.children) {
                  transformerRef.current?.nodes(e.target.parent?.children);
                  toggleButtonActive(id);
                  setHoveredId(id);
                }
              }}
              image={toCanvasImage(el.url, el.coordinates.x, el.coordinates.y)}
              parentHeight={MAX_HEIGHT_MOODBOARD}
              parentWidth={size?.width as number}
              id={el.id}
            />
          ))}
          <Transformer
            ref={transformerRef}
            x={bounceLocation.x}
            y={bounceLocation.y}
            padding={IMAGE_PADDING}
            keepRatio
            anchorStroke={COLOR_NAMES.BackgroundWhite}
            anchorFill={COLOR_NAMES.TextBrand}
            borderStroke={COLOR_NAMES.BackgroundWhite}
            boundBoxFunc={(oldBoundBox: Box, newBoundBox: Box) => {
              if (Math.abs(newBoundBox.width) < MIN_WIDTH_IMAGE) {
                return oldBoundBox;
              }

              if (Math.abs(newBoundBox.width) > MAX_WIDTH_IMAGE) {
                return oldBoundBox;
              }

              return newBoundBox;
            }}
            enabledAnchors={["top-left", "top-right", "bottom-left", "bottom-right"]}
            rotateEnabled={false}
            flipEnabled={false}
          />
        </Layer>
      </Stage>
    </Flex>
  );
};
