import eth from "assets/partnership/eth.svg";
import ethBlack from "assets/partnership/eth_black.svg";
import ewor from "assets/partnership/ewor.svg";
import eworBlack from "assets/partnership/ewor_black.svg";
import nvidia from "assets/partnership/nvidia.svg";
import nvidiaBlack from "assets/partnership/nvidia_black.svg";
import opium from "assets/partnership/opium.svg";
import opiumBlack from "assets/partnership/opium_black.svg";
import quokka from "assets/partnership/quokka.svg";
import quokkaBlack from "assets/partnership/quokka_black.svg";

export const PARTNERSHIP = [
  {
    img: ewor,
    imgBlack: eworBlack,
    title: "Investors",
    description: "Supporting Blooper as strategic sponsors, helping us scale and innovate faster",
    link: "https://www.ewor.com/",
    isPartner: true,
  },
  {
    img: eth,
    imgBlack: ethBlack,
    title: "Member of ETH AI Center",
    description:
      "Proud to be part of ETH’s network, connecting with leading AI researchers and innovator",
    link: "https://ai.ethz.ch/",
    isPartner: true,
  },
  {
    img: nvidia,
    imgBlack: nvidiaBlack,
    title: "Part of NVIDIA Inception program",
    description:
      "Gaining access to AI expertise and resources to enhance Blooper’s technological development.",
    link: "https://www.nvidia.com/en-us/startups/",
    isPartner: true,
  },
  {
    img: quokka,
    imgBlack: quokkaBlack,
    title: "Studio film production",
    description:
      "Collaborating on film projects to streamline pre-production workflows with Blooper.",
    link: "https://quokkafilms.co.uk/",
    isPartner: false
  },
  {
    img: opium,
    imgBlack: opiumBlack,
    title: "Studio film production",
    description:
      "Partnering to optimize creative processes and bring innovative tools to production teams.",
    link: "https://opium.rs/",
    isPartner: false
  },
];
