import redo from "assets/shared/redo.svg";
import { IconButtonWrapper } from "shared/ui";

export const RedoIcon = () => {
  return (
    <IconButtonWrapper>
      <img style={{ width: 16, height: 16 }} src={redo} alt="redo" />
    </IconButtonWrapper>
  );
};
