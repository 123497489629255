import { Viewer } from "../index";

export const CREDENTIAL_KEY = "BLOOPER__CREDENTIAL_KEY";
export const USER_SUBSCRIPTION_KEY = "USER_SUBSCRIPTION_KEY";
export const USER_PERMISSION_KEY = "USER_PERMISSION_KEY";

export const AUTH_USER_VIEWER: Omit<Viewer, "isGuest" | "email"> = {
  isAuth: true,
  isSubscriptionAuth: false,
};

export const NOT_AUTH_USER_VIEWER: Omit<Viewer, "isGuest" | "email"> = {
  isAuth: false,
  isSubscriptionAuth: false,
};

export const AUTH_SUBSCRIPTION_USER_VIEWER: Omit<Viewer, "isGuest" | "email"> = {
  isAuth: false,
  isSubscriptionAuth: true,
};

export const AUTH_UPGRADE_SUBSCRIPTION_USER_VIEWER: Omit<Viewer, "isGuest" | "email"> = {
  isAuth: true,
  isSubscriptionAuth: true,
};

export const NOT_AUTH_VIEWER: Viewer = {
  isAuth: false,
  isSubscriptionAuth: false,
  isGuest: false,
  email: "",
};
