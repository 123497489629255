import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";

import collobaration from "assets/product/collobaration.svg";
import location from "assets/product/location.svg";
import moodboard from "assets/product/moodboard.svg";
import pitch from "assets/product/pitch.svg";
import script from "assets/product/script.svg";
import shotBig from "assets/product/shot-big.svg";
import bgMobile from "assets/product/shot-mobile.svg";
import bg from "assets/product/shot.svg";
import storyboard from "assets/product/storyboard.svg";
import { TrialButton } from "shared/ui";
import { ProductAction } from "./ProductAction.tsx";

import "./ProductShot.scss";

export const ProductShot: FC = () => {
  const { isTablet, isLaptopM, isMobileXL, isFullHd } = useMediaQueries();

  return (
    <Flex className="product-shot" align="center" justify="center">
      <img
        className="product-shot__bg"
        src={isMobileXL ? bgMobile : isFullHd ? bg : shotBig}
        alt="bg"
      />
      <Flex vertical className="product-shot__content gap-m">
        <Typography.Text
          className={cn(
            TEXT_CLASSNAMES.LPHeadlineH3,
            COLOR_CLASSNAMES.TextWhitePrimary,
            "text-center",
            {
              [TEXT_CLASSNAMES.LPHeadlineH4]: isLaptopM,
              [TEXT_CLASSNAMES.LPHeadlineH5]: isTablet,
              [TEXT_CLASSNAMES.HeadlineH2]: isMobileXL,
              "text-left": isMobileXL,
            },
          )}
        >
          Transforms pre-production into
          {!isMobileXL && <br />}a seamless, stress-free process
        </Typography.Text>
        <Flex
          justify="center"
          className={cn("product-shot__actions gap-m", {
            "gap-s": isTablet,
          })}
          wrap="wrap"
        >
          <ProductAction
            img={script}
            title="Break Down Scripts"
            description="Automatically get lists of shots, characters, props and more just from your script. "
            link="/use-case-script"
          />
          <ProductAction
            img={storyboard}
            title="Instant Storyboards"
            description="Generate grammatically correct storyboards with all rules of video composition."
            link="/use-case-storyboard"
          />
          <ProductAction
            img={location}
            title="Automatic location scouting"
            description="Discover perfect venues in seconds in any city exactly for your idea."
            link="/use-case-storyboard"
          />
          <ProductAction
            img={collobaration}
            title="Collect feedback"
            description="Keep your internal communication aligned and get all the external feedback in a centralized way."
            link="/"
          />
          <ProductAction
            img={moodboard}
            title="Moodboard"
            description="Precise suggestions for your project with references for the treatment."
            link="/use-case-storyboard"
          />
          <ProductAction
            img={pitch}
            title="Pitch deck"
            description="All your materials in one place—easily create a professional, ready-to-share presentation for ads and campaigns"
            link="/use-case-video?anchor=pitch"
          />
        </Flex>
        <TrialButton />
      </Flex>
    </Flex>
  );
};
