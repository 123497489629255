import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAccountWithAvatar } from "@/entities/account";
import { sessionAuthUpgradeSubscriptionAction, viewerModel } from "@/entities/viewer";
import { GRAYSCALE_TOKEN } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";

export const TrialBanner: FC = () => {
  const navigate = useNavigate();
  const sessionAuthUpgradeSubscription = useAction(sessionAuthUpgradeSubscriptionAction);
  const [subscription] = useAtom(viewerModel.viewerSubscription.dataAtom);
  const { account } = useAccountWithAvatar();

  const onRedirect = () => {
    sessionAuthUpgradeSubscription({ isGuest: false, email: account.email });
    navigate("/plan");
  };

  const isTrialExpired =
    subscription?.message === "No subscription found" ||
    (subscription?.message === "User has no subscription" &&
      subscription?.trial_status === "past_trial");

  // const isExpired = subscription?.trial_status === "no_trial";

  if (isTrialExpired) {
    return (
      <Modal open={true} closeIcon={null} footer={[]}>
        <Flex vertical className="text-left gap-s">
          <Flex vertical>
            <Typography.Text className={TEXT_CLASSNAMES.Title50}>
              Your trail period has expired
            </Typography.Text>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.Body20, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
            >
              Renew now to continue enjoying uninterrupted access to all features.
            </Typography.Text>
          </Flex>
          <Flex justify="flex-start">
            <Button size="large" type="primary" onClick={onRedirect}>
              Renew Subscription
            </Button>
          </Flex>
        </Flex>
      </Modal>
    );
  }

  // if (isExpired) {
  //   return (
  //     <Modal open={true} closeIcon={null} footer={[]}>
  //       <Flex vertical className="text-left gap-s">
  //         <Flex vertical>
  //           <Typography.Text className={TEXT_CLASSNAMES.Title50}>
  //             Your subscription has expired
  //           </Typography.Text>
  //           <Typography.Text
  //             className={cn(TEXT_CLASSNAMES.Body20, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
  //           >
  //             Renew now to continue enjoying uninterrupted access to all features.
  //           </Typography.Text>
  //         </Flex>
  //         <Flex justify="flex-start">
  //           <Button size="large" type="primary" onClick={onRedirect}>
  //             Renew Subscription
  //           </Button>
  //         </Flex>
  //       </Flex>
  //     </Modal>
  //   );
  // }

  return null;
};
