import { useHover } from "ahooks";
import { Avatar, Dropdown, Flex, Skeleton, Typography } from "antd";
import emailIcon from "assets/navigation-top-bar/email.svg";
import exitIcon from "assets/navigation-top-bar/exit.svg";
import logo from "assets/navigation-top-bar/logo.svg";
import profileSettingsIcon from "assets/navigation-top-bar/profile-settings.svg";
import projectsIcon from "assets/navigation-top-bar/projects.svg";
import settingsIcon from "assets/navigation-top-bar/settings.svg";
import teamIcon from "assets/navigation-top-bar/team.svg";
import { FC, PropsWithChildren, useRef } from "react";

import { useNavigate } from "react-router-dom";
import chevron from "assets/shared/chevron_down.svg";
import { GRAYSCALE_TOKEN } from "shared/const/color-names.ts";
import { NOT_RELEASE, RELEASE } from "shared/const/release.ts";
import { ROUTES } from "shared/const/routes.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";

import "./NavigationTopBar.scss";

type Props = {
  avatarLoading: boolean;
  onFeedback: () => void;
  onSettings: () => void;
  onLogout: () => void;
  avatar: {
    role: string;
    username: string;
    image: string | null;
    placeholder: string;
  };
};

export const NavigationTopBar: FC<PropsWithChildren<Props>> = ({
  children,
  onFeedback,
  avatar,
  onSettings,
  avatarLoading,
  onLogout,
}) => {
  const navigate = useNavigate();
  const logoRef = useRef(null);
  const isLogoHover = useHover(logoRef);
  const accountRef = useRef(null);
  const isAccountHover = useHover(accountRef);

  const onProjects = () => {
    navigate(ROUTES.PROJECTS.fullPath);
  };

  const onTeam = () => {
    navigate(ROUTES.TEAM_MANAGE.fullPath);
  };

  const onProfileSettings = () => {
    navigate(ROUTES.SETTINGS_PROFILE.fullPath);
  };

  const ProjectsItem = {
    isRelease: RELEASE,
    key: "Projects",
    label: (
      <Flex onClick={onProjects} align="center" gap={8} role="button">
        <img src={projectsIcon} alt="projects" />
        <Typography.Text className={TEXT_CLASSNAMES.Body20}>Projects</Typography.Text>
      </Flex>
    ),
  };

  const TeamItem = {
    isRelease: NOT_RELEASE,
    key: "Team",
    label: (
      <Flex onClick={onTeam} align="center" gap={8} role="button">
        <img src={teamIcon} alt="team" />
        <Typography.Text className={TEXT_CLASSNAMES.Body20}>Team</Typography.Text>
      </Flex>
    ),
  };

  const ProfileSettingsItem = {
    isRelease: RELEASE,
    key: "Profile settings",
    label: (
      <Flex onClick={onProfileSettings} align="center" gap={8} role="button">
        <img src={profileSettingsIcon} alt="projects" />
        <Typography.Text className={TEXT_CLASSNAMES.Body20}>Profile settings</Typography.Text>
      </Flex>
    ),
  };

  const AccountSettingsItem = {
    isRelease: NOT_RELEASE,
    key: "General settings",
    label: (
      <Flex onClick={onSettings} align="center" gap={8} role="button">
        <img src={settingsIcon} alt="projects" />
        <Typography.Text className={TEXT_CLASSNAMES.Body20}>General settings</Typography.Text>
      </Flex>
    ),
  };

  const ExitItem = {
    isRelease: RELEASE,
    key: "Exit",
    label: (
      <Flex onClick={onLogout} align="center" gap={8} role="button">
        <img src={exitIcon} alt="projects" />
        <Typography.Text className={TEXT_CLASSNAMES.Body20}>Exit</Typography.Text>
      </Flex>
    ),
  };

  const mainItems = [ProjectsItem, TeamItem]
    .filter((item) => (RELEASE ? item.isRelease : true))
    .map((el) => ({
      key: el.key,
      label: el.label,
    }));

  const accountItems = [ProfileSettingsItem, AccountSettingsItem, ExitItem]
    .filter((item) => (RELEASE ? item.isRelease : true))
    .map((el) => ({
      key: el.key,
      label: el.label,
    }));

  return (
    <Flex align="center" justify="space-between" className="navigation-top-bar full-width">
      <Dropdown menu={{ items: mainItems }}>
        <Flex style={{ paddingRight: 16 }} ref={logoRef} gap={4} align="center">
          <Flex className="pointer" role="button" onClick={onProjects}>
            <img className="navigation-top-bar__logo" src={logo} alt="logo" />
          </Flex>
          <img
            className="cubic-animation"
            style={{ rotate: isLogoHover ? "180deg" : "0deg" }}
            src={chevron}
            alt="chevron"
          />
        </Flex>
      </Dropdown>
      <Flex className="full-width flex-1 full-height">{children}</Flex>
      <Flex>
        <Flex
          onClick={onFeedback}
          role="button"
          className="navigation-top-bar--item pointer"
          justify="center"
          align="center"
        >
          <img src={emailIcon} alt="feedback" />
        </Flex>
        <Flex
          role="button"
          onClick={onSettings}
          className="navigation-top-bar--item pointer"
          justify="center"
          align="center"
        >
          <img src={settingsIcon} alt="feedback" />
        </Flex>
        {avatarLoading ? (
          <Flex align="center" gap={8}>
            <Skeleton.Avatar active />
            <Skeleton.Node active style={{ width: 100, height: 20 }} />
          </Flex>
        ) : (
          <Dropdown menu={{ items: accountItems }}>
            <Flex ref={accountRef} gap={4} align="center">
              <Flex
                ref={accountRef}
                gap={8}
                align="center"
                className="navigation-top-bar--item pointer"
              >
                <Avatar
                  style={{
                    backgroundColor: GRAYSCALE_TOKEN.SURFACE.DEFAULT_SUBTLE.color,
                    color: GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.color,
                    verticalAlign: "middle",
                  }}
                  size="small"
                >
                  {avatar.placeholder}
                </Avatar>
                <Flex vertical gap={2}>
                  <Typography.Text className="navigation-top-bar--username">
                    {avatar.username}
                  </Typography.Text>
                  <Typography.Text className="navigation-top-bar--role">
                    {avatar.role}
                  </Typography.Text>
                </Flex>
              </Flex>
              <img
                className="cubic-animation"
                style={{ rotate: isAccountHover ? "180deg" : "0deg" }}
                src={chevron}
                alt="chevron"
              />
            </Flex>
          </Dropdown>
        )}
      </Flex>
    </Flex>
  );
};
