import { FC } from "react";
import { Avatar, Flex, Typography } from "antd";
import { toCharacter } from "@/shared/methods/to-character";

import { useShotStoryboard } from "@/entities/storyboard";
import { SceneShotCharactersProps } from "../model";
import { COLOR_NAMES } from "shared/const/color-names";

export const SceneShotCharacters: FC<SceneShotCharactersProps> = ({ characters }) => {
  const { onCharacterRedirect } = useShotStoryboard();

  return (
    <Flex vertical className="scene-shot-grid__line scene-shot-grid__line__actions">
      {!Object.values(characters ?? {}).length && <Typography.Text>-</Typography.Text>}
      <Flex className="gap-xxs">
        {Object.values(characters ?? {}).map((character, index) => (
          <Avatar
            style={{
              borderColor: COLOR_NAMES.MetalGray300,
              backgroundColor: COLOR_NAMES.MetalGray50,
            }}
            key={index.toString(36)}
            size={46}
            icon={<img src={toCharacter(character, "2")} alt="character" />}
            onClick={() => onCharacterRedirect(character)}
          />
        ))}
      </Flex>
    </Flex>
  );
};