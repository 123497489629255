import { useAtom } from "@reatom/npm-react";
import { Flex, Form, Input, Modal, Typography } from "antd";
import { FC } from "react";

import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { IOpenModal } from "shared/hooks";
import { DisableSubmitButton } from "shared/ui";
import { projectsListAtom } from "../../index";

interface IRenameProjectModal extends IOpenModal {
  onRename: (oldName: string, newName: string) => void;
  projectName: string;
}

type TFiled = {
  name: string;
};

export const RenameProjectModal: FC<IRenameProjectModal> = ({
  isOpen,
  toggleModal,
  onRename,
  projectName,
}) => {
  const [form] = Form.useForm();
  const [projects] = useAtom(projectsListAtom);

  const onFinish = (data: TFiled) => {
    onRename(projectName, data.name);
  };

  return (
    <Modal footer={[]} open={isOpen} onCancel={() => toggleModal(false)}>
      <Form onFinish={onFinish} form={form}>
        <Flex vertical className="gap-xs">
          <Typography.Text className={TEXT_CLASSNAMES.Title50}>Rename a project</Typography.Text>
          <Form.Item<TFiled>
            name="name"
            rules={[
              { required: true, message: "Please input your project name" },
              {
                message: "This name is already in use",
                validator: (_, value) => {
                  const projectNames = projects.map((el) => el.projectName);
                  const newProjectName = value?.trim();

                  if (!projectNames.includes(newProjectName)) {
                    return Promise.resolve();
                  } else {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject();
                  }
                },
              },
            ]}
          >
            <Input defaultValue={projectName} placeholder="Type here" />
          </Form.Item>
          <DisableSubmitButton type="primary" form={form}>
            <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Rename</Typography.Text>
          </DisableSubmitButton>
        </Flex>
      </Form>
    </Modal>
  );
};
