import { Button, Flex, Form, Input, message, Modal, Radio, Tooltip, Typography } from "antd";
import axios from "axios";
import cn from "classnames";
import { FC, useState } from "react";
import { trackGoogleAnalyticsEvent } from "@/shared/methods/track-analytics.ts";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { IOpenModal } from "shared/hooks";
import { useAccountWithAvatar } from "@/entities/account";

import { FeedbackGrade, FEEDBACK_GRADE } from "../lib";
import { Wave } from "./Wave";

interface IFeedbackForm {
  feedback_message: string;
  feedback_name: string;
  feedback_grade: FeedbackGrade;
}

export const FeedbackModal: FC<IOpenModal> = ({ isOpen, toggleModal }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const { account } = useAccountWithAvatar();
  const [form] = Form.useForm();
  const feedbackGrade = Form.useWatch("feedback_grade", form);
  const onFinish = async (values: IFeedbackForm) => {
    try {
      setIsLoading(true);
      trackGoogleAnalyticsEvent("feedback", "feedback", "submit", {
        ...values,
        feedback_email: account.email?.replace("@", "_"),
      });

      const data = {
        service_id: "service_fftdtow",
        template_id: "template_lae8dge",
        user_id: "AQK3iUSsogwlK2dMZ",
        template_params: {
          email: account.email,
          message: `${values.feedback_grade} - ${values.feedback_message}`,
        },
      };

      await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      messageApi.open({
        type: "success",
        content: "Your feedback has been successfully accepted",
      });
    } catch (e) {
    } finally {
      setIsLoading(false);
      toggleModal(false);
    }
  };

  const feedbackGradeOptions = Object.entries(FEEDBACK_GRADE);

  return (
    <>
      {contextHolder}
      <Modal open={isOpen} onCancel={() => toggleModal(false)} footer={[]}>
        <Flex vertical className="gap-s text-center">
          <Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>
            How are you feeling?
          </Typography.Text>
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}
          >
            Your input valuable in helping us better understand your needs and tailor our service
            accordingly
          </Typography.Text>
          <Form style={{ maxWidth: 800 }} onFinish={onFinish} layout="vertical" form={form}>
            <Flex vertical className="gap-s text-center">
              <Form.Item<IFeedbackForm>
                name="feedback_grade"
                className="full-width"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Flex
                    align="center"
                    justify="center"
                    className="gap-s"
                    style={{ padding: "16px 0 32px" }}
                  >
                    {feedbackGradeOptions.map(([key, value]) => (
                      <Wave isActive={feedbackGrade === key} key={key}>
                        <Tooltip placement="bottom" title={value.title}>
                          <Flex
                            align="center"
                            justify="center"
                            style={{ position: "relative", height: 76, zIndex: 100 }}
                          >
                            <Radio
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                opacity: 0,
                              }}
                              value={key}
                            />
                            <img
                              style={{
                                width: feedbackGrade === key ? 76 : 50,
                                height: feedbackGrade === key ? 76 : 50,
                              }}
                              className="cubic-animation"
                              src={feedbackGrade === key ? value.activeImg : value.inActiveImg}
                              alt={key}
                            />
                          </Flex>
                        </Tooltip>
                      </Wave>
                    ))}
                  </Flex>
                </Radio.Group>
              </Form.Item>
              <Flex vertical gap="middle">
                {/* <Form.Item<IFeedbackForm> id="name" name="feedback_name" noStyle rules={[{ required: true }]}> */}
                {/*	<Input placeholder="Name" /> */}
                {/* </Form.Item> */}
                {/* <Form.Item<IFeedbackForm> id="email" name="feedback_email" noStyle rules={[{ type: "email", required: true }]}> */}
                {/*	<Input placeholder="Email" /> */}
                {/* </Form.Item> */}
                <Form.Item<IFeedbackForm>
                  id="message"
                  name="feedback_message"
                  noStyle
                  rules={[{ required: true }]}
                >
                  <Input.TextArea placeholder="Message" rows={3} />
                </Form.Item>
              </Flex>
              <Form.Item>
                <Button loading={isLoading} className="full-width" htmlType="submit" type="primary">
                  <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
                    Thank you!
                  </Typography.Text>
                </Button>
              </Form.Item>
            </Flex>
          </Form>
        </Flex>
      </Modal>
    </>
  );
};
