import { FC } from "react";
import redoIcon from "assets/shared/arrow_left_16.svg";
import { IconButtonWrapper } from "shared/ui";

export const UndoIcon: FC = () => {
  return (
    <IconButtonWrapper>
      <img src={redoIcon} alt="undo" style={{ width: 16, height: 16 }} />
    </IconButtonWrapper>
  );
};
