import { GoogleOAuthProvider } from "@react-oauth/google";
import { FC, useEffect } from "react";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { clarity } from "react-microsoft-clarity";
import { RouterProvider } from "react-router-dom";
import TiktokPixel from "tiktok-pixel";
import { router } from "@/pages";

import { PermissionWrapper } from "@/entities/viewer";
import { AntDProvider, ReatomProvider } from "./providers";

import "./styles/index.css";

export const App: FC = () => {
  useEffect(() => {
    if (process.env.MODE !== "development") {
      ReactGA.initialize([
        {
          trackingId: process.env.VITE_GOOGLE_ANALYTICS_ID,
        },
      ]);
      TagManager.initialize({
        gtmId: process.env.VITE_GOOGLE_TAG_MANAGER,
      });
      clarity.init("p416zobp3p");
      TiktokPixel.init(process.env.VITE_TIKTOK_ANALYTICS_ID);
    }
  }, []);

  return (
    <ReatomProvider>
      <AntDProvider>
        <GoogleOAuthProvider clientId={process.env.VITE_GOOGLE_CLIENT_ID}>
          <PermissionWrapper>
            <RouterProvider router={router} />
          </PermissionWrapper>
        </GoogleOAuthProvider>
      </AntDProvider>
    </ReatomProvider>
  );
};
