import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import archiveBoardEmptyImage from "assets/moodboard/empty-archive-moodboard.svg";
import cn from "classnames";
import { FC, useEffect } from "react";

import { Gallery } from "react-grid-gallery";
import { DRAGGABLE_IMAGE_ID } from "@/entities/inspiration-board";
import { useExtractParams } from "@/shared/hooks/use-extract-params";
import { GRAYSCALE_TOKEN } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";

import { MoodboardPath } from "shared/types/routes";

import { DraggableImage, DraggableOverlay, BoardLoading } from "shared/ui";
import { getArchiveBoardAction } from "../model";

import "./ArchiveBoard.scss";

export const ArchiveBoard: FC = () => {
  const { id } = useExtractParams<MoodboardPath>();
  const [archiveBoardList] = useAtom(getArchiveBoardAction.dataAtom);
  const [archiveBoardStatuses] = useAtom(getArchiveBoardAction.statusesAtom);
  const getArchiveBoard = useAction(getArchiveBoardAction);
  const abortArchiveBoard = useAction(getArchiveBoardAction.abort);

  const images = archiveBoardList.map((image) => ({
    src: image.url,
    width: image.coordinates.w,
    height: image.coordinates.h,
    alt: image.id,
    customOverlay: <DraggableOverlay />,
  }));

  useEffect(() => {
    getArchiveBoard(id);

    return () => {
      abortArchiveBoard();
    };
  }, []);

  if (archiveBoardStatuses.isPending) {
    return (
      <Flex vertical className="archive-board gap-s">
        <BoardLoading />
      </Flex>
    );
  }

  if (!archiveBoardList.length) {
    return (
      <Flex vertical align="center" className="full-width gap-xs">
        <img
          className="archive-board__image"
          src={archiveBoardEmptyImage}
          alt="archive board empty"
        />
        <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, "archive-board__text")}>
          All deleted images will be stored here
        </Typography.Text>
      </Flex>
    );
  }

  return (
    <Flex vertical className="archive-board gap-s">
      <Typography.Text
        className={cn(TEXT_CLASSNAMES.Body10, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
      >
        All deleted images are stored here <br />
        To put them back to the moodboard click on the return - button
      </Typography.Text>
      <Gallery
        margin={3}
        rowHeight={200}
        images={images}
        // @ts-ignore
        thumbnailImageComponent={(props) => (
          <DraggableImage draggableImageId={DRAGGABLE_IMAGE_ID} {...props} />
        )}
        enableImageSelection={false}
      />
    </Flex>
  );
};
