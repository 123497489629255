import { useAtom } from "@reatom/npm-react";
import { ProjectType } from "shared/types/page.ts";
import { userPermissionInitAction } from "../index";

export const useProjectType = () => {
  const [data] = useAtom(userPermissionInitAction.dataAtom);

  return {
    isOwn: data.projectType === "own",
    isShare: data.projectType === "share",
    projectType: data.projectType as ProjectType,
  };
};
