export const REG_EXP = {
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!$%^&*()_+|~=`{}\[\]:\/;<>?,'"\\.@£#])[A-Za-z\d-!$%^&*()_+|~=`{}\[\]:\/;<>?,'"\\.@£#]{8,}$/,
  lowerCaseLetter: /^(.*[a-z].*)$/,
  upperCaseLetter: /^(.*[A-Z].*)$/,
  specialSymbol: /^(.*[-!$%^&*()_+|~=`{}\[\]:\/;<>?,'"\\.@£#].*)$/,
  oneNumber: /^.*[0-9].*$/,
  email:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
