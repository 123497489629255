import { Flex, Form, GetProps, Input } from "antd";
import { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { validateResetPasswordResource } from "@/shared/api/auth";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ROUTES } from "shared/const/routes.ts";
import { CreateNewPasswordPath, EnterTheCodePath } from "shared/types/routes.ts";
import { generateUrl } from "shared/methods";
import { BackgroundPreLogin, LoginWrapper, RecoverPasswordWrapper } from "@/entities/viewer";
import { CountDownResendCode } from "./CountDownResendCode";

import "./EnterTheCode.scss";

type OTPProps = GetProps<typeof Input.OTP>;

export const EnterTheCode = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { email } = useExtractParams<EnterTheCodePath>();
  const [error, setError] = useState<null | string>(null);
  const [code, setCode] = useState<string>("");

  const onFinish = async () => {
    try {
      await validateResetPasswordResource(email, code);
      navigate(generateUrl<CreateNewPasswordPath>(ROUTES.CREATE_NEW_PASSWORD.fullPath, { email }));
    } catch (e) {
      const err = e as AxiosError;

      // @ts-ignore
      setError(err.response?.data?.message ?? "Something went wrong. Please try again");
    }
  };

  const onChange: OTPProps["onChange"] = (text) => {
    setCode(text);
  };

  return (
    <BackgroundPreLogin>
      <LoginWrapper title={""} questionText={""} actionText={""} action={() => {}}>
        <Form form={form} onFinish={onFinish}>
          <RecoverPasswordWrapper
            form={form}
            title="Enter the code"
            description={`We sent a 6-digit password recovery code to your email ${email}.`}
            buttonText="Reset password"
          >
            <Flex align="center" className="enter-the-code" gap={12}>
              <Form.Item rules={[{ required: true, message: " " }]}>
                <Input.OTP
                  status={error ? "error" : undefined}
                  size="large"
                  length={6}
                  onChange={onChange}
                />
              </Form.Item>
            </Flex>
            <CountDownResendCode error={error} />
          </RecoverPasswordWrapper>
        </Form>
      </LoginWrapper>
    </BackgroundPreLogin>
  );
};
