import { createContext } from "react";

export type TLandingLayoutContext = {
  isOpen: boolean;
  toggleOpen: () => void;
};

export const LandingLayoutContext = createContext<TLandingLayoutContext>({
  isOpen: false,
  toggleOpen: () => {},
});
