import { useAction } from "@reatom/npm-react";
import { useHover, useSize } from "ahooks";
import { useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { updateGenerateImageAction } from "@/entities/storyboard";
import {
  generateStoryboardShotResource,
  regenerateImageResource,
  UpdateShotInfo,
  updateStoryboardShotResource,
} from "@/shared/api/storyboard";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ROUTES, STORYBOARD_TYPE, STORYBOARD_TYPE_PARAM } from "shared/const/routes.ts";
import { useOpenModal } from "shared/hooks";
import { generateUrl } from "shared/methods";
import { TTabView } from "shared/types/common.ts";
import { CharacterInfoPath, ShotEditPath, StoryboardPath } from "shared/types/routes.ts";

type ShotData = { shotId: string; sceneId: string };

export const useShotStoryboard = () => {
  const navigate = useNavigate();
  const params = useExtractParams<StoryboardPath>();

  const updateGenerateImage = useAction(updateGenerateImageAction);
  const [isOpenModal, toggleOpenModal] = useOpenModal();

  const imageRef = useRef(null);
  const descriptionRef = useRef<null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const descriptionSize = useSize(descriptionRef);
  const isHoverImage = useHover(imageRef);

  const onCharacterRedirect = (key: string | null) => {
    navigate(
      generateUrl<CharacterInfoPath>(ROUTES.CHARACTER_INFO.fullPath, {
        id: params.id,
        characterId: key ?? "",
      }),
    );
  };

  const onRedirect = ({ shotId, sceneId }: ShotData) => {
    navigate(
      generateUrl<ShotEditPath>(ROUTES.SHOT_EDIT.fullPath, {
        id: params.id,
        shotId,
        sceneId,
      }),
    );
  };

  const onGenerate = async ({ shotId, sceneId }: ShotData) => {
    updateGenerateImage({ shotId, loading: true, image: null, error: false });
    let image = null;
    let error = false;

    try {
      toggleOpenModal(false);
      const shotInfo = await generateStoryboardShotResource({
        shotId,
        projectKey: params.id,
        sceneId,
      });

      image = shotInfo.data.image_url_compressed ?? "";
    } catch (err) {
      error = true;
      updateGenerateImage({ shotId, loading: false, image, error });
    } finally {
      updateGenerateImage({ shotId, loading: false, image, error });
    }
  };

  const onRegenerate = async ({
    shotId,
    sceneId,
    updateShotInfo,
    updateShotsOrder,
  }: ShotData & { updateShotInfo: UpdateShotInfo; updateShotsOrder: string[] }) => {
    updateGenerateImage({ shotId, loading: true, image: null, error: false });
    let image = null;
    let error = false;

    try {
      toggleOpenModal(false);
      await updateStoryboardShotResource({
        shotId,
        sceneId,
        projectKey: params.id,
        shot_info: updateShotInfo,
        shots_order: updateShotsOrder,
      });
      const shotInfo = await regenerateImageResource({
        shotId,
        projectKey: params.id,
        sceneId,
      });

      image = shotInfo.data.image_url_compressed ?? "";
    } catch (err) {
      error = true;
      updateGenerateImage({ shotId, loading: false, image, error });
    } finally {
      updateGenerateImage({ shotId, loading: false, image, error });
    }
  };

  const onScroll = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: 0,
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  return {
    isOpenModal,
    toggleOpenModal,
    onRedirect,
    onGenerate,
    imageRef,
    isHoverImage,
    onCharacterRedirect,
    descriptionRef,
    containerRef,
    descriptionSize,
    onScroll,
    onRegenerate,
  };
};

export const useStoryboardType = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const storyboardType = searchParams.get(STORYBOARD_TYPE_PARAM) ?? STORYBOARD_TYPE.grid;

  const setStoryboardType = (type: TTabView) => {
    setSearchParams({
      [STORYBOARD_TYPE_PARAM]: type,
    });
  };

  return {
    storyboardType: storyboardType as TTabView,
    setStoryboardType,
  };
};
