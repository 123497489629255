import { FC } from "react";
import { Flex, Typography } from "antd";
import cn from "classnames";

import { SceneShotDialogueProps } from "../model";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";

export const SceneShotDialogue: FC<SceneShotDialogueProps> = ({ dialogue }) => {
  return (
    <Flex vertical className="scene-shot-grid__line scene-shot-grid__line__height gap-xxs">
      {!dialogue.length && <Typography.Text>-</Typography.Text>}
      {!!dialogue.length && (
        <Typography.Text
          className={cn("scene-shot-grid__line--dialogue", TEXT_CLASSNAMES.XsRegular)}
        >
          {dialogue}
        </Typography.Text>
      )}
    </Flex>
  );
};