import { DragEvent, FC } from "react";
import { ThumbnailImageProps } from "react-grid-gallery";

interface IDraggableImage {
  draggableImageId: string;
}

export const DraggableImage: FC<IDraggableImage & ThumbnailImageProps> = ({
  draggableImageId,
  ...props
}) => {
  const handleDragStart = (event: DragEvent) => {
    event.dataTransfer?.setData(draggableImageId, props.imageProps.alt);
  };

  return (
    // @ts-ignore
    <img draggable onDragStart={handleDragStart} {...props.imageProps} />
  );
};
