import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Switch, Typography } from "antd";
import { FC } from "react";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { activeSharingPagesAtom, togglePageAction } from "../model";

export const PagesToggles: FC = () => {
  const [activePages] = useAtom(activeSharingPagesAtom);
  const togglePage = useAction(togglePageAction);

  return (
    <Flex className="gap-l">
      <Flex vertical className="gap-xxs">
        <Flex className="gap-xs">
          <Switch checked={activePages.storyboards} onChange={() => togglePage("storyboards")} />
          <Typography.Text className={TEXT_CLASSNAMES.Body20}>Storyboard</Typography.Text>
        </Flex>
        <Flex className="gap-xs">
          <Switch checked={activePages.script} onChange={() => togglePage("script")} />
          <Typography.Text className={TEXT_CLASSNAMES.Body20}>Script</Typography.Text>
        </Flex>
      </Flex>
      <Flex vertical className="gap-xxs">
        <Flex className="gap-xs">
          <Switch checked={activePages.moodboard} onChange={() => togglePage("moodboard")} />
          <Typography.Text className={TEXT_CLASSNAMES.Body20}>Moodboard</Typography.Text>
        </Flex>
        <Flex className="gap-xs">
          <Switch checked={activePages.characters} onChange={() => togglePage("characters")} />
          <Typography.Text className={TEXT_CLASSNAMES.Body20}>Characters</Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
