import { useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ROUTES } from "shared/const/routes.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { ShotEditPath } from "shared/types/routes.ts";
import { generateUrl } from "shared/methods";
import {
  getNextShotId,
  getPrevShotId,
  getShotsOrderAction,
} from "@/entities/shot-edit";

import { UndoIcon, RedoIcon } from "../icons";

type Props = {
  title: string;
};

export const ShotTitle: FC<Props> = ({ title }) => {
  const { shotId, id, sceneId } = useExtractParams<ShotEditPath>();
  const navigate = useNavigate();
  const [shotsOrder] = useAtom(getShotsOrderAction.dataAtom);

  const currentShot = shotsOrder.find((shot) => shot.shot_id === shotId);

  const onNext = () => {
    const list = shotsOrder.map((shot) => ({ id: shot.shot_id }));
    const nextId = getNextShotId(list, shotId);

    navigate(
      generateUrl<ShotEditPath>(ROUTES.SHOT_EDIT.fullPath, {
        id,
        sceneId,
        shotId: nextId!,
      }),
    );
  };

  const onPrev = () => {
    const list = shotsOrder.map((shot) => ({ id: shot.shot_id }));
    const prevId = getPrevShotId(list, shotId);

    navigate(
      generateUrl<ShotEditPath>(ROUTES.SHOT_EDIT.fullPath, {
        id,
        shotId: prevId!,
        sceneId,
      }),
    );
  };

  return (
    <>
      <Flex justify="space-between" className="shot-details-info__row full-width">
        <Flex>
          <Typography.Text className={TEXT_CLASSNAMES.Title20}>
            Scene {currentShot?.scene_ix}. Shot {currentShot?.shot_ix}
          </Typography.Text>
          <Typography.Text className={TEXT_CLASSNAMES.Body10}>{title}</Typography.Text>
        </Flex>
        <Flex className="gap-4">
          <Button onClick={onPrev} size="small" icon={<UndoIcon />} />
          <Button onClick={onNext} size="small" icon={<RedoIcon />} />
        </Flex>
      </Flex>
      <div className="shot-details-info__divider" />
    </>
  );
};
