import {
  reatomAsync,
  withAbort,
  withCache,
  withDataAtom,
  withErrorAtom,
  withStatusesAtom,
} from "@reatom/async";
import { action } from "@reatom/core";
import { callErrorAction } from "@/entities/notification";
import { getMoodboardsResource, TMoodboardImage } from "@/shared/api/moodboard";

export const getArchiveBoardAction = reatomAsync(async (ctx, id) => {
  const { data } = await getMoodboardsResource(id, ctx.controller);
  const images: TMoodboardImage[] = data.images.filter((img) => img.archived);

  return images;
}).pipe(
  withCache({
    swr: {
      shouldFulfill: false,
    },
    ignoreAbort: false,
  }),
  withDataAtom([], (_ctx, images) => images),
  withStatusesAtom(),
  withErrorAtom(callErrorAction),
  withAbort(),
);

export const removeFromArchiveBoardAction = action((ctx, id: string) => {
  getArchiveBoardAction.dataAtom(
    ctx,
    ctx.get(getArchiveBoardAction.dataAtom).filter((item) => item.id !== id),
  );
});

export const addToArchiveBoardAction = action((ctx, image: TMoodboardImage) => {
  getArchiveBoardAction.dataAtom(ctx, [...ctx.get(getArchiveBoardAction.dataAtom), image]);
});
