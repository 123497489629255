import { useAtom } from "@reatom/npm-react";
import { Flex, Menu, MenuProps, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { NOT_RELEASE } from "shared/const/release.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { isGuestAtom } from "@/entities/viewer";

import "./SettingsLayout.scss";

type MenuItem = Required<MenuProps>["items"][number] & { guest: boolean };
type MenuItemKey = "profile" | "account" | "members" | "billing" | "preferences";

export const SettingsLayout: FC = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState<MenuItemKey>("profile");
  const [isGuest] = useAtom(isGuestAtom);

  const notReleaseMembers = [
    {
      label: (
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary, {
            [TEXT_CLASSNAMES.XsBold]: current === "preferences",
            [COLOR_CLASSNAMES.TextPrimary]: current === "preferences",
          })}
        >
          Preferences
        </Typography.Text>
      ),
      key: "preferences",
      guest: false,
    },
    {
      label: (
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary, {
            [TEXT_CLASSNAMES.XsBold]: current === "members",
            [COLOR_CLASSNAMES.TextPrimary]: current === "members",
          })}
        >
          Members
        </Typography.Text>
      ),
      key: "members",
      guest: false,
    },
  ];

  const releaseItems: MenuItem[] = [
    {
      label: (
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary, {
            [TEXT_CLASSNAMES.XsBold]: current === "profile",
            [COLOR_CLASSNAMES.TextPrimary]: current === "profile",
          })}
        >
          Profile
        </Typography.Text>
      ),
      key: "profile",
      guest: true,
    },
    {
      label: (
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary, {
            [TEXT_CLASSNAMES.XsBold]: current === "account",
            [COLOR_CLASSNAMES.TextPrimary]: current === "account",
          })}
        >
          Account
        </Typography.Text>
      ),
      key: "account",
      guest: true,
    },
    {
      label: (
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary, {
            [TEXT_CLASSNAMES.XsBold]: current === "billing",
            [COLOR_CLASSNAMES.TextPrimary]: current === "billing",
          })}
        >
          Billing
        </Typography.Text>
      ),
      key: "billing",
      guest: false,
    },
  ];

  const items = NOT_RELEASE ? [...releaseItems, ...notReleaseMembers] : [...releaseItems];

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key as MenuItemKey);
    navigate(`${e.key}`);
  };

  useEffect(() => {
    const pathnameArray = location.pathname.split("/");
    const routeName = pathnameArray[pathnameArray.length - 1] as MenuItemKey;

    setCurrent(routeName);
  }, [location.pathname]);

  return (
    <>
      <Flex vertical>
        <Typography.Text className={TEXT_CLASSNAMES.HeadlineH1}>Settings</Typography.Text>
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
          Control your profile setup and projects settings
        </Typography.Text>
      </Flex>
      <Menu
        style={{
          background: "transparent",
        }}
        // @ts-ignore
        items={items
          .filter((el) => (isGuest ? el.guest : true))
          // @ts-ignore
          .map((el) => ({ key: el.key, label: el.label }))}
        selectedKeys={[current]}
        onClick={onClick}
        mode="horizontal"
      />
    </>
  );
};
