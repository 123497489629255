import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { useNavigate } from "react-router-dom";

import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import emptyProject from "assets/projects/empty-projects.svg";
import upgrade from "assets/projects/upgrade.svg";
import plus from "assets/shared/plus_square.svg";
import { useOpenModal } from "shared/hooks";
import { useAccountWithAvatar } from "@/entities/account";
import { isGuestAtom, sessionAuthSubscriptionAction } from "@/entities/viewer";
import { AddNewProject } from "../../add-new-project";

import "./EmptyProject.scss";

export const EmptyProject: FC = () => {
  const navigate = useNavigate();
  const [isOpen, toggleModal] = useOpenModal();
  const [isGuest] = useAtom(isGuestAtom);
  const sessionAuthSubscription = useAction(sessionAuthSubscriptionAction);
  const { account } = useAccountWithAvatar();

  const handleUpgrade = () => {
    sessionAuthSubscription({ isGuest: false, email: account.email }); // ??
    navigate("/plan");
  };

  if (isGuest) {
    return (
      <Flex className="empty-project gap-s full-height" vertical align="center" justify="center">
        <Flex vertical align="center">
          <img src={upgrade} width={160} height={160} alt="add member" />
          <Flex vertical className="gap-xxs" align="center">
            <Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>
              Upgrade Required
            </Typography.Text>
            <Typography.Text
              className={cn(
                TEXT_CLASSNAMES.XsRegular,
                "text-center",
                COLOR_CLASSNAMES.TextSecondary,
              )}
            >
              To create a project and access the full functionality of <br /> the product, please
              upgrade your subscription.
            </Typography.Text>
          </Flex>
        </Flex>
        <Button htmlType="submit" type="primary" onClick={handleUpgrade}>
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Upgrade now</Typography.Text>
        </Button>
      </Flex>
    );
  }

  return (
    <Flex className="empty-project gap-s full-height" vertical align="center" justify="center">
      <img className="empty-project__img" src={emptyProject} alt="empty project" />
      <Flex vertical className="gap-xxs">
        <Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>
          No projects here yet
        </Typography.Text>
        {/* <Typography.Text className={TEXT_CLASSNAMES.SmRegular}>Start creating projects and storyboards</Typography.Text> */}
      </Flex>
      <Button
        onClick={() => toggleModal(true)}
        type="primary"
        className="flex"
        icon={<img src={plus} alt="plus" />}
        size="large"
      >
        Create your project
      </Button>
      <AddNewProject isOpen={isOpen} toggleModal={toggleModal} />
    </Flex>
  );
};
