import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";
import bgMobile from "assets/use-cases/bg-mobile.svg";
import bg from "assets/use-cases/bg.svg";
import { TrialButton } from "shared/ui";

import "./UseCaseFooter.scss";

export const UseCaseFooter: FC = () => {
  const { isLaptopM, isMobileXL } = useMediaQueries();

  return (
    <Flex vertical align="center" justify="center" className="use-case-footer text-center gap-m">
      <img src={isMobileXL ? bgMobile : bg} className="use-case-footer__bg" alt="bg" />
      <Flex vertical className="use-case-footer__content gap-xs">
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.LPHeadlineH3, COLOR_CLASSNAMES.TextWhitePrimary, {
            [TEXT_CLASSNAMES.LPHeadlineH4]: isLaptopM,
            [TEXT_CLASSNAMES.HeadlineH2]: isMobileXL,
          })}
        >
          Ready to simplify your <br />
          pre-production workflow?
        </Typography.Text>
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextWhitePrimary, {
            [TEXT_CLASSNAMES.MdRegular]: isLaptopM,
            [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
          })}
        >
          Start now and save time and money on every project!
        </Typography.Text>
      </Flex>
      <Flex justify="center" style={{ zIndex: 10 }}>
        <TrialButton />
      </Flex>
    </Flex>
  );
};
