import { useAction } from "@reatom/npm-react";
import { Button, Flex } from "antd";
import { FC } from "react";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { MoodboardPath } from "shared/types/routes.ts";
import trashIcon from "assets/moodboard/trash.svg";
import { IconButtonWrapper } from "shared/ui";
import { removeFromMoodBoardAction } from "@/entities/moodboard";

type Props = {
  imageId: string;
};

export const DeleteOverlay: FC<Props> = ({ imageId }) => {
  const { id } = useExtractParams<MoodboardPath>();
  const deleteImage = useAction(removeFromMoodBoardAction);

  const onDelete = () => {
    deleteImage(id, imageId);
  };

  return (
    <Flex
      justify="flex-start"
      align="flex-end"
      style={{ padding: 8, boxSizing: "border-box" }}
      className="full-height full-width"
    >
      <Button
        type="primary"
        size="small"
        onClick={onDelete}
        icon={
          <IconButtonWrapper>
            <img style={{ width: 16, height: 16 }} src={trashIcon} alt="trash" />
          </IconButtonWrapper>
        }
      >
        Delete
      </Button>
    </Flex>
  );
};
