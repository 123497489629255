import { useAtom } from "@reatom/npm-react";
import { viewerModel } from "@/entities/viewer";
import { PermissionPage, PermissionGroup } from "shared/types/share.ts";

type Params = {
  page: PermissionPage;
  group: PermissionGroup;
  projectKey: string;
};

export const usePermission = ({ group, projectKey, page }: Params) => {
  const [permissions] = useAtom(viewerModel.viewerPermission.dataAtom);

  if (!permissions) {
    return { can: false, cannot: true };
  }

  const permission = permissions?.[projectKey]?.[page]?.[group]?.permission_group_title === group;

  return { can: permission, cannot: !permission };
};
