import {
  reatomAsync,
  withCache,
  withDataAtom,
  withErrorAtom,
  withStatusesAtom,
} from "@reatom/async";
import { action, atom } from "@reatom/core";
import {
  Comment,
  CreateComment,
  createCommentResource,
  DeleteComment,
  deleteCommentResource,
  getCommentsResource,
  ResolveComment,
  resolveCommentResource,
} from "@/shared/api/comments";
import { callErrorAction } from "@/entities/notification";

export const activeTabAtom = atom("all", "activeTabAtom");

export const getCommentsAction = reatomAsync(
  (_ctx, id: string) => getCommentsResource(id),
  "getCommentsAction",
).pipe(
  withDataAtom([], (_ctx, res) => res.data),
  withErrorAtom((ctx, err) => callErrorAction(ctx, err)),
  withStatusesAtom(),
  withCache(),
);

export const addCommentToBottomAction = action((ctx, message: Comment) => {
  const comments = ctx.get(getCommentsAction.dataAtom);
  getCommentsAction.dataAtom(ctx, [...comments, message]);
});

export const createCommentAction = reatomAsync((_ctx, comment: CreateComment) => {
  return createCommentResource(comment);
}, "createCommentActionAtom");

export const toggleResolveCommentAction = reatomAsync(async (ctx, comment: ResolveComment) => {
  const { comment_id, resolved, project_key } = comment;
  const comments = ctx.get(getCommentsAction.dataAtom);
  const threadComments = comments.filter(
    (comment) => comment.id === comment_id || comment.parent_id === comment_id,
  );
  const newComments = comments.map((comment) => {
    const changedComment = threadComments.find((threadComment) => threadComment.id === comment.id);
    if (changedComment) return { ...comment, resolved };
    return comment;
  });
  getCommentsAction.dataAtom(ctx, newComments);
  await Promise.all(
    threadComments.map((c) =>
      resolveCommentResource({ project_key, comment_id: c.id, resolved: !c.resolved }),
    ),
  );
}, "createCommentActionAtom");

export const deleteCommentAction = reatomAsync((ctx, data: DeleteComment) => {
  const { project_id, comment_id } = data;
  const comments = ctx.get(getCommentsAction.dataAtom);

  const newComments = comments.filter((c) => c.id !== comment_id);
  getCommentsAction.dataAtom(ctx, newComments);

  return deleteCommentResource(project_id, comment_id);
}, "createCommentActionAtom");
