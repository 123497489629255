import { FC, useState } from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../footer";
import { LandingLayoutContext } from "./LandingLayoutContext";

import "./LandingLayout.scss";

export const LandingLayout: FC = () => {
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const toggleOpen = () => {
    setIsBurgerMenuOpen((prev) => !prev);
  };

  return (
    <>
      <LandingLayoutContext.Provider value={{ isOpen: isBurgerMenuOpen, toggleOpen }}>
        <div
          id="layout"
          style={{
            position: "relative",
            overflowY: isBurgerMenuOpen ? "hidden" : "auto",
            overflowX: "hidden",
          }}
        >
          <Outlet />
          <Footer />
        </div>
      </LandingLayoutContext.Provider>
    </>
  );
};
