import { useAtom } from "@reatom/npm-react";
import { captureException, setUser } from "@sentry/react";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
import {
  applyPromoCodeResource,
  checkPromoCodeResource,
  getPaymentCredentialsResource,
} from "@/shared/api/payment";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { PromoCodePath } from "shared/types/routes.ts";
import { viewerAtom } from "@/entities/viewer";

export const useBehavior = () => {
  const navigate = useNavigate();
  const { termType } = useExtractParams<PromoCodePath>();
  const [viewer] = useAtom(viewerAtom);

  const [promoCode, setPromoCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const { data: credentials } = await getPaymentCredentialsResource();

        if (credentials === null) {
          navigate("/plan");
        }
      } catch (e) {
        setUser({
          email: viewer.email,
        });

        captureException(e, {
          tags: {
            feature: "promo-code-apply",
          },
          level: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  const onApply = async () => {
    try {
      setLoading(true);
      const { data: checked } = await checkPromoCodeResource(promoCode, termType ?? "");

      if (checked.code) {
        await applyPromoCodeResource(checked.code);

        if (checked.percent_off === 100) {
          navigate("/payment-success");

          TagManager.dataLayer({
            dataLayer: {
              event: "start_subscription_100_percent_off_promo_code",
            },
          });
        } else {
          navigate("/payment");
        }
      } else {
        setError("The promo code you entered is invalid. Please check the code and try again.");
      }
    } catch (e) {
      setError("The promo code you entered is invalid. Please check the code and try again.");
    } finally {
      setLoading(false);
    }
  };

  const onSkip = () => {
    navigate("/payment");
  };

  return {
    onSkip,
    onApply,
    loading,
    error,
    setPromoCode,
  };
};
