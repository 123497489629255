import { useAction, useAtom } from "@reatom/npm-react";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Button, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC, FormEvent, useEffect, useState } from "react";

import { updatePaymentDetails } from "@/shared/api/payment";
import { COLOR_CLASSNAMES, COLOR_NAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import email from "assets/settings/billing/email.svg";
import visa from "assets/settings/billing/visa.svg";
import { IOpenModal, useOpenModal } from "shared/hooks";
import { useAccountWithAvatar } from "@/entities/account";
import { SettingsWrapper } from "@/entities/settings";
import { getPaymentCredentialsAction, getPaymentDetailsAction } from "../../index";

export const PaymentMethod: FC = () => {
  const [paymentDetails] = useAtom(getPaymentDetailsAction.dataAtom);
  const [credentials] = useAtom(getPaymentCredentialsAction.dataAtom);
  const { account } = useAccountWithAvatar();
  const [open, toggleModal] = useOpenModal();

  return (
    <SettingsWrapper
      title="Payment Method"
      description="Update your billing information"
      className="gap-s full-width"
      style={{
        height: "244px",
        justifyContent: "space-between",
      }}
    >
      <Flex vertical className="gap-xs">
        <Flex className="gap-xs">
          <img src={visa} alt="visa" />
          <Flex vertical className="gap-2">
            <Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>
              •••• •••• •••• {paymentDetails?.last4}
            </Typography.Text>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
            >
              Exp Date: {paymentDetails?.exp_month}/{paymentDetails?.exp_year}
            </Typography.Text>
          </Flex>
        </Flex>
        <Flex className="gap-xxs" align="center">
          <img src={email} alt="email" />
          <Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>{account.email}</Typography.Text>
        </Flex>
      </Flex>
      <Button className="full-width" onClick={() => toggleModal(true)}>
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Update Payment Info</Typography.Text>
      </Button>
      {credentials?.client_secret && (
        <UpdatePaymentModal
          clientSecret={credentials.client_secret}
          toggleModal={toggleModal}
          isOpen={open}
        />
      )}
    </SettingsWrapper>
  );
};

type TPaymentForm = {
  closeModal: () => void;
};

const PaymentForm: FC<TPaymentForm> = ({ closeModal }) => {
  const getPaymentDetails = useAction(getPaymentDetailsAction);
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-success`,
      },
      redirect: "if_required",
    });

    if (result.error) {
      const paymentMethod = result.error.payment_intent?.payment_method;

      if (paymentMethod) {
        await updatePaymentDetails(paymentMethod as string);
        setIsLoading(false);
        getPaymentDetails();
        closeModal();
      }
    }
  };

  return (
    <form style={{ maxWidth: 462 }} className="full-width" onSubmit={handleSubmit}>
      <PaymentElement className="payment-details" />
      <Button
        htmlType="submit"
        className="full-width payment-details__button"
        type="primary"
        disabled={isLoading}
        loading={isLoading}
      >
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Save</Typography.Text>
      </Button>
    </form>
  );
};

type Props = {
  clientSecret: string;
};

const UpdatePaymentModal: FC<IOpenModal & Props> = ({ isOpen, toggleModal, clientSecret }) => {
  const [stripe, setStripe] = useState<Promise<Stripe | null>>(Promise.resolve(null));
  const onClose = () => {
    toggleModal(false);
  };

  useEffect(() => {
    const stripePromise = loadStripe(process.env.VITE_STRIPE_KEY, { locale: "en" });

    setStripe(stripePromise);
  }, []);

  return (
    <Modal footer={[]} open={isOpen} onClose={onClose} onCancel={onClose}>
      <Flex vertical gap={32}>
        <Flex vertical gap={4}>
          <Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>
            Update Payment Info
          </Typography.Text>
          <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_NAMES.TextSecondary)}>
            Update your billing information.
          </Typography.Text>
        </Flex>
      </Flex>
      <div style={{ marginBottom: 32 }} />
      <Elements stripe={stripe} options={{ clientSecret }}>
        <PaymentForm closeModal={onClose} />
      </Elements>
    </Modal>
  );
};
