import { FC } from "react";
import { Flex, Typography } from "antd";
import bottom from "assets/storyboard/bottom.svg";

import { useShotStoryboard } from "@/entities/storyboard";
import { SceneShotDescriptionProps } from "../model";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";

export const SceneShotDescription: FC<SceneShotDescriptionProps> = ({ shot_description }) => {
  const { descriptionSize, descriptionRef, containerRef, onScroll } = useShotStoryboard();

  return (
    <Flex style={{ position: "relative" }}>
      <Flex
        ref={containerRef}
        vertical
        className="scene-shot-grid__line__height scene-shot-grid__line scene-shot-grid__line__description"
      >
        {!shot_description && <Typography.Text>-</Typography.Text>}
        <Typography.Text ref={descriptionRef} className={TEXT_CLASSNAMES.XsRegular}>
          {shot_description}
        </Typography.Text>
      </Flex>
      {(descriptionSize?.height || 0) > 60 && (
        <Flex
          align="center"
          justify="center"
          className="scene-shot-grid__line__description--scroll"
          role="button"
          onClick={onScroll}
        >
          <img style={{ width: 20, height: 20 }} src={bottom} alt="bottom" />
        </Flex>
      )}
    </Flex>
  );
};