export const COLOR_NAMES = {
  White100: "#FFF",

  BrandGreen50: "#F2FDF6",
  BrandGreen100: "#E5FBED",
  BrandGreen200: "#CAF7DC",
  BrandGreen300: "#95EEB9",
  BrandGreen400: "#65E699",
  BrandGreen500: "#22D469",
  BrandGreen600: "#1DB459",
  BrandGreen700: "#178D46",
  BrandGreen800: "#0F6130",
  BrandGreen900: "#083018",

  Purple50: "#F6F0FF",
  Purple100: "#F3EBFF",
  Purple200: "#DAC2FE",
  Purple300: "#BC91FE",
  Purple400: "#9958FD",
  Purple500: "#7821FD",
  Purple600: "#5102CA",
  Purple700: "#4101A2",
  Purple800: "#2C016F",
  Purple900: "#160138",

  Orange50: "#FFF4EB",
  Orange100: "#FFF2EB",
  Orange200: "#FED9C3",
  Orange300: "#FDB287",
  Orange400: "#FB813C",
  Orange500: "#F55D05",
  Orange600: "#CD4E04",
  Orange700: "#A03C03",
  Orange800: "#6E2902",
  Orange900: "#371501",

  Red50: "#FFEBEF",
  Red100: "#FEECED",
  Red200: "#FCC6CB",
  Red300: "#F98D98",
  Red400: "#F65465",
  Red500: "#F31B31",
  Red600: "#CE0B1E",
  Red700: "#9C0816",
  Red800: "#690610",
  Red900: "#350308",

  Blue50: "#EBF5FF",
  Blue100: "#D6EBFF",
  Blue200: "#ADD8FF",
  Blue300: "#75BDFF",
  Blue400: "#47A7FF",
  Blue500: "#1890FF",
  Blue600: "#0072DB",
  Blue700: "#0055A3",
  Blue800: "#00407A",
  Blue900: "#001D38",

  MetalGray50: "#F4F5F5",
  MetalGray100: "#EAEAEC",
  MetalGray200: "#E2E3E4",
  MetalGray300: "#D8D9DB",
  MetalGray400: "#BCBEC2",
  MetalGray500: "#A1A4AA",
  MetalGray600: "#797C86",
  MetalGray700: "#5C5F66",
  MetalGray800: "#313235",
  MetalGray900: "#131415",

  DarkOverlay100: "rgba(0, 0, 0, 0.1)",
  DarkOverlay200: "rgba(0, 0, 0, 0.2)",
  DarkOverlay300: "rgba(0, 0, 0, 0.3)",
  DarkOverlay400: "rgba(0, 0, 0, 0.4)",
  DarkOverlay500: "rgba(0, 0, 0, 0.5)",
  DarkOverlay600: "rgba(0, 0, 0, 0.6)",
  DarkOverlay700: "rgba(0, 0, 0, 0.7)",
  DarkOverlay800: "rgba(0, 0, 0, 0.8)",
  DarkOverlay900: "rgba(0, 0, 0, 0.9)",

  LightOverlay100: "rgba(255, 255, 255, 0.1)",
  LightOverlay200: "rgba(255, 255, 255, 0.2)",
  LightOverlay300: "rgba(255, 255, 255, 0.3)",
  LightOverlay400: "rgba(255, 255, 255, 0.4)",
  LightOverlay500: "rgba(255, 255, 255, 0.5)",
  LightOverlay600: "rgba(255, 255, 255, 0.6)",
  LightOverlay700: "rgba(255, 255, 255, 0.7)",
  LightOverlay800: "rgba(255, 255, 255, 0.8)",
  LightOverlay900: "rgba(255, 255, 255, 0.9)",

  TextPrimary: "#0C0C0D",
  TextSecondary: "#616161",
  TextTertiary: "#BFBFBF",
  TextWhitePrimary: "#FFF",
  TextBrand: "#22D469",

  BackgroundWhite: "#FFF",
  BackgroundLightGray: "#EAEAEC",
  BackgroundDarkGray: "#4E5055",
  BackgroundBlack: "#131415",
};

export const COLOR_CLASSNAMES = {
  BrandGreen50: "brand-green-50",
  BrandGreen100: "brand-green-100",
  BrandGreen200: "brand-green-200",
  BrandGreen300: "brand-green-300",
  BrandGreen400: "brand-green-400",
  BrandGreen500: "brand-green-500",
  BrandGreen600: "brand-green-600",
  BrandGreen700: "brand-green-700",
  BrandGreen800: "brand-green-800",
  BrandGreen900: "brand-green-900",

  Purple50: "purple-50",
  Purple100: "purple-100",
  Purple200: "purple-200",
  Purple300: "purple-300",
  Purple400: "purple-400",
  Purple500: "purple-500",
  Purple600: "purple-600",
  Purple700: "purple-700",
  Purple800: "purple-800",
  Purple900: "purple-900",

  MetalGray50: "metal-gray-50",
  MetalGray100: "metal-gray-100",
  MetalGray200: "metal-gray-200",
  MetalGray300: "metal-gray-300",
  MetalGray400: "metal-gray-400",
  MetalGray500: "metal-gray-500",
  MetalGray600: "metal-gray-600",
  MetalGray700: "metal-gray-700",
  MetalGray800: "metal-gray-800",
  MetalGray900: "metal-gray-900",

  Orange50: "orange-50",
  Orange100: "orange-100",
  Orange200: "orange-200",
  Orange300: "orange-300",
  Orange400: "orange-400",
  Orange500: "orange-500",
  Orange600: "orange-600",
  Orange700: "orange-700",
  Orange800: "orange-800",
  Orange900: "orange-900",

  Red50: "red-50",
  Red100: "red-100",
  Red200: "red-200",
  Red300: "red-300",
  Red400: "red-400",
  Red500: "red-500",
  Red600: "red-600",
  Red700: "red-700",
  Red800: "red-800",
  Red900: "red-900",

  Blue50: "blue-50",
  Blue100: "blue-100",
  Blue200: "blue-200",
  Blue300: "blue-300",
  Blue400: "blue-400",
  Blue500: "blue-500",
  Blue600: "blue-600",
  Blue700: "blue-700",
  Blue800: "blue-800",
  Blue900: "blue-900",

  DarkOverlay100: "dark-overlay-100",
  DarkOverlay200: "dark-overlay-200",
  DarkOverlay300: "dark-overlay-300",
  DarkOverlay400: "dark-overlay-400",
  DarkOverlay500: "dark-overlay-500",
  DarkOverlay600: "dark-overlay-600",
  DarkOverlay700: "dark-overlay-700",
  DarkOverlay800: "dark-overlay-800",
  DarkOverlay900: "dark-overlay-900",
  DarkOverlay1000: "dark-overlay-1000",

  LightOverlay100: "light-overlay-100",
  LightOverlay200: "light-overlay-200",
  LightOverlay300: "light-overlay-300",
  LightOverlay400: "light-overlay-400",
  LightOverlay500: "light-overlay-500",
  LightOverlay600: "light-overlay-600",
  LightOverlay700: "light-overlay-700",
  LightOverlay800: "light-overlay-800",
  LightOverlay900: "light-overlay-900",
  LightOverlay1000: "light-overlay-1000",

  TextPrimary: "text-primary",
  TextSecondary: "text-secondary",
  TextTertiary: "text-tertiary",
  TextWhitePrimary: "text-white-primary",
  TextBrand: "text-brand",

  BackgroundWhite: "background-white",
  BackgroundLightGray: "background-light-gray",
  BackgroundDarkGray: "background-dark-gray",
  BackgroundBlack: "background-black",
};

export const GRAYSCALE_TOKEN = {
  SURFACE: {
    DEFAULT_SUBTLE: {
      className: "grayscale-surface-default-subtle",
      color: COLOR_NAMES.MetalGray100,
    },
    DEFAULT_LIGHT: {
      className: "grayscale-surface-default-light",
      color: COLOR_NAMES.MetalGray50,
    },
    DEFAULT_DARK: {
      className: "grayscale-surface-default-dark",
      color: COLOR_NAMES.MetalGray900,
    },
    DEFAULT: {
      className: "grayscale-surface-default",
      color: COLOR_NAMES.White100,
    },
    DISABLED: {
      className: "grayscale-surface-disabled",
      color: COLOR_NAMES.MetalGray400,
    },
  },
  BORDER: {
    DEFAULT_LIGHT: {
      className: "grayscale-border-default-light",
      color: COLOR_NAMES.MetalGray100,
    },
    DEFAULT_DARK: {
      className: "grayscale-border-default-dark",
      color: COLOR_NAMES.MetalGray900,
    },
    DEFAULT: {
      className: "grayscale-border-default",
      color: COLOR_NAMES.MetalGray200,
    },
    DISABLED: {
      className: "grayscale-border-disabled",
      color: COLOR_NAMES.MetalGray50,
    },
  },
  TEXT_ICON: {
    TITLE: {
      className: "grayscale-text-icon-title",
      color: COLOR_NAMES.MetalGray900,
    },
    BODY: {
      className: "grayscale-text-icon-body",
      color: COLOR_NAMES.MetalGray800,
    },
    SUBTITLE: {
      className: "grayscale-text-icon-subtitle",
      color: COLOR_NAMES.MetalGray600,
    },
    CAPTION: {
      className: "grayscale-text-icon-caption",
      color: COLOR_NAMES.MetalGray400,
    },
    NEGATIVE: {
      className: "grayscale-text-icon-negative",
      color: COLOR_NAMES.White100,
    },
    DISABLED: {
      className: "grayscale-text-icon-disabled",
      color: COLOR_NAMES.MetalGray400,
    },
  },
};

export const PRIMARY_TOKEN = {
  SURFACE: {
    DEFAULT_SUBTLE: {
      className: "primary-surface-default-subtle",
      color: COLOR_NAMES.BrandGreen50,
    },
    DEFAULT_LIGHT: {
      className: "primary-surface-default-light",
      color: COLOR_NAMES.BrandGreen400,
    },
    DEFAULT_DARK: {
      className: "primary-surface-default-dark",
      color: COLOR_NAMES.BrandGreen700,
    },
    DEFAULT: {
      className: "primary-surface-default",
      color: COLOR_NAMES.BrandGreen600,
    },
  },
  BORDER: {
    DEFAULT_LIGHT: {
      className: "primary-border-default-light",
      color: COLOR_NAMES.BrandGreen300,
    },
    DEFAULT_DARK: {
      className: "primary-border-default-dark",
      color: COLOR_NAMES.BrandGreen800,
    },
    DEFAULT: {
      className: "primary-border-default",
      color: COLOR_NAMES.BrandGreen600,
    },
  },
  TEXT_ICON: {
    LINK_LABEL: {
      className: "primary-text-icon-link-label",
      color: COLOR_NAMES.BrandGreen700,
    },
  },
};

export const SECONDARY_TOKEN = {
  SURFACE: {
    DEFAULT_SUBTLE: {
      className: "secondary-surface-default-subtle",
      color: COLOR_NAMES.DarkOverlay100,
    },
    DEFAULT_LIGHT: {
      className: "secondary-surface-default-light",
      color: COLOR_NAMES.DarkOverlay400,
    },
    DEFAULT_DARK: {
      className: "secondary-surface-default-dark",
      color: COLOR_NAMES.DarkOverlay800,
    },
    DEFAULT: {
      className: "secondary-surface-default",
      color: COLOR_NAMES.DarkOverlay600,
    },
  },
  BORDER: {
    DEFAULT_LIGHT: {
      className: "secondary-border-default-light",
      color: COLOR_NAMES.DarkOverlay300,
    },
    DEFAULT_DARK: {
      className: "secondary-border-default-dark",
      color: COLOR_NAMES.DarkOverlay900,
    },
    DEFAULT: {
      className: "secondary-border-default",
      color: COLOR_NAMES.DarkOverlay600,
    },
  },
  TEXT_ICON: {
    LINK_LABEL: {
      className: "secondary-text-icon-link-label",
      color: COLOR_NAMES.DarkOverlay800,
    },
  },
};

export const ERROR_TOKEN = {
  SURFACE: {
    DEFAULT_SUBTLE: {
      className: "error-surface-default-subtle",
      color: COLOR_NAMES.Red50,
    },
    DEFAULT_LIGHT: {
      className: "error-surface-default-light",
      color: COLOR_NAMES.Red400,
    },
    DEFAULT_DARK: {
      className: "error-surface-default-dark",
      color: COLOR_NAMES.Red700,
    },
    DEFAULT: {
      className: "error-surface-default",
      color: COLOR_NAMES.Red500,
    },
  },
  BORDER: {
    DEFAULT_LIGHT: {
      className: "error-border-default-light",
      color: COLOR_NAMES.Red300,
    },
    DEFAULT_DARK: {
      className: "error-border-default-dark",
      color: COLOR_NAMES.Red700,
    },
    DEFAULT: {
      className: "error-border-default",
      color: COLOR_NAMES.Red600,
    },
  },
  TEXT_ICON: {
    LINK_LABEL: {
      className: "error-text-icon-link-label",
      color: COLOR_NAMES.Red600,
    },
  },
};

export const WARNING_TOKEN = {
  SURFACE: {
    DEFAULT_SUBTLE: {
      className: "warning-surface-default-subtle",
      color: COLOR_NAMES.Orange50,
    },
    DEFAULT_LIGHT: {
      className: "warning-surface-default-light",
      color: COLOR_NAMES.Orange400,
    },
    DEFAULT_DARK: {
      className: "warning-surface-default-dark",
      color: COLOR_NAMES.Orange700,
    },
    DEFAULT: {
      className: "warning-surface-default",
      color: COLOR_NAMES.Orange500,
    },
  },
  BORDER: {
    DEFAULT_LIGHT: {
      className: "warning-border-default-light",
      color: COLOR_NAMES.Orange300,
    },
    DEFAULT_DARK: {
      className: "warning-border-default-dark",
      color: COLOR_NAMES.Orange700,
    },
    DEFAULT: {
      className: "warning-border-default",
      color: COLOR_NAMES.Orange600,
    },
  },
  TEXT_ICON: {
    LINK_LABEL: {
      className: "warning-text-icon-link-label",
      color: COLOR_NAMES.Orange600,
    },
  },
};

export const SUCCESS_TOKEN = {
  SURFACE: {
    DEFAULT_SUBTLE: {
      className: "success-surface-default-subtle",
      color: COLOR_NAMES.BrandGreen50,
    },
    DEFAULT_LIGHT: {
      className: "success-surface-default-light",
      color: COLOR_NAMES.BrandGreen400,
    },
    DEFAULT_DARK: {
      className: "success-surface-default-dark",
      color: COLOR_NAMES.BrandGreen700,
    },
    DEFAULT: {
      className: "success-surface-default",
      color: COLOR_NAMES.BrandGreen500,
    },
  },
  BORDER: {
    DEFAULT_LIGHT: {
      className: "success-border-default-light",
      color: COLOR_NAMES.BrandGreen300,
    },
    DEFAULT_DARK: {
      className: "success-border-default-dark",
      color: COLOR_NAMES.BrandGreen800,
    },
    DEFAULT: {
      className: "success-border-default",
      color: COLOR_NAMES.BrandGreen600,
    },
  },
  TEXT_ICON: {
    LINK_LABEL: {
      className: "success-text-icon-link-label",
      color: COLOR_NAMES.BrandGreen600,
    },
  },
};
