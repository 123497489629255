export const getNextShotId = (list: { id: string }[], currentId?: string) => {
  const shotIndex = list.findIndex((shot) => shot.id === currentId);
  const lastIndex = list.length - 1;
  const isLast = shotIndex === lastIndex;

  if (isLast) {
    return list[0].id;
  }

  if (shotIndex > -1) {
    return list[shotIndex + 1].id;
  }

  return currentId;
};

export const getPrevShotId = (list: { id: string }[], currentId?: string) => {
  const shotIndex = list.findIndex((shot) => shot.id === currentId);
  const isFirst = shotIndex === 0;

  if (isFirst) {
    return list[list.length - 1].id;
  }

  if (shotIndex > -1) {
    return list[shotIndex - 1].id;
  }

  return currentId;
};
