import { Button, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { IOpenModal } from "shared/hooks";

type Props = IOpenModal & {
  onDelete: () => void;
  projectName: string;
};

export const DeleteProjectModal: FC<Props> = ({ isOpen, onDelete, toggleModal, projectName }) => {
  const handleCancel = () => {
    toggleModal(false);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      footer={[
        <Flex className="gap-xs" justify="flex-end">
          <Button className="" key="back" onClick={handleCancel}>
            <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>No, cancel</Typography.Text>
          </Button>
          <Button type="primary" className="" key="back" onClick={onDelete}>
            <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Yes, delete it</Typography.Text>
          </Button>
        </Flex>,
      ]}
    >
      <Flex vertical className="gap-xs">
        <Typography.Text className={TEXT_CLASSNAMES.Title50}>Deleting the project</Typography.Text>
        <Flex vertical className="gap-2">
          <Typography.Text className={TEXT_CLASSNAMES.Body20}>
            Are you sure you want to delete the project <Typography.Text className={TEXT_CLASSNAMES.Title20}>"{projectName}"</Typography.Text>?
          </Typography.Text>
          <Typography.Text className={cn(TEXT_CLASSNAMES.Body20)}>
            The project will not be restored
          </Typography.Text>
        </Flex>
      </Flex>
    </Modal>
  );
};
