import { Button, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { IOpenModal } from "shared/hooks";

type Props = IOpenModal & {
  onLeave: () => void;
};

export const LeaveProjectModal: FC<Props> = ({ isOpen, onLeave, toggleModal }) => {
  const handleCancel = () => {
    toggleModal(false);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      footer={[
        <Flex
          style={{
            marginTop: 24,
          }}
          className="gap-xs"
          justify="flex-end"
        >
          <Button className="" key="back" onClick={handleCancel}>
            <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>No, cancel</Typography.Text>
          </Button>
          <Button type="primary" className="" key="back" onClick={onLeave}>
            <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Yes, leave</Typography.Text>
          </Button>
        </Flex>,
      ]}
    >
      <Flex vertical className="gap-xs">
        <Flex vertical className="gap-xxs">
          <Typography.Text className={TEXT_CLASSNAMES.Title50}>
            Are you sure you want to leave this shared project?
          </Typography.Text>
          <Typography.Text className={cn(TEXT_CLASSNAMES.Body20, COLOR_CLASSNAMES.TextSecondary)}>
            You will lose access to view, comment, and collaborate on it, but the project will
            remain available to other members.
          </Typography.Text>
        </Flex>
      </Flex>
    </Modal>
  );
};
