import { Dropdown, Flex, MenuProps } from "antd";
import cn from "classnames";
import { FC, HTMLAttributes } from "react";

import blackDots from "assets/shared/black_dots.svg";

import "./DropdownDots.scss";

interface IDropdownDots extends HTMLAttributes<HTMLElement> {
  items: MenuProps["items"];
  disabled?: boolean;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
}

export const DropdownDots: FC<IDropdownDots> = ({
  items,
  onClick,
  disabled,
  getPopupContainer = () => document.body,
  ...props
}) => {
  return (
    <Dropdown
      disabled={disabled}
      menu={{ items }}
      placement="bottomRight"
      getPopupContainer={getPopupContainer}
    >
      <Flex
        {...props}
        onClick={(event) => {
          event.stopPropagation();
          if (onClick) {
            onClick(event);
          }
        }}
        className={cn("dropdown-dots cubic-animation", props.className, {
          "dropdown-dots--disabled": disabled,
        })}
        align="center"
        justify="center"
      >
        <img src={blackDots} alt="white dots" />
      </Flex>
    </Dropdown>
  );
};
