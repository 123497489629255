import * as Sentry from "@sentry/react";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const IS_PROD = process.env.VITE_PRODUCTION_BRANCH === "refs/heads/main";
const PROD_DOMAIN = IS_PROD ? [/^https:\/\/blooper\.ai\/api/] : [/^https:\/\/dev.blooper\.ai\/api/];

if (process.env.MODE !== "development") {
  Sentry.init({
    dsn: process.env.VITE_SENTRY_DSN,
    environment: IS_PROD ? "production" : "development",
    beforeSend(event) {
      if (event.message && event.message.includes("Non-critical error")) {
        return null;
      }
      return event;
    },
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      Sentry.replayCanvasIntegration({
        enableManualSnapshot: true,
      }),
    ],
    tracesSampleRate: 1.0,

    tracePropagationTargets: PROD_DOMAIN,

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
