import { Viewer } from "../index";
import { CREDENTIAL_KEY } from "./const";

export const getCredential = () => {
  if (localStorage.getItem(CREDENTIAL_KEY)) {
    return JSON.parse(localStorage.getItem(CREDENTIAL_KEY) || "") as { data: Viewer | null };
  }

  return null;
};
