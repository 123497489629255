import { Button, Flex, Typography } from "antd";
import info from "assets/landing/info.svg";
import { FC } from "react";
import { TEXT_CLASSNAMES } from "../../const/text-classnames.ts";

import { useLocalStorage } from "../../hooks";
import { mobileCheck } from "../../methods";

import "./DesktopAccess.scss";

export const DesktopAccess: FC = () => {
  const [isAvailable, setIsAvailable] = useLocalStorage<boolean>("desktopAccess", true);
  const isMobile = mobileCheck();

  const onClose = () => {
    setIsAvailable(false);
  };

  if (!isAvailable) {
    return null;
  }

  if (!isMobile) {
    return null;
  }

  return (
    <Flex className="desktop-access" justify="center">
      <Flex gap={24} align="center" className="desktop-access__content">
        <Flex align="flex-start" gap={8}>
          <img src={info} alt="info" />
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
            You can access the Blooper app on a desktop browser only.
          </Typography.Text>
        </Flex>
        <Button type="primary" onClick={onClose}>
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Okay</Typography.Text>
        </Button>
      </Flex>
    </Flex>
  );
};
