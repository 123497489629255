import { Flex } from "antd";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { useUpdateTitle } from "shared/hooks";
import { HeaderLayout } from "shared/ui";
import { useAccountWithAvatar } from "@/entities/account";
import { SettingsLayout } from "../settings-layout";
import { HeaderContent } from "./ui";

export const SettingsNavigation: FC = () => {
  const { account, loading } = useAccountWithAvatar();

  useUpdateTitle();

  return (
    <HeaderLayout HeaderContent={() => <HeaderContent account={account} loading={loading} />}>
      <Flex className="full-height" vertical style={{ padding: 24 }}>
        <SettingsLayout />
        <Flex className="full-height" vertical style={{ padding: "24px 0" }}>
          <Outlet />
        </Flex>
      </Flex>
    </HeaderLayout>
  );
};
