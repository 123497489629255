import { useAction, useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC, useEffect } from "react";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { CharacterInfoPath } from "shared/types/routes.ts";
import { Spinner } from "shared/ui";
import {
  activeCharacterAtom,
  getCharactersAction,
  updateCharacterAction,
} from "@/entities/character";
import { CharacterInfoView } from "./CharacterInfoView";

import "./CharacterInfo.scss";

export const CharacterInfo: FC = () => {
  const { id, characterId } = useExtractParams<CharacterInfoPath>();
  const [activeCharacter, setActiveCharacter] = useAtom(activeCharacterAtom);
  const initCharacters = useAction(getCharactersAction);
  const [statuses] = useAtom(getCharactersAction.statusesAtom);
  const [updateStatuses] = useAtom(updateCharacterAction.statusesAtom);

  useEffect(() => {
    if (id && characterId) {
      setActiveCharacter(characterId);
      initCharacters(id);
    }
  }, []);

  useEffect(() => {
    if (characterId) {
      setActiveCharacter(characterId);
    }
  }, [id, characterId]);

  if (statuses.isPending || updateStatuses.isPending) {
    return (
      <Flex vertical justify="space-between" className="full-height full-width character-info">
        <Spinner isOverlay />
      </Flex>
    );
  }

  return (
    <Flex vertical justify="space-between" className="full-height full-width character-info">
      <CharacterInfoView characterId={activeCharacter ?? characterId ?? ""} />
    </Flex>
  );
};
