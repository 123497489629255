import exportIcon from "assets/storyboard/export-icon.svg";
import { IconButtonWrapper } from "shared/ui";

export const ExportIcon = () => {
  return (
    <IconButtonWrapper>
      <img src={exportIcon} alt="export icon" style={{ width: 16, height: 16 }} />
    </IconButtonWrapper>
  );
};
