import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import chatIcon from "assets/projects/chat-icon.svg";
import shareGreen from "assets/projects/share-green.svg";
import { UsersWithShared } from "shared/ui";

type Props = {
  sharedAccessCount: number;
  commentsCount: number;
  onShare: () => void;
  onManage: () => void;
  isShare: boolean;
};

export const ProjectCardBottomInfo: FC<Props> = ({
  sharedAccessCount,
  commentsCount,
  onShare,
  onManage,
  isShare,
}) => {
  const isMembers = sharedAccessCount === 0;

  return (
    <Flex align="center" className="full-width your-storyboard__info">
      <Flex align="center" justify="space-between" style={{ height: 27 }} className="full-width">
        {isMembers ? (
          <>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextPrimary)}
            >
              No guest members
            </Typography.Text>
            <Flex align="center" role="button" gap={2} className="pointer" onClick={onShare}>
              <img style={{ width: 16, height: 16 }} src={shareGreen} alt="share" />
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.BrandGreen700)}
              >
                Share project
              </Typography.Text>
            </Flex>
          </>
        ) : (
          <>
            {isShare && <UsersWithShared amount={sharedAccessCount} />}
            {!isShare && <UsersWithShared onClick={onManage} amount={sharedAccessCount} />}
            <Flex gap={4} align="center">
              <img src={chatIcon} alt="chat" />
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
              >
                {commentsCount} comments
              </Typography.Text>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
};
