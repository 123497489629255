import { useSize } from "ahooks";
import { Flex, Typography } from "antd";

import cn from "classnames";
import { FC } from "react";
import { GRAYSCALE_TOKEN } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";
import { BackgroundPreLogin, LoginWrapper } from "@/entities/viewer";
import { useBehavior } from "../model";
import { ComingSoonPlanType, EnterprisePlanType, SubPlans, FreeTrialPlan } from "./PlanType";

import "./Plan.scss";

export const Plan: FC = () => {
  const { isMobileL } = useMediaQueries();
  const size = useSize(() => document.querySelector(".login-wrapper"));

  const padding = isMobileL ? 32 : 120;

  const {
    loading,
    planParams,
    startUsing,
    startTrial,
    changePlanParams,
    possibleSubscriptions,
    logout,
    onApplyPromoCode,
    isShowTrial,
  } = useBehavior();

  return (
    <BackgroundPreLogin>
      <LoginWrapper
        title=""
        questionText=""
        actionText=""
        action={() => {}}
        isChildrenFullHeight
        isChildrenFullWidth
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            overflowY: "scroll",
            width: "100%",
            height: "100%",
            maxHeight: (size?.height ?? 0) - padding,
          }}
        >
          <Flex vertical align="flex-start">
            <Typography.Text className={TEXT_CLASSNAMES.Display70}>
              Choose your plan
            </Typography.Text>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.Body30, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
            >
              Switching to another account or just want to sign out?
              <span style={{ paddingLeft: 8 }} />
              <Typography.Link
                underline
                type="secondary"
                onClick={logout}
                role="button"
                className={cn(TEXT_CLASSNAMES.Link30)}
              >
                Sign out
              </Typography.Link>
            </Typography.Text>
          </Flex>
          <Flex style={{ marginTop: 24 }} vertical className="gap-xs">
            {isShowTrial && (
              <FreeTrialPlan
                activePlanParams={planParams}
                onChange={changePlanParams}
                loading={!possibleSubscriptions.isFulfilled}
                fetching={loading}
                onTrial={startTrial}
              />
            )}
            <SubPlans
              loading={!possibleSubscriptions.isFulfilled}
              activePlanParams={planParams}
              onChange={changePlanParams}
              onSubscription={startUsing}
              onApplyPromoCode={onApplyPromoCode}
              fetching={loading}
            />
            <ComingSoonPlanType loading={!possibleSubscriptions.isSettled} />
            <EnterprisePlanType loading={!possibleSubscriptions.isSettled} />
          </Flex>
        </div>
      </LoginWrapper>
    </BackgroundPreLogin>
  );
};
