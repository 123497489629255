import { FC, HTMLAttributes, PropsWithChildren } from "react";

export const IconButtonWrapper: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
  children,
  ...rest
}) => {
  return (
    <div {...rest} className="full-width full-height flex-ctr-ctr">
      {children}
    </div>
  );
};
