import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Form, Input, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GRAYSCALE_TOKEN } from "shared/const/color-names";
import { REG_EXP } from "shared/const/reg-exp";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { useMediaQueries } from "shared/hooks/use-media-queries";
import { DisableSubmitButton } from "shared/ui";
import { BackgroundPreLogin, LoginWrapper, sessionAuthAction } from "@/entities/viewer";
import { invitationAction, invitationCodeAtom, invitationErrorAtom } from "../model";

import "./Invitation.scss";

type FieldType = {
  email: string;
  password: string;
  repeatPassword: string;
};

export const Invitation: FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { isMobileL } = useMediaQueries();
  const [form] = Form.useForm();
  const [error] = useAtom(invitationErrorAtom);
  const [key] = useAtom(invitationCodeAtom);

  const [currentEmail, setCurrentEmail] = useState("");

  const inviteUser = useAction(invitationAction);
  const sessionAuth = useAction(sessionAuthAction);

  const handleSubmit = (data: FieldType) => {
    const params = new URLSearchParams(search);
    const verificationCode = params.get("verification_code");
    const teamId = params.get("team_id");
    const position = params.get("position");
    const email = params.get("email");

    inviteUser({
      verification_code: verificationCode ?? "",
      team_id: teamId ?? "",
      role: "manager",
      position: position?.split(".")[1] ?? "",
      email: email ?? "",
      password: data.password,
      username: email ?? "",
    });
  };

  const onDocumentRedirect = (href: string) => {
    const anchor = document.createElement("a");
    anchor.href = href;
    anchor.target = "_blank";
    anchor.click();
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const email = params.get("email");

    if (email) {
      setCurrentEmail(email);
    }

    if (key === "ok") {
      sessionAuth({ isGuest: true, email: email ?? "" });
      navigate("/plan");
    }
  }, [key]);

  return (
    <BackgroundPreLogin>
      <LoginWrapper
        action={() => {}}
        title="Hello"
        questionText={[
          "You now have access to the project and can start ",
          <br />,
          "collaborating right away.",
          <br />,
          "Just set up your password and jump in!",
        ]}
        actionText=""
        containerClassName="login-container"
      >
        <Form
          className="invitation-form"
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          <Flex vertical className="gap-xs">
            <Input className="invitation-form__email-input" disabled value={currentEmail} />
            <Form.Item<FieldType>
              name="password"
              rules={[
                { required: true, message: "Please input your new password" },
                {
                  pattern: REG_EXP.password,
                  message:
                    "Min. eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item<FieldType>
              dependencies={["password"]}
              name="repeatPassword"
              rules={[
                { required: true, message: "Please input your password" },
                {
                  pattern: REG_EXP.password,
                  message:
                    "Min. eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The new password that you entered do not match"),
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Repeat Password" />
            </Form.Item>
          </Flex>
          <Flex style={{ margin: "32px 0" }} className="full-width">
            <DisableSubmitButton isFullWidth errorMessage={error} type="primary" form={form}>
              Sign up
            </DisableSubmitButton>
          </Flex>
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.Body20, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
          >
            By signing up I agree with{" "}
            <Typography.Link
              underline
              type="secondary"
              onClick={() => onDocumentRedirect("/terms-of-conditions")}
              className={TEXT_CLASSNAMES.Link20}
            >
              Terms of Service
            </Typography.Link>
            {!isMobileL && <br />} and{" "}
            <Typography.Link
              underline
              type="secondary"
              onClick={() => onDocumentRedirect("/privacy-policy")}
              className={TEXT_CLASSNAMES.Link20}
            >
              Privacy Policy
            </Typography.Link>{" "}
            and{" "}
            <Typography.Link
              underline
              type="secondary"
              onClick={() => onDocumentRedirect("/acceptable-use-policy")}
              className={TEXT_CLASSNAMES.Link20}
            >
              Acceptable Use Policy
            </Typography.Link>
          </Typography.Text>
        </Form>
      </LoginWrapper>
    </BackgroundPreLogin>
  );
};
