import { useAction, useAtom } from "@reatom/npm-react";
import {Flex, Skeleton} from "antd";
import { FC, useEffect } from "react";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ScriptPath } from "shared/types/routes.ts";
import {
  addScriptToProjectAction,
  clearHistoryAction,
  descriptionStatusAtom,
  getSceneListAction,
} from "@/entities/script";
import { EmptyDescription } from "@/features/empty-description";
import { HighlightScript } from "@/features/highlight-script";
import {ShotSkeleton} from "@/features/highlight-script/ui/shot-skeleton";

import "./Script.scss";

const ScriptContent: FC = () => {
  const [descriptionStatus] = useAtom(descriptionStatusAtom);
  const initScript = useAction(getSceneListAction);
  const clearHistory = useAction(clearHistoryAction);

  const { id } = useExtractParams<ScriptPath>();

  useEffect(() => {
    initScript(id);

    return () => {
      clearHistory();
    };
  }, []);

  return (
    <Flex className="full-height">
      <Flex vertical className="gap-m full-height description flex-1">
        <div className="full-height description__content">
          {descriptionStatus === "full" && <HighlightScript />}
          {descriptionStatus === "empty" && <EmptyDescription />}
        </div>
      </Flex>
    </Flex>
  );
};

export const Script: FC = () => {
  const [uploadScriptStatuses] = useAtom(addScriptToProjectAction.statusesAtom);
  const fakeSkeletons = [...Array(10)];

  if (!uploadScriptStatuses.isPending) {
    return <ScriptContent />
  }

  return (
    <Flex vertical className="highlight-the-shots full-height gap-xs">
      <Flex justify="center">
        <Skeleton style={{ width: "50%" }} active paragraph={false} />
      </Flex>
      <Flex vertical className="gap-s">
        {fakeSkeletons.map((el) => <ShotSkeleton key={el} />)}
      </Flex>
    </Flex>
  );
};
