import { useAtom } from "@reatom/npm-react";
import { Button, Flex, notification, Skeleton, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { trackGoogleAnalyticsEvent } from "@/shared/methods/track-analytics.ts";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";
import info from "assets/plan/info.svg";
import { viewerAtom } from "@/entities/viewer";

import "./PlanType.scss";

const KEY = "KEY";

type Props = {
  loading: boolean;
};

export const EnterprisePlanType: FC<Props> = ({ loading }) => {
  const { isMobileL } = useMediaQueries();
  const [viewer] = useAtom(viewerAtom);
  const [api, contextHolder] = notification.useNotification();

  const onTextClick = () => {
    trackGoogleAnalyticsEvent("enterprise", "enterprise", "enterprise", {
      email: viewer.email.replace("@", "_"),
    });

    api.open({
      key: KEY,
      message: (
        <Flex vertical gap={16}>
          <Flex gap={8}>
            <img src={info} alt="info" style={{ width: 24, height: 24 }} />
            <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
              Thank you for reaching out! Currently, only the Single User subscription plan is
              available for use. However, we’d be happy to discuss the Enterprise plan details with
              you. Our team will contact you shortly to explore your needs and provide more
              information about the Enterprise option.
            </Typography.Text>
          </Flex>
          <Flex gap={32}>
            <div />
            <Button onClick={() => api.destroy(KEY)}>Okay</Button>
          </Flex>
        </Flex>
      ),
      placement: "top",
      pauseOnHover: false,
      style: {
        width: 500,
      },
      duration: 8,
    });
  };

  if (loading) {
    return (
      <Flex className="plan-type">
        <Skeleton active paragraph={{ rows: 2 }} />
      </Flex>
    );
  }

  return (
    <Flex
      gap={8}
      vertical
      className={cn("plan-type cubic-animation", {
        "plan-type--disabled": true,
      })}
    >
      {contextHolder}
      <Flex align={isMobileL ? "center" : "initial"}>
        <span className={cn("plan-type__radio cubic-animation")} />
        <Flex vertical className="plan-type__text">
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.MdSemibold, {
              [TEXT_CLASSNAMES.XsSemibold]: isMobileL,
            })}
          >
            Enterprise
          </Typography.Text>
          {!isMobileL && (
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}
            >
              Unlimited users, Unlimited projects, No watermarks
            </Typography.Text>
          )}
        </Flex>
        {isMobileL && <div style={{ flex: 1 }} />}
        <Flex vertical>
          <Flex className="gap-xxs" align="center">
            <Button
              onClick={onTextClick}
              style={{
                minWidth: "max-content",
                textDecoration: "none",
                cursor: "pointer",
                zIndex: 10,
              }}
              size="small"
            >
              Contact us for a custom plan
            </Button>
          </Flex>
        </Flex>
      </Flex>
      {isMobileL && (
        <Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>
          Unlimited users, unlimited projects, no watermarks
        </Typography.Text>
      )}
    </Flex>
  );
};
