import { AxiosResponse } from "axios";
import { api } from "@/shared/api";

import {
  GenerateImageResult,
  GenerateShotParams,
  GenerateShotResult,
  GetImagesShotsResult,
  GetShotImageVersionParams,
  GetShotParams,
  GetShotResult,
  GetStoryboardStatusResult,
  RegenerateImageParams,
  SetCustomImageParams,
  SetMainImageParams,
  ShotOrderResult,
  ShotVersion,
  TStoryboardResponse,
  UpdateSceneParams,
  UpdateShotParams,
} from "./types";
import { TResponse } from "@/vite-env";

export const getStoryboardResource = (
  projectId: string,
  controller: AbortController,
): Promise<AxiosResponse<TStoryboardResponse>> =>
  api.get(`/storyboards/${projectId}/full`, { signal: controller.signal });

export const getStoryboardShotResource = (
  params: GetShotParams,
  controller: AbortController,
): TResponse<GetShotResult> =>
  api.get(`/storyboards/${params.projectKey}/scenes/${params.sceneId}/shots/${params.shotId}`, {
    signal: controller.signal,
  });

function validURL(str: string) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locator
  return !!pattern.test(str);
}

export const generateStoryboardShotResource = async (
  params: GenerateShotParams,
): TResponse<GenerateImageResult> => {
  const res = await api.post<GenerateShotResult>(
    `/storyboards/${params.projectKey}/scenes/${params.sceneId}/shots/${params.shotId}/image`,
  );

  if (validURL(res.data.image_url_compressed)) {
    return res;
  }

  throw "Error generating image";
};

export const regenerateImageResource = async (
  params: RegenerateImageParams,
): TResponse<GenerateImageResult> => {
  await api.patch(
    `/storyboards/${params.projectKey}/scenes/${params.sceneId}/shots/${params.shotId}/regenerate_image`,
  );
  const res = await api.get<GenerateShotResult>(
    `/storyboards/${params.projectKey}/scenes/${params.sceneId}/shots/${params.shotId}`,
  );

  if (validURL(res.data.image_url_compressed)) {
    return res;
  }

  throw "Error generating image";
};

export const updateStoryboardShotResource = (params: UpdateShotParams) =>
  api.patch(
    `/storyboards/${params.projectKey}/scenes/${params.sceneId}/shots/${params.shotId}/details`,
    {
      shot_info: params.shot_info,
      shots_order: params.shots_order,
    },
  );

export const updateStoryboardSceneResource = (params: UpdateSceneParams) =>
  api.put(`/storyboards/${params.projectKey}/scenes`, {
    scene_info: params.scene_info,
    scenes_order: params.scenes_order,
  });

export const setCustomImageResource = (params: SetCustomImageParams, image: FormData) =>
  api.post(
    `/storyboards/${params.projectKey}/scenes/${params.sceneId}/shots/${params.shotId}/custom_main_image`,
    image,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

export const changeMainImageResource = (params: SetMainImageParams) =>
  api.patch(
    `/storyboards/${params.projectKey}/scenes/${params.sceneId}/shots/${params.shotId}/main_image`,
    {
      image_id: params.imageId,
    },
  );

export const getShotImagesVersionsResource = (
  params: GetShotImageVersionParams,
): TResponse<ShotVersion[]> =>
  api.get(
    `/storyboards/${params.projectKey}/scenes/${params.sceneId}/shots/${params.shotId}/images`,
  );

export const getOrderedShotsResource = (
  projectKey: string,
  controller: AbortController,
): TResponse<ShotOrderResult> =>
  api.get(`/storyboards/${projectKey}/scenes/shots/ordered`, { signal: controller.signal });

export const getImagesShotsResource = (
  projectKey: string,
  controller?: AbortController,
): TResponse<GetImagesShotsResult> =>
  api.get(`/storyboards/${projectKey}/scenes/shots/images`, { signal: controller?.signal });

export const getStoryboardStatusResource = (
  projectKey: string,
  controller?: AbortController,
): TResponse<GetStoryboardStatusResult> =>
  api.get(`/storyboards/${projectKey}/status`, { signal: controller?.signal });
