import { Flex, Typography } from "antd";
import cn from "classnames";
import { Dispatch, FC, SetStateAction } from "react";
import { Descendant } from "slate";
import { Editable, Slate } from "slate-react";

import { ShotColor } from "@/shared/api/script";
import plus from "assets/shared/add_shot_script.svg";
import plusGray from "assets/shared/plus_gray.svg";

import { GRAYSCALE_TOKEN } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { ActionRestrictor } from "shared/ui";
import { ShotTitle } from "../shot-title";
import { SplitShotButton } from "../split-shot-button";
import { LastShot } from "./LastShot";
import { useBehavior } from "./useBehavior";

import "./Shot.scss";

interface IShot {
  color: ShotColor;
  title: string;
  content: Descendant[];
  shotId: string;
  sceneId: string;
  shotIdx: number;
  isAddButtonDisabled: boolean;
  setIsAddButtonDisabled: Dispatch<SetStateAction<boolean>>;
  sceneName: string;
  shotsLength: number;
  projectLastShotId: string;
  description: string;
}

export const Shot: FC<IShot> = ({
  color = "red",
  content,
  title,
  shotId,
  sceneId,
  shotIdx,
  isAddButtonDisabled,
  setIsAddButtonDisabled,
  sceneName,
  shotsLength,
  projectLastShotId,
}) => {
  const {
    onFocus,
    onBlur,
    onChange,
    handleSplit,
    handleSelect,
    handleAddShot,
    toggleHover,
    subscription,
    scriptLength,
    isHover,
    isOutlined,
    isActive,
    descriptionRef,
    ref,
    setEditingShot,
    isShare,
    prevSelection,
    isSelectionStarted,
    splitShotButtonPosition,
    onDomBeforeInput,
    localShotValue,
    editor
  } = useBehavior({ content, setIsAddButtonDisabled, shotId, sceneId, isAddButtonDisabled })

  const isLast = projectLastShotId === shotId;

  const disabled = scriptLength >= (subscription?.num_symbols || 10000);

  const LIMIT_TEXT = {
    disabled: ` You’ve reached the symbol limit: ${subscription?.num_symbols || 10000}/${
      subscription?.num_symbols || 10000
    }`,
    active: `${scriptLength}/${subscription?.num_symbols || 10000}`,
  };

  const notOutlinedHover = isHover && !isOutlined;
  const notOutlinedActive = isActive && !isOutlined;

  return (
    <>
      <Flex
        onClick={onFocus}
        vertical
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        className={cn("shot")}
      >
        <ShotTitle
          color={color}
          isHover={isHover || isActive}
          isActive={isActive}
          shotIdx={shotIdx}
          title={title}
          shotId={shotId}
          sceneId={sceneId}
          isContent={!!descriptionRef.current}
          sceneName={sceneName}
          projectLastShotId={projectLastShotId}
          shotsLength={shotsLength}
        />
        <div
          onClick={() => setEditingShot(shotId)}
          ref={ref}
          style={{ position: "relative" }}
          className={cn("full-width", "shot__body")}
        >
          <ActionRestrictor show={!isShare}>
            {prevSelection && !isSelectionStarted && (
              <SplitShotButton position={splitShotButtonPosition} onClick={handleSplit} />
            )}
          </ActionRestrictor>
          <Slate
            onSelectionChange={handleSelect}
            onChange={onChange}
            editor={editor}
            initialValue={localShotValue}
          >
            <Editable
              onFocus={onFocus}
              onBlur={onBlur}
              className={cn("shot__text full-width", {
                [`shot__text__${color}`]: isOutlined,
                "shot__text--hover": isHover,
                "shot__text--active": isOutlined,
                "shot__text--hover-noColor": notOutlinedHover,
                "shot__text--active-noColor": notOutlinedActive,
              })}
              onDOMBeforeInput={onDomBeforeInput}
              readOnly={isShare}
            />
          </Slate>
          {isActive && (
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XxsRegular, GRAYSCALE_TOKEN.TEXT_ICON.BODY.color)}
              style={{ position: "absolute", bottom: -20, right: 15 }}
            >
              {LIMIT_TEXT[disabled ? "disabled" : "active"]}
            </Typography.Text>
          )}
        </div>
      </Flex>
      <ActionRestrictor show={!isShare}>
        {isLast ? (
          <LastShot shotId={shotId} sceneId={sceneId} />
        ) : (
          <>
            <Flex
              onClick={handleAddShot}
              role="button"
              align="center"
              justify="center"
              className="shot__add-shot cubic-animation pointer"
            >
              <Flex className="shot__add-line pointer" align="center" justify="center">
                <img src={isAddButtonDisabled ? plusGray : plus} alt="plus" className="shot__img" />
              </Flex>
            </Flex>
          </>
        )}
      </ActionRestrictor>
    </>
  );
};
