import { useAtom } from "@reatom/npm-react";
import { captureException, setUser } from "@sentry/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Flex } from "antd";
import { FC, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { getPaymentCredentialsResource, PaymentCredentials } from "@/shared/api/payment";
import { centsToDollars } from "shared/methods";
import { Spinner } from "shared/ui";
import { BillingWrapper } from "@/entities/payment";
import { BackgroundPreLogin, viewerAtom } from "@/entities/viewer";
import { StripePaymentDetails } from "../../../features/payment-details";

import { PaymentSchedule } from "./payment-schedule";
import { PaymentSummary } from "./payment-summary";

export const Payment: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState<PaymentCredentials | null>(null);
  const [interval, setCustomInterval] = useState("");
  const [viewer] = useAtom(viewerAtom);
  const [stripe, setStripe] = useState<Promise<Stripe | null>>(Promise.resolve(null));

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);

        const { data: credentials } = await getPaymentCredentialsResource();

        if (credentials === null) {
          navigate("/plan");
        } else {
          if (credentials?.message === "Subscription was voided, please renew") {
            navigate("/plan");
          } else {
            setCustomInterval("month");
            setCustomer({
              ...credentials,
              upcoming_invoice: credentials.upcoming_invoice.map((invoice) => ({
                ...invoice,
                amount: centsToDollars(invoice.amount),
              })),
              current_invoice: {
                ...credentials.current_invoice,
                amount: centsToDollars(credentials.current_invoice.amount),
              },
            });
          }
        }
      } catch (e) {
        setUser({
          email: viewer.email,
        });
        captureException(e, {
          tags: {
            feature: "get-payment-credentials",
          },
          level: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  useEffect(() => {
    const stripePromise = loadStripe(process.env.VITE_STRIPE_KEY, { locale: "en" });

    setStripe(stripePromise);
  }, []);

  return (
    <BackgroundPreLogin>
      <BillingWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            overflowY: "scroll",
            width: "100%",
            height: "100%",
          }}
        >
          {loading && (
            <Flex align="center" justify="center">
              <Spinner />
            </Flex>
          )}
          {customer && (
            <Elements
              stripe={stripe}
              options={{
                clientSecret: customer.client_secret,
              }}
            >
              <Flex className="gap-m" vertical>
                <PaymentSummary interval={interval} sub={customer} />
                <PaymentSchedule sub={customer} />
                <StripePaymentDetails />
              </Flex>
            </Elements>
          )}
        </div>
      </BillingWrapper>
    </BackgroundPreLogin>
  );
};
