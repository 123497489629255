import Konva from "konva";
import { IRect } from "konva/lib/types";
import { MutableRefObject } from "react";

export const toCanvasImage = (data: string, x: number, y: number) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const image = new Image();
  image.onload = function () {
    if (ctx) {
      ctx?.drawImage(image, x, y);
    }
  };

  image.src = data;

  return image;
};

export const getPosition = (
  transformerRef: MutableRefObject<null | Konva.Transformer>,
): { x: number; y: number } => {
  let x = 0;
  let y = 0;
  const tr = transformerRef.current;

  tr?.nodes().forEach((shape) => {
    const absPos = shape.getAbsolutePosition();

    x = absPos.x;
    y = absPos.y;
  });

  return { x: Math.round(x), y: Math.round(y) };
};

export function getTotalBox(boxes: IRect[]) {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  boxes.forEach((box: IRect) => {
    minX = Math.min(minX, box.x);
    minY = Math.min(minY, box.y);
    maxX = Math.max(maxX, box.x + box.width);
    maxY = Math.max(maxY, box.y + box.height);
  });
  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
}

export const getSize = (imageRef: MutableRefObject<null | Konva.Image>) => {
  const image = imageRef.current;

  const w = image?.width() ?? 0;
  const h = image?.height() ?? 0;

  const scaleX = image?.scaleX() ?? 0;
  const scaleY = image?.scaleY() ?? 0;

  return {
    width: Math.round(w * scaleX),
    height: Math.round(h * scaleY),
  };
};

export const toBorder = (
  transformerRef: MutableRefObject<null | Konva.Transformer>,
  parentWidth: number,
  parentHeight: number,
) => {
  const tr = transformerRef.current;
  if (tr) {
    const boxes = tr.nodes().map((node) => node.getClientRect());
    const box = getTotalBox(boxes);

    tr.nodes().forEach((shape) => {
      const absPos = shape.getAbsolutePosition();
      // where are shapes inside bounding box of all shapes?
      const boxX = box.x;
      const boxY = box.y;

      const offsetX = boxX - absPos.x;
      const offsetY = boxY - absPos.y;

      // we total box goes outside of viewport, we need to move absolute position of shape
      const newAbsPos = { ...absPos };
      if (boxX < 0) {
        newAbsPos.x = -offsetX;
      }
      if (boxY < 0) {
        newAbsPos.y = -offsetY;
      }
      if (boxX + box.width > parentWidth) {
        newAbsPos.x = parentWidth - box.width - offsetX;
      }
      if (boxY + box.height > parentHeight) {
        newAbsPos.y = parentHeight - box.height - offsetY;
      }

      shape.setAbsolutePosition(newAbsPos);
    });
  }
};

export const getRandomCoordinatesInRectangle = (
  centerX: number,
  centerY: number,
  width: number,
  height: number,
  imageW: number,
  imageH: number,
) => {
  const offsetX = (Math.random() - 0.5) * width;
  const offsetY = (Math.random() - 0.5) * height;

  let x = Math.round(centerX + offsetX);
  let y = Math.round(centerY + offsetY);

  if (width - x < imageW) {
    x -= imageW;
  }
  if (height - y < imageH) {
    y -= imageH;
  }
  if (x < 0) x = 0;
  if (y < 0) y = 0;

  return { x, y };
};

export const REFRESH_TIME_SAVE = 60 * 500;

export const getNewCoordinatesAfterTransform = (imageRef: MutableRefObject<null | Konva.Image>) => {
  const node = imageRef.current!;

  const scaleX = node.scaleX();
  const scaleY = node.scaleY();

  const newW = node.width() * scaleX;
  const newH = node.height() * scaleY;

  const w = node?.width() ?? 0;
  const h = node?.height() ?? 0;

  node.scaleX(1);
  node.scaleY(1);

  return {
    x: node.x(),
    y: node.y(),
    w: Math.round(newW),
    h: Math.round(newH),
    minH: Math.round(h),
    minW: Math.round(w),
  };
};
