import {
  reatomAsync,
  withAbort,
  withDataAtom,
  withErrorAtom,
  withStatusesAtom,
} from "@reatom/async";

import { atom } from "@reatom/core";
import { CharacterTab, TCharacter } from "@/entities/character";
import { TRightSidebarView } from "@/entities/moodboard";
import { callErrorAction } from "@/entities/notification";
import {
  CharacterUpdateDTO,
  getCharactersResource,
  updateCharacterResource,
} from "@/shared/api/characters";
import { toCharacterInfo } from "@/shared/methods/to-character.ts";

export const characterSidebarView = atom<TRightSidebarView>("comments");

export const getCharactersAction = reatomAsync(async (ctx, projectId: string) =>
  getCharactersResource(projectId, ctx.controller),
).pipe(
  withAbort(),
  withDataAtom([], (_ctx, res) => {
    const characters: Record<string, never> = res.data.characters.characters.reduce(
      (acc, character) => ({
        ...acc,
        [character.character_key]: character.name,
      }),
      {},
    );

    const result: TCharacter[] = Object.keys(characters).map((id) => ({
      name: characters[id],
      id,
      description: toCharacterInfo(id).description,
      img: toCharacterInfo(id)["1"],
    }));

    return result.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }),
  withStatusesAtom(),
  withErrorAtom((ctx, err) => callErrorAction(ctx, err)),
);

export const updateCharacterAction = reatomAsync((ctx, id: string, params: CharacterUpdateDTO) =>
  updateCharacterResource(id, params, ctx.controller),
).pipe(
  withAbort(),
  withDataAtom(),
  withStatusesAtom(),
  withErrorAtom((ctx, err) => callErrorAction(ctx, err)),
);

export const characterTabAtom = atom<CharacterTab>("all");
export const activeCharacterAtom = atom<string | null>(null);
