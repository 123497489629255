import {
  reatomAsync,
  withAbort,
  withCache,
  withDataAtom,
  withErrorAtom,
  withStatusesAtom,
} from "@reatom/async";
import { action, atom } from "@reatom/core";
import { AxiosError } from "axios";

import { callErrorAction } from "@/entities/notification";
import { getPermissionParams } from "@/entities/projects/lib/utils.ts";
import { leaveProjectResource } from "@/shared/api/guests";
import {
  addThumbnailResource, createProjectResource,
  deleteProjectResource,
  getAllProjectsResource,
  getProjectResource,
  ProjectResult,
  shareProjectResource,
  updateProjectNameResource,
} from "@/shared/api/project";
import { getImagesShotsResource, GetImagesShotsResult } from "@/shared/api/storyboard";
import { isObjectEmpty } from "shared/methods";
import { ProjectType } from "shared/types/page.ts";
import {
  RenameProjectParams,
  ShareProjectParams, TNewProject,
  TProject,
  UserPermissionInitParams,
} from "../lib";

export const getProjectByNameAction = reatomAsync((_ctx, name: string) =>
  getProjectResource(name),
).pipe(
  withDataAtom(null, (_ctx, res) => res?.data ?? null),
  withStatusesAtom(),
  withErrorAtom((ctx, err) => callErrorAction(ctx, err as AxiosError)),
);

export const projectsListAtom = atom<TProject[]>([], "projectsListAtom");
export const projectsLoadingAtom = atom(false, "projectsLoadingAtom");
export const uploadThumbnailLoadingKeyAtom = atom<string | null>(null, "uploadThumbnailAtom");

export const createProjectAction = reatomAsync(async (ctx, project: TNewProject) => {
  return await createProjectResource({
    name: project.name ?? "",
    brief: project.briefing ?? "",
    location_details: {
      country: project.country ?? "",
      city: project.city ?? "",
    },
  }, ctx.controller);
}).pipe(withStatusesAtom(), withAbort());

export const initProjectsAction = action(async (ctx) => {
  try {
    projectsLoadingAtom(ctx, true);
    const { data } = await getAllProjectsResource();

    const toProject = (project: ProjectResult, isShare: boolean): TProject => ({
      date: project.creation_time,
      img: project.thumbnail_url,
      projectKey: project.key,
      projectName: project.name,
      sharedAccessCount: project.shared_access_count,
      commentsCount: project.comments_count,
      projectType: isShare ? "share" : "own",
    });

    const initProjects = [
      ...data.names.map((project) => toProject(project, false)),
      ...data.shared_names.map((project) => toProject(project, true)),
    ];

    const projectWithThumbnails = await Promise.all(
      initProjects.map(async (project) => {
        if (project.img) {
          return project;
        }

        const images = await getImagesShotsResource(project.projectKey)
          .then((res) => res.data)
          .catch(() => {
            return {} as GetImagesShotsResult;
          });

        if (isObjectEmpty(images)) {
          return project;
        }

        const thumbnails: string[] = [];

        Object.keys(images).forEach((key) => {
          if (images[key].bw_image_url_compressed && images[key].bw_image_url) {
            thumbnails.push(images[key].bw_image_url_compressed);
          }
        });

        const thumbnail = thumbnails[thumbnails.length - 1];

        return {
          ...project,
          img: thumbnail,
        };
      }),
    );

    projectsListAtom(ctx, projectWithThumbnails);
  } catch (e: unknown) {
    const error = e as AxiosError;

    // @ts-ignore
    if (error?.response?.data?.message !== "Guest has not access to this endpoint") {
      callErrorAction(ctx, e);
    }
  } finally {
    projectsLoadingAtom(ctx, false);
  }
});

export const renameProjectByIdAction = action(async (ctx, params: RenameProjectParams) => {
  try {
    const projectList = ctx.get(projectsListAtom);

    await updateProjectNameResource(params.projectKey, params.newName);

    const newProjects = projectList.map((project) => ({
      ...project,
      projectName: project.projectKey === params.projectKey ? params.newName : project.projectName,
    }));

    projectsListAtom(ctx, newProjects);
  } catch (err) {
    callErrorAction(ctx, err as AxiosError);
  }
});

export const deleteProjectByIdAction = action(async (ctx, projectKey: string) => {
  try {
    const projectList = ctx.get(projectsListAtom);

    await deleteProjectResource(projectKey);

    const newProjects = projectList.filter((project) => project.projectKey !== projectKey);

    projectsListAtom(ctx, newProjects);
  } catch (e) {
    callErrorAction(ctx, e as AxiosError);
  }
});

export const leaveProjectByIdAction = action(async (ctx, projectKey: string) => {
  try {
    const projectList = ctx.get(projectsListAtom);
    await leaveProjectResource(projectKey);

    const newProjects = projectList.filter((project) => project.projectKey !== projectKey);

    projectsListAtom(ctx, newProjects);
  } catch (e) {
    callErrorAction(ctx, e as AxiosError);
  }
});

export const uploadThumbnailAction = action(async (ctx, projectKey: string, formData: FormData) => {
  try {
    uploadThumbnailLoadingKeyAtom(ctx, projectKey);
    const { data: url } = await addThumbnailResource(projectKey, formData);

    const projectList = ctx.get(projectsListAtom);

    const newProjects = projectList.map((project) => ({
      ...project,
      img: project.projectKey === projectKey ? url : project.img,
    }));

    projectsListAtom(ctx, newProjects);
  } catch (e) {
    callErrorAction(ctx, e as AxiosError);
  } finally {
    uploadThumbnailLoadingKeyAtom(ctx, null);
  }
});

export const getProjectStoryboardImagesActions = reatomAsync((_ctx, projectKey: string) =>
  getImagesShotsResource(projectKey),
).pipe(
  withDataAtom([], (_ctx, res) => {
    const images = Object.entries(res.data).map(([id, img]) => ({
      img,
      id,
    }));

    return images;
  }),
  withErrorAtom(callErrorAction),
  withStatusesAtom(),
);

export const shareProjectAction = action(async (ctx, params: ShareProjectParams) => {
  try {
    const permissions = getPermissionParams(params.projectShareParams, params.permissionGroup);

    await shareProjectResource({
      permissions,
      project_key: params.projectKey,
      user_email: params.userEmail,
    });
  } catch (e) {
    callErrorAction(ctx, e);
  }
});

export const userPermissionInitAction = reatomAsync(
  async (ctx, params: UserPermissionInitParams) => {
    const { data: project } = await getProjectResource(params.projectKey, ctx.controller);

    return {
      ...params,
      sharedAccess: project.shared_access,
      projectName: project.name,
    };
  },
  "userPermissionInit",
).pipe(
  withDataAtom(
    { projectType: "share" as ProjectType, projectName: "" },
    (_ctx, { sharedAccess, email, projectName }) => {
      const access = sharedAccess?.find((el) => el.email === email);

      if (access) {
        return { projectType: "share" as ProjectType, projectName };
      }

      return { projectType: "own" as ProjectType, projectName };
    },
  ),
  withStatusesAtom(),
  withCache(),
  withAbort(),
  withErrorAtom(),
);
