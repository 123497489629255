import { Flex, Skeleton } from "antd";
import { FC } from "react";
import { SettingsWrapper } from "@/entities/settings";

export const SettingsProfileSkeleton: FC = () => (
  <SettingsWrapper
    className="gap-s settings-profile"
    title="Profile Settings"
    description="These are your personal details, visible to your team members"
  >
    <Flex vertical gap={31.7}>
      <Skeleton title={false} paragraph={{ rows: 5 }} />
      <Flex vertical className="gap-xs">
        <Flex vertical className="gap-4 flex-1">
          <Skeleton title={false} paragraph={{ rows: 1 }} />
          <Skeleton.Input style={{ width: "100%" }} active size="large" />
        </Flex>
        <Flex vertical className="gap-4 flex-1">
          <Skeleton title={false} paragraph={{ rows: 1 }} />
          <Skeleton.Input style={{ width: "100%" }} active size="large" />
        </Flex>
        <Flex vertical className="gap-4 flex-1">
          <Skeleton title={false} paragraph={{ rows: 1 }} />
          <Skeleton.Input style={{ width: "100%" }} active size="large" />
        </Flex>
      </Flex>
      <Flex className="gap-xs">
        <Skeleton.Button style={{ width: "100%" }} className="flex-1" active size="large" />
        <Skeleton.Button style={{ width: "100%" }} className="flex-1" active size="large" />
      </Flex>
    </Flex>
  </SettingsWrapper>
);
