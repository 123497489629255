import { createContext } from "react";

export interface IStepsContextProps {
  index: number;
  isFirst: boolean;
  isLast: boolean;
  isActive: boolean;
  isCompleted: boolean;
  isValid: boolean;
  onSelect?: (step: number) => void;
}

export const StepsContext = createContext({} as IStepsContextProps);
