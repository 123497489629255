import { reatomAsync, withAbort, withStatusesAtom } from "@reatom/async";
import { atom } from "@reatom/core";
import axios from "axios";
import TagManager from "react-gtm-module";
import {
  createUserGoogleResource,
  createUserResource,
  GoogleToken,
  loginUserGoogleResource,
  TRegisterCredentials,
} from "@/shared/api/auth";
import { setPathAction } from "@/entities/notification";
import {
  sessionAuthAction,
  sessionAuthSubscriptionAction,
  sessionNotAuthAction,
  viewerModel,
} from "@/entities/viewer";
import { axiosErrorHandler } from "@/shared/api";
import { IMessageError } from "@/vite-env";

export const errorRegisterMessageAtom = atom<null | string>(null, "errorRegisterMessageAtom");

export const regularRegisterAction = reatomAsync(
  async (ctx, params: TRegisterCredentials) => {
    await createUserResource(
      {
        password: params.password,
        email: params.email.toLowerCase(),
        name: params.name,
      },
      ctx.controller,
    );

    return {
      email: params.email.toLowerCase(),
    };
  },
  {
    onFulfill: async (ctx, { email }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: "sign_up",
          signupMethod: "email",
          signupEmail: email,
        },
      });

      sessionNotAuthAction(ctx, { isGuest: false, email });

      setPathAction(ctx, `/success-email/${email}`);
    },
    onReject: (ctx, err) => {
      const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

      if (type === "axios-error") {
        if (error.response?.status === 403) {
          errorRegisterMessageAtom(ctx, "Incorrect email or password");
          return;
        }

        if (error.response?.status === 409) {
          errorRegisterMessageAtom(ctx, "Please try a different email");
          return;
        }
      }

      errorRegisterMessageAtom(ctx, "Oops, something went wrong");
    },
  },
).pipe(withAbort(), withStatusesAtom());

export const onRegisterGoogleLoginAction = reatomAsync(
  async (ctx, token: GoogleToken) => {
    const decoded = await axios.get<{ email: string }>(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token.access_token}`,
    );
    await loginUserGoogleResource(token, ctx.controller);

    return {
      email: decoded.data.email,
    };
  },
  {
    onFulfill: async (ctx, { email }) => {
      await viewerModel.viewerSubscription(ctx);
      sessionAuthAction(ctx, { isGuest: false, email });
      setPathAction(ctx, "/projects");
    },
    onReject: (ctx, err) => {
      const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

      if (type === "axios-error" && error.status === 409) {
        errorRegisterMessageAtom(ctx, "Incorrect email or password");
        return;
      }

      errorRegisterMessageAtom(ctx, "Oops, something went wrong");
    },
  },
).pipe(withAbort(), withStatusesAtom());

const errorRegisterGoogleToken = atom<null | GoogleToken>(null, "errorRegisterGoogleToken");
export const googleRegisterAction = reatomAsync(
  async (ctx, token: GoogleToken) => {
    errorRegisterGoogleToken(ctx, token);
    const decoded = await axios.get<{ email: string }>(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token.access_token}`,
    );
    await createUserGoogleResource(token, ctx.controller);

    return {
      email: decoded.data.email,
    };
  },
  {
    onFulfill: async (ctx, { email }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: "sign_up",
          signupMethod: "google",
          signupEmail: email,
        },
      });
      sessionAuthSubscriptionAction(ctx, { isGuest: false, email });
      setPathAction(ctx, "/plan");
    },
    onReject: (ctx, err) => {
      const token = ctx.get(errorRegisterGoogleToken);
      const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

      if (type === "axios-error") {
        if (error.response?.status === 409 && token) {
          onRegisterGoogleLoginAction(ctx, token);
          return;
        }
      }

      errorRegisterMessageAtom(ctx, "Oops, something went wrong");
    },
  },
).pipe(withAbort(), withStatusesAtom());
