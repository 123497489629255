import { useAtom } from "@reatom/npm-react";
import { Flex, Form, Input, Typography } from "antd";
import cn from "classnames";

import { useMemo } from "react";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { DisableSubmitButton } from "shared/ui";
import { projectsListAtom, TNewProject } from "@/entities/projects";

import { newProjectAtom, stepAtom } from "../model";

type TFiled = Pick<TNewProject, "name">;

export const FirstStepForm = () => {
  const [projects] = useAtom(projectsListAtom);
  const [_step, setStep] = useAtom(stepAtom);
  const [newProject, setNewProject] = useAtom(newProjectAtom);
  const [form] = Form.useForm();

  const onFinish = (data: TFiled) => {
    setNewProject({
      ...newProject,
      ...data,
      name: data.name?.trim() ?? "",
    });
    setStep("2");
  };

  const fields = useMemo(() => {
    const result = [];
    result[0] = { name: "name", value: newProject.name };

    return result;
  }, []);

  return (
    <Form fields={fields} onFinish={onFinish} form={form}>
      <Flex vertical className="gap-s">
        <Flex vertical>
          <Typography.Text className={TEXT_CLASSNAMES.Title50}>
            Creating a new project
          </Typography.Text>
          <Typography.Text className={cn(TEXT_CLASSNAMES.Body20, COLOR_CLASSNAMES.TextSecondary)}>
            Create name of the new project
          </Typography.Text>
        </Flex>
        <Flex vertical className="gap-xs">
          <Form.Item<TFiled>
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your project name",
              },
              {
                message: "This name is already in use",
                validator: (_, value) => {
                  const projectNames = projects.map((el) => el.projectName);
                  const newProjectName = value?.trim();

                  if (!projectNames.includes(newProjectName)) {
                    return Promise.resolve();
                  } else {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject();
                  }
                },
              },
            ]}
          >
            <Input defaultValue={newProject.name ?? ""} placeholder="Type here storyboard name" />
          </Form.Item>
        </Flex>
        {/* <Flex vertical className="gap-xs"> */}
        {/*	<Typography.Text className={TEXT_CLASSNAMES.MdSemibold}> */}
        {/*		Style */}
        {/*	</Typography.Text> */}
        {/*	<Form.Item<TFiled> */}
        {/*		name="style" */}
        {/*		rules={[{ required: true, message: "Please select your project style!" }]} */}
        {/*	> */}
        {/*		<Radio.Group name="style" defaultValue={newProject.style}> */}
        {/*			<Flex align="center" className="gap-xxs"> */}
        {/*				<CustomRadio name="style" value="photorealistic" text="Photorealistic" /> */}
        {/*				<CustomRadio name="style" value="sketch" text="Sketch" /> */}
        {/*			</Flex> */}
        {/*		</Radio.Group> */}
        {/*	</Form.Item> */}
        {/* </Flex> */}
        {/* <Flex vertical className="gap-xs"> */}
        {/*	<Typography.Text className={TEXT_CLASSNAMES.MdSemibold}> */}
        {/*		Image format */}
        {/*	</Typography.Text> */}
        {/*	<Form.Item<TFiled> */}
        {/*		name="imageFormat" */}
        {/*		rules={[{ required: true, message: "Please select your project style!" }]} */}
        {/*	> */}
        {/*		<Radio.Group name="imageFormat" defaultValue={newProject.imageFormat}> */}
        {/*			<Flex align="center" className="gap-xxs"> */}
        {/*				<CustomRadio name="imageFormat" value="social" text="Social 4:5" /> */}
        {/*				<CustomRadio name="imageFormat" value="tv" text="TV 4:3" /> */}
        {/*				<CustomRadio name="imageFormat" value="landscape" text="Landscape 16:9" /> */}
        {/*			</Flex> */}
        {/*		</Radio.Group> */}
        {/*	</Form.Item> */}
        {/* </Flex> */}
        <DisableSubmitButton type="primary" form={form}>
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Next</Typography.Text>
        </DisableSubmitButton>
      </Flex>
    </Form>
  );
};
