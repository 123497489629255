import { GetPermissions } from "./types";

export const getPermissions = ({
  resolved,
  parentId,
  currUserEmail,
  commentUserEmail,
}: GetPermissions) => {
  const canResolve = !resolved && !parentId;
  const canUnresolve = resolved && !parentId;
  const canDeleteThread = commentUserEmail === currUserEmail && Boolean(!parentId);
  const canDeleteMessage = commentUserEmail === currUserEmail && Boolean(parentId);

  return { canResolve, canDeleteMessage, canUnresolve, canDeleteThread };
};
