import { Flex } from "antd";
import { FC } from "react";
import { toCharacterInfo } from "@/shared/methods/to-character.ts";

type Props = {
  characterId: string;
};

export const CharacterInfoView: FC<Props> = ({ characterId }) => {
  const character = toCharacterInfo(characterId);

  return (
    <Flex align="center" justify="space-between" className="gap-s full-height">
      <Flex className="gap-s character-info-view__content full-height full-width">
        <Flex vertical className="gap-s flex-1">
          <div
            className="flex-1 character-info-view__img character-info-view__img--3"
            style={{ backgroundImage: `url(${character["3"]})` }}
          />
          <div
            className="flex-1 character-info-view__img character-info-view__img--2"
            style={{ backgroundImage: `url(${character["2"]})` }}
          />
        </Flex>
        <Flex className="flex-1">
          <div
            className="flex-1 character-info-view__img character-info-view__img--1"
            style={{ backgroundImage: `url(${character["1"]})` }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
