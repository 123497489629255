import backIcon from "assets/shared/arrow_left_16.svg";
import { IconButtonWrapper } from "shared/ui";

export const BackIcon = () => {
  return (
    <IconButtonWrapper>
      <img src={backIcon} style={{ width: 16, height: 16 }} alt="back" />
    </IconButtonWrapper>
  );
};
