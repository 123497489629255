import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Skeleton, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";
import { Gallery } from "react-grid-gallery";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";

import { ShotEditPath } from "shared/types/routes.ts";
import emptyVersions from "assets/shot-selection/empty-veriosns.svg";
import { initShotsVersionsAction } from "../model";
import { DraggableOverlay } from "./DraggableOverlay.tsx";

export const ShotVersions: FC = () => {
  const { id, shotId, sceneId } = useExtractParams<ShotEditPath>();

  const [shotsImages] = useAtom(initShotsVersionsAction.dataAtom);
  const [shotsImagesStatuses] = useAtom(initShotsVersionsAction.statusesAtom);
  const initShotsVersions = useAction(initShotsVersionsAction);

  useEffect(() => {
    initShotsVersions({ projectKey: id, shotId, sceneId });
  }, []);

  if (shotsImagesStatuses.isPending) {
    return (
      <Flex style={{ padding: 16 }} wrap="wrap" gap={16}>
        <Skeleton.Image style={{ width: 160, height: 160 }} active={true} />
        <Skeleton.Image style={{ width: 160, height: 160 }} active={true} />
        <Skeleton.Image style={{ width: 160, height: 160 }} active={true} />
        <Skeleton.Image style={{ width: 160, height: 160 }} active={true} />
      </Flex>
    );
  }

  if (!shotsImages.length) {
    return (
      <Flex
        style={{
          padding: "16px 16px 0 16px",
        }}
        vertical
        align="center"
        justify="flex-start"
        className="gap-s"
      >
        <img src={emptyVersions} alt="empty versions" />
        <Typography.Text
          className={cn("text-center", TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}
        >
          Here we will store your versions of the images.
        </Typography.Text>
      </Flex>
    );
  }

  const images = shotsImages.map((image) => ({
    src: image?.bw_image_url_watermarked_compressed ?? "",
    width: image.width,
    height: image.height,
    alt: image?.id.toString() ?? "",
    key: image.id,
  }));

  return (
    <Flex
      style={{
        padding: "16px 16px 0 16px",
      }}
    >
      <Gallery
        margin={3}
        rowHeight={165}
        images={images}
        enableImageSelection={false}
        thumbnailImageComponent={DraggableOverlay}
      />
    </Flex>
  );
};
