import { UserOutlined } from "@ant-design/icons";
import { Avatar, Flex, Select, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { COLOR_CLASSNAMES } from "../../const/color-names.ts";
import { SHARE_OPTIONS } from "../../const/share.ts";
import { TEXT_CLASSNAMES } from "../../const/text-classnames.ts";
import { PermissionGroup } from "../../types/share.ts";

import "./Guest.scss";

type Props = {
  name: string;
  permissionGroup: PermissionGroup;
  email: string;
  onRemove: (email: string) => void;
  onChangePermission: (permission: PermissionGroup, email: string) => void;
};

export const Guest: FC<Props> = ({
  name,
  permissionGroup,
  onRemove,
  onChangePermission,
  email,
}) => {
  return (
    <Flex className="guest" justify="space-between" align="center">
      <Flex className="gap-xxs">
        <Flex className="gap-xxs" align="center">
          <Avatar style={{ minWidth: 32 }} shape="square" size={32} icon={<UserOutlined />} />
        </Flex>
        <Flex vertical>
          <Typography.Text className={cn(TEXT_CLASSNAMES.Subtitle20, COLOR_CLASSNAMES.TextPrimary)}>
            {name}
          </Typography.Text>
          <Select
            variant="borderless"
            defaultValue={permissionGroup}
            style={{ height: 16 }}
            options={SHARE_OPTIONS}
            className="guest__select"
            onChange={(value) => onChangePermission(value, email)}
            popupClassName="guest__popup"
          />
        </Flex>
      </Flex>
      <button type="button" className="guest__remove pointer" onClick={() => onRemove(email)}>
        <Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextPrimary)}>
          Remove
        </Typography.Text>
      </button>
    </Flex>
  );
};
