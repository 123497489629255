import { useAtom } from "@reatom/npm-react";
import { Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { SecondStepForm } from "@/features/add-new-project/ui/SecondStepForm.tsx";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { IOpenModal } from "shared/hooks";
import { ButtonBack } from "shared/ui";
import { stepAtom, TStep } from "../model";
import { FirstStepForm } from "./FirstStepForm";
import { ThirdStepForm } from "./ThirdStepForm.tsx";

export const AddNewProject: FC<IOpenModal> = ({ isOpen, toggleModal }) => {
  const [step, setStep] = useAtom(stepAtom);
  const handleBack = () => {
    const curStep = Number(step);
    const prevStep = curStep - 1;
    setStep(prevStep.toString() as TStep);
  };

  const onCloseModal = () => {
    toggleModal(false);
  };

  return (
    <Modal footer={[]} open={isOpen} onCancel={() => toggleModal(false)}>
      <Flex vertical className="gap-s">
        <Flex className="gap-xxs" align="center">
          {step !== "1" && <ButtonBack onAction={handleBack} />}
          <Typography.Text className={cn(TEXT_CLASSNAMES.MdBold, COLOR_CLASSNAMES.TextSecondary)}>
            {step}/3
          </Typography.Text>
        </Flex>
        {step === "1" && <FirstStepForm />}
        {step === "2" && <SecondStepForm />}
        {step === "3" && <ThirdStepForm closeModal={onCloseModal} isBriefOpen />}
      </Flex>
    </Modal>
  );
};
