import { Button, Flex, Form, Input, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { WARNING_TOKEN } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import googleIcon from "assets/shared/google_icon.svg";
import { LoginFormType } from "../lib";
import { useBehaviour } from "../model";

type Props = {
  handleRedirectToRecover: () => void;
  isFromInstagram: boolean;
};

export const LoginForm: FC<Props> = ({ handleRedirectToRecover, isFromInstagram }) => {
  const {
    form,
    onLogin,
    onResendLink,
    loading,
    onGoogleLogin,
    isUserShowLink,
    linkCountdown,
    errorMessage,
  } = useBehaviour();

  return (
    <Form
      initialValues={{ remember: true }}
      onFinish={onLogin}
      autoComplete="on"
      form={form}
      className="login-form"
      onSubmitCapture={(event) => event.preventDefault()}
    >
      <Flex vertical className="gap-xs">
        <Form.Item<LoginFormType>
          name="email"
          rules={[
            { required: true, message: "Please input your email" },
            { type: "email", message: "Please input your valid email" },
          ]}
        >
          <Input
            onChange={(event) => form.setFieldValue("email", event.target.value)}
            placeholder="Email"
            name="email"
            disabled={loading}
            autoComplete="email"
          />
          {isUserShowLink && (
            <Flex gap={4} style={{ paddingTop: 4 }}>
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.Body10, WARNING_TOKEN.TEXT_ICON.LINK_LABEL.className)}
              >
                Email is not verified.
              </Typography.Text>
              <Typography.Link
                disabled={linkCountdown !== 0}
                onClick={onResendLink}
                underline
                type="warning"
                className={TEXT_CLASSNAMES.Body10}
              >
                Verify email
              </Typography.Link>
              {linkCountdown !== 0 && (
                <Typography.Text
                  className={cn(
                    TEXT_CLASSNAMES.Body10,
                    WARNING_TOKEN.TEXT_ICON.LINK_LABEL.className,
                  )}
                >
                  ({Math.round(linkCountdown / 1000)}) sec
                </Typography.Text>
              )}
            </Flex>
          )}
        </Form.Item>
        <Form.Item<LoginFormType>
          name="password"
          rules={[{ required: true, message: "Please input your password" }]}
        >
          <Input.Password
            disabled={loading}
            placeholder="Password"
            onChange={(event) => form.setFieldValue("password", event.target.value)}
            name="password"
            autoComplete="password"
          />
        </Form.Item>
      </Flex>
      <Flex justify="space-between" align="center" className="full-width login-form__actions">
        <Typography.Link
          underline
          onClick={handleRedirectToRecover}
          type="secondary"
          className={cn(TEXT_CLASSNAMES.Link20)}
        >
          Forgot password?
        </Typography.Link>
      </Flex>
      <Flex vertical className="login-form__buttons">
        <Button
          htmlType="submit"
          className="full-width"
          size="large"
          loading={loading}
          type="primary"
        >
          Sign in
        </Button>
        {!isFromInstagram && (
          <Button
            htmlType="button"
            className="login-form__buttons__google"
            icon={<img src={googleIcon} alt="google icon" />}
            onClick={() => onGoogleLogin()}
            loading={loading}
            size="large"
          >
            Login with Google
          </Button>
        )}

        {!!errorMessage && (
          <Flex style={{ paddingTop: 4 }}>
            <Typography.Text className={TEXT_CLASSNAMES.Body20} type="danger">
              {errorMessage}
            </Typography.Text>
          </Flex>
        )}
      </Flex>
    </Form>
  );
};
