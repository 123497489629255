import { action } from "@reatom/core";
import { callErrorAction } from "@/entities/notification";
import { shotEditStateAtom } from "@/entities/shot-edit";

type UpdateShot = {
  props: string;
};

export const updateShotPropsActions = action((ctx, { props }: UpdateShot) => {
  try {
    shotEditStateAtom(ctx, (prev) => ({
      ...prev,
      props,
    }));
  } catch (e) {
    callErrorAction(ctx, e);
  }
});

type UpdateShotCameraAngle = {
  cameraAngle: string;
};

export const updateShotCameraAngleActions = action(
  (ctx, { cameraAngle }: UpdateShotCameraAngle) => {
    try {
      shotEditStateAtom(ctx, (prev) => ({
        ...prev,
        camera_angle: cameraAngle,
      }));
    } catch (e) {
      callErrorAction(ctx, e);
    }
  },
);
