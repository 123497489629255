import { useAction, useAtom } from "@reatom/npm-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectType } from "shared/types/page.ts";
import { UserPermissionInitParams } from "../index";
import { userPermissionInitAction } from "./projectsModel.ts";

export const useProjectTypePermission = (params: UserPermissionInitParams) => {
  const initPermission = useAction(userPermissionInitAction);
  const abortPermission = useAction(userPermissionInitAction.abort);
  const [data] = useAtom(userPermissionInitAction.dataAtom);
  const [status] = useAtom(userPermissionInitAction.statusesAtom);
  const [error] = useAtom(userPermissionInitAction.errorAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (params.email) {
      initPermission(params);

      return abortPermission;
    }

    return () => {};
  }, [params.email, params.projectKey]);

  useEffect(() => {
    if (error) {
      navigate("/projects");
    }
  }, [error]);

  return {
    loading: status.isPending,
    projectType: data.projectType as ProjectType,
    projectName: data.projectName,
  };
};
