import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { PaymentCredentials } from "@/shared/api/payment";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";
import { toDate } from "shared/methods";
import { CURRENCY } from "../../../plan/lib";

import "./PaymentSummary.scss";

type Props = {
  sub: PaymentCredentials;
  interval: string;
};

export const PaymentSummary: FC<Props> = ({ sub, interval }) => {
  const { isMobileL } = useMediaQueries();
  const date = toDate(Date.now() / 1000);

  return (
    <Flex vertical className="gap-s payment-summary">
      <Typography.Text
        className={cn(TEXT_CLASSNAMES.HeadlineH2, {
          [TEXT_CLASSNAMES.HeadlineH3]: isMobileL,
        })}
      >
        Summary
      </Typography.Text>
      <Flex vertical className="gap-xs">
        <Flex align="center" justify="space-between">
          <Typography
            className={cn(TEXT_CLASSNAMES.SmSemibold, {
              [TEXT_CLASSNAMES.XsSemibold]: isMobileL,
            })}
          >
            Due today
          </Typography>
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.MdRegular, {
              [TEXT_CLASSNAMES.XsRegular]: isMobileL,
            })}
          >
            {CURRENCY[sub?.current_invoice.currency ?? "usd"]}
            {sub?.current_invoice.amount}
          </Typography.Text>
        </Flex>
        <div className="payment-summary__divider full-width" />
        <Flex vertical align="flex-start" className="gap-xs">
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.SmSemibold, {
              [TEXT_CLASSNAMES.XsSemibold]: isMobileL,
            })}
          >
            Starting on {date}
          </Typography.Text>
          <Flex vertical className="full-width gap-xxs">
            <Flex align="center" justify="space-between">
              <Typography.Text
                className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.MdRegular, {
                  [TEXT_CLASSNAMES.XsRegular]: isMobileL,
                })}
              >
                Monthly access
              </Typography.Text>
              <Typography.Text
                className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.MdRegular, {
                  [TEXT_CLASSNAMES.XsRegular]: isMobileL,
                })}
              >
                {CURRENCY[sub?.current_invoice.currency ?? "usd"]}
                {sub?.current_invoice.amount.toFixed(2)}/{interval}
              </Typography.Text>
            </Flex>
            <Flex align="center" justify="space-between">
              <Typography.Text
                className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.MdRegular, {
                  [TEXT_CLASSNAMES.XsRegular]: isMobileL,
                })}
              >
                Estimated tax
              </Typography.Text>
              <Typography.Text
                className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.MdRegular, {
                  [TEXT_CLASSNAMES.XsRegular]: isMobileL,
                })}
              >
                {CURRENCY[sub?.current_invoice.currency ?? "usd"]}0.00/{interval}
              </Typography.Text>
            </Flex>
          </Flex>
        </Flex>
        <div className="payment-summary__divider full-width" />
        <Flex align="center" justify="space-between">
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.SmSemibold, {
              [TEXT_CLASSNAMES.XsSemibold]: isMobileL,
            })}
          >
            Total
          </Typography.Text>
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.SmSemibold, {
              [TEXT_CLASSNAMES.XsRegular]: isMobileL,
            })}
          >
            {CURRENCY[sub?.current_invoice.currency ?? "usd"]}
            {sub?.current_invoice.amount.toFixed(2)}/{interval}
          </Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
