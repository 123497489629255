import { Flex } from "antd";
import { FC } from "react";

import { SceneShotGrid, TStoryboardShot } from "@/entities/storyboard";

interface IStoryboardGrid {
  shots: TStoryboardShot[];
  sceneId: string;
}

export const StoryboardGrid: FC<IStoryboardGrid> = ({ shots, sceneId }) => (
  <Flex wrap="wrap" className="gap-xs">
    {shots.map((shot) => (
      <SceneShotGrid
        key={shot.id}
        shotsOrder={shots.map((shot) => shot.id)}
        renderType="render"
        sceneId={sceneId}
        {...shot}
      />
    ))}
  </Flex>
);
