import {useAction, useAtom} from "@reatom/npm-react";
import {editingShotAtom} from "@/features/highlight-script";
import {viewerModel} from "@/entities/viewer";
import {useProjectType} from "@/entities/projects";
import {useRef, useState} from "react";
import {createEditor, Descendant, Selection} from "slate";
import {withReact} from "slate-react";
import {withHistory} from "slate-history";
import {
	addShotToSceneAction,
	scriptSignsLengthAtom,
	splitShotAction, styleSettingsOutlineAtom,
	updateShotValueInSceneAction
} from "@/entities/script";
import {SelectionPosition} from "@/features/highlight-script/lib";
import {useExtractParams} from "@/shared/hooks/use-extract-params.ts";
import {ScriptPath} from "shared/types/routes.ts";

type UseStoreParams = {
	content: Descendant[];
}

export const useStore = ({ content }: UseStoreParams) => {
	const { id } = useExtractParams<ScriptPath>();

	const [editingShot, setEditingShot] = useAtom(editingShotAtom);
	const [subscription] = useAtom(viewerModel.viewerSubscription.dataAtom);
	const { isShare } = useProjectType();
	const [isHover, setIsHover] = useState(false);
	const [isActiveState, setIsActiveState] = useState(false);
	const [localShotValue, setLocalShotValue] = useState<Descendant[]>(content);
	const [editor] = useState(() => withReact(withHistory(createEditor())));
	const descriptionRef = useRef<string | null>(null);

	const ref = useRef<HTMLDivElement>(null);

	const [scriptLength, setScriptLength] = useAtom(scriptSignsLengthAtom);
	const scriptLengthRef = useRef(scriptLength);
	const localValueRef = useRef<Descendant[]>(localShotValue);
	const updateShot = useAction(updateShotValueInSceneAction);
	const addShot = useAction(addShotToSceneAction);
	const splitShot = useAction(splitShotAction);
	const [isSelectionStarted, setIsSelectionStarted] = useState(false);
	const [prevSelection, setPrevSelection] = useState<Selection | null>(null);
	const [splitShotButtonPosition, setSplitShotButtonPosition] = useState<SelectionPosition | null>(
		null,
	);
	const editableRef = useRef<HTMLElement | null>(null);
	const [outlined] = useAtom(styleSettingsOutlineAtom);

	const isOutlined = outlined[id]

	return {
		descriptionRef,
		editor,
		setScriptLength,
		setLocalShotValue,
		setEditingShot,
		localValueRef,
		scriptLengthRef,
		setIsActiveState,
		scriptLength,
		setIsHover,
		addShot,
		isShare,
		subscription,
		setPrevSelection,
		prevSelection,
		splitShot,
		setSplitShotButtonPosition,
		editingShot,
		isActiveState,
		updateShot,
		localShotValue,
		ref,
		editableRef,
		setIsSelectionStarted,
		isOutlined,
		isHover,
		isSelectionStarted,
		splitShotButtonPosition
	}
};
