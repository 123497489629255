import { useAtom } from "@reatom/npm-react";
import { Button, Flex, Modal, Segmented, Typography } from "antd";
import cn from "classnames";
import ExcelJS from "exceljs";
import { FC, useState } from "react";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { COLOR_NAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";

import { StoryboardPath } from "shared/types/routes.ts";
import excelImg from "assets/storyboard/excel.svg";
import pdfImg from "assets/storyboard/pdf.svg";
import { IOpenModal } from "shared/hooks";
import { DotTyping, Spinner } from "shared/ui";
import { getStoryboardAction } from "@/entities/storyboard";

type Props = IOpenModal & {
  isPending: boolean;
  isSaving: boolean;
  toPDF: () => Promise<void>;
};
type FileType = "excel" | "pdf";

export const ExportStoryboardModal: FC<Props> = ({
  toggleModal,
  isOpen,
  isPending,
  toPDF,
  isSaving,
}) => {
  const [file, setFile] = useState<FileType>("pdf");
  const { id } = useExtractParams<StoryboardPath>();
  const [storyboardList] = useAtom(getStoryboardAction.dataAtom);

  const save = async () => {
    if (file === "excel") {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const shots = storyboardList.flatMap((storyboard) =>
        storyboard.shots.map(({ id, ...shot }) => ({
          ...shot,
          sceneTitle: storyboard.title,
        })),
      );

      const toDataURL = (url: string): Promise<{ base64Url: string }> => {
        const promise = new Promise((resolve) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            const reader = new FileReader();
            reader.readAsDataURL(xhr.response);
            reader.onloadend = function () {
              resolve({ base64Url: reader.result });
            };
          };
          xhr.open("GET", url);
          xhr.responseType = "blob";
          xhr.send();
        });

        // @ts-ignore
        return promise;
      };

      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("My Sheet");
      sheet.getRow(1).border = {
        top: { style: "thick", color: { argb: "FFFF0000" } },
        left: { style: "thick", color: { argb: "000000FF" } },
        bottom: { style: "thick", color: { argb: "F08080" } },
        right: { style: "thick", color: { argb: "FF00FF00" } },
      };

      sheet.getRow(1).height = 20;

      sheet.getRow(1).fill = {
        type: "pattern",
        pattern: "darkVertical",
        fgColor: { argb: "FFFF00" },
      };

      sheet.getRow(1).font = {
        name: "Comic Sans MS",
        family: 4,
        size: 16,
        bold: true,
      };

      sheet.columns = [
        {
          header: "Title",
          key: "title",
          width: 80,
        },
        {
          header: "Time",
          key: "time",
          width: 10,
        },
        {
          header: "Location",
          key: "location",
          width: 40,
        },
        {
          header: "Description",
          key: "description",
          width: 80,
        },
        {
          header: "Props",
          key: "props",
          width: 40,
        },
        {
          header: "Camera Movement",
          key: "cameraMovement",
          width: 20,
        },
        {
          header: "Dialogue",
          key: "dialogue",
          width: 60,
        },
        {
          header: "Camera Angle",
          key: "cameraAngle",
          width: 20,
        },
        {
          header: "Image",
          key: "image",
          width: 37,
        },
      ];

      const promise = Promise.all(
        shots.map(async (shot, index) => {
          sheet.addRow({
            title: `Scene: ${shot.sceneTitle}. Shot: ${shot.idx + 1}`,
            time: shot.time,
            location: shot.location,
            description: shot.description,
            props: shot.props,
            cameraMovement: shot.cameraMovement,
            dialogue: shot.dialogue,
            cameraAngle: shot.cameraAngle,
          });
          const rowNumber = index + 2;
          const imageRowNumber = index + 1;

          if (shot.image) {
            const result = await toDataURL(shot.image);

            const imageId2 = workbook.addImage({
              base64: result?.base64Url,
              extension: "jpeg",
            });
            sheet.getRow(rowNumber).height = 170;
            sheet.addImage(imageId2, {
              tl: { col: 8, row: imageRowNumber },
              ext: { width: 300, height: 300 },
            });
          } else {
            sheet.getRow(rowNumber).height = 50;
          }
        }),
      );

      promise.then(() => {
        workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = `${id}.xlsx`;
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      });
    }

    if (file === "pdf") {
      await toPDF();
    }

    toggleModal(false);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => toggleModal(false)}
      footer={() =>
        isPending ? null : (
          <Button
            style={{
              gap: 20,
            }}
            disabled={isSaving}
            type="primary"
            className="full-width flex-ctr-ctr"
            onClick={save}
          >
            <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular)}>Export</Typography.Text>
            {isSaving && <DotTyping />}
          </Button>
        )
      }
    >
      {isPending ? (
        <Spinner />
      ) : (
        <Flex vertical className="gap-xs text-center">
          <Flex vertical className="gap-xs" align="flex-start">
            <Typography.Text className={TEXT_CLASSNAMES.Title50}>Export Storyboard</Typography.Text>
            <Typography.Text className={TEXT_CLASSNAMES.Body20}>
              Select the file type and view you would like to export
            </Typography.Text>
          </Flex>
          <Segmented
            style={{
              border: `1px solid ${COLOR_NAMES.MetalGray300}`,
              padding: 4,
              height: 48,
            }}
            size="large"
            block
            onChange={(value) => setFile(value as FileType)}
            options={[
              {
                label: (
                  <Flex
                    style={{ height: 40 }}
                    align="center"
                    justify="center"
                    className="full-height gap-xxs"
                  >
                    <img src={pdfImg} alt="pdf" />
                    <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular)}>
                      PDF tile view
                    </Typography.Text>
                  </Flex>
                ),
                value: "pdf",
              },
              {
                label: (
                  <Flex
                    style={{ height: 40 }}
                    align="center"
                    justify="center"
                    className="full-height gap-xxs"
                  >
                    <img src={excelImg} alt="excel" />
                    <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular)}>
                      Excel table view
                    </Typography.Text>
                  </Flex>
                ),
                value: "excel",
              },
            ]}
          />
        </Flex>
      )}
    </Modal>
  );
};
