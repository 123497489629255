import { useAction, useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC, useEffect } from "react";

import { useMediaQueries } from "shared/hooks/use-media-queries";
import { Spinner } from "shared/ui";
import {
  getCurrentHistoryPlanAction,
  getCurrentPlanAction,
  getPaymentCredentialsAction,
  getPaymentDetailsAction,
} from "../../model";
import { BillingHistory } from "./billing-history";
import { CurrentPlan } from "./CurrentPlan";
import { PaymentMethod } from "./PaymentMethod";

export const SettingsBilling: FC = () => {
  const { isLaptopS } = useMediaQueries();
  const getPaymentData = useAction(getPaymentDetailsAction);
  const getPlan = useAction(getCurrentPlanAction);
  const getHistory = useAction(getCurrentHistoryPlanAction);
  const getCredentials = useAction(getPaymentCredentialsAction);

  const [planStatus] = useAtom(getCurrentPlanAction.statusesAtom);
  const [historyStatus] = useAtom(getCurrentHistoryPlanAction.statusesAtom);

  useEffect(() => {
    getPlan();
    getHistory();
    getPaymentData();
    getCredentials();
  }, []);

  if (planStatus.isPending || historyStatus.isPending) {
    return (
      <Flex justify="center" align="center" className="full-height full-width">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex vertical className="gap-xs">
      <Flex vertical={isLaptopS} className="gap-xs">
        <CurrentPlan />
        <PaymentMethod />
      </Flex>
      <BillingHistory />
    </Flex>
  );
};
