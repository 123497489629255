import { Flex } from "antd";
import cn from "classnames";
import { FC, HTMLAttributes, PropsWithChildren } from "react";
import edit from "assets/shared/edit.svg";

import "./EditButton.scss";

interface Props extends HTMLAttributes<HTMLDivElement> {
  isActive: boolean;
}

export const EditButton: FC<PropsWithChildren<Props>> = ({
  children,
  onClick,
  className,
  isActive,
  ...props
}) => {
  return (
    <Flex
      onClick={isActive ? onClick : () => {}}
      className={cn(className, "storyboard-edit-button gap-4", {
        "storyboard-edit-button__active": isActive,
      })}
      justify="center"
      align="center"
      role="button"
      {...props}
    >
      <img style={{ width: 20, height: 20 }} className="image-contain" src={edit} alt="edit" />
      {children}
    </Flex>
  );
};
