import { useAtom } from "@reatom/npm-react";
import { Flex, Input, Typography, Upload } from "antd";
import cn from "classnames";
import { FC } from "react";

import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import uploadImage from "assets/moodboard/upload-image.svg";
import close from "assets/shared/close.svg";
import search from "assets/shared/search.svg";
import { searchBoardViewAtom } from "../index";

export const SearchBoardImageDownload: FC = () => {
  const [_searchBoardView, setSearchBoardView] = useAtom(searchBoardViewAtom);

  return (
    <Flex vertical align="center" className="search-board__image-downloader gap-xs">
      <Flex className="full-width" align="center" justify="space-between">
        <div />
        <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Search by image</Typography.Text>
        <img
          onClick={() => setSearchBoardView("autocomplete")}
          className="pointer"
          src={close}
          alt="close"
        />
      </Flex>
      <Upload.Dragger
        name="file"
        listType="picture"
        className="full-width search-board__image-downloader__drag"
      >
        <p className="ant-upload-drag-icon">
          <img src={uploadImage} alt="uplaod image" />
        </p>
        <Flex vertical>
          <Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>
            Drag an image here or{" "}
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.BrandGreen600)}
            >
              upload a file
            </Typography.Text>
          </Typography.Text>
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
          >
            Supports: PNG, JPG
          </Typography.Text>
        </Flex>
      </Upload.Dragger>
      <Typography.Text className={TEXT_CLASSNAMES.XxsSemibold}>or</Typography.Text>
      <Input
        placeholder="Paste image link"
        suffix={<img src={search} alt="search" />}
        className="search-board__image-downloader__input"
      />
    </Flex>
  );
};
