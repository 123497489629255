import { reatomResource, withCache, withDataAtom, withRetry } from "@reatom/async";
import { atom } from "@reatom/core";
import { createMemStorage, reatomPersist } from "@reatom/persist";
import { TSettingsResult, getSettingsProfileResource } from "@/shared/api/settings";

const accountModelStorage = createMemStorage({ name: "accountData" });
const withAccountDataPersist = reatomPersist(accountModelStorage);

export const accountAtom = (isAvatar: boolean) => {
  const withAvatarAtom = atom(isAvatar, `${isAvatar}.withAvatarAtom`);

  const accountResource = reatomResource(async (ctx) => {
    const isAvatar = ctx.spy(withAvatarAtom);

    return await getSettingsProfileResource(isAvatar, ctx.controller);
  }, `${isAvatar}.userResource`).pipe(
    withDataAtom({} as TSettingsResult, (_, res) => res?.data ?? {}),
    withCache({
      swr: {
        shouldFulfill: false,
      },
      withPersist: () => withAccountDataPersist("accountData"), // Share persistence between instances
    }),
    withRetry(),
  );

  return {
    isAvatarAtom: withAvatarAtom,
    resource: accountResource,
  };
};

export const accountWithAvatarModel = accountAtom(true);
