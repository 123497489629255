import { useAction, useAtom } from "@reatom/npm-react";
import { callErrorAction } from "@/entities/notification";
import { promoCodeAtom } from "@/entities/plan";
import { logoutAction, sessionAuthAction, viewerAtom, viewerModel } from "@/entities/viewer";

import { getPossibleSubscriptionsAction } from "./planModel.ts";

export const useStore = () => {
  const callError = useAction(callErrorAction);
  const sessionAuth = useAction(sessionAuthAction);
  const getSubscription = useAction(viewerModel.viewerSubscription);
  const logout = useAction(logoutAction);
  const getPossibleSubscriptions = useAction(getPossibleSubscriptionsAction);

  const [possibleSubscriptions] = useAtom(getPossibleSubscriptionsAction.statusesAtom);
  const [promoCode, setPromoCode] = useAtom(promoCodeAtom);
  const [viewer] = useAtom(viewerAtom);
  const [subscription] = useAtom(viewerModel.viewerSubscription.dataAtom);

  const clearPromoCode = () => {
    setPromoCode(null);
  };

  return {
    callError,
    sessionAuth,
    subscription,
    getSubscription,
    logout,
    getPossibleSubscriptions,
    possibleSubscriptions,
    promoCode,
    viewer,
    clearPromoCode,
  };
};
