import { action, atom } from "@reatom/core";
import { withSessionStorage } from "@reatom/persist-web-storage";
import { callErrorAction } from "@/entities/notification";
import { checkPromoCodeResource, PromoSuccess } from "@/shared/api/payment";

export const userInputPromoCodeAtom = atom<null | string>(null, "promoCodeAtom");
export const promoCodeAtom = atom<null | PromoSuccess>(null, "promoCodeAtom");
export const promoErrorAtom = atom<null | string>(null, "promoErrorAtom");
export const trialCodeAtom = atom<null | string>(null, "trialCodeAtom").pipe(
  withSessionStorage("trial"),
);

export const getPromoCodeAction = action(async (ctx, code: string, subscriptionId: string) => {
  try {
    const { data } = await checkPromoCodeResource(code, subscriptionId);
    if (data.code) {
      promoErrorAtom(ctx, null);
      promoCodeAtom(ctx, data as PromoSuccess);
      return data;
    } else {
      promoErrorAtom(
        ctx,
        "The promo code you entered is invalid. Please check the code and try again.",
      );
      promoCodeAtom(ctx, null);
    }
  } catch (e) {
    callErrorAction(ctx, e);
  }
});
