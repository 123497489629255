import { useAction } from "@reatom/npm-react";
import { useSearchParams } from "react-router-dom";
import { MOODBOARD_TYPE_PARAM_NAME, MOODBORD_TYPE } from "shared/const/routes.ts";
import { MoodboardType } from "shared/types/routes.ts";
import { useOpenModal } from "shared/hooks";
import { clearMoodBoardAction } from "@/entities/moodboard";

type Params = {
  projectKey: string;
};

export const uesBehavior = ({ projectKey }: Params) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const moodboardType = searchParams.get(MOODBOARD_TYPE_PARAM_NAME) ?? MOODBORD_TYPE.structure;

  const [isOpenDeleteModal, toggleDeleteModal] = useOpenModal();
  const [isOpenAddImage, toggleAddImage] = useOpenModal();

  const clearMoodBoard = useAction(clearMoodBoardAction);

  const deleteMoodBoard = () => {
    clearMoodBoard(projectKey);
  };

  const onChangeMoodboardType = (type: MoodboardType) => {
    setSearchParams({
      [MOODBOARD_TYPE_PARAM_NAME]: type,
    });
  };

  return {
    isOpenDeleteModal,
    toggleDeleteModal,
    isOpenAddImage,
    toggleAddImage,
    deleteMoodBoard,
    onChangeMoodboardType,
    moodboardType,
  };
};
