import { AxiosResponse } from "axios";

import { api } from "@/shared/api";
import {TEmbeddingResponse, TMoodboardResult, UpdateMoodboardImage, UpdateMoodboardTagsParams} from "./types";

export const deleteMoodboardImages = (
  id: string,
  data: string[],
): Promise<AxiosResponse<{ count: number }>> =>
  api({
    method: "DELETE",
    url: `/moodboards/images/${id}`,
    data,
  });

export const addMoodboardImages = (
  id: string,
  formData: FormData,
): Promise<AxiosResponse<{ [id: string]: string }>> =>
  api.post(`/moodboards/images/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getMoodboardsResource = (
  id: string,
  abortController?: AbortController,
): Promise<AxiosResponse<TMoodboardResult>> =>
  api.get(`/moodboards/images/${id}/ids`, { signal: abortController?.signal });

export const addMoodboardsImagesCoordinates = (
  id: string,
  coordinates: UpdateMoodboardImage[],
  controller?: AbortController,
) => api.patch(`/moodboards/images/${id}`, { images: coordinates }, { signal: controller?.signal });

export const getInspirationResource = (
  queries: string[],
  limit: number,
  abortController?: AbortController,
): Promise<AxiosResponse<TEmbeddingResponse>> =>
  api.get("/moodboards/embeddings", {
    params: {
      embeddings: queries,
      peek_limit: limit,
    },
    paramsSerializer: {
      indexes: null,
    },
    signal: abortController?.signal,
  });

export const updateMoodboardTagsResource = (params: UpdateMoodboardTagsParams, controller?: AbortController
): Promise<AxiosResponse<unknown>> => api.patch(`/moodboards/tags/${params.id}`, { tags: params.tags }, { signal: controller?.signal });

export const getMoodboardTagsResource = (
  id: string,
  controller?: AbortController,
): Promise<AxiosResponse<{ tags: string[] | null }>> =>
  api.get(`/moodboards/tags/${id}`, { signal: controller?.signal });

export const getInspirationPromptsResource = (
  projectKey: string,
  controller?: AbortController,
): Promise<AxiosResponse<{ prompt: string }>> =>
  api.get(`/moodboards/inspiration_prompt/${projectKey}`, { signal: controller?.signal });

export const updateInspirationPromptsResource = (
  id: string,
  prompt: string,
): Promise<AxiosResponse<{ prompt: string }>> =>
  api.patch(`/moodboards/inspiration_prompt/${id}`, { inspiration_prompt: prompt });
