import { Flex } from "antd";
import { FC, PropsWithChildren } from "react";

import "./LandingContent.scss";

export const LandingContent: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex className="full-width">
      <Flex className="landing-content">{children}</Flex>
    </Flex>
  );
};
