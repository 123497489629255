import { Flex, Typography } from "antd";
import { FC, MouseEvent } from "react";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { StoryboardSidebarPath } from "shared/types/routes";
import { useOpenModal } from "shared/hooks";
import { DeleteMessageModal } from "./DeleteMessageModal";

interface Props {
  onClick: () => void;
}

export const DeleteMessage: FC<Props> = ({ onClick }) => {
  const { id } = useExtractParams<StoryboardSidebarPath>();
  const [open, toggleModal] = useOpenModal();
  const onLabelClick = (callback: () => void) => (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    callback();
  };
  return (
    <>
      <Flex onClick={onLabelClick(onClick)} align="center" className="gap-xxs full-width">
        <Typography.Text className={TEXT_CLASSNAMES.Body10}>Delete message</Typography.Text>
      </Flex>
      {open && <DeleteMessageModal isOpen={open} toggleModal={toggleModal} commentId={id} />}
    </>
  );
};
