import { AxiosResponse } from "axios";
import { api } from "@/shared/api";
import { ChangePermission, TGuestResult } from "./types";

export const getGuestsResource = (projectKey: string): Promise<AxiosResponse<TGuestResult[]>> =>
  api.get(`/guests/${projectKey}`);

export const removeGuestResource = (projectKey: string, email: string) =>
  api.delete(`/guests/${projectKey}/${email}`);

export const changePermissionResource = (params: ChangePermission) =>
  api.patch("/guests/permissions", params);

export const leaveProjectResource = (projectKey: string) => api.post(`/guests/self/${projectKey}`);
