import { FC } from "react";
import { Outlet } from "react-router-dom";
import { HeaderLayout } from "shared/ui";
import { useAccountWithAvatar } from "@/entities/account";
import { HeaderContent } from "./ui";

export const ProjectsNavigation: FC = () => {
  const { account, loading } = useAccountWithAvatar();

  return (
    <HeaderLayout HeaderContent={() => <HeaderContent account={account} loading={loading} />}>
      <Outlet />
    </HeaderLayout>
  );
};
