import { useAction, useAtom } from "@reatom/npm-react";
import { notification } from "antd";
import { FC, PropsWithChildren, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { clearErrorAction, clearPathAction, errorAtom, pathAtom } from "@/entities/notification";

export const ErrorWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();
  const clearError = useAction(clearErrorAction);
  const [error] = useAtom(errorAtom);
  const [path] = useAtom(pathAtom);
  const clearPath = useAction(clearPathAction);
  const navigate = useNavigate();

  useEffect(() => {
    if (error?.type === "axios-error") {
      if (error.error.response?.status === 500) {
        api.error({
          message: `Error status ${error.error.response?.status}`,
          description: "Internal server error",
        });
      } else if (error.error.response?.status) {
        api.error({
          message: `Error status ${error.error.response?.status}`,
          description: "Internal server error",
        });
      }
    }

    if (error?.type === "stock-error") {
      api.error({
        message: "Error status 500",
        description: "Internal server error",
      });
    }

    return () => {
      clearError();
    };
  }, [error]);

  useEffect(() => {
    if (path) {
      navigate(path);
    }

    return () => {
      clearPath();
    };
  }, [path]);

  return (
    <>
      {contextHolder}
      {children}
    </>
  );
};
