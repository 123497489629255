import { useAtom } from "@reatom/npm-react";
import { Flex, Tag, Tooltip } from "antd";
import cn from "classnames";
import { FC } from "react";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { CharacterInfoPath } from "shared/types/routes.ts";
import grayQuestion from "assets/character/question.svg";
import { IconButtonWrapper } from "shared/ui";
import { activeCharacterAtom, CharacterFilterList } from "@/entities/character";

type Props = CharacterFilterList;

export const CharacterButton: FC<Props> = ({ id, img, selected }) => {
  const [activeCharacter, setActiveCharacter] = useAtom(activeCharacterAtom);
  const { characterId } = useExtractParams<CharacterInfoPath>();
  const canSelect = !selected || characterId === id;

  const onSelect = () => {
    if (canSelect) {
      setActiveCharacter(id);
    }
  };

  return (
    <div
      onClick={onSelect}
      role="button"
      className={cn("character-settings__character cubic-animation", {
        "character-settings__character--active": id === activeCharacter,
        pointer: canSelect,
      })}
    >
      {!canSelect && (
        <Flex className="character-settings__tag-wrapper">
          <Tooltip placement="topLeft" title="This character is already in use">
            <Tag className="character-settings__tag">
              <IconButtonWrapper>
                <img src={grayQuestion} alt="question" />
              </IconButtonWrapper>
              used
            </Tag>
          </Tooltip>
        </Flex>
      )}
      <img className="character-settings__character--img" src={img} alt={id} />
    </div>
  );
};
