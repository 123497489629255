import { action, atom } from "@reatom/core";
import { InvitationVerifyParams, verifyInvitationResource } from "@/shared/api/auth";
import { callErrorAction } from "@/entities/notification";

export const invitationErrorAtom = atom<null | string>(null, "invitationErrorAtom");
export const invitationCodeAtom = atom<string>("", "invitationCodeAtom");

export const invitationAction = action(async (ctx, params: InvitationVerifyParams) => {
  try {
    await verifyInvitationResource(params);

    invitationCodeAtom(ctx, "ok");
  } catch (err) {
    invitationErrorAtom(ctx, "Oops, something went wrong");
    callErrorAction(ctx, err);
  }
});
