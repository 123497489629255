import { useAction, useAtom } from "@reatom/npm-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOpenModal } from "shared/hooks";
import { useAccountWithAvatar } from "@/entities/account";
import {
  initProjectsAction,
  projectsListAtom,
  projectsLoadingAtom,
} from "@/entities/projects";
import { sessionAuthUpgradeSubscriptionAction } from "@/entities/viewer";

export const useBehaviour = () => {
  const navigate = useNavigate();
  const [isOpen, toggleModal] = useOpenModal();
  const [isUpgradeModalOpen, toggleUpgradeModalModal] = useOpenModal();
  const [projects] = useAtom(projectsListAtom);
  const [loading] = useAtom(projectsLoadingAtom);
  const getAllProjects = useAction(initProjectsAction);
  const sessionAuthUpgradeSubscription = useAction(sessionAuthUpgradeSubscriptionAction);
  const { account } = useAccountWithAvatar();

  const onUpgrade = () => {
    sessionAuthUpgradeSubscription({ isGuest: false, email: account.email });
    navigate("/plan");
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  return {
    projects,
    isOpen,
    toggleModal,
    isUpgradeModalOpen,
    toggleUpgradeModalModal,
    loading,
    onUpgrade,
  };
};
