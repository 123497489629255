import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";
import bgMobile from "assets/product/simplify-bg-mobile.svg";
import bg from "assets/product/simplify-bg.svg";
import { TrialButton } from "shared/ui";

import "./ProductSimplify.scss";

export const ProductSimplify: FC = () => {
  const { isLaptopM, isMobileXL, isTablet } = useMediaQueries();

  return (
    <Flex vertical align="center" justify="center" className="product-simplify text-center gap-m">
      <img src={isMobileXL ? bgMobile : bg} className="product-simplify__bg" alt="bg" />
      <Flex vertical className="product-simplify__content gap-xs">
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.LPHeadlineH3, COLOR_CLASSNAMES.TextWhitePrimary, {
            [TEXT_CLASSNAMES.LPHeadlineH4]: isLaptopM,
            [TEXT_CLASSNAMES.HeadlineH1]: isTablet,
            [TEXT_CLASSNAMES.HeadlineH2]: isMobileXL,
          })}
        >
          Ready to simplify your <br />
          pre-production workflow?
        </Typography.Text>
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextWhitePrimary, {
            [TEXT_CLASSNAMES.MdRegular]: isLaptopM,
            [TEXT_CLASSNAMES.SmRegular]: isTablet,
            [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
          })}
        >
          Start now and save time and money on every project!
        </Typography.Text>
      </Flex>
      <Flex style={{ zIndex: 10 }}>
        <TrialButton />
      </Flex>
    </Flex>
  );
};
