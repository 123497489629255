import { useAtom } from "@reatom/npm-react";
import { Flex, Typography, message } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";
import { GRAYSCALE_TOKEN } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import info from "assets/shared/info_purple.svg";
import { bannerShownAtom } from "@/entities/inpainting";

import "./InpaintingBanner.scss";

type Props = {
  shotId: string;
};

export const InpaintingBanner: FC<Props> = ({ shotId }) => {
  const [bannerShown] = useAtom(bannerShownAtom);

  const openMessage = () => {
    message.open({
      key: "inpainting-banner",
      content: (
        <Flex align="center" justify="center" className="inpainting-banner gap-xxs">
          <img src={info} alt="Banner Icon" className="inpainting-banner__img" />
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.Body20, GRAYSCALE_TOKEN.TEXT_ICON.TITLE.className)}
          >
            Place points to create an outline around the object you want to change.
          </Typography.Text>
        </Flex>
      ),
      duration: 0,
      className: "custom-message",
    });
  };

  useEffect(() => {
    if (!bannerShown[shotId]) {
      openMessage();
    }

    return () => {
      message.destroy("inpainting-banner");
    };
  }, [bannerShown[shotId]]);

  return null;
};
