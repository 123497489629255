import { ProjectSharedAccess } from "@/shared/api/project";
import { getDisplayedUserName } from "shared/methods/getDisplayedUserName";
import { PermissionGroup } from "shared/types/share.ts";

export const guestMapper = (access: ProjectSharedAccess) => {
  const { first_name: fn, last_name: ln, username, email } = access;
  const name = getDisplayedUserName({ fn, ln, username, email });
  const canComment = access.permissions.some((acc) => acc.group === "comment");
  const permissionGroup = canComment ? "comment" : "view";
  return { ...access, name, permissionGroup: permissionGroup as PermissionGroup };
};
