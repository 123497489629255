import { useAction, useAtom } from "@reatom/npm-react";
import { Col, Flex, Row } from "antd";
import { FC, useEffect } from "react";

import {
  CharacterCard,
  CharacterCardSkeleton,
  getCharactersAction,
} from "@/entities/character";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { CharacterPath } from "shared/types/routes.ts";
import { useUpdateTitle } from "shared/hooks";

import "./Character.scss";

export const Character: FC = () => {
  const { id } = useExtractParams<CharacterPath>();
  const initCharacters = useAction(getCharactersAction);

  const [characterList] = useAtom(getCharactersAction.dataAtom);
  const [status] = useAtom(getCharactersAction.statusesAtom);

  useUpdateTitle();

  useEffect(() => {
    initCharacters(id);
  }, []);

  if (status.isPending) {
    return (
      <Flex className="character full-width full-height" gap={16} wrap="wrap">
        <CharacterCardSkeleton />
        <CharacterCardSkeleton />
        <CharacterCardSkeleton />
      </Flex>
    );
  }

  return (
    <Flex justify="space-between" className="full-width full-height">
      <Flex vertical className="character full-height pointer gap-l">
        <Row gutter={[16, 16]} style={{ padding: "0 8px", overflow: "auto" }}>
          {characterList.map((card) => (
            <Col key={card.id}>
              <CharacterCard key={card.id} {...card} />
            </Col>
          ))}
        </Row>
      </Flex>
    </Flex>
  );
};
