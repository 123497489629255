import { Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import chevron from "assets/comments/chevron-down.svg";
import messageIcon from "assets/shared/message.svg";

interface RepliesProps {
  amount: number;
  isActive: boolean;
  isResolved: boolean;
}

export const Replies: FC<RepliesProps> = ({ amount, isActive, isResolved }) => {
  if (amount > 0) {
    return (
      <>
        <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.BrandGreen700)}>
          {amount} replies
        </Typography.Text>
        <img src={chevron} alt="down" style={{ transform: isActive ? "rotate(180deg)" : "" }} />
      </>
    );
  }
  if (!isActive && !isResolved) {
    return (
      <>
        <img src={messageIcon} alt="message" />
        <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.BrandGreen700)}>
          Reply
        </Typography.Text>
      </>
    );
  }

  return null;
};
