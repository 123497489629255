export function getImage(file: File): Promise<HTMLImageElement> {
  const reader = new FileReader();
  const image = new Image();
  return new Promise((resolve, reject) => {
    if (!file.type.match(/image.*/)) {
      reject(new Error("Not an image"));
      return;
    }
    reader.onload = (readerEvent: any) => {
      image.onload = () => resolve(image);
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
}

export async function getAspectRatio(w: number, h: number) {
  let aspectRatio;

  if (w > h) {
    aspectRatio = w / h;
  } else {
    aspectRatio = h / w;
  }

  return aspectRatio;
}

export function getImageFromBlob(blob: Blob): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    // Create a new FileReader
    const reader = new FileReader();

    // When the FileReader loads the image blob
    reader.onload = (event) => {
      // Create a new Image object
      const img = new Image();

      // When the Image object loads the image
      img.onload = () => {
        // Resolve with the Image object
        resolve(img);
      };

      // If there's an error loading the Image object
      img.onerror = (error) => {
        // Reject with the error
        reject(error);
      };

      // Set the image source to the blob URL
      // @ts-ignore
      img.src = event.target.result as string;
    };

    // Read the blob as a data URL
    reader.readAsDataURL(blob);

    // If there's an error reading the blob
    reader.onerror = (error) => {
      // Reject with the error
      reject(error);
    };
  });
}

export function gcd(a: number, b: number) {
  if (b === 0) {
    return a;
  } else {
    return gcd(b, a % b);
  }
}

export function getGcd(num: number) {
  const roundedNumber = Math.round(num * 10) / 10;
  let numerator = roundedNumber * 10;
  let denominator = 10;

  function gcd(a: number, b: number) {
    if (b === 0) {
      return a;
    } else {
      return gcd(b, a % b);
    }
  }

  const GCD = gcd(numerator, denominator);

  numerator /= GCD;
  denominator /= GCD;

  return {
    numerator,
    denominator,
  };
}

type NotMaxSize = {
  width: number;
  height: number;
  aspectWidth: number;
  aspectHeight: number;
  divisor: number;
};

export const getNotMaxSize = (width: number, height: number): NotMaxSize => {
  const isMaxSize = width > 500 || height > 500;

  if (isMaxSize) {
    const divisor = gcd(width, height);

    const aspectWidth = width / divisor;
    const aspectHeight = height / divisor;

    if (divisor === 1) {
      return getNotMaxSize(3, 3);
    } else {
      return getNotMaxSize(aspectWidth, aspectHeight);
    }
  } else {
    const divisor = gcd(width, height);

    const aspectWidth = width / divisor;
    const aspectHeight = height / divisor;

    return { width, height, aspectWidth, aspectHeight, divisor };
  }
};

export const getImgSize = (url: string): Promise<{ width: number; height: number }> =>
  new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      const { width, height, aspectWidth, aspectHeight } = getNotMaxSize(img.width, img.height);

      const isMinSize = aspectWidth < 10 || aspectHeight < 10;
      resolve({
        width: isMinSize ? width * 100 : width,
        height: isMinSize ? height * 100 : height,
      });
    };

    img.src = url;
  });
