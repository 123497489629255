type Name = string | null | undefined;
type NameValues = {
  [value: string]: Name;
};

export const getDisplayedUserName = ({ fn, ln, username, email }: NameValues) => {
  let displayName = "";
  if (fn && ln) {
    displayName = `${fn} ${ln}`;
    return displayName;
  } else if (fn && !ln) {
    displayName = fn;
  } else if (ln && !fn) {
    displayName = ln;
  } else if (!fn && !ln) {
    if (username) {
      displayName = username;
      return displayName;
    }
  }
  return email ?? "";
};
