import {
  reatomAsync,
  withStatusesAtom,
  withErrorAtom,
  withDataAtom,
  withAbort,
} from "@reatom/async";
import { action, atom, AtomMut } from "@reatom/core";
import { withLocalStorage } from "@reatom/persist-web-storage";
import { callErrorAction } from "@/entities/notification";
import { getScriptLength, sortScenes } from "@/entities/script";
import {
  convertShot,
  GenerateImage,
  GenerateImageParams,
  REGENERATE_STORYBOARD_IMAGES,
  toScenes,
  TIME_PER_1000_CHARS_S,
} from "@/entities/storyboard";
import { getScriptResource } from "@/shared/api/script";
import {
  generateStoryboardShotResource,
  getStoryboardResource,
  getStoryboardStatusResource,
  RegenerateImageParams,
  regenerateImageResource,
  UpdateSceneParams,
  updateStoryboardSceneResource,
  updateStoryboardShotResource,
} from "@/shared/api/storyboard";

export const storyboardEstimatedTimeAtom = atom<number>(0);

export const generateImageAtom = atom<GenerateImage>({}, "generateImageAtom");
export const regenerateImageAtom = atom<GenerateImage>({}, "regenerateImageAtom");

export const updateGenerateImageAction = action((ctx, params: GenerateImageParams) => {
  const generateImage = ctx.get(generateImageAtom);

  generateImageAtom(ctx, {
    ...generateImage,
    [params.shotId]: {
      pending: params.loading,
      image: params.image === null ? generateImage[params.shotId]?.image : params.image,
      error: params.error,
    },
  });
});

export const updateRegenerateImageAction = action((ctx, params: GenerateImageParams) => {
  const regenerateImage = ctx.get(regenerateImageAtom);

  regenerateImageAtom(ctx, {
    ...regenerateImage,
    [params.shotId]: {
      pending: params.loading,
      image: params.image === null ? regenerateImage[params.shotId]?.image : params.image,
      error: params.error,
    },
  });
});

export const regenerateImageAction = reatomAsync(
  async (_ctx, params: RegenerateImageParams) => {
    const { data } = await regenerateImageResource(params);

    return {
      ...params,
      ...data,
    };
  },
  {
    onFulfill: (ctx, data) => {
      regeneratedImagesAtom(ctx, { ...ctx.get(regeneratedImagesAtom), [data.shotId]: true });
    },
  },
).pipe(withStatusesAtom(), withErrorAtom());

export const generateImageAction = reatomAsync(
  async (_ctx, params: RegenerateImageParams) => {
    const { data } = await generateStoryboardShotResource(params);

    return {
      ...params,
      ...data,
    };
  },
  {
    onFulfill: (ctx, data) => {
      regeneratedImagesAtom(ctx, { ...ctx.get(regeneratedImagesAtom), [data.shotId]: true });
    },
  },
).pipe(withStatusesAtom(), withErrorAtom());

type RegeneratedImages = { [shotId: string]: boolean };

export const regeneratedImagesAtom = atom<RegeneratedImages>(
  {} as RegeneratedImages,
  "regeneratedImages",
).pipe(withLocalStorage(REGENERATE_STORYBOARD_IMAGES)) as AtomMut<RegeneratedImages>;

export const getStoryboardStatusAction = reatomAsync(async (ctx, projectKey: string) =>
  getStoryboardStatusResource(projectKey, ctx.controller),
).pipe(
  withDataAtom(false, (_ctx, res) => res.data.need_generation),
  withErrorAtom(callErrorAction),
  withAbort(),
  withStatusesAtom(),
);

export const getStoryboardAction = reatomAsync(async (ctx, projectKey: string) => {
  const { data } = await getScriptResource(projectKey, ctx.controller);

  const orders = data.scenes_order;
  const scenes = data.scenes_info;
  const sceneList = sortScenes(scenes, orders);
  const length = getScriptLength(sceneList);

  const maxTokens = Math.ceil(length / 1000);
  const time = maxTokens * TIME_PER_1000_CHARS_S * 1000;

  storyboardEstimatedTimeAtom(ctx, time);

  return await getStoryboardResource(projectKey, ctx.controller);
}, "getStoryboardAction").pipe(
  withDataAtom([], (ctx, res) => {
    const regeneratedImages = ctx.get(regeneratedImagesAtom);

    const scenes = toScenes(
      res.data.scenes_info,
      res.data.scenes_order,
      regeneratedImages,
      res.data.city,
      res.data.country,
    );

    return scenes;
  }),
  withErrorAtom((ctx, err) => callErrorAction(ctx, err)),
  withStatusesAtom(),
  withAbort(),
);

export const updateSceneLocationAction = action(async (ctx, params: UpdateSceneParams) => {
  try {
    const sceneList = ctx.get(getStoryboardAction.dataAtom);
    const scene = sceneList.find((el) => el.id === params.scene_info.scene_id);
    const shots = scene?.shots.map((shot) =>
      convertShot(scene.id, params.scene_info.shots_order, {
        ...shot,
        location: params.scene_info.selected_location,
      }),
    );

    await updateStoryboardSceneResource(params);

    if (shots?.length) {
      await Promise.all(
        shots.map((shot, index) =>
          updateStoryboardShotResource({
            projectKey: params.projectKey,
            sceneId: params.sceneId,
            shotId: shot.shots_order[index],
            shot_info: shot.shot_info,
            shots_order: shot.shots_order,
          }),
        ),
      );
    }

    const newSceneList = sceneList.map((scene) => ({
      ...scene,
      shots: scene.shots.map((shot) => ({
        ...shot,
        location: params.scene_info.selected_location,
      })),
    }));

    getStoryboardAction.dataAtom(ctx, newSceneList);
  } catch (e) {
    callErrorAction(ctx, e);
  }
});
