import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { cancelSubscriptionResource, renewSubscriptionResource } from "@/shared/api/payment";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { callErrorAction } from "@/entities/notification";
import { SettingsWrapper } from "@/entities/settings";

import { getCurrentPlanAction } from "../../model";

export const CurrentPlan: FC = () => {
  const [current] = useAtom(getCurrentPlanAction.dataAtom);
  const getPlan = useAction(getCurrentPlanAction);

  const catchError = useAction(callErrorAction);
  const cancelSubscription = async () => {
    try {
      await cancelSubscriptionResource();
      getPlan();
    } catch (e) {
      catchError(e);
    }
  };

  const renewSubscription = async () => {
    try {
      await renewSubscriptionResource();
      getPlan();
    } catch (e) {
      catchError(e);
    }
  };

  return (
    <SettingsWrapper
      title="Current Plan"
      description="See information regarding your current plan"
      className="full-width gap-s"
      style={{
        height: "244px",
        justifyContent: "space-between",
      }}
    >
      <Flex
        align="center"
        justify="space-between"
        style={{
          borderRadius: "8px",
          padding: "16px",
          border: "1px solid var(--metal-gray-300)",
        }}
      >
        <Flex vertical className="gap-4">
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
          >
            Plan
          </Typography.Text>
          <Typography.Text className={TEXT_CLASSNAMES.SmSemibold}>{current?.name}</Typography.Text>
        </Flex>
        <div style={{ width: "1px", height: "100%", backgroundColor: "var(--metal-gray-300)" }} />
        <Flex vertical className="gap-4">
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
          >
            Projects
          </Typography.Text>
          <Typography.Text className={TEXT_CLASSNAMES.SmSemibold}>
            {current?.num_projects}
          </Typography.Text>
        </Flex>
        <div style={{ width: "1px", height: "100%", backgroundColor: "var(--metal-gray-300)" }} />
        <Flex vertical className="gap-4">
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
          >
            Monthly Seats
          </Typography.Text>
          <Typography.Text className={TEXT_CLASSNAMES.SmSemibold}>
            {current?.num_users}
          </Typography.Text>
        </Flex>
        <div style={{ width: "1px", height: "100%", backgroundColor: "var(--metal-gray-300)" }} />
        <Flex vertical className="gap-4">
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
          >
            Invoice total
          </Typography.Text>
          <Typography.Text className={TEXT_CLASSNAMES.SmSemibold}>
            ${current?.plan_amount}
          </Typography.Text>
        </Flex>
      </Flex>
      <Flex className="gap-xs">
        {current?.cancel_at_period_end ? (
          <Button size="large" type="primary" onClick={renewSubscription} className="full-width">
            Renew Subscription
          </Button>
        ) : (
          <Button size="large" onClick={cancelSubscription} className="full-width">
            Cancel Subscription
          </Button>
        )}
        <Button size="large" disabled type="primary" className="full-width">
          Upgrade plan
        </Button>
      </Flex>
    </SettingsWrapper>
  );
};
