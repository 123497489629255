import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { useNavigate } from "react-router-dom";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import error505 from "assets/shared/error_500.png";

export const Error500: FC = () => {
  const navigate = useNavigate();

  return (
    <Flex
      style={{
        height: "100vh",
      }}
      vertical
      justify="center"
      align="center"
      className="gap-xl"
    >
      <img
        style={{
          maxWidth: "700px",
          maxHeight: "344px",
        }}
        className="image-contain"
        src={error505}
        alt="505 error"
      />
      <Flex vertical justify="center" align="center" className="gap-xs">
        <Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>
          Oops, we’re getting the 500 Error
        </Typography.Text>
        <Typography.Text className={cn("text-center", TEXT_CLASSNAMES.MdRegular)}>
          Something went wrong and we’re already working on it.
          <br />
          Try again soon or contact our customer care if you need immediate help.
        </Typography.Text>
        <Button onClick={() => navigate(-1)} type="primary">
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Back Home</Typography.Text>
        </Button>
      </Flex>
    </Flex>
  );
};
