import { Flex } from "antd";
import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { NewsPath } from "shared/types/routes.ts";
import { NEWS } from "../../utils";

import "./NewsContent.scss";

export const NewsContent: FC = () => {
  const { id } = useExtractParams<NewsPath>();

  const content = id ? NEWS.find((el) => el.id === id) : NEWS[0];

  return (
    <Flex vertical className="news-content gap-m">
      <img className="news-content__img" src={content?.contentImg} alt="content" />
      {content?.dataType === "markdown" && <ReactMarkdown children={content?.contentUrl ?? ""} />}
      {content?.dataType === "react" && <content.contentComponent />}
    </Flex>
  );
};
