import { useWebSocket } from "ahooks";
import { Flex, Menu, MenuProps, Typography } from "antd";
import cn from "classnames";
import { FC, useRef } from "react";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { StoryboardSidebarPath } from "shared/types/routes.ts";

import { useBehavior } from "../model";
import { RenderComments } from "./RenderComments";
import { TextField } from "./text-field";

type MenuItem = Required<MenuProps>["items"][number];
type MenuItemKey = "all" | "resolved";

const ORIG_API_SOCKET_URL = `${process.env.VITE_API_URL}`
  .replace("https", "wss")
  .replace("/api", "");

export const CommentsScriptOutline: FC = () => {
  const { id } = useExtractParams<StoryboardSidebarPath>();

  const commentsRef = useRef<HTMLDivElement>(null);

  const { latestMessage } = useWebSocket(`${ORIG_API_SOCKET_URL}/ws?project_key=${id}`);

  const { activeTab, setActiveTab, filteredComments } = useBehavior({
    latestMessage,
    id,
    commentsRef,
  });

  const items: MenuItem[] = [
    {
      label: (
        <Typography.Text
          className={cn(COLOR_CLASSNAMES.MetalGray600, TEXT_CLASSNAMES.Subtitle10, {
            [COLOR_CLASSNAMES.MetalGray900]: activeTab === "all",
            [TEXT_CLASSNAMES.Link10]: activeTab === "all",
          })}
        >
          All
        </Typography.Text>
      ),
      key: "all",
    },
    {
      label: (
        <Typography.Text
          className={cn(COLOR_CLASSNAMES.MetalGray600, TEXT_CLASSNAMES.Subtitle10, {
            [COLOR_CLASSNAMES.MetalGray900]: activeTab === "unresolved",
            [TEXT_CLASSNAMES.Link10]: activeTab === "unresolved",
          })}
        >
          Unresolved
        </Typography.Text>
      ),
      key: "unresolved",
    },
    {
      label: (
        <Typography.Text
          className={cn(COLOR_CLASSNAMES.MetalGray600, TEXT_CLASSNAMES.Subtitle10, {
            [COLOR_CLASSNAMES.MetalGray900]: activeTab === "resolved",
            [TEXT_CLASSNAMES.Link10]: activeTab === "resolved",
          })}
        >
          Resolved
        </Typography.Text>
      ),
      key: "resolved",
    },
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    setActiveTab(e.key as MenuItemKey);
  };

  return (
    <Flex vertical style={{ height: "100%" }} ref={commentsRef}>
      <Menu
        style={{
          background: "transparent",
          padding: "0 16px",
          lineHeight: "32px",
        }}
        items={items}
        selectedKeys={[activeTab]}
        onClick={onClick}
        mode="horizontal"
      />
      <Flex
        style={{
          boxSizing: "border-box",
          overflowY: "auto",
        }}
        flex={1}
        vertical
        className="comments"
      >
        <RenderComments filteredComments={filteredComments} />
      </Flex>
      <div style={{ padding: 16 }}>
        <TextField placeholder="Add a comment" />
      </div>
    </Flex>
  );
};
