import { Button, Flex, Form, Input, Select, Typography } from "antd";
import { TEXT_CLASSNAMES } from "../../const/text-classnames.ts";
import { SelectProps, InviteProps, InviteMemberFieldType } from "./types.ts";

type Props = SelectProps & InviteProps & { isButtonDisabled: boolean };

export function InviteEmailField({ placeholder, options, isButtonDisabled, onFinish }: Props) {
  return (
    <Form onFinish={onFinish} className="inviter-member__form">
      <Flex key="unique" className="gap-xxs" justify="space-between">
        <Form.Item<InviteMemberFieldType>
          name="email"
          rules={[{ required: true, message: "Missing email" }]}
          style={{ width: "100%" }}
        >
          <Input placeholder="email@address.com" />
        </Form.Item>
        <Form.Item<InviteMemberFieldType>
          name="select"
          rules={[{ required: true, message: "Missing field" }]}
        >
          <Select placeholder={placeholder} style={{ width: 174 }} options={options} />
        </Form.Item>
        <Button htmlType="submit" type="primary" disabled={isButtonDisabled}>
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Invite</Typography.Text>
        </Button>
      </Flex>
    </Form>
  );
}
