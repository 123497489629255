import { useAction } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import success from "assets/shared/success.svg";
import { mobileCheck } from "shared/methods";
import { BackgroundPreLogin, LoginWrapper, sessionAuthAction } from "@/entities/viewer";

export const AlreadySubscribed = () => {
  const navigate = useNavigate();
  const sessionAuth = useAction(sessionAuthAction);
  const isMobile = mobileCheck();

  const start = () => {
    sessionAuth({ isGuest: false, email: "" });
    navigate("/projects");
  };

  return (
    <BackgroundPreLogin>
      <LoginWrapper title="" questionText="" actionText="" action={() => {}}>
        <Flex className="text-center" vertical gap={24}>
          <img src={success} alt="success" style={{ maxWidth: 200, margin: "0 auto" }} />
          <Flex style={{ maxWidth: 390 }} vertical gap={8}>
            <Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>
              You’re already subscribed!
            </Typography.Text>
          </Flex>
          {isMobile ? (
            <Button style={{ height: 60 }} onClick={start}>
              <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
                Start using the app <br />
                from the desktop
              </Typography.Text>
            </Button>
          ) : (
            <Button type="primary" onClick={start}>
              <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Start using</Typography.Text>
            </Button>
          )}
        </Flex>
      </LoginWrapper>
    </BackgroundPreLogin>
  );
};
