import {useClickAway, useKeyPress, useTimeout} from "ahooks";
import {Dispatch, SetStateAction, useCallback, useEffect} from "react";
import {Descendant, Selection} from "slate";
import {HistoryEditor} from "slate-history";
import {useOutsideClick} from "shared/hooks";
import {ReactEditor} from "slate-react";
import {getPosition} from "@/features/highlight-script/ui/split-shot-button";
import { useStore } from "./useStore";
import {useExtractParams} from "@/shared/hooks/use-extract-params.ts";
import {ScriptPath} from "shared/types/routes.ts";
import {useShotActiveHash} from "@/entities/script";

type UseBehaviorParams = {
	content: Descendant[];
	setIsAddButtonDisabled: Dispatch<SetStateAction<boolean>>;
	shotId: string;
	isAddButtonDisabled: boolean;
	sceneId: string;
}

export const useBehavior = ({ content, setIsAddButtonDisabled, shotId, isAddButtonDisabled, sceneId }: UseBehaviorParams) => {
	const { id } = useExtractParams<ScriptPath>();
	const {
		descriptionRef,
		editor,
		setScriptLength,
		setLocalShotValue,
		setEditingShot,
		localValueRef,
		scriptLengthRef,
		setIsActiveState,
		scriptLength,
		setIsHover,
		isShare,
		addShot,
		subscription,
		setPrevSelection,
		prevSelection,
		splitShot,
		setSplitShotButtonPosition,
		editingShot,
		isActiveState,
		updateShot,
		localShotValue,
		ref,
		editableRef,
		setIsSelectionStarted,
		isOutlined,
		isHover,
		isSelectionStarted,
		splitShotButtonPosition
	} = useStore({ content });
	const { activeHash, setActiveHash } = useShotActiveHash();
	const isActive = isActiveState || shotId === activeHash

	const setIsActive = (val: boolean) => {
		setIsActiveState(val)
		setActiveHash(shotId);
	};

	const setDelay = useTimeout(() => {
		setIsAddButtonDisabled(false);
	}, 1000);

	const onChange = useCallback((value: Descendant[]) => {
		const oldShotDescriptionLength = descriptionRef.current?.length ?? 0;
		const newShotDescriptionLength = editor.string([]).length;

		const diff = newShotDescriptionLength - oldShotDescriptionLength;

		setScriptLength(scriptLengthRef.current + diff);
		setLocalShotValue(value);
		setEditingShot(shotId);
		localValueRef.current = value;
	}, []);

	const onFocus = () => {
		setIsActive(true);
		scriptLengthRef.current = scriptLength;
	};

	const onBlur = () => {
		setIsActive(false);
		scriptLengthRef.current = scriptLength;
	};

	const toggleHover = () => {
		setIsHover((prev) => !prev);
	};

	const handleAddShot = () => {
		if (!isAddButtonDisabled && !isShare) {
			setIsAddButtonDisabled(true);
			addShot({
				projectKey: id,
				sceneId,
				shotId,
			});
			setDelay();
		}
	};

	const onDomBeforeInput = (e: Event) => {
		const event = e as InputEvent;
		const disabled = scriptLength >= (subscription?.num_symbols || 10000);

		if (event.inputType === "insertText" && disabled) {
			e.preventDefault();
		}

		if (event.inputType === "insertFromPaste" && disabled) {
			e.preventDefault();
		}

		if (event.inputType === "insertFromPaste") {
			const pasteText = event.dataTransfer?.getData("text") ?? "";
			const length = pasteText.length;

			if (length + scriptLength > (subscription?.num_symbols || 10000)) {
				e.preventDefault();
			}
		}
	};

	const handleSelect = useCallback(
		(selection: Selection) => {
			if (selection) {
				const { anchor, focus } = selection;
				if (anchor.offset !== focus.offset) {
					setPrevSelection(selection);
				}
			}
		},
		[setPrevSelection],
	);

	const handleSplit = () => {
		if (prevSelection) {
			setEditingShot("");
			splitShot({ selection: prevSelection, sceneId, shotId, projectKey: id });
			setSplitShotButtonPosition(null);
		}
	};


	useKeyPress(
		["ctrl.z", "meta.z"],
		() => {
			if (editingShot === shotId && !isShare) {
				HistoryEditor.undo(editor);
			}
		},
		{ exactMatch: true },
	);

	useKeyPress(["shift.ctrl.z", "meta.ctrl.z"], () => {
		if (editingShot === shotId && !isShare) {
			HistoryEditor.redo(editor);
		}
	});

	useKeyPress(["esc"], () => {
		if (isActive) {
			const description = editor.string([]);
			setEditingShot("");
			updateShot({
				projectKey: id,
				sceneId,
				shotId,
				value: localShotValue,
				description,
			});
		}
	});

	useClickAway(() => {
		const prevContent = JSON.stringify(content);
		const currentContent = JSON.stringify(localShotValue);

		if (prevContent !== currentContent) {
			const description = editor.string([]);

			setEditingShot("");
			updateShot({
				projectKey: id,
				shotId,
				sceneId,
				value: localShotValue,
				description,
			});
			scriptLengthRef.current = scriptLength;
		}
	}, ref);

	useEffect(() => {
		descriptionRef.current = editor.string([]);
	}, []);

	useOutsideClick(ref, () => {
		setPrevSelection(null);
		setSplitShotButtonPosition(null);
	});

	useEffect(() => {
		editableRef.current = ReactEditor.toDOMNode(editor, editor);
	}, []);

	useEffect(() => {
		const handleMouseUp = (e: MouseEvent) => {
			setSplitShotButtonPosition((prev) => {
				const position = getPosition(e, ref.current as HTMLElement);
				return { ...prev, endX: position.x, endY: position.y };
			});
			setIsSelectionStarted(false);
		};
		const handleMouseDown = (e: MouseEvent) => {
			setSplitShotButtonPosition((prev) => {
				const position = getPosition(e, ref.current as HTMLElement);
				return { ...prev, startX: position.x, startY: position.y };
			});
			setPrevSelection(null);
			// setSplitShotButtonPosition(null)
			setIsSelectionStarted(true);
		};

		if (editableRef.current) {
			editableRef.current.addEventListener("mouseup", handleMouseUp);
			editableRef.current.addEventListener("mousedown", handleMouseDown);
		}

		return () => {
			if (editableRef.current) {
				editableRef.current.removeEventListener("mouseup", handleMouseUp);
				editableRef.current.removeEventListener("mousedown", handleMouseDown);
			}
		};
	}, [editor, prevSelection, setPrevSelection, setIsSelectionStarted]);

	return {
		descriptionRef,
		scriptLength,
		subscription,
		isOutlined,
		isHover,
		isActive,
		onChange,
		onFocus,
		onBlur,
		toggleHover,
		handleAddShot,
		handleSplit,
		handleSelect,
		ref,
		setEditingShot,
		isShare,
		prevSelection,
		isSelectionStarted,
		splitShotButtonPosition,
		editor,
		localShotValue,
		onDomBeforeInput
	};
};
