import { Divider, Flex, Skeleton } from "antd";
import { FC } from "react";

import { SettingsWrapper } from "@/entities/settings";

export const AccountSettingsSkeleton: FC = () => {
  return (
    <SettingsWrapper
      title="Account Settings"
      description="Update your personal details here"
      className="gap-s full-width"
    >
      <div>
        <Flex vertical gap={26.2}>
          <Flex className="gap-xs">
            <Flex vertical className="gap-4 flex-1">
              <Skeleton title={false} paragraph={{ rows: 1 }} />
              <Skeleton.Input style={{ width: "100%" }} active size="large" />
            </Flex>
            <Flex vertical className="gap-4 flex-1">
              <Skeleton title={false} paragraph={{ rows: 1 }} />
              <Skeleton.Input style={{ width: "100%" }} active size="large" />
            </Flex>
          </Flex>
          <Flex vertical className="gap-4 flex-1">
            <Skeleton title={false} paragraph={{ rows: 1 }} />
            <Skeleton.Input style={{ width: "100%" }} active size="large" />
          </Flex>
        </Flex>
        <Divider />
        <Flex vertical className="gap-s">
          <Flex justify="space-between" align="center">
            <Skeleton title={true} paragraph={{ rows: 1 }} />
            <Skeleton.Button style={{ width: "211px" }} active size="large" />
          </Flex>
          <Flex className="gap-xs">
            <Skeleton.Button style={{ width: "100%" }} className="flex-1" active size="large" />
            <Skeleton.Button style={{ width: "100%" }} className="flex-1" active size="large" />
          </Flex>
        </Flex>
      </div>
    </SettingsWrapper>
  );
};
