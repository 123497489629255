import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Form, Input, Progress, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createGuestUserResource } from "@/shared/api/auth";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { REG_EXP } from "shared/const/reg-exp.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";
import { DisableSubmitButton } from "shared/ui";
import { validatePasswordReg } from "shared/validation";
import { showGuestInvitationToProjectAtom } from "@/entities/guest/model/guest-model";
import {
  BackgroundPreLogin,
  LoginWrapper,
  sessionAuthAction,
  viewerModel,
} from "@/entities/viewer";
import { axiosErrorHandler } from "@/shared/api";
import { IMessageError } from "@/vite-env";
import { colors, percent } from "../../register/utils";

type LocationParams = {
  verificationCode: string;
  email: string;
};

type FieldType = {
  username: string;
  password: string;
};

export const GuestInvitation: FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { isMobileL } = useMediaQueries();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [locationParams, setLocationParams] = useState<LocationParams>({
    verificationCode: "",
    email: "",
  });

  const getPermission = useAction(viewerModel.viewerPermission);
  const sessionAuth = useAction(sessionAuthAction);
  const [_, setShowGuestInvitationToProject] = useAtom(showGuestInvitationToProjectAtom);

  const [form] = Form.useForm();
  const username = Form.useWatch("username", { form, preserve: true });
  const password = Form.useWatch("password", { form, preserve: true });

  const validatedPassword = validatePasswordReg(password);
  const passwordChecks = Object.values(validatedPassword).filter((value) => value);
  const strokeColor = colors(passwordChecks);

  const onDocumentRedirect = (href: string) => {
    const anchor = document.createElement("a");
    anchor.href = href;
    anchor.target = "_blank";
    anchor.click();
  };

  const onLogin = () => {
    navigate("/login");
  };

  const onRegister = async (data: FieldType) => {
    try {
      setLoading(true);

      await createGuestUserResource({
        email: locationParams.email,
        verification_code: locationParams.verificationCode,
        username: data.username,
        password: data.password,
      });

      await getPermission();

      sessionAuth({ isGuest: true, email: locationParams.email });
      setShowGuestInvitationToProject(true);
      navigate("/projects");
    } catch (err) {
      const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

      if (type === "axios-error") {
        if (error.response?.status === 403) {
          setError("Incorrect email or password");
        } else if (error.response?.status === 409) {
          setError("Incorrect email or password");
        } else {
          setError(error?.response?.data?.message ?? "Oops, something went wrong");
        }
      } else {
        setError("Oops, something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const verificationCode = params.get("verification_code") ?? "";
    const email = params.get("email") ?? "";

    setLocationParams({
      verificationCode,
      email,
    });
  }, []);

  return (
    <BackgroundPreLogin>
      <LoginWrapper
        action={onLogin}
        title="Get started"
        questionText="Already have an account?"
        actionText="Sign in"
      >
        <Form
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onRegister}
          autoComplete="off"
          className="register-form"
          validateTrigger={["onSubmit"]}
        >
          <Flex vertical className="gap-xs">
            <Input placeholder="Email" value={locationParams.email} disabled />
            <Form.Item<FieldType>
              name="username"
              rules={[{ required: true, message: "Please input your name!" }]}
              hasFeedback
              validateStatus={username ? "success" : ""}
            >
              <Input
                onChange={(event) => form.setFieldValue("username", event.target.value)}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item<FieldType>
              name="password"
              style={{
                maxWidth: 380,
              }}
              rules={[
                { required: true, message: "Please input your password!" },
                { min: 8, message: "Password must be at least 8 characters" },
                {
                  pattern: REG_EXP.upperCaseLetter,
                  message: "Min. eight characters at least one uppercase letter",
                },
              ]}
            >
              <Input.Password
                onChange={(event) => form.setFieldValue("password", event.target.value)}
                placeholder="Password"
              />
              <Progress
                strokeColor={strokeColor}
                percent={percent(passwordChecks)}
                showInfo={false}
              />
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
              >
                The password must be at least 8 characters long and include at least one uppercase
                letter.
              </Typography.Text>
            </Form.Item>
          </Flex>
          <Flex vertical className="register-form__buttons gap-xs">
            <DisableSubmitButton loading={loading} errorMessage={error} type="primary" form={form}>
              <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular)}>Sign up</Typography.Text>
            </DisableSubmitButton>
          </Flex>
          <Typography.Text
            className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.SmRegular, {
              [TEXT_CLASSNAMES.XsRegular]: isMobileL,
            })}
          >
            By signing up I agree with
            <Typography.Text
              onClick={() => onDocumentRedirect("/terms-of-conditions")}
              className={cn(
                COLOR_CLASSNAMES.TextSecondary,
                TEXT_CLASSNAMES.SmRegular,
                "register-form__underline pointer",
                {
                  [TEXT_CLASSNAMES.XsRegular]: isMobileL,
                },
              )}
            >
              Terms of Service
            </Typography.Text>
            {!isMobileL && <br />}
            and
            <Typography.Text
              onClick={() => onDocumentRedirect("/privacy-policy")}
              className={cn(
                COLOR_CLASSNAMES.TextSecondary,
                TEXT_CLASSNAMES.SmRegular,
                "register-form__underline pointer",
                {
                  [TEXT_CLASSNAMES.XsRegular]: isMobileL,
                },
              )}
            >
              Privacy Policy
            </Typography.Text>
            and
            <Typography.Text
              onClick={() => onDocumentRedirect("/acceptable-use-policy")}
              className={cn(
                COLOR_CLASSNAMES.TextSecondary,
                TEXT_CLASSNAMES.SmRegular,
                "register-form__underline pointer",
                {
                  [TEXT_CLASSNAMES.XsRegular]: isMobileL,
                },
              )}
            >
              Acceptable Use Policy
            </Typography.Text>
          </Typography.Text>
        </Form>
      </LoginWrapper>
    </BackgroundPreLogin>
  );
};
