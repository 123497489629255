import { useAction } from "@reatom/npm-react";
import { Button, Flex, Form, Input } from "antd";
import { FC } from "react";

import { CreateComment } from "@/shared/api/comments";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ScriptPath } from "shared/types/routes.ts";
import arrow from "assets/shared/arrow.svg";
import { createCommentAction } from "../../model";

import "./TextField.scss";

type Props = {
  placeholder: string;
  parentId?: string | null;
};

type FieldType = {
  message: string;
};

export const TextField: FC<Props> = ({ placeholder, parentId }) => {
  const { id } = useExtractParams<ScriptPath>();
  const [form] = Form.useForm();
  const message = Form.useWatch("message", form);
  const addComment = useAction(createCommentAction);

  const onSubmit = async (data: FieldType) => {
    const comment: CreateComment = {
      message: data.message,
      project_key: id ?? "",
    };

    if (parentId) {
      comment.parent_id = parentId;
    }

    if (comment.message) {
      form.resetFields();
      await addComment(comment);
    }
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    onSubmit({ message });
  };

  return (
    <Form form={form} onFinish={onSubmit}>
      <Flex className="text-field">
        <Form.Item name="message" className="full-width">
          <Input.TextArea
            name="message"
            placeholder={placeholder}
            rows={2}
            onPressEnter={onPressEnter}
          />
        </Form.Item>
        <Flex className="text-field__icon">
          <Button
            htmlType="submit"
            size="small"
            type="primary"
            className="flex-ctr-ctr text-field__icon__btn"
          >
            <img src={arrow} alt="arrow" />
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};
