import { useAction } from "@reatom/npm-react";
import { useHover } from "ahooks";
import { Flex } from "antd";
import cn from "classnames";
import { FC, useRef } from "react";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ScriptPath } from "shared/types/routes.ts";
import { addShotToSceneAction } from "@/entities/script";

type Props = {
  sceneId: string;
  shotId: string;
};

export const LastShot: FC<Props> = ({ shotId, sceneId }) => {
  const { id } = useExtractParams<ScriptPath>();
  const ref = useRef(null);
  const isHover = useHover(ref);
  const addShot = useAction(addShotToSceneAction);

  const handleAddShot = () => {
    addShot({
      projectKey: id,
      shotId,
      sceneId,
    });
  };

  return (
    <Flex ref={ref} style={{ paddingTop: 32 }} className="full-width">
      <div
        onClick={handleAddShot}
        className={cn("shot__last-shot full-width pointer", {
          "shot__last-shot--hover": isHover,
        })}
      />
    </Flex>
  );
};
