import { useAtom } from "@reatom/npm-react";
import { Flex, Skeleton } from "antd";
import { FC } from "react";
import { ApplyPromoCodeParams, PlanParams } from "../../lib";
import { getPossibleSubscriptionsAction } from "../../model";
import { PlanType } from "./PlanType.tsx";

type Props = {
  activePlanParams: PlanParams;
  onChange: (params: PlanParams) => void;
  loading: boolean;
  onSubscription: (productId: number) => void;
  onApplyPromoCode: (params: ApplyPromoCodeParams) => void;
  fetching: boolean;
};

export const SubPlans: FC<Props> = ({
  onChange,
  activePlanParams,
  loading,
  onSubscription,
  onApplyPromoCode,
  fetching,
}) => {
  const [subscriptions] = useAtom(getPossibleSubscriptionsAction.dataAtom);

  if (loading) {
    return (
      <Flex className="plan-type">
        <Skeleton active paragraph={{ rows: 2 }} />
      </Flex>
    );
  }

  return (
    <>
      {subscriptions
        .filter((el) => el.interval === "month")
        .map((plan, idx) => (
          <PlanType
            key={idx.toString(36)}
            activePlanParams={activePlanParams}
            name="plan"
            productId={plan.id}
            title="Single User"
            description={`${plan.users} user, Short scripts (10 000 characters max), ${
              plan.projects
            } projects maximum, ${plan.watermark ? "Watermarks" : "No watermarks"}`}
            interval={plan.interval}
            amount={plan.unit_amount}
            currency={plan.currency}
            onChange={onChange}
            onSubscription={onSubscription}
            onApplyPromoCode={onApplyPromoCode}
            fetching={fetching}
          />
        ))}
    </>
  );
};
