import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, ReactNode } from "react";

import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";

import "./Step.scss";

type Props = {
  num: number;
  title: string;
  description: string;
  img: ReactNode;
  numImg: string;
  arrowImg: string;
};

export const Step: FC<Props> = ({ num, description, title, img, numImg, arrowImg }) => {
  const { isTablet, isMobileXL } = useMediaQueries();

  return (
    <Flex vertical={isTablet}>
      <Flex justify="center" align="center" className="product-step__text">
        <Flex
          vertical
          className="gap-m product-step__text--width"
          justify="center"
          align="flex-start"
        >
          <Flex vertical align="start" className="gap-xs text-left">
            <img src={numImg} alt={num.toString()} />
            {/* <Typography.Text className={cn(TEXT_CLASSNAMES.LgSemibold, COLOR_CLASSNAMES.TextWhitePrimary, "product-step__num")}>{num}</Typography.Text> */}
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.HeadlineH1, {
                [TEXT_CLASSNAMES.HeadlineH2]: isTablet,
              })}
            >
              {title}
            </Typography.Text>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.MdRegular, {
                [TEXT_CLASSNAMES.SmRegular]: isTablet,
                [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
              })}
            >
              {description}
            </Typography.Text>
          </Flex>
          {!isTablet && !!arrowImg && (
            <img className="product-step__img pointer" src={arrowImg} alt="arrow" />
          )}
        </Flex>
      </Flex>
      {img}
    </Flex>
  );
};
