import { useAction, useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC, useEffect } from "react";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { StoryboardPath } from "shared/types/routes.ts";
import { useUpdateTitle } from "shared/hooks";
import { getProjectByNameAction } from "@/entities/projects";
import { RenderTable, StoryboardView } from "../../../features/storyboard-view";

import "./Storyboard.scss";

export const Storyboard: FC = () => {
  const { id } = useExtractParams<StoryboardPath>();
  const getProject = useAction(getProjectByNameAction);
  const [project] = useAtom(getProjectByNameAction.dataAtom);

  useUpdateTitle(project?.name);

  useEffect(() => {
    if (id) {
      getProject(id);
    }
  }, []);

  return (
    <Flex vertical className="storyboard full-height">
      <Flex
        style={{
          position: "absolute",
          zIndex: -10,
          opacity: 0,
          height: 0,
          overflow: "hidden",
          top: 0,
          right: 0,
        }}
      >
        <RenderTable />
      </Flex>
      <Flex className="storyboard__content full-height">
        <StoryboardView />
      </Flex>
    </Flex>
  );
};
