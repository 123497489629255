import { Button, Flex, Segmented } from "antd";
import { FC } from "react";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { NOT_RELEASE } from "shared/const/release.ts";
import { STORYBOARD_TYPE } from "shared/const/routes.ts";
import { TTabView } from "shared/types/common.ts";
import { StoryboardPath } from "shared/types/routes.ts";
import { RegenerateImagesModal } from "@/entities/storyboard";
import { ExportStoryboardModal } from "../../../../../features/export-storyboard-modal";
import { useBehavior } from "../model";

import { ExportIcon, GridIcon, ListIcon, SettingsIcon } from "./icons";

export const StoryboardSettings: FC = () => {
  const { id } = useExtractParams<StoryboardPath>();
  const {
    storyboardType,
    setStoryboardType,
    toggleOpenExport,
    isOpenExport,
    isOpenRegenerate,
    toggleRegenerate,
    toPDF,
    isSaving,
    onGenerateImages,
    shots,
    onRegenerate,
    isRegenerate,
    disabled,
  } = useBehavior({ projectKey: id });

  return (
    <Flex justify="space-between" className="full-width">
      <Flex className="gap-xxs">
        <Segmented
          onChange={setStoryboardType}
          className="segmented-border moodboard-segmented"
          size="small"
          value={storyboardType as TTabView}
          options={[
            { value: STORYBOARD_TYPE.grid, icon: <GridIcon /> },
            { value: STORYBOARD_TYPE.list, icon: <ListIcon /> },
          ]}
        />
        {NOT_RELEASE && (
          <Button size="small" icon={<SettingsIcon />}>
            View settings
          </Button>
        )}
      </Flex>
      <Flex className="gap-xxs">
        <Button onClick={() => toggleOpenExport()} size="small" icon={<ExportIcon />}>
          Export
        </Button>
        <Button
          onClick={isRegenerate ? onRegenerate : onGenerateImages(shots)}
          size="small"
          type="primary"
        >
          Generate images
        </Button>
      </Flex>
      <ExportStoryboardModal
        toggleModal={toggleOpenExport}
        isOpen={isOpenExport}
        isPending={disabled}
        toPDF={toPDF}
        isSaving={isSaving}
      />
      <RegenerateImagesModal
        shots={shots}
        toggleModal={toggleRegenerate}
        isOpen={isOpenRegenerate}
        onGenerateImages={onGenerateImages}
      />
    </Flex>
  );
};
