import { Flex, Modal, Typography } from "antd";
import { FC, PropsWithChildren } from "react";

import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { IOpenModal } from "shared/hooks";

import "./GuestModal.scss";

export const GuestModal: FC<PropsWithChildren<IOpenModal>> = ({
  isOpen,
  toggleModal,
  children,
}) => {
  return (
    <Modal width={640} footer={[]} open={isOpen} onCancel={() => toggleModal(false)}>
      <Flex vertical className="gap-xs">
        <Flex vertical>
          <Typography.Text className={TEXT_CLASSNAMES.Title50}>Manage team project</Typography.Text>
        </Flex>
        <Flex vertical className="gap-s">
          {children}
        </Flex>
      </Flex>
    </Modal>
  );
};
