import { Flex, Skeleton } from "antd";
import { FC } from "react";

import "./ProjectCard.scss";

export const ProjectCardSkeleton: FC = () => {
  return (
    <Flex vertical className="your-storyboard">
      <Flex vertical justify="space-between" className="your-storyboard__top">
        <Skeleton.Image style={{ height: 200, width: 288 }} active />
        <Skeleton active paragraph={{ rows: 1 }} />
      </Flex>
      <Flex className="your-storyboard__info">
        <Skeleton.Input active style={{ height: 27 }} />
      </Flex>
    </Flex>
  );
};
