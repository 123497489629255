import { useAction } from "@reatom/npm-react";
import { ConfigProvider, Flex, Select, Typography } from "antd";
import chevron from "assets/storyboard/chevron.svg";
import map from "assets/storyboard/map.svg";
import { FC, MouseEvent, useState } from "react";

import { useProjectType } from "@/entities/projects";
import { updateSceneLocationAction } from "@/entities/storyboard";
import { ProjectLocation } from "@/shared/api/project";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { COLOR_NAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { StoryboardPath } from "shared/types/routes.ts";

import "./SceneHeader.scss";

interface ISceneHeader {
  title: string;
  sceneId: string;
  shotsOrder: string[];
  scenesOrder: string[];
  shotLength: number;
  idx: number;
  activeLocation: string;
  locations: string[];
  projectLocation: ProjectLocation;
}

export const SceneHeader: FC<ISceneHeader> = ({
  title,
  shotLength,
  idx,
  locations,
  activeLocation,
  sceneId,
  scenesOrder,
  shotsOrder,
}) => {
  const { id } = useExtractParams<StoryboardPath>();
  const updateSceneLocation = useAction(updateSceneLocationAction);
  const [location, setLocation] = useState(activeLocation);
  const { isShare } = useProjectType();

  const handleChange = (value: string) => {
    setLocation(value);
    updateSceneLocation({
      sceneId,
      projectKey: id,
      scene_info: {
        title,
        scene_id: sceneId,
        shots_order: shotsOrder,
        scene_locations: locations,
        selected_location: value,
      },
      scenes_order: scenesOrder,
    });
  };

  const toMap = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    window.open(`https://maps.google.com/maps?q=${location}`, "_blank");
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorBgContainer: COLOR_NAMES.BackgroundWhite,
            controlHeight: 40,
          },
        },
      }}
    >
      <Flex className="scene-storyboard" justify="space-between" align="center">
        <Flex align="center" className="gap-xs">
          <Flex
            style={{
              background: COLOR_NAMES.BackgroundWhite,
              padding: "8px 16px",
              borderRadius: 8,
            }}
            align="center"
            className="gap-xxs"
          >
            <Typography.Text className={TEXT_CLASSNAMES.HeadlineH4}>
              Scene {idx + 1} "{title}"
            </Typography.Text>
            <span style={{ width: 3, height: 3, borderRadius: "50%", background: "black" }} />
            <Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>
              {shotLength === 0
                ? "not Shots"
                : shotLength === 1
                  ? `${shotLength} Shot`
                  : `${shotLength} Shots`}
            </Typography.Text>
          </Flex>
          {!isShare && (
            <Flex className="map-selector" style={{ position: "relative" }}>
              <div onClick={toMap} className="scene-storyboard__fake-map" />
              <Select
                suffixIcon={
                  <Flex gap={8}>
                    <img src={map} alt="map" style={{ width: 20, height: 20 }} />
                    <img src={chevron} alt="chevron" style={{ width: 20, height: 20 }} />
                  </Flex>
                }
                defaultValue={activeLocation}
                value={location}
                style={{ width: 320 }}
                onChange={handleChange}
                onClick={(event) => event.stopPropagation()}
                options={locations.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </ConfigProvider>
  );
};
