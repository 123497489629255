import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";
import alice from "assets/use-cases/script/alice.png";
import assistant from "assets/use-cases/script/assistant.png";
import breakdown from "assets/use-cases/script/breakdown.png";

import john from "assets/use-cases/script/john.png";
import mary from "assets/use-cases/script/mary.png";
import mike from "assets/use-cases/script/mike.png";
import preview from "assets/use-cases/script/preview.png";
import rob from "assets/use-cases/script/rob.png";
import { useToTop } from "shared/hooks";
import { LandingContent } from "shared/ui";
import {
  UseCaseBlock,
  UseCaseContent,
  UseCaseFooter,
  UseCaseTitle,
  UseCaseWrapper,
} from "@/entities/use-cases";
import { Header } from "../../widgets/header";

import "./UseCaseScript.scss";

export const UseCaseScript: FC = () => {
  const { isMobileXL, isLaptopM } = useMediaQueries();
  useToTop();

  return (
    <Flex vertical>
      <LandingContent>
        <Header />
      </LandingContent>
      <UseCaseTitle
        isComingSoon={false}
        description="AI-powered script breakdowns turn projects into reality with speed and precision "
        italicHeaderText="effortless"
        headerText="Script breakdowns made "
      />
      <UseCaseBlock
        previewImg={preview}
        title="Break Down Scripts"
        description={
          <Flex vertical className="gap-xs">
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextWhitePrimary, {
                [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
                [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
              })}
            >
              Whether you have an idea or a finished script, Blooper streamlines the process.
            </Typography.Text>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextWhitePrimary, {
                [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
                [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
              })}
            >
              <ul
                className="list-white"
                style={{ display: "flex", flexDirection: "column", gap: 16 }}
              >
                <li>Our Script Assistant structures concepts into clear screenplays.</li>
                <li>
                  Script Breakdown organizes shot lists, scenes, and schedules.
                  <ul style={{ marginTop: 16, marginBottom: 0 }}>
                    <li>
                      You can select if you want plenty of shots for each scene or not, and we will
                      do it automatically.
                    </li>
                  </ul>
                </li>
                <li>
                  Character Creation ensures every role is well-defined—getting your
                  production-ready faster.
                </li>
              </ul>
            </Typography.Text>
          </Flex>
        }
      />
      <UseCaseWrapper>
        <Flex vertical={isMobileXL} className="gap-m">
          <UseCaseContent
            flex={1}
            title="Script assistant"
            description={
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.MdRegular, "text-left", {
                  [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
                  [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
                })}
              >
                <ul
                  className="list-black"
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <li>
                    Upload your screenplay to Blooper and instantly receive a script breakdown.
                  </li>
                  <li>
                    Text could be in any form, you don’t need to make it look as a professional
                    script.
                  </li>
                </ul>
              </Typography.Text>
            }
          >
            <img
              className="script-block-img__1 image-contain"
              src={assistant}
              alt="script assistant"
            />
          </UseCaseContent>
          <UseCaseContent
            id="script"
            flex={1}
            title="Script breakdown"
            description={
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.MdRegular, "text-left", {
                  [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
                  [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
                })}
              >
                <ul
                  className="list-black"
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <li>Define the shots of your movie by hand, or let Blooper do it.</li>
                  <li>Our AI-engine analyses your script and proposes a shot outline.</li>
                </ul>
              </Typography.Text>
            }
          >
            <img
              className="script-block-img__2 image-contain"
              src={breakdown}
              alt="script assistant"
            />
          </UseCaseContent>
        </Flex>
        <Flex>
          <UseCaseContent
            flex={1}
            title="Character creation"
            description="Our AI assistant recognizes characters in your script and provides you with a selection of people to embody them in your storyboard."
          >
            <Flex style={{ position: "relative", width: "100%" }}>
              {isMobileXL && <div style={{ height: 170 }} />}
              <Flex className="script-block-img__container-3" justify="space-between">
                <img className="script-block-img__3" src={mike} alt="script assistant" />
                <img className="script-block-img__3" src={alice} alt="script assistant" />
                <img className="script-block-img__3" src={john} alt="script assistant" />
                <img className="script-block-img__3" src={mary} alt="script assistant" />
                <img className="script-block-img__3" src={rob} alt="script assistant" />
              </Flex>
            </Flex>
          </UseCaseContent>
        </Flex>
      </UseCaseWrapper>
      <UseCaseFooter />
    </Flex>
  );
};
