import { Flex, Typography } from "antd";
import cn from "classnames";
import React, { FC, PropsWithChildren, useEffect } from "react";
import { COLOR_CLASSNAMES, COLOR_NAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";

import "./UseCaseContent.scss";

type Props = {
  flex: number;
  title: string;
  description: string | React.ReactNode;
  id?: string;
};

export const UseCaseWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div style={{ background: COLOR_NAMES.MetalGray50 }}>
      <Flex className="use-case-wrapper" vertical>
        {children}
      </Flex>
    </div>
  );
};

export const UseCaseContent: FC<PropsWithChildren<Props>> = ({
  flex,
  title,
  description,
  children,
  id,
}) => {
  const { isMobileXL } = useMediaQueries();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const anchor = params.get("anchor");

    if (anchor) {
      const element = document.getElementById(anchor);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <Flex id={id} className="use-case-content" flex={flex}>
      <Flex
        vertical
        justify="space-between"
        align="center"
        className="gap-s"
        style={{ margin: "0 auto" }}
      >
        <Flex vertical className="text-center gap-xs">
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.HeadlineH1, COLOR_CLASSNAMES.TextPrimary, {
              [TEXT_CLASSNAMES.HeadlineH2]: isMobileXL,
            })}
          >
            {title}
          </Typography.Text>
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextPrimary, {
              [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
            })}
          >
            {description}
          </Typography.Text>
        </Flex>
        {children}
      </Flex>
    </Flex>
  );
};
