import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex } from "antd";
import down from "assets/moodboard/down.svg";
import trash from "assets/moodboard/trash.svg";
import up from "assets/moodboard/up.svg";
import { CSSProperties, memo } from "react";
import {
  Attrs,
  getMoodboardAction,
  removeFromMoodBoardAction,
  shadowMoodboardButtonsAtom,
  // updateMoodboardImagesZIndexAction,
} from "@/entities/moodboard";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { COLOR_NAMES } from "shared/const/color-names";

import { MoodboardPath } from "shared/types/routes.ts";
// import lock from "assets/moodboard/lock.svg";

export const ShadowButtons = memo(() => {
  const { id } = useExtractParams<MoodboardPath>();
  const [buttons] = useAtom(shadowMoodboardButtonsAtom);
  const remove = useAction(removeFromMoodBoardAction);
  const [moodBoardList] = useAtom(getMoodboardAction.dataAtom);
  // const updateMoodboardImagesZIndex = useAction(updateMoodboardImagesZIndexAction);

  const onRemove = (imageId: string) => {
    remove(id, imageId);
  };

  const toStyle = (button: Attrs): CSSProperties => {
    const ACTIONS_WIDTH = 55;
    if (button.x > ACTIONS_WIDTH) {
      return {
        width: ACTIONS_WIDTH,
        zIndex: 100,
        position: "absolute",
        top: button.y,
        paddingRight: 15,
        left: button.x - ACTIONS_WIDTH,
      };
    }

    return {
      width: ACTIONS_WIDTH,
      zIndex: 100,
      position: "absolute",
      top: button.y,
      paddingLeft: 15,
      left: button.width + button.x,
    };
  };

  const zIndexAction = (button: Attrs, action: "up" | "down") => {
    if (button.group) {
      action === "up" ? button.group?.moveUp() : button.group?.moveDown();
      if (id) {
        // TODO optimize calculations
        const zIndexMap = buttons.reduce(
          (acc, curr) => {
            acc[curr.name] = curr.group?.zIndex() ?? 0;
            return acc;
          },
          {} as Record<string, number>,
        );

        moodBoardList.map((item) => {
          return { ...item, coordinates: { ...item.coordinates, minW: zIndexMap[item.id] } };
        });
        // updateMoodboardImagesZIndex(id, newImagesCoordiantes);
      }
    }
  };

  return (
    <>
      {buttons.map(
        (button) =>
          button.isActive && (
            <Flex style={toStyle(button)} vertical className="gap-xs" key={button.name}>
              <Flex vertical className="gap-xs">
                {/* <Button icon={<img src={lock} alt="lock" />} style={{ background: COLOR_NAMES.BackgroundWhite }} /> */}
                <Button
                  onClick={() => onRemove(button.name)}
                  icon={<img src={trash} alt="trash" />}
                  style={{ background: COLOR_NAMES.BackgroundWhite }}
                />
              </Flex>
              <Flex vertical className="gap-xs">
                <Button
                  icon={<img src={up} alt="up" />}
                  style={{ background: COLOR_NAMES.BackgroundWhite }}
                  onClick={() => zIndexAction(button, "up")}
                />
                <Button
                  icon={<img src={down} alt="down" />}
                  style={{ background: COLOR_NAMES.BackgroundWhite }}
                  onClick={() => zIndexAction(button, "down")}
                />
              </Flex>
            </Flex>
          ),
      )}
    </>
  );
});
