import { captureException } from "@sentry/react";

type Point = {
  x: number;
  y: number;
};

export const toBlackMask = (
  src: string,
  points: Point[],
  callback: (canvas: HTMLCanvasElement) => void,
) => {
  const backgroundCanvas = document.createElement("canvas");
  const ctx = backgroundCanvas.getContext("2d");

  if (!ctx) {
    captureException("No context to get mask", {
      tags: {
        feature: "to-black-mask-ctx",
      },
      level: "error",
    });
    return;
  }

  const image = new Image();

  image.onload = () => {
    try {
      backgroundCanvas.width = image.width;
      backgroundCanvas.height = image.height;
      backgroundCanvas.style.fill = "#000";

      if (points.length < 3) {
        callback(backgroundCanvas);
        return;
      }

      ctx.fillStyle = "#000";
      ctx.fillRect(0, 0, image.width, image.height);

      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);
      points.forEach(({ x, y }) => {
        ctx.lineTo(x, y);
      });
      ctx.lineTo(points[0].x + 1, points[0].y + 1);

      ctx.closePath();
      ctx.lineWidth = 5;
      ctx.fillStyle = "#fff";
      ctx.fill();
      ctx.stroke();

      callback(backgroundCanvas);
    } catch (err) {
      captureException(err, {
        tags: {
          feature: "mask-load",
        },
        level: "error",
      });
    }
  };

  image.src = src;
};
