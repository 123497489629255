import { useAtom } from "@reatom/npm-react";
import { Flex, Table, TableProps, Typography } from "antd";
import { FC } from "react";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { toDate } from "shared/methods";
import { SettingsWrapper } from "@/entities/settings";
import { getCurrentHistoryPlanAction } from "../../../index";

import "./BillingHidtory.scss";

interface DataType {
  key: string;
  date: string;
  amount: number;
  projectsAmount: number;
  seatsAmount: number;
  id: string;
  name: string;
}

export const BillingHistory: FC = () => {
  const [history] = useAtom(getCurrentHistoryPlanAction.dataAtom);

  const columns: TableProps<DataType>["columns"] = [
    {
      title: () => <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Date</Typography.Text>,
      dataIndex: "date",
      key: "date",
      render: (date) => (
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{date}</Typography.Text>
      ),
      width: 250,
    },
    {
      title: () => <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Amount</Typography.Text>,
      dataIndex: "amount",
      key: "amount",
      render: (amount) => (
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>€{amount}</Typography.Text>
      ),
      width: 220,
    },
    {
      title: () => (
        <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Projects</Typography.Text>
      ),
      dataIndex: "projectsAmount",
      key: "projectsAmount",
      render: (amount) => (
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{amount}</Typography.Text>
      ),
      width: 180,
    },
    {
      title: () => <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Seats</Typography.Text>,
      dataIndex: "seatsAmount",
      key: "seatsAmount",
      render: (amount) => (
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{amount}</Typography.Text>
      ),
      width: 180,
    },
    {
      title: () => <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Plan</Typography.Text>,
      key: "status",
      render: (data: DataType) => (
        <Flex vertical className="gap-4">
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{data.name}</Typography.Text>
        </Flex>
      ),
    },
  ];

  const data: DataType[] = history.map((el, index) => ({
    key: index.toString(36),
    amount: el.amount,
    date: toDate(el.date),
    projectsAmount: el.num_projects,
    seatsAmount: el.num_users,
    name: el.name,
    id: index.toString(36),
  }));

  return (
    <SettingsWrapper
      title="Billing History and Payment Schedule"
      description="See information regarding your billing history"
      className="gap-s full-width billing-history"
    >
      <Table
        className="billing-history__table"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </SettingsWrapper>
  );
};
