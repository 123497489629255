import { useHover } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useProjectType } from "@/entities/projects";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { ROUTES } from "shared/const/routes.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { emptyClick, generateUrl } from "shared/methods";
import { CharacterInfoPath, CharacterPath } from "shared/types/routes.ts";
import { ActionRestrictor } from "shared/ui";

import "./CharacterCard.scss";

interface ICharacterCard {
  img: string;
  name: string;
  description: string;
  id: string;
}

export const CharacterCard: FC<ICharacterCard> = ({ img, name, id }) => {
  const params = useExtractParams<CharacterPath>();
  const navigate = useNavigate();
  const ref = useRef(null);
  const isHover = useHover(ref);
  const { isShare } = useProjectType();

  const onRedirect = () => {
    navigate(
      generateUrl<CharacterInfoPath>(ROUTES.CHARACTER_INFO.fullPath, {
        id: params.id,
        characterId: id,
      }),
    );
  };

  return (
    <Flex
      ref={ref}
      onClick={isShare ? emptyClick : onRedirect}
      vertical
      className={cn("character-card pointer gap-xxs", {
        "non-pointer-events": isShare,
      })}
    >
      <Typography.Paragraph ellipsis={{ rows: 1 }} className={TEXT_CLASSNAMES.HeadlineH3}>
        "{name}"
      </Typography.Paragraph>
      {/* <Typography.Paragraph ellipsis={{ rows: 3 }} className={TEXT_CLASSNAMES.XsRegular}>{description}</Typography.Paragraph> */}
      <div className="character-card__img">
        <img src={img} alt={`character ${name}`} />
      </div>
      <ActionRestrictor show={!isShare}>
        {isHover && (
          <div className="character-card__hover">
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextWhitePrimary)}
            >
              Change character
            </Typography.Text>
          </div>
        )}
      </ActionRestrictor>
    </Flex>
  );
};
