import { Flex, Typography } from "antd";

import { TEXT_CLASSNAMES } from "../../const/text-classnames.ts";
import { InviteEmailField } from "./InviteEmailField.tsx";
import { InviteProps, SelectProps } from "./types.ts";

import "./InviteEmail.scss";

type Props = SelectProps &
  InviteProps & {
    title: string;
    isButtonDisabled: boolean;
  };

export function InviteEmail({ isButtonDisabled, options, placeholder, onFinish, title }: Props) {
  return (
    <Flex vertical className="gap-m">
      {!!title && <Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>{title}</Typography.Text>}
      <Flex vertical className="gap-xs">
        <Typography.Text className={TEXT_CLASSNAMES.Title20}>Invite with Email</Typography.Text>
        <InviteEmailField
          isButtonDisabled={isButtonDisabled}
          onFinish={onFinish}
          options={options}
          placeholder={placeholder}
        />
      </Flex>
    </Flex>
  );
}
