import axios, { AxiosError } from "axios";
import { logoutAction } from "@/entities/viewer";
import { refreshTokenResource } from "@/shared/api/auth";
import { ctx } from "@/shared/ctx";
import { IAxiosError, IStockError } from "@/vite-env";

export const api = axios.create({
  baseURL: "/api",
  withCredentials: true,
});

api.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    try {
      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const { status } = await refreshTokenResource();

        if (status !== 200) {
          logoutAction(ctx);
        } else {
          return api.request(originalRequest);
        }
      }

      // if (error.response?.status === 403 && error.response?.data?.message === "Access denied") {
      // 	logoutAction(ctx);
      // }

      return Promise.reject(error);
    } catch (e) {
      logoutAction(ctx);
    }
  },
);

export function axiosErrorHandler<T>(
  error: Error | AxiosError<T>,
  isStock?: boolean,
): IAxiosError<T> | IStockError<T> {
  if (isStock) {
    return {
      error,
      type: "stock-error",
    };
  }

  if (axios.isAxiosError(error)) {
    return {
      error,
      type: "axios-error",
    };
  } else {
    return {
      error,
      type: "stock-error",
    };
  }
}
