import {
  reatomAsync,
  reatomResource,
  withAbort,
  withCache,
  withDataAtom,
  withErrorAtom,
  withStatusesAtom,
} from "@reatom/async";
import { action, atom } from "@reatom/core";
import { v4 as uuidv4 } from "uuid";
import { callErrorAction } from "@/entities/notification";
import {
  getInspirationResource,
  getMoodboardTagsResource,
  TMoodboardImage, UpdateMoodboardTagsParams, updateMoodboardTagsResource,
} from "@/shared/api/moodboard";
import { getImgSize } from "shared/methods";

export const isDroppableAtom = atom(false, "isDroppable");

export const limitAtom = atom(20, "limit");
export const queryTagsAtom = atom<string[]>([], "queryTags");
export const isShowMoreActiveAtom = atom(false, "isShowMoreActive");

export const getInspirationImagesAction = reatomResource(async (ctx) => {
  const limit = ctx.spy(limitAtom);
  const queryTags = ctx.spy(queryTagsAtom);

  if (!queryTags.length) {
    return [];
  }

  const { data } = await getInspirationResource(queryTags, limit, ctx.controller);

  const imagesPromises: Promise<TMoodboardImage>[] = data.images.map(async (image) => {
    const { width, height } = await getImgSize(image.compressed_image_url);

    const updatedImage: TMoodboardImage = {
      coordinates: { x: 0, y: 0, w: width, h: height, minW: width, minH: height },
      id: uuidv4(),
      archived: false,
      url: image.compressed_image_url,
    };

    return updatedImage;
  });

  const images = await Promise.all(imagesPromises);

  return images;
}, "getInspirationAction").pipe(
  withDataAtom([], (_ctx, images) => images),
  withCache({
    swr: true,
  }),
  withStatusesAtom(),
  withErrorAtom(callErrorAction),
);

export const removeFromInspirationBoardAction = action((ctx, id: string) => {
  getInspirationImagesAction.dataAtom(
    ctx,
    ctx.get(getInspirationImagesAction.dataAtom).filter((item) => item.id !== id),
  );
});

export const getInspirationTagsAction = reatomAsync(
  async (ctx, projectKey: string) => {
    const {
      data: { tags },
    } = await getMoodboardTagsResource(projectKey, ctx.controller);

    return {
      tags,
    };
  },
  {
    onFulfill: (ctx, { tags }) => {
      queryTagsAtom(ctx, tags ?? []);
    },
  },
).pipe(
  withCache({
    swr: false,
  }),
  withDataAtom([], (_ctx, { tags }) => {
    return tags ?? [];
  }),
  withErrorAtom(callErrorAction),
  withAbort(),
  withStatusesAtom(),
);

export const updateMoodboardTagsAction = reatomAsync((ctx, params: UpdateMoodboardTagsParams) => updateMoodboardTagsResource(params, ctx.controller)).pipe(
  withAbort()
)
