import { ConfigProvider, Flex, Skeleton } from "antd";

import "./ShotSkeleton.scss";

export const ShotSkeleton = () => (
  <Flex className="shot-skeleton">
    <ConfigProvider
      theme={{
        components: {
          Skeleton: {
            paragraphMarginTop: 10,
          },
        },
      }}
    >
      <Skeleton
        active
        title={{ width: "50%" }}
        style={{ width: "100%" }}
        paragraph={{ rows: 2, width: "100%" }}
      />
    </ConfigProvider>
  </Flex>
);
