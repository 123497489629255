import { Button, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { GRAYSCALE_TOKEN } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { SelectionPosition } from "../../lib";
import { PlusIcon } from "../icons";

import "./SplitShotButton.scss";

interface Props {
  onClick: () => void;
  position: SelectionPosition | null;
}

const BUTTON_WIDTH = 115;
const EXTRA_TOP = 35;

export function getPosition(e: MouseEvent, target: HTMLElement) {
  const rect = target.getBoundingClientRect();

  const relativeX = e.clientX - rect.left;
  const relativeY = e.clientY - rect.top;

  return { x: relativeX, y: relativeY };
}

export const SplitShotButton: FC<Props> = ({ onClick, position }) => {
  if (!position) return;

  const { startX, startY, endX, endY } = position;

  if (startX && startY && endX && endY) {
    const middleX = Math.round(Math.abs(startX + endX) / 2 - BUTTON_WIDTH / 2);
    const top = (startY < endY ? startY : endY) - EXTRA_TOP;
    // const top = startY - EXTRA_TOP

    return (
      <Button
        className="split-shot-button"
        size="small"
        onClick={onClick}
        icon={<PlusIcon />}
        style={{ position: "absolute", top, left: middleX, zIndex: 1 }}
      >
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.Subtitle10, GRAYSCALE_TOKEN.TEXT_ICON.TITLE.color)}
        >
          Split the shot
        </Typography.Text>
      </Button>
    );
  }

  return null;
};
