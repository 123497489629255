import { useAtom } from "@reatom/npm-react";
import { Avatar, Flex, Space, Table, TableProps, Tag, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { toCharacter } from "@/shared/methods/to-character.ts";
import {COLOR_CLASSNAMES, COLOR_NAMES} from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import bottom from "assets/storyboard/bottom.svg";
import emptyShot from "assets/storyboard/empty-list-shot.svg";
import { Spinner } from "shared/ui";
import {
  generateImageAtom,
  TStoryboardShot,
  EditButton,
  useShotStoryboard,
  regenerateImageAtom,
} from "@/entities/storyboard";
import refresh from "assets/storyboard/refresh.svg";
import {useProjectType} from "@/entities/projects";
import {UpdateShotInfo} from "@/shared/api/storyboard";

import "./StoryboardList.scss";

interface IStoryboardList {
  shots: TStoryboardShot[];
  sceneId: string;
}

type DataType = Pick<
  TStoryboardShot,
  | "image"
  | "time"
  | "cameraMovement"
  | "location"
  | "description"
  | "props"
  | "shotSettings"
  | "characters"
  | "id"
  | "idx"
>;

const useBottom = (id: string) => {
  const onScroll = () => {
    const root = document.querySelector("#root");
    const el = root?.querySelector(`#${id}`);

    if (el) {
      el.scrollTo({ left: 0, top: el.scrollHeight, behavior: "smooth" });
    }
  };

  return {
    onScroll,
  };
};

export const StoryboardList: FC<IStoryboardList> = ({ shots, sceneId }) => {
  const { onRedirect, onCharacterRedirect, onRegenerate } = useShotStoryboard();
  const [generateImage] = useAtom(generateImageAtom);
  const [regenerateImage] = useAtom(regenerateImageAtom);
  const { isShare } = useProjectType();

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Shot",
      dataIndex: "idx",
      key: "idx",
      render: (data: TStoryboardShot["idx"]) => <Typography.Text>{data + 1}</Typography.Text>,
      width: 60,
    },
    {
      title: () => "Image",
      width: 100,
      className: "storyboard-list__image",
      render: (data: TStoryboardShot) => {
        const id = data.id;
        const image = data.image;
        const pending = regenerateImage[id]?.pending ?? generateImage[id]?.pending;
        const error = regenerateImage[id]?.error ?? generateImage[id]?.error;
        const imageGenerated = regenerateImage[id]?.image ?? generateImage[id]?.image;
        const currentImage = imageGenerated ?? image;

        const shotsOrder = shots.map((el) => el.id);

        const updateShotInfo: UpdateShotInfo = {
          title: data.title,
          shot_description: data.shot_description,
          location: data.location,
          dialog: data.dialogue,
          time: data.time,
          camera_movement: data.cameraMovement,
          camera_angle: data.cameraAngle,
          props: data.props,
        };


        return (
          <Flex align="center" justify="center" className="full-height full-width">
            {pending && (
              <Flex align="center" justify="center" className="scene-shot-grid__overlay">
                <Spinner />
              </Flex>
            )}
            {error ? (
              <Flex
                role="button"
                className={cn({
                  pointer: !isShare,
                  "non-pointer-events": isShare,
                })}
                style={{
                  background: "#5c5f6699",
                  borderRadius: 12,
                  height: 24,
                  padding: "0 8px"
                }}
                onClick={() => onRegenerate({
                  shotId: id,
                  sceneId,
                  updateShotsOrder: shotsOrder,
                  updateShotInfo,
                })}
                align="center"
                justify="center"
                gap={4}
              >
                <img style={{ width: 16, height: 16 }} src={refresh} alt="refresh" />
                <Typography.Text className={cn(TEXT_CLASSNAMES.Body10, COLOR_CLASSNAMES.TextWhitePrimary)}>
                  Generate
                </Typography.Text>
              </Flex>
            ) : (
              <>
                {currentImage ? (
                  <img className="image-contain" src={currentImage} alt="shot" />
                ) : (
                  <img className="image-contain" src={emptyShot} alt="shot" />
                )}
              </>
            )}
          </Flex>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width: 80,
      render: (data: TStoryboardShot["time"]) => (
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{data} sec</Typography.Text>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 165,
      render: (data: TStoryboardShot["location"]) => (
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{data}</Typography.Text>
      ),
    },
    {
      title: "Description",
      dataIndex: "shot_description",
      key: "shot_description",
      width: 340,
      className: "storyboard-list__description",
      render: (data: TStoryboardShot["shot_description"], dataType) => {
        const id = `description-${dataType.id}-${dataType.idx}`;
        const { onScroll } = useBottom(id);
        const isScroll = data.length > 235;

        return (
          <div style={{ position: "relative" }} className="storyboard-list__unscroll">
            <Flex id={id} className="storyboard-list__cell">
              <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{data}</Typography.Text>
            </Flex>
            {isScroll && (
              <Flex
                align="center"
                justify="center"
                className="storyboard-list__cell--scroll"
                role="button"
                onClick={onScroll}
              >
                <img style={{ width: 20, height: 20 }} src={bottom} alt="bottom" />
              </Flex>
            )}
          </div>
        );
      },
    },
    {
      title: "Dialogue",
      dataIndex: "dialogue",
      key: "dialogue",
      width: 300,
      className: "storyboard-list__dialogue",
      render: (data: TStoryboardShot["dialogue"]) => (
        <>
          {!data.length && <Typography.Text>-</Typography.Text>}
          {!!data.length && (
            <Flex className="storyboard-list__cell">
              <Typography.Text
                className={cn("storyboard-list__cell--dialogue", TEXT_CLASSNAMES.XsRegular)}
              >
                {data}
              </Typography.Text>
            </Flex>
          )}
        </>
      ),
    },
    {
      title: "Props",
      dataIndex: "props",
      key: "props",
      width: 180,
      render: (data: TStoryboardShot["props"]) => {
        const props = data.split(",");
        return (
          <Space direction="vertical" align="start">
            {props.map((tag, index) => (
              <Tag key={index.toString(36)}>{tag}</Tag>
            ))}
          </Space>
        );
      },
    },
    {
      title: "Shot Settings",
      dataIndex: "shotSettings",
      key: "shotSettings",
      width: 140,
      render: (data: TStoryboardShot["shotSettings"]) => (
        <Space direction="vertical" align="start">
          {data.map((tag, index) => (
            <Tag key={index.toString(36)}>{tag}</Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Camera movement",
      dataIndex: "cameraMovement",
      key: "cameraMovement",
      width: 230,
      render: (data: TStoryboardShot["cameraMovement"]) => (
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{data}</Typography.Text>
      ),
    },
    {
      title: "Characters",
      dataIndex: "characters",
      key: "characters",
      width: 100,
      className: "storyboard-list__dialogue",
      render: (data: TStoryboardShot["characters"]) => (
        <Flex vertical className="gap-xxs storyboard-list__cell">
          {Object.values(data ?? {}).map((character, index) => (
            <Avatar
              style={{
                borderColor: COLOR_NAMES.MetalGray300,
                backgroundColor: COLOR_NAMES.MetalGray50,
                minHeight: 44,
              }}
              key={index}
              size={46}
              icon={<img src={toCharacter(character, "2")} alt="character" />}
              onClick={() => onCharacterRedirect(character)}
            />
          ))}
        </Flex>
      ),
    },
    {
      title: "Actions",
      width: 70,
      render: (data: TStoryboardShot) => (
        <Flex align="center" justify="center">
          <EditButton
            isActive={!!data.image}
            onClick={() => onRedirect({ shotId: data.id, sceneId })}
          />
        </Flex>
      ),
    },
  ];

  return (
    <Flex vertical className="gap-xs">
      <Table
        rowClassName="storyboard-list"
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={shots}
        bordered
        pagination={false}
      />
    </Flex>
  );
};
