import { useSize } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { BackgroundPreLogin, LoginWrapper } from "@/entities/viewer";

const text =
  "\n" +
  "\n" +
  "\n" +
  "Our business name is Blooper AG and we are registered as a private limited company in Switzerland under Switzerland laws with company registration number: 14049961. The place of our business is Switzerland Zugerstrasse 32, 6340 Baar, Switzerland. \n" +
  "\n" +
  'By "Blooper", "us" and "our" we mean Blooper AG. \n' +
  "\n" +
  'These Terms and Conditions are a legal binding document. If you access or use our website – [www.blooper.ai](http://www.blooper.ai/) ("Blooper", "site", "service"), you agree to follow these Terms and our Acceptable Use Policy (https\\://blooper.ai/acceptable-use-policy). Please read these documents carefully to know what you can expect from us and our service.\n' +
  "\n" +
  "We may update these terms, so we encourage you to check this page from time to time. We will notify you about any changes of these terms by email or by making an announcement in the service. Date of last updates is stated at the top. You can find the latest version of these terms at https\\://blooper.ai/terms-and-conditions.\n" +
  "\n" +
  "Please note that these Terms do not apply to privacy issues. To learn more about your privacy rights, visit our Privacy Policy page https\\://blooper.ai/privacy-policy.\n" +
  "\n" +
  "We tried to make these terms easy to understand. All headings here are only for your information.\n" +
  "\n" +
  "**1. Eligibility**\n" +
  "\n" +
  "You must be of legal age according to the law of the state of your residence to use Blooper. If you're using Blooper on behalf of an organization, you confirm you’re authorized to agree to these Terms on their behalf.\n" +
  "\n" +
  "**2. Acceptance of Terms**\n" +
  "\n" +
  "By accessing or using Blooper, you agree to abide by these Terms. If you are using Blooper on behalf of an organization, you confirm that you are authorized to agree to these Terms on its behalf.\n" +
  "\n" +
  "**3. Privacy and Data Collection**\n" +
  "\n" +
  "Your privacy is important to us. Please review our Privacy Policy (https\\://blooper.ai/privacy-policy) to understand how we collect, use, and protect your information. By using Blooper, you consent to the collection, use, and processing of your data to enhance our service. We may analyze usage patterns, preferences, and feedback to improve the functionality, design, and user experience of Blooper, as well as to support product development and research.\n" +
  "\n" +
  "**4. Account Creation and Security**\n" +
  "\n" +
  "To access certain features of Blooper, you may need to create an account. You agree to provide accurate information and to keep your account details secure.\n" +
  "\n" +
  "You are responsible for all activities under your account. If you suspect unauthorized use of your account, notify us immediately. We reserve the right to suspend or terminate accounts at our discretion.\n" +
  "\n" +
  "**5. License and Permitted Use**\n" +
  "\n" +
  "Blooper grants you a non-exclusive, non-transferable, revocable license to access and use the Services for your personal or authorized professional purposes. You agree not to:\n" +
  "\n" +
  "- Use Blooper for any illegal or unauthorized purposes.\n" +
  "\n" +
  "- Interfere with or disrupt the operation of Blooper or any connected servers.\n" +
  "\n" +
  "- Reverse-engineer, decompile, or attempt to access Blooper’s source code.\n" +
  "\n" +
  "**6. User Content and Licensing**\n" +
  "\n" +
  "You may upload or create content through Blooper. By submitting content, you grant us a worldwide, royalty-free, non-exclusive license to use, distribute, and modify this content solely to provide, maintain, and improve our service.\n" +
  "\n" +
  "You confirm that you have the necessary rights to all content you upload or create through Blooper, and that it does not infringe any third-party rights.\n" +
  "\n" +
  "**7. Fees, Payments, and Refunds**\n" +
  "\n" +
  "Certain features of Blooper may require payment. By selecting a paid feature, you agree to pay the applicable fees, which are non-refundable unless otherwise specified herein.\n" +
  "\n" +
  "All payments are made via a third-party payment gateway. Any other payment method requires our express consent. All payment obligations under these Terms are non-cancelable and non-refundable.\n" +
  "\n" +
  "You shall bear responsibility for payment of all taxes associated with the purchase excluding those based solely on our income. If your place of residence is in Switzerland, then fees under these Terms must be paid together with value added tax.\n" +
  "\n" +
  "Upon a delay in the performance of a payment for three or more days, we may downgrade any fee-based features to free plans. You hereby acknowledge and agree that a downgrade will result in a decrease in certain features and functionality and potential loss of access to your content.\n" +
  "\n" +
  "**8. Modifications and Updates to Services**\n" +
  "\n" +
  "Blooper reserves the right to modify or update our service at any time to improve user experience, add functionality, or address security and compliance requirements.\n" +
  "\n" +
  "We will provide notice in cases of significant changes that impact your use of Blooper. Continued use of the service after such updates indicates acceptance of the modified Terms.\n" +
  "\n" +
  "**9. Intellectual Property Rights**\n" +
  "\n" +
  "All rights, titles, and interests in Blooper, including software, logos, trademarks, and all associated intellectual property, are owned by Blooper or our licensors. You may not copy, modify, distribute, or use Blooper’s intellectual property without express permission.\n" +
  "\n" +
  "**10. Disclaimer of Warranties**\n" +
  "\n" +
  'Blooper is a new product and we cannot give you any warranties. We do not provide you with any warranty in connection with our service. Blooper is a recently released product and it is not error-free and may contain some bugs and other defects. Service is provided "as is" and "as available" without any warranties. Blooper hereby disclaims all warranties and conditions with regard to this service, including all implied warranties and conditions or merchantability, fitness for a particular purpose, title, and non-infringement. (This section shall be effective to the maximum extent permitted by applicable law. The laws of certain countries do not allow to limit any warranties. You should check if this paragraph applies to you.)\n' +
  "\n" +
  "**11. Limitation of Liability**\n" +
  "\n" +
  "In no event shall Blooper or its affiliates be liable for indirect, incidental, special, consequential, or punitive damages, including loss of profits or data, arising from your use of the Services.\n" +
  "\n" +
  "Our maximum liability to you shall not exceed the amount you paid, if any, for accessing the Services in the three months prior to the event giving rise to the claim.\n" +
  "\n" +
  "We are not responsible for any payment failure or delay caused by any errors or inaccuracy in payment details provided by you or payment gateway failure.\n" +
  "\n" +
  "To the extent not prohibited by applicable law, we shall not be liable for any loss or damage resulting from: any changes of the service not provided by us; violation of these Terms or Privacy Policy (https\\://blooper.ai/privacy-policy) by you; any unauthorized access to your account; third party actions. \n" +
  "\n" +
  "If you use our service for commercial or business purposes, we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.\n" +
  "\n" +
  "We are not responsible for the content or privacy of third party websites or software since we do not have any control over them.\n" +
  "\n" +
  "Our total liability under these Terms, whether in contract, tort (including negligence), breach of statutory duty or otherwise, will be limited to the total charges paid by you under these Terms.\n" +
  "\n" +
  "Blooper and you are not liable for breaching of obligations due to circumstances which are beyond the control of the obligor and which, at the time the contract was entered into or the noncontractual obligation arose, the obligor could not reasonably have been expected to take into account, avoid or overcome the impediment or the consequences thereof which the obligor could not reasonably have been expected to overcome. You and we hereby agree that the following circumstances shall be deemed as a force majeure: technological attacks; damaging of the hardware hosting our service. \n" +
  "\n" +
  "**12. Indemnification**\n" +
  "\n" +
  "You agree to indemnify and hold Blooper, its affiliates, officers, and employees harmless from any claims, damages, losses, liabilities, and expenses arising from your use of the service or any violation of these Terms.\n" +
  "\n" +
  "**13. Termination**\n" +
  "\n" +
  "We reserve the right to suspend or terminate your access to Blooper if you violate these Terms or engage in any prohibited conduct. Upon termination, your rights to use Blooper will cease immediately.\n" +
  "\n" +
  "**14. Governing Law and Dispute Resolution**\n" +
  "\n" +
  "These Terms are governed by and interpreted in accordance with the laws of Zug, Switzerland. Blooper is a tool that is not designed to be oriented for consumers. You hereby acknowledge and agree that no consumer laws apply to these Terms. \n" +
  "\n" +
  "**15. Dispute Resolution**\n" +
  "\n" +
  'If any dispute arises, Blooper and you will send a written notice to the other. We will send you such a notice using your email provided during registration on the service. You will send us such notice to the email stated in the "Contacts" section of these Terms.\n' +
  "\n" +
  "Blooper and you will attempt to resolve the dispute through direct negotiations in good faith. If we fail to resolve such a dispute on our own or if no response to the notice has been received, the respective dispute shall be submitted to the court in accordance with the rules below.\n" +
  "\n" +
  "Any disputes arising from these Terms shall be resolved in the courts of Zug, Switzerland.\n" +
  "\n" +
  "**16. User Feedback and Service Improvement**\n" +
  "\n" +
  "We welcome user feedback as part of our commitment to improving Blooper. By submitting feedback or suggestions, you grant us a license to use this information to enhance our Services. Blooper may incorporate your feedback into product updates, research, and service improvements without obligation to compensate you.\n" +
  "\n" +
  "**17. General Provisions**\n" +
  "\n" +
  "**Third party links.** Blooper may contain links to third party websites, which are governed by their own terms of service and privacy policies. We are not responsible for the content or privacy of third party websites, so we encourage you to check third parties privacy and security policies before providing them with any information.\n" +
  "\n" +
  "**Severability.** If any part of these Terms is held to be illegal or unenforceable neither the validity, nor the enforceability of the remainder of these terms shall be affected. The choice of applicable law and jurisdiction is an independent agreement between you and us and does not depend on other terms of this document.\n" +
  "\n" +
  "**Waiver.** The failure of either party to enforce its rights under these Terms at any time for any period shall not be construed as a waiver of such rights.\n" +
  "\n" +
  "**18. Contact Us**\n" +
  "\n" +
  "For any questions, concerns, or support related to these Terms or Blooper’s Services, please contact us at info\\@blooper.ai.\n" +
  "\n";

export const TermsConditions: FC = () => {
  const size = useSize(() => document.querySelector(".login-wrapper"));

  return (
    <BackgroundPreLogin>
      <LoginWrapper isChildrenFullHeight title="" questionText="" actionText="" action={() => {}}>
        <Flex vertical gap={8}>
          <Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH4)}>
            Terms and Conditions - Blooper
          </Typography.Text>
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}
          >
            Last Updated: 28.10.2024
          </Typography.Text>
        </Flex>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            overflowY: "scroll",
            width: "100%",
            height: "100%",
            maxHeight: (size?.height ?? 0) - 120,
            marginTop: 24,
          }}
        >
          <ReactMarkdown children={text} />
        </div>
      </LoginWrapper>
    </BackgroundPreLogin>
  );
};
