import { Flex, Tag, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { GRAYSCALE_TOKEN } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import timeImg from "assets/storyboard/time.svg";
import { IconButtonWrapper } from "shared/ui";

type Props = {
  time?: number;
  location?: string;
  characteristics?: string[];
};

const TimeIcon: FC = () => (
  <IconButtonWrapper>
    <img style={{ width: 16, height: 16 }} src={timeImg} className="image-contain" alt="time" />
  </IconButtonWrapper>
);

export const ShotLocation: FC<Props> = ({ time, location, characteristics }) => {
  return (
    <Flex align="center" justify="space-between" className="shot-details-info__row gap-xxs">
      <Flex vertical className="gap-xxs">
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.Body10, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
        >
          Location
        </Typography.Text>
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{location}</Typography.Text>
        <Flex>
          <Tag icon={<TimeIcon />} className="flex-ctr-ctr gap-4">
            {time} sec
          </Tag>
          {characteristics?.map((tag) => (
            <Tag className="shot-details-info__tag" key={tag}>
              {tag}
            </Tag>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
