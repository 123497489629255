import { Button, Flex, Form, Input, Progress, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { GRAYSCALE_TOKEN } from "shared/const/color-names.ts";
import { REG_EXP } from "shared/const/reg-exp.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";
import googleIcon from "assets/shared/google_icon.svg";
import { validatePasswordReg } from "shared/validation";

import { useBehavior } from "../model";
import { colors, percent } from "../utils";

type FieldType = {
  username: string;
  email: string;
  password: string;
};

type Props = {
  isFromInstagram: boolean;
  onDocumentRedirect: (doc: string) => void;
};

export const RegisterForm: FC<Props> = ({ isFromInstagram, onDocumentRedirect }) => {
  const { isMobileL } = useMediaQueries();

  const { onRegister, errorMessage, onGoogleRegister, loading, form, password } = useBehavior();

  const validatedPassword = validatePasswordReg(password);
  const passwordChecks = Object.values(validatedPassword).filter((value) => value);

  const strokeColor = colors(passwordChecks);

  return (
    <Form
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onRegister}
      autoComplete="off"
      className="register-form"
      validateTrigger={["onSubmit"]}
    >
      <Flex vertical className="gap-xs">
        <Form.Item<FieldType>
          name="username"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input
            onChange={(event) => form.setFieldValue("username", event.target.value)}
            placeholder="Name"
            name="username"
            autoComplete="username"
          />
        </Form.Item>
        <Form.Item<FieldType>
          name="email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "The input is not valid E-mail!" },
          ]}
        >
          <Input
            onChange={(event) => form.setFieldValue("email", event.target.value)}
            placeholder="Email"
            name="email"
            autoComplete="email"
          />
        </Form.Item>
        <Form.Item<FieldType>
          name="password"
          rules={[
            { required: true, message: "Please input your password!" },
            { min: 8, message: "Password must be at least 8 characters" },
            {
              pattern: REG_EXP.upperCaseLetter,
              message: "Min. eight characters at least one uppercase letter",
            },
          ]}
        >
          <Input.Password
            name="password"
            onChange={(event) => form.setFieldValue("password", event.target.value)}
            placeholder="Password"
            autoComplete="password"
          />
          <Progress strokeColor={strokeColor} percent={percent(passwordChecks)} showInfo={false} />
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.Body10, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
          >
            The password must be at least 8 characters long and include at least one uppercase
            letter.
          </Typography.Text>
        </Form.Item>
      </Flex>
      <Flex vertical className="register-form__buttons">
        <Button
          htmlType="submit"
          className="full-width"
          size="large"
          loading={loading}
          type="primary"
        >
          Sign up
        </Button>
        {!isFromInstagram && (
          <Button
            htmlType="button"
            className="register-form__buttons__google"
            icon={<img src={googleIcon} alt="google icon" />}
            onClick={() => onGoogleRegister()}
            loading={loading}
            size="large"
          >
            Sign up with Google
          </Button>
        )}
        {!!errorMessage && (
          <Flex style={{ paddingTop: 4 }}>
            <Typography.Text className={TEXT_CLASSNAMES.Body20} type="danger">
              {errorMessage}
            </Typography.Text>
          </Flex>
        )}
      </Flex>
      <Typography.Text
        className={cn(TEXT_CLASSNAMES.Body20, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
      >
        By signing up I agree with{" "}
        <Typography.Link
          underline
          type="secondary"
          onClick={() => onDocumentRedirect("/terms-of-conditions")}
          className={TEXT_CLASSNAMES.Link20}
        >
          Terms of Service
        </Typography.Link>
        {!isMobileL && <br />} and{" "}
        <Typography.Link
          underline
          type="secondary"
          onClick={() => onDocumentRedirect("/privacy-policy")}
          className={TEXT_CLASSNAMES.Link20}
        >
          Privacy Policy
        </Typography.Link>{" "}
        and{" "}
        <Typography.Link
          underline
          type="secondary"
          onClick={() => onDocumentRedirect("/acceptable-use-policy")}
          className={TEXT_CLASSNAMES.Link20}
        >
          Acceptable Use Policy
        </Typography.Link>
      </Typography.Text>
    </Form>
  );
};
