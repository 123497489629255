import { useAtom } from "@reatom/npm-react";
import { useSize } from "ahooks";
import { useEffect, useRef } from "react";

import { useSearchParams } from "react-router-dom";
import { MOODBOARD_TYPE_PARAM_NAME, MOODBORD_TYPE } from "shared/const/routes";
import { centerMoodboardPositionAtom, moodBoardStatusAtom } from "@/entities/moodboard";
import { EmptyMoodboard } from "../../../features/empty-moodboard";
import { KonvaMoodboard } from "../../../features/full-moodboard";
import { StructureMoodboard } from "./structure-moodboard";

export const MoodboardVisible = () => {
  const [params] = useSearchParams();
  const ref = useRef(null);
  const size = useSize(ref);

  const moodboardType = params.get(MOODBOARD_TYPE_PARAM_NAME) ?? MOODBORD_TYPE.structure;

  const [moodBoardStatus] = useAtom(moodBoardStatusAtom);

  const [_, setCenterMoodboardPosition] = useAtom(centerMoodboardPositionAtom);

  const isMoodBoardEmpty = moodBoardStatus === "empty";

  const isUnsplash = moodboardType === "structure" && !isMoodBoardEmpty;
  const isKonva = moodboardType === "chaos" && !isMoodBoardEmpty;

  useEffect(() => {
    if (!size) return;
    const { width, height } = size;
    const position = {
      centerCoordinates: [Math.floor(width / 2), Math.floor(height / 2)] as [number, number],
      width,
      height,
    };

    setCenterMoodboardPosition(position);
  }, [size]);

  return (
    <div ref={ref} style={{ overflow: "auto", height: "100%" }}>
      {isMoodBoardEmpty && <EmptyMoodboard />}
      {isKonva && <KonvaMoodboard />}
      {isUnsplash && <StructureMoodboard />}
    </div>
  );
};
