import { TTabView } from "../types/common.ts";
import { MoodboardType } from "../types/routes.ts";

const MAIN = {
  fullPath: "/",
  routePath: "/",
};

const BLOOPER_EXAMPLE = {
  fullPath: "/blooper-example",
  routePath: "blooper-example",
};

const USE_CASE_VIDEO = {
  fullPath: "/use-case-video",
  routePath: "use-case-video",
};

const USE_CASE_STORYBOARD = {
  fullPath: "/use-case-storyboard",
  routePath: "use-case-storyboard",
};

const USE_CASE_SCRIPT = {
  fullPath: "/use-case-script",
  routePath: "use-case-script",
};

const CONTACTS = {
  fullPath: "/contacts",
  routePath: "contacts",
};

const TEAM = {
  fullPath: "/team",
  routePath: "team",
};

const PRICING = {
  fullPath: "/pricing",
  routePath: "pricing",
};

const NEWS_ID = {
  fullPath: "/news/:id" as const,
  routePath: ":id",
};

const STORYBOARD_SIDEBAR = {
  fullPath: "/:id" as const,
  routePath: "/:id",
};

const SCRIPT = {
  fullPath: "/:id/script" as const,
  routePath: "script",
};

const SCRIPT_SHOT_OUTLINE = {
  fullPath: "/:id/script/shot-outline" as const,
  routePath: "shot-outline",
};

const SCRIPT_COMMENTS = {
  fullPath: "/:id/script/comments" as const,
  routePath: "comments",
};

export const MOODBOARD = {
  fullPath: "/:id/moodboard" as const,
  routePath: "moodboard",
};

export const MOODBORD_INSPIRATION_BOARD = {
  fullPath: "/:id/moodboard/inspiration-board" as const,
  routePath: "inspiration-board",
};

export const MOODBORD_SEARCH_BOARD = {
  fullPath: "/:id/moodboard/search-board" as const,
  routePath: "search-board",
};

export const MOODBORD_ARCHIVE_BOARD = {
  fullPath: "/:id/moodboard/archive-board" as const,
  routePath: "archive-board",
};

export const MOODBORD_COMMENTS = {
  fullPath: "/:id/moodboard/comments" as const,
  routePath: "comments",
};

const CHARACTER = {
  fullPath: "/:id/character" as const,
  routePath: "character",
};

const CHARACTER_COMMENTS = {
  fullPath: "/:id/character/comments" as const,
  routePath: "comments",
};

const CHARACTER_INFO = {
  fullPath: "/:id/:characterId/character-info" as const,
  routePath: ":characterId/character-info",
};

const CHARACTER_INFO_SETTINGS = {
  fullPath: "/:id/:characterId/character-info/settings" as const,
  routePath: "settings",
};

const CHARACTER_INFO_COMMENTS = {
  fullPath: "/:id/:characterId/character-info/comments" as const,
  routePath: "comments",
};

const STORYBOARD = {
  fullPath: "/:id/storyboard" as const,
  routePath: "storyboard",
};

const SHOT_EDIT = {
  fullPath: "/:id/:shotId/:sceneId/shot-edit" as const,
  routePath: ":shotId/:sceneId/shot-edit",
};

const SHOT_EDIT_DETAILS = {
  fullPath: "/:id/:shotId/:sceneId/shot-edit/details" as const,
  routePath: "details",
};

const SHOT_EDIT_EDIT = {
  fullPath: "/:id/:shotId/:sceneId/shot-edit/edit" as const,
  routePath: "edit",
};

const SHOT_EDIT_VERSIONS = {
  fullPath: "/:id/:shotId/:sceneId/shot-edit/versions" as const,
  routePath: "versions",
};

const SHOT_EDIT_COMMENTS = {
  fullPath: "/:id/:shotId/:sceneId/shot-edit/comments" as const,
  routePath: "comments",
};

const SUCCESS_EMAIL = {
  fullPath: "/success-email/:email" as const,
  routePath: "/success-email/:email",
};

const PROMO_CODE = {
  fullPath: "/promo-code/:termType" as const,
  routePath: "/promo-code/:termType",
};

const ENTER_THE_CODE = {
  fullPath: "/enter-the-code/:email" as const,
  routePath: "/enter-the-code/:email",
};

const CREATE_NEW_PASSWORD = {
  fullPath: "/create-new-password/:email",
  routePath: "/create-new-password/:email",
};

export const PROJECTS = {
  fullPath: "/projects",
  routePath: "projects",
};

export const TEAM_MANAGE = {
  fullPath: "/team-manage",
  routePath: "team-manage",
};

export const SETTINGS = {
  fullPath: "/settings",
  routePath: "settings",
};

export const SETTINGS_PROFILE = {
  fullPath: "/settings/profile",
  routePath: "profile",
};

export const SETTINGS_ACCOUNT = {
  fullPath: "/settings/account",
  routePath: "account",
};

export const SETTINGS_MEMBERS = {
  fullPath: "/settings/members",
  routePath: "members",
};

export const SETTINGS_PREFERENCES = {
  fullPath: "/settings/preferences",
  routePath: "preferences",
};

export const SETTINGS_BILLING = {
  fullPath: "/settings/billing",
  routePath: "billing",
};

export const ROUTES = {
  PROJECTS,
  TEAM_MANAGE,
  SETTINGS,
  SETTINGS_ACCOUNT,
  SETTINGS_PROFILE,
  SETTINGS_MEMBERS,
  SETTINGS_PREFERENCES,
  SETTINGS_BILLING,
  MAIN,
  BLOOPER_EXAMPLE,
  USE_CASE_VIDEO,
  USE_CASE_SCRIPT,
  USE_CASE_STORYBOARD,
  CONTACTS,
  TEAM,
  PRICING,
  NEWS_ID,
  STORYBOARD_SIDEBAR,
  SCRIPT,
  SCRIPT_SHOT_OUTLINE,
  SCRIPT_COMMENTS,
  MOODBOARD,
  MOODBORD_INSPIRATION_BOARD,
  MOODBORD_SEARCH_BOARD,
  MOODBORD_ARCHIVE_BOARD,
  MOODBORD_COMMENTS,
  CHARACTER,
  CHARACTER_COMMENTS,
  CHARACTER_INFO,
  CHARACTER_INFO_SETTINGS,
  CHARACTER_INFO_COMMENTS,
  STORYBOARD,
  SHOT_EDIT,
  SHOT_EDIT_DETAILS,
  SHOT_EDIT_EDIT,
  SHOT_EDIT_VERSIONS,
  SHOT_EDIT_COMMENTS,
  SUCCESS_EMAIL,
  PROMO_CODE,
  ENTER_THE_CODE,
  CREATE_NEW_PASSWORD,
};

export const MOODBORD_TYPE: Record<MoodboardType, MoodboardType> = {
  structure: "structure",
  chaos: "chaos",
};

export const STORYBOARD_TYPE: Record<TTabView, TTabView> = {
  list: "list",
  grid: "grid",
};

export const STORYBOARD_TYPE_PARAM = "view";

export const MOODBOARD_TYPE_PARAM_NAME = "grid";
