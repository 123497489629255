import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { COLOR_CLASSNAMES } from "../../const/color-names.ts";
import { TEXT_CLASSNAMES } from "../../const/text-classnames.ts";
import { LandingContent } from "../../ui";

import "./PageHeader.scss";

type Props = {
  headerText: string;
  italicHeaderText: string;
  description?: string;
};

export const PageHeader: FC<Props> = ({ italicHeaderText, headerText, description }) => {
  return (
    <Flex justify="center" className="page-header full-width">
      <LandingContent>
        <Flex
          vertical
          justify="center"
          align="center"
          className="page-header__content gap-s text-center"
        >
          <Typography.Text
            className={`${COLOR_CLASSNAMES.TextPrimary} text-center page-header--title`}
          >
            {headerText} <span className="page-header--italic">{italicHeaderText}</span>
          </Typography.Text>
          {!!description && (
            <Typography.Text
              className={cn(COLOR_CLASSNAMES.TextPrimary, TEXT_CLASSNAMES.MdRegular)}
            >
              {description}
            </Typography.Text>
          )}
        </Flex>
      </LandingContent>
    </Flex>
  );
};
