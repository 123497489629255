import { reatomAsync, withAbort, withCache, withDataAtom } from "@reatom/async";
import { action, atom, AtomMut } from "@reatom/core";
import { createMemStorage, reatomPersist } from "@reatom/persist";
import { withLocalStorage } from "@reatom/persist-web-storage";
import { captureException, setUser } from "@sentry/react";
import { AxiosError } from "axios";
import { callErrorAction } from "@/entities/notification";
import {
  AUTH_SUBSCRIPTION_USER_VIEWER,
  AUTH_UPGRADE_SUBSCRIPTION_USER_VIEWER,
  AUTH_USER_VIEWER,
  CREDENTIAL_KEY,
  GuestViewer,
  NOT_AUTH_USER_VIEWER,
  NOT_AUTH_VIEWER,
  USER_PERMISSION_KEY,
  USER_SUBSCRIPTION_KEY,
  Viewer,
} from "@/entities/viewer";
import {
  deleteUserResource,
  getUserPermissionResource,
  logoutResource,
  Permission,
  resendVerifyResource,
} from "@/shared/api/auth";
import { getCustomerSubscriptionResource } from "@/shared/api/payment";

const viewerPermissionModelStorage = createMemStorage({ name: USER_PERMISSION_KEY });
const withViewerPermissionPersist = reatomPersist(viewerPermissionModelStorage);

const viewerSubscriptionModelStorage = createMemStorage({ name: USER_SUBSCRIPTION_KEY });
const withViewerSubscriptionPersist = reatomPersist(viewerSubscriptionModelStorage);

const viewerPermissionAtom = () => {
  const viewerPermission = reatomAsync(
    (ctx) => getUserPermissionResource(ctx.controller),
    "viewerPermission",
  ).pipe(
    withCache({
      swr: {
        shouldFulfill: false,
      },
      withPersist: () => withViewerPermissionPersist(USER_PERMISSION_KEY),
    }),
    withDataAtom({} as Permission, (_, res) => res?.data ?? {}),
  );

  const viewerSubscription = reatomAsync(
    (ctx) => getCustomerSubscriptionResource(ctx.controller),
    "viewerSubscription",
  ).pipe(
    withCache({
      swr: true,
      withPersist: () => withViewerSubscriptionPersist(USER_SUBSCRIPTION_KEY),
    }),
    withDataAtom(null, (_, res) => res?.data ?? null),
  );

  return {
    viewerPermission,
    viewerSubscription,
  };
};

export const viewerModel = viewerPermissionAtom();

export const viewerAtom = atom<Viewer>(NOT_AUTH_VIEWER, "viewerAtom").pipe(
  withLocalStorage(CREDENTIAL_KEY),
) as AtomMut<Viewer>;

export const isGuestAtom = atom<boolean>((ctx) => {
  const viewer = ctx.spy(viewerAtom);
  return viewer.isGuest;
});

export const getTrialEndAtom = atom((ctx) => {
  const subscription = ctx.spy(viewerModel.viewerSubscription.dataAtom);

  if (subscription?.trial_status === "on_trial") {
    const a = new Date();
    const b = new Date((subscription?.trial_end ?? 0) * 1000);
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    const days = Math.floor((utc2 - utc1) / _MS_PER_DAY);
    const isEnd = days < 0;

    return {
      trialStatus: subscription?.trial_status,
      days,
      isEnd,
      message: subscription?.message ?? "",
    };
  }

  return {
    trialStatus: subscription?.trial_status,
    days: -1,
    isEnd: true,
    message: subscription?.message ?? "",
  };
});

export const sessionAuthAction = action((ctx, { isGuest, email }: GuestViewer) => {
  const prevViewer = ctx.get(viewerAtom);

  if (email) {
    viewerAtom(ctx, { ...prevViewer, ...AUTH_USER_VIEWER, isGuest, email });
  } else {
    viewerAtom(ctx, { ...prevViewer, ...AUTH_USER_VIEWER, isGuest });
  }
}, "session login");

export const sessionNotAuthAction = action((ctx, { isGuest, email }: GuestViewer) => {
  const prevViewer = ctx.get(viewerAtom);

  if (email) {
    viewerAtom(ctx, { ...prevViewer, ...NOT_AUTH_USER_VIEWER, isGuest, email });
  } else {
    viewerAtom(ctx, { ...prevViewer, ...NOT_AUTH_USER_VIEWER, isGuest });
  }
});

export const sessionAuthSubscriptionAction = action((ctx, { isGuest, email }: GuestViewer) => {
  const prevViewer = ctx.get(viewerAtom);

  if (email) {
    viewerAtom(ctx, { ...prevViewer, ...AUTH_SUBSCRIPTION_USER_VIEWER, isGuest, email });
  } else {
    viewerAtom(ctx, { ...prevViewer, ...AUTH_SUBSCRIPTION_USER_VIEWER, isGuest });
  }
});

export const sessionAuthUpgradeSubscriptionAction = action(
  (ctx, { isGuest, email }: GuestViewer) => {
    const prevViewer = ctx.get(viewerAtom);

    if (email) {
      viewerAtom(ctx, { ...prevViewer, ...AUTH_UPGRADE_SUBSCRIPTION_USER_VIEWER, isGuest, email });
    } else {
      viewerAtom(ctx, { ...prevViewer, ...AUTH_UPGRADE_SUBSCRIPTION_USER_VIEWER, isGuest });
    }
  },
);

export const logoutAction = action(async (ctx) => {
  try {
    await logoutResource();
  } catch (e) {
    const viewer = ctx.get(viewerAtom);

    setUser({
      email: viewer.email,
    });

    captureException(e, {
      tags: {
        feature: "logout",
      },
      level: "warning",
    });
  } finally {
    viewerModel.viewerSubscription.cacheAtom.reset(ctx);
    viewerModel.viewerPermission.cacheAtom.reset(ctx);
    localStorage.removeItem(CREDENTIAL_KEY);
    localStorage.removeItem(USER_SUBSCRIPTION_KEY);
    localStorage.removeItem(USER_PERMISSION_KEY);
    window.location.href = "/login";
  }
});

export const deleteAccountAction = action(async (ctx) => {
  try {
    await deleteUserResource();
    logoutAction(ctx);
  } catch (e) {
    callErrorAction(ctx, e as AxiosError);
  }
});

export const resendLinkAction = reatomAsync(
  (ctx, email: string) => resendVerifyResource(email, ctx.controller),
  {
    onReject: (_, err) => {
      captureException(err, {
        tags: {
          feature: "resend-link",
        },
      });
    },
  },
).pipe(withAbort());
