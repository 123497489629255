import { reatomAsync, withAbort } from "@reatom/async";
import { atom } from "@reatom/core";
import { UploadFile } from "antd";
import {
  changePasswordResource,
  TChangePasswordDTO,
  TSettingsDTO,
  updateSettingsProfileResource,
} from "@/shared/api/settings";
import { accountWithAvatarModel } from "@/entities/account";
import { callErrorAction, callSuccessNotificationAction } from "@/entities/notification";

export const imageListAvatarAtom = atom<UploadFile[]>([], "imageList");

accountWithAvatarModel.resource.dataAtom.onChange((ctx, response) => {
  if (response.avatar) {
    imageListAvatarAtom(ctx, [
      {
        uid: "-1",
        name: "avatar.png",
        status: "done",
        url: response.avatar,
      },
    ]);
  }
});

export const updateProfileSeeingsAction = reatomAsync(
  (ctx, data: TSettingsDTO) => updateSettingsProfileResource(data, ctx.controller),
  {
    onFulfill: (ctx) => {
      callSuccessNotificationAction(ctx, "Profile updated successfully");
      accountWithAvatarModel.resource.reset(ctx);
      accountWithAvatarModel.resource.retry(ctx);
    },
    onReject: (ctx, err) => {
      callErrorAction(ctx, err);
    },
  },
).pipe(withAbort());

export const updateProfilePasswordAction = reatomAsync(
  (ctx, data: TChangePasswordDTO) => changePasswordResource(data, ctx.controller),
  {
    onFulfill: (ctx) => {
      callSuccessNotificationAction(ctx, "Password updated successfully");
    },
    onReject: (ctx, err) => {
      callErrorAction(ctx, err);
    },
  },
).pipe(withAbort());
