import { Button, Flex } from "antd";
import { FC } from "react";
import { useBehaviour } from "../model";
import { BackIcon, DownloadIcon, UploadIcon } from "./icons";

export const ShotEditSettings: FC = () => {
  const { onDownload, handleUpload, handleBack } = useBehaviour();

  return (
    <Flex className="full-width gap-xxs">
      <Button onClick={handleBack} size="small" icon={<BackIcon />}>
        Back to storyboard
      </Button>
      <Button onClick={onDownload} size="small" icon={<DownloadIcon />}>
        Download
      </Button>
      <Button style={{ position: "relative" }} size="small" icon={<UploadIcon />}>
        <input
          onChange={handleUpload}
          accept="image/*"
          type="file"
          multiple={false}
          style={{
            position: "absolute",
            opacity: 0,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
        Upload image
      </Button>
    </Flex>
  );
};
