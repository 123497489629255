import { useEffect, useState } from "react";
import { TStoryboardRoutes } from "@/entities/storyboard";
import { usePermission } from "@/shared/hooks/use-permission.ts";
import { useOpenModal } from "shared/hooks";

type Params = {
  projectKey: string;
};

export const useBehaviour = ({ projectKey }: Params) => {
  const [activeStep, setActiveStep] = useState<TStoryboardRoutes>("script");
  const [shareOpen, toggleShare] = useOpenModal();

  const { can: canViewScript } = usePermission({ page: "script", group: "view", projectKey });
  const { can: canViewStoryboards } = usePermission({
    page: "storyboards",
    group: "view",
    projectKey,
  });

  const { can: canViewMoodboard } = usePermission({
    page: "moodboard",
    group: "view",
    projectKey,
  });

  const { can: canViewCharacters } = usePermission({
    page: "characters",
    group: "view",
    projectKey,
  });

  const { can: canCommentScript } = usePermission({ page: "script", group: "comment", projectKey });
  const { can: canCommentStoryboards } = usePermission({
    page: "storyboards",
    group: "comment",
    projectKey,
  });

  const { can: canCommentMoodboard } = usePermission({
    page: "moodboard",
    group: "comment",
    projectKey,
  });

  const { can: canCommentCharacters } = usePermission({
    page: "characters",
    group: "comment",
    projectKey,
  });

  const onShare = () => {
    toggleShare(!shareOpen);
  };

  useEffect(() => {
    const pathnameArray = location.pathname.split("/");
    const routeName = pathnameArray[pathnameArray.length - 1] as TStoryboardRoutes;

    setActiveStep(routeName);
  }, [location.pathname]);

  return {
    canViewScript,
    canViewStoryboards,
    canViewMoodboard,
    canViewCharacters,
    canCommentStoryboards,
    canCommentScript,
    canCommentCharacters,
    canCommentMoodboard,
    activeStep,
    shareOpen,
    onShare,
  };
};
