import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { BillingHistoryMap } from "../index";

export const BILLING_HISTORY_MAP: BillingHistoryMap = {
  paid: {
    title: "Paid",
    color: COLOR_CLASSNAMES.BrandGreen600,
  },
  processing: {
    title: "Processing",
    color: COLOR_CLASSNAMES.Orange600,
  },
  auto_scheduled: {
    title: "Auto scheduled",
    color: COLOR_CLASSNAMES.TextSecondary,
  },
};
