import { reatomAsync, withAbort, withStatusesAtom } from "@reatom/async";
import { atom } from "@reatom/core";
import axios from "axios";
import TagManager from "react-gtm-module";
import {
  createUserGoogleResource,
  GoogleToken,
  loginResource,
  loginUserGoogleResource,
  TCredentials,
} from "@/shared/api/auth";
import { setPathAction } from "@/entities/notification";
import {
  sessionAuthAction,
  sessionAuthSubscriptionAction,
  viewerModel,
} from "@/entities/viewer";
import { axiosErrorHandler } from "@/shared/api";
import { IMessageError } from "@/vite-env";

export const errorLoginMessageAtom = atom<null | string>(null, "errorLoginMessageAtom");
export const isUserVerifiedAtom = atom<boolean>(false, "isUserVerifiedAtom");

export const regularLoginAction = reatomAsync(
  async (ctx, params: TCredentials) => {
    const res = await loginResource(params, ctx.controller);
    return {
      ...res.data,
      ...params,
    };
  },
  {
    onFulfill: async (ctx, credential) => {
      TagManager.dataLayer({
        dataLayer: {
          event: "login",
          loginMethod: "email",
        },
      });

      if (credential.is_guest) {
        await viewerModel.viewerPermission(ctx);
        sessionAuthAction(ctx, { isGuest: true, email: credential.email.toLowerCase() });
        setPathAction(ctx, "/projects");
        return;
      }

      if (!credential.is_guest && Number.isInteger(credential.subscription_id)) {
        await viewerModel.viewerSubscription(ctx);
        sessionAuthAction(ctx, { isGuest: false, email: credential.email.toLowerCase() });
        setPathAction(ctx, "/projects");
        return;
      }

      if (!credential.is_guest && !Number.isInteger(credential.subscription_id)) {
        sessionAuthSubscriptionAction(ctx, {
          isGuest: false,
          email: credential.email.toLowerCase(),
        });
        setPathAction(ctx, "/plan");
      }
    },
    onReject: (ctx, err) => {
      const { error, type } = axiosErrorHandler<IMessageError<string>>(err as Error);

      if (type === "axios-error") {
        if (error?.status === 403) {
          if (error?.response?.data?.message === "Incorrect password") {
            errorLoginMessageAtom(ctx, "Incorrect password");
            return;
          }

          if (error?.response?.data?.message === "Email not verified") {
            isUserVerifiedAtom(ctx, true);
            return;
          }
        }

        if (error.status === 401) {
          errorLoginMessageAtom(ctx, "Incorrect email or password");
          return;
        }
      }

      errorLoginMessageAtom(ctx, "Oops, something went wrong");
    },
  },
).pipe(withAbort(), withStatusesAtom());

export const onLoginGoogleRegisterAction = reatomAsync(
  async (ctx, token: GoogleToken) => {
    const decoded = await axios.get<{ email: string }>(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token.access_token}`,
    );
    await createUserGoogleResource(token, ctx.controller);

    return {
      email: decoded.data.email,
    };
  },
  {
    onFulfill: async (ctx, { email }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: "sign_up",
          signupMethod: "google",
          signupEmail: email,
        },
      });
      sessionAuthSubscriptionAction(ctx, { isGuest: false, email });
      setPathAction(ctx, "/plan");
    },
    onReject: (ctx, err) => {
      const { error, type } = axiosErrorHandler<IMessageError<string>>(err as Error);

      if (type === "axios-error" && error?.status === 409) {
        errorLoginMessageAtom(ctx, "Incorrect email or password");
        return;
      }

      errorLoginMessageAtom(ctx, "Oops, something went wrong");
    },
  },
).pipe(withAbort(), withStatusesAtom());

const errorGoogleToken = atom<null | GoogleToken>(null, "errorGoogleToken");
export const googleLoginAction = reatomAsync(
  async (ctx, token: GoogleToken) => {
    errorGoogleToken(ctx, token);
    const decoded = await axios.get<{ email: string }>(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token.access_token}`,
    );
    await loginUserGoogleResource(token, ctx.controller);

    return {
      email: decoded.data.email,
    };
  },
  {
    onFulfill: async (ctx, { email }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: "login",
          loginMethod: "google",
        },
      });

      await viewerModel.viewerSubscription(ctx);

      sessionAuthAction(ctx, { isGuest: false, email });
      setPathAction(ctx, "/projects");
    },
    onReject: (ctx, err) => {
      const token = ctx.get(errorGoogleToken);
      const { error, type } = axiosErrorHandler<IMessageError<string>>(err as Error);

      if (type === "axios-error") {
        if (error.status === 404 && token) {
          onLoginGoogleRegisterAction(ctx, token);
          return;
        } else if (error.status === 409) {
          errorLoginMessageAtom(ctx, "Incorrect email or password");
          return;
        }

        errorLoginMessageAtom(ctx, "Oops, something went wrong");
      }
    },
  },
).pipe(withAbort(), withStatusesAtom());
