import { useGoogleLogin } from "@react-oauth/google";
import { useAction } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { linkProfileGoogleResource } from "@/shared/api/auth";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import google from "assets/settings/profile/google.svg";
import { useAccountWithAvatar } from "@/entities/account";
import { callErrorAction } from "@/entities/notification";
import { SettingsWrapper } from "@/entities/settings";

export const AccountSettingsForm: FC = () => {
  const errorAction = useAction(callErrorAction);
  const { account } = useAccountWithAvatar();
  const connectGoogleAccount = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        await linkProfileGoogleResource(tokenResponse.access_token);
      } catch (e) {
        errorAction(e);
      }
    },
    onError: (e) => {
      errorAction(e);
    },
  });

  return (
    <SettingsWrapper
      title="Account Settings"
      description="Update your personal details here"
      className="gap-s full-width"
    >
      <Flex vertical className="gap-s">
        <Flex justify="space-between" align="center">
          <Flex vertical className="gap-xxs">
            <Flex className="gap-xxs" align="center">
              <img src={google} alt="google" />
              <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>
                Link Google account
              </Typography.Text>
            </Flex>
            <Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>
              Update your personal details here.
            </Typography.Text>
          </Flex>
          {account?.is_google_linked ? (
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.BrandGreen500)}
            >
              Connected
            </Typography.Text>
          ) : (
            <Button size="large" onClick={() => connectGoogleAccount()}>
              Connect Google account
            </Button>
          )}
        </Flex>
      </Flex>
    </SettingsWrapper>
  );
};
