import { reatomAsync, withAbort, withStatusesAtom } from "@reatom/async";
import {action, atom, AtomMut} from "@reatom/core";

import { AxiosError } from "axios";
import { Descendant, Selection } from "slate";
import { v4 as uuidv4 } from "uuid";

import { callErrorAction } from "@/entities/notification";
import { ProjectLocation } from "@/shared/api/project";
import {
  createSceneResource,
  createShotResource,
  deleteSceneResource,
  deleteShotResource,
  getScriptResource,
  SceneInfo,
  ShotColor,
  ShotInfoParams,
  updateSceneResource,
  updateShotResource,
  UploadScriptParams,
  uploadScriptResource,
} from "@/shared/api/script";
import { insert } from "shared/methods";
import {
  TShotDTO,
  TScene,
  TShot,
  TUpdateShotDTO,
  TUpdateShotColorDTO,
  SceneHistory,
  HistoryRequest,
  sortScenes,
  insertShots,
  randomColor,
  split,
  HistoryData,
  SceneHistoryRequest,
  SceneTitleDTO,
  getScriptLength,
  TDeleteSceneDTO,
  createShotInfo,
  createShot,
  getFilteredList,
  getNewSceneList,
} from "../lib";
import { withSessionStorage } from "@reatom/persist-web-storage";

export const shotActiveHash = atom("", "shotActiveHash");
export const sceneListAtom = atom<TScene[]>([], "scenesAtom");

export const styleSettingsOutlineAtom = atom<Record<string, boolean>>(
  {},
  "styleSettingsAtom",
).pipe(withSessionStorage("outlined")) as AtomMut<Record<string, boolean>>;

export const activeSceneAtom = atom("", "activeSceneAtom");

sceneListAtom.onChange((ctx, newState) => {
  const length = getScriptLength(newState);

  scriptSignsLengthAtom(ctx, length);
});

export const scriptSignsLengthAtom = atom<number>(0, "scriptSignsLengthAtom");

export const historySceneListAtom = atom<SceneHistory>(
  {
    past: [],
    future: [],
  },
  "historySceneListAtom",
);

export const clearHistoryAction = action((ctx) => {
  historySceneListAtom(ctx, {
    past: [],
    future: [],
  });
});

export const updateHistoryValue = action(
  (ctx, val: TScene[], type: HistoryRequest, data: HistoryData) => {
    const sceneListHistory = ctx.get(historySceneListAtom);
    const currentSceneList = ctx.get(sceneListAtom);

    const currentListHistory: SceneHistoryRequest = {
      history: currentSceneList,
      request: type,
      data,
    };

    const _past = [...sceneListHistory.past, currentListHistory];
    const maxLengthNum = 10;
    // maximum number of records exceeded
    if (maxLengthNum > 0 && _past.length > maxLengthNum) {
      // delete first
      _past.splice(0, 1);
    }

    historySceneListAtom(ctx, {
      past: _past,
      future: [],
    });

    sceneListAtom(ctx, val);
  },
);

const forward = action(async (ctx, step: number = 1, projectId: string) => {
  try {
    const sceneListHistory = ctx.get(historySceneListAtom);
    const currentSceneList = ctx.get(sceneListAtom);

    if (sceneListHistory.future.length === 0) {
      return;
    }

    const { _before, _current, _after, _type } = split(step, sceneListHistory.future);

    const currentListHistory: SceneHistoryRequest = {
      history: currentSceneList,
      request: _current.request,
      data: _current.data,
    };

    historySceneListAtom(ctx, {
      past: [...sceneListHistory.past, currentListHistory, ..._before],
      future: _after,
    });
    sceneListAtom(ctx, _current.history);

    await historyRequests(ctx, _current, _type, projectId);
  } catch (err) {
    callErrorAction(ctx, err as AxiosError);
  }
});

const backward = action(async (ctx, step: number = -1, projectId: string) => {
  try {
    const sceneListHistory = ctx.get(historySceneListAtom);
    const currentSceneList = ctx.get(sceneListAtom);

    if (sceneListHistory.past.length === 0) {
      return;
    }

    const { _before, _current, _after, _type } = split(step, sceneListHistory.past);

    const currentListHistory: SceneHistoryRequest = {
      history: currentSceneList,
      request: _current.request,
      data: _current.data,
    };

    if (_current.history.length) {
      historySceneListAtom(ctx, {
        past: _before,
        future: [..._after, currentListHistory, ...sceneListHistory.future],
      });

      sceneListAtom(ctx, _current.history);
    }

    await historyRequests(ctx, _current, _type, projectId);
  } catch (err) {
    callErrorAction(ctx, err as AxiosError);
  }
});

const historyRequests = action(
  async (ctx, _current: SceneHistoryRequest, _type: "undo" | "redo", projectKey: string) => {
    try {
      const { shotIds, sceneId } = _current.data;
      const currentScene = _current.history.find((scene) => scene.id === sceneId);

      const shotsMap: Record<string, ShotInfoParams> = {};

      shotIds.forEach((id) => {
        const shot = currentScene?.shots.find((shot) => shot.id === id);

        if (shot) {
          shotsMap[id] = {
            title: shot.title,
            prompt: shot.prompt,
            description: shot.description,
            color: shot.color,
          };
        }
      });

      const sceneInfo: SceneInfo = {
        scene_id: sceneId,
        title: currentScene?.title ?? "",
        shots_order: currentScene?.shots.map((shot) => shot.id) || [],
        scene_locations: currentScene?.locations || [],
        selected_location: currentScene?.selectedLocation ?? "",
      };

      const scenesOrder: string[] = _current.history.map((scene) => scene.id);
      const shotsOrder: string[] = currentScene?.shots.map((shot) => shot.id) || [];

      if (_current.request === "updateShotResource") {
        Promise.all(
          shotIds.map(
            async (id) =>
              await updateShotResource({
                shotId: id,
                shotInfo: shotsMap[id],
                projectKey,
                sceneId,
                shotsOrder,
              }),
          ),
        );
      }

      if (_current.request === "deleteShotResource") {
        Promise.all(
          shotIds.map(
            async (id) =>
              await createShotResource({
                shotId: id,
                shotInfo: shotsMap[id],
                projectKey,
                sceneId,
                shotsOrder,
              }),
          ),
        );
      }

      if (_current.request === "createShotResource") {
        Promise.all(
          shotIds.map(
            async (id) =>
              await deleteShotResource({
                shotId: id,
                projectKey,
                sceneId,
                shotsOrder,
              }),
          ),
        );
      }

      if (_current.request === "deleteSceneResource") {
        await createSceneResource({
          projectKey,
          scenesOrder,
          sceneInfo,
        });
        const currentShotsOrder: string[] = currentScene?.shots.map((shot) => shot.id) || [];
        const shotPromises = currentScene?.shots.map(async (shot) => {
          const currentShotInfo: ShotInfoParams = {
            title: shot.title,
            prompt: shot.prompt,
            color: shot.color,
            description: shot.description,
          };

          await createShotResource({
            projectKey,
            sceneId,
            shotsOrder: currentShotsOrder,
            shotInfo: currentShotInfo,
            shotId: shot.id,
          });
        });

        await Promise.all(shotPromises || []);
      }

      if (_current.request === "createSceneResource") {
        await deleteSceneResource({ sceneInfo, scenesOrder, projectKey });
      }

      if (_current.request === "updateSceneResource") {
        await updateSceneResource({ sceneInfo, scenesOrder, projectKey });
      }

      if (_current.request === "splitShotResourceOnTwo") {
        const currentShotsOrder: string[] = currentScene?.shots.map((shot) => shot.id) || [];

        if (_type === "undo") {
          const [deletedShotId, ...createdShotsIds] = _current.data.shotIds;
          await Promise.all(
            createdShotsIds.map(
              async (id) =>
                await deleteShotResource({
                  shotId: id,
                  projectKey,
                  sceneId,
                  shotsOrder,
                }),
            ),
          );
          await createShotResource({
            projectKey,
            sceneId,
            shotsOrder: currentShotsOrder,
            shotInfo: shotsMap[deletedShotId],
            shotId: deletedShotId,
          });
        }

        if (_type === "redo") {
          const [deletedShotId, ...createdShotsIds] = _current.data.shotIds;

          await deleteShotResource({
            shotId: deletedShotId,
            projectKey,
            sceneId,
            shotsOrder,
          });

          await Promise.all(
            createdShotsIds.map(
              async (id) =>
                await createShotResource({
                  projectKey,
                  sceneId,
                  shotsOrder: currentShotsOrder,
                  shotInfo: shotsMap[id],
                  shotId: id,
                }),
            ),
          );
        }
      }
      if (_current.request === "splitShotResourceOnThree") {
        const currentShotsOrder: string[] = currentScene?.shots.map((shot) => shot.id) || [];

        if (_type === "undo") {
          const [deletedShotId, ...createdShotsIds] = _current.data.shotIds;
          await Promise.all(
            createdShotsIds.map(
              async (id) =>
                await deleteShotResource({
                  shotId: id,
                  projectKey,
                  sceneId,
                  shotsOrder,
                }),
            ),
          );
          await createShotResource({
            projectKey,
            sceneId,
            shotsOrder: currentShotsOrder,
            shotInfo: shotsMap[deletedShotId],
            shotId: deletedShotId,
          });
        }

        if (_type === "redo") {
          const [deletedShotId, ...createdShotsIds] = _current.data.shotIds;

          await deleteShotResource({
            shotId: deletedShotId,
            projectKey,
            sceneId,
            shotsOrder,
          });

          await Promise.all(
            createdShotsIds.map(
              async (id) =>
                await createShotResource({
                  projectKey,
                  sceneId,
                  shotsOrder: currentShotsOrder,
                  shotInfo: shotsMap[id],
                  shotId: id,
                }),
            ),
          );
        }
      }
    } catch (err) {
      callErrorAction(ctx, err as AxiosError);
    }
  },
);

const go = action((ctx, step: number, projectId: string) => {
  if (step === 0) {
    return;
  }

  if (step > 0) {
    return forward(ctx, step, projectId);
  }

  backward(ctx, step, projectId);
});

export const backAction = action((ctx, projectId: string) => {
  go(ctx, -1, projectId);
});
export const forwardAction = action((ctx, projectId: string) => {
  go(ctx, 1, projectId);
});

export const descriptionStatusAtom = atom<"empty" | "full">((ctx) => {
  const sceneList = ctx.spy(sceneListAtom);
  const status = ctx.get(getSceneListAction.statusesAtom);
  if (!sceneList.length && status.isFulfilled) {
    return "empty";
  }

  return "full";
}, "isDescriptionEmptyAtom");

export const getSceneListAction = reatomAsync(
  async (ctx, projectKey: string) => {
    const { data } = await getScriptResource(projectKey, ctx.controller);

    return data;
  },
  {
    onFulfill: (ctx, response) => {
      const orders = response.scenes_order;
      const scenes = response.scenes_info;
      const sceneList = sortScenes(scenes, orders);

      updateHistoryValue(ctx, sceneList, "getHighlightTheShotsResource", {
        sceneId: "",
        shotIds: [""],
      });
    },
    onReject: (ctx, err) => {
      callErrorAction(ctx, err as AxiosError);
    },
  },
).pipe(withAbort(), withStatusesAtom());

export const addSceneAction = action(async (ctx, projectKey: string, location: ProjectLocation) => {
  try {
    const sceneList = ctx.get(sceneListAtom);
    const sceneTitle = `Scene ${sceneList.length + 1}`;
    const sceneId = uuidv4();
    const locations =
      location.country && location.city ? [`${location.country} ${location.city}`] : [];
    const selectedLocation =
      location.country && location.city ? `${location.country} ${location.city}` : "";

    const sceneInfo: SceneInfo = {
      scene_id: sceneId,
      shots_order: [],
      title: sceneTitle,
      scene_locations: locations,
      selected_location: selectedLocation,
    };

    const scenesOrder: string[] = [...sceneList.map((currentScene) => currentScene.id), sceneId];

    const scene: TScene = {
      id: sceneId,
      title: sceneTitle,
      idx: sceneList.length,
      shots: [],
      locations,
      selectedLocation,
    };

    updateHistoryValue(ctx, [...sceneList, scene], "createSceneResource", {
      sceneId,
      shotIds: [""],
    });

    await createSceneResource({ projectKey, scenesOrder, sceneInfo });
    await addShotToSceneAction(ctx, { projectKey, sceneId, shotId: "" });
  } catch (err) {
    callErrorAction(ctx, err as AxiosError);
  }
});

export const addShotToSceneAction = action(async (ctx, params: TShotDTO) => {
  try {
    const sceneList = ctx.get(sceneListAtom);
    const currentScene = sceneList.find((scene) => scene.id === params.sceneId);
    const lastShotIndex = currentScene?.shots.findIndex((shot) => shot.id === params.shotId) || 0;
    const shotIndex = currentScene?.shots.length || 1;

    const shotId = uuidv4();
    const shotTitle = "";
    const shotColor = randomColor.next().value as ShotColor;
    const shotDescription = "";

    const shotPrompt = [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ] as Descendant[];

    const shotInfo: ShotInfoParams = {
      title: shotTitle,
      prompt: shotPrompt,
      color: shotColor,
      description: shotDescription,
    };

    const currentOrderList = currentScene?.shots.map((shot) => shot.id) || [];

    const shotsOrder: string[] = currentScene
      ? insert<string>(lastShotIndex + 1, [shotId], currentOrderList)
      : [shotId];

    const shot: TShot = {
      id: shotId,
      idx: shotIndex,
      title: shotTitle,
      prompt: shotPrompt,
      color: shotColor,
      description: shotDescription,
    };

    const updatedScene: TScene[] = sceneList.map((scene) => {
      if (scene.id === params.sceneId) {
        const params = {
          newShots: [shot],
          lastShotIndex,
          initialShots: scene.shots,
        };

        const updatedShots = insertShots(params, shotsOrder);

        return {
          ...scene,
          shots: updatedShots,
        };
      }

      return {
        ...scene,
      };
    });

    shotActiveHash(ctx, shotId);

    updateHistoryValue(ctx, updatedScene, "createShotResource", {
      sceneId: params.sceneId,
      shotIds: [shotId],
    });

    await createShotResource({
      projectKey: params.projectKey,
      sceneId: params.sceneId,
      shotId,
      shotInfo,
      shotsOrder,
    });
  } catch (err) {
    callErrorAction(ctx, err as AxiosError);
  }
});

export const deleteShotAction = action(async (ctx, params: TShotDTO) => {
  try {
    const sceneList = ctx.get(sceneListAtom);
    const scene = sceneList.find((scene) => scene.id === params.sceneId);
    const deletedShot = scene?.shots.find((shot) => shot.id === params.shotId);

    if (deletedShot) {
      const shotsOrder: string[] =
        scene?.shots.map((shot) => shot.id)?.filter((shotId) => shotId !== params.shotId) || [];

      const filterShots = (shots: TShot[]) => {
        const filteredShots = shots.filter((shot) => shot.id !== params.shotId);

        return filteredShots.map((shot, idx) => ({
          ...shot,
          idx,
        }));
      };

      const updatedSceneList = sceneList.map((scene) => ({
        ...scene,
        shots: filterShots(scene.shots),
      }));

      updateHistoryValue(ctx, updatedSceneList, "deleteShotResource", {
        sceneId: params.sceneId,
        shotIds: [deletedShot.id],
      });

      await deleteShotResource({
        projectKey: params.projectKey,
        shotId: params.shotId,
        sceneId: params.sceneId,
        shotsOrder,
      });
    }
  } catch (err) {
    callErrorAction(ctx, err as AxiosError);
  }
});

export const updateShotValueInSceneAction = action(async (ctx, params: TUpdateShotDTO) => {
  try {
    const sceneList = ctx.get(sceneListAtom);
    const currentScene = sceneList.find((scene) => scene.id === params.sceneId);
    const currentShot = currentScene?.shots.find((shot) => shot.id === params.shotId);

    const shotInfo: ShotInfoParams = {
      title: currentShot?.title ?? "",
      prompt: params.value,
      color: currentShot?.color ?? "red",
      description: params.description,
    };

    const shotsOrder: string[] = currentScene?.shots.map((shot) => shot.id) || [];

    const updatedScene: TScene[] = sceneList.map((scene) => {
      if (scene.id === params.sceneId) {
        return {
          ...scene,
          shots: scene.shots.map((shot) => ({
            ...shot,
            prompt: shot.id === params.shotId ? params.value : shot.prompt,
            description: shot.id === params.shotId ? params.description : shot.description,
          })),
        };
      }

      return {
        ...scene,
      };
    });

    updateHistoryValue(ctx, updatedScene, "updateShotResource", {
      sceneId: params.sceneId,
      shotIds: [params.shotId],
    });
    await updateShotResource({
      projectKey: params.projectKey,
      sceneId: params.sceneId,
      shotId: params.shotId,
      shotInfo,
      shotsOrder,
    });
  } catch (err) {
    callErrorAction(ctx, err as AxiosError);
  }
});

export const updateShotColorAction = action(async (ctx, params: TUpdateShotColorDTO) => {
  try {
    const sceneList = ctx.get(sceneListAtom);
    const currentScene = sceneList.find((scene) => scene.id === params.sceneId);
    const currentShot = currentScene?.shots.find((shot) => shot.id === params.shotId);

    const shotInfo: ShotInfoParams = {
      title: currentShot?.title ?? "",
      prompt: currentShot?.prompt ?? [],
      color: params.color ?? currentShot?.color ?? "red",
      description: currentShot?.description ?? "",
    };

    const shotsOrder: string[] = currentScene?.shots.map((shot) => shot.id) || [];

    updateHistoryValue(
      ctx,
      sceneList.map((scene) => ({
        ...scene,
        shots: scene.shots.map((shot) => ({
          ...shot,
          color: params.shotId === shot.id ? params.color : shot.color,
        })),
      })),
      "updateShotResource",
      { sceneId: params.sceneId, shotIds: [params.shotId] },
    );

    await updateShotResource({
      projectKey: params.projectKey,
      shotId: params.shotId,
      sceneId: params.sceneId,
      shotsOrder,
      shotInfo,
    });
  } catch (err) {
    callErrorAction(ctx, err as AxiosError);
  }
});

export const deleteSceneAction = action(async (ctx, params: TDeleteSceneDTO) => {
  try {
    const sceneList = ctx.get(sceneListAtom);
    const currentScene = sceneList.find((scene) => scene.id === params.sceneId);

    const sceneInfo: SceneInfo = {
      scene_id: params.sceneId,
      title: currentScene?.title ?? "",
      shots_order: currentScene?.shots.map((shot) => shot.id) || [],
      scene_locations: currentScene?.locations || [],
      selected_location: currentScene?.selectedLocation ?? "",
    };

    const scenesOrder: string[] =
      sceneList.map((scene) => scene.id)?.filter((id) => id !== params.sceneId) || [];

    const filterScenes = () => {
      const updatedSceneList = sceneList.filter((scene) => scene.id !== params.sceneId);

      return updatedSceneList.map((scene, idx) => ({
        ...scene,
        idx,
      }));
    };

    updateHistoryValue(ctx, filterScenes(), "deleteSceneResource", {
      sceneId: params.sceneId,
      shotIds: [],
    });

    await deleteSceneResource({
      projectKey: params.projectKey,
      scenesOrder,
      sceneInfo,
    });
  } catch (err) {
    callErrorAction(ctx, err as AxiosError);
  }
});

export const updateSceneTitleAction = action(async (ctx, params: SceneTitleDTO) => {
  try {
    const sceneList = ctx.get(sceneListAtom);
    const currentScene = sceneList.find((scene) => scene.id === params.sceneId);

    const sceneInfo: SceneInfo = {
      scene_id: params.sceneId,
      title: params.title,
      shots_order: currentScene?.shots.map((shot) => shot.id) || [],
      scene_locations: currentScene?.locations || [],
      selected_location: currentScene?.selectedLocation ?? "",
    };

    const scenesOrder: string[] = sceneList.map((scene) => scene.id);

    updateHistoryValue(
      ctx,
      sceneList.map((scene) => ({
        ...scene,
        title: scene.id === params.sceneId ? params.title : scene.title,
      })),
      "updateSceneResource",
      { sceneId: params.sceneId, shotIds: [""] },
    );

    await updateSceneResource({
      projectKey: params.projectKey,
      scenesOrder,
      sceneInfo,
    });
  } catch (err) {
    callErrorAction(ctx, err as AxiosError);
  }
}, "");

const cutSceneTitle = (title: string) => {
  let result = title;
  if (title[0] === '"') {
    result = title.slice(1);
  }

  if (title[title.length - 1] === '"') {
    result = result.slice(0, title.length - 2);
  }

  return result;
};

export const addScriptToProjectAction = reatomAsync(async (_ctx, params: UploadScriptParams) => {
  const response = await uploadScriptResource(params);
  const scenesOrder = response.data.scenes_order;
  const scenesInfo = response.data.scenes_info;

  const scenePromises = Object.entries(scenesInfo).map(async ([sceneId, scene]) => {
    const sceneInfo: SceneInfo = {
      title: cutSceneTitle(scene.title),
      shots_order: scene.shots_order,
      scene_id: sceneId,
      scene_locations: scene.location_options,
      selected_location: scene.location_options?.[0] ?? "",
    };

    await createSceneResource({
      projectKey: params.projectKey,
      sceneInfo,
      scenesOrder,
    });

    const shotsOrder = scene.shots_order;

    const shotsPromises = Object.entries(scene.shots_info).map(async ([shotId, shot]) => {
      const shotInfo: ShotInfoParams = {
        title: "",
        prompt: [{ type: "paragraph", children: [{ text: shot.description }] }],
        color: randomColor.next().value as ShotColor,
        description: shot.description,
      };

      await createShotResource({
        projectKey: params.projectKey,
        shotId,
        sceneId,
        shotsOrder,
        shotInfo,
      });
    });

    await Promise.all(shotsPromises);
  });

  await Promise.all(scenePromises);
}).pipe(withStatusesAtom());

export const splitShotAction = action(
  async (
    ctx,
    params: { selection: Selection; sceneId: string; shotId: string; projectKey: string },
  ) => {
    const sceneList = ctx.get(sceneListAtom);
    const { selection, sceneId, shotId, projectKey } = params;
    const currentScene = sceneList?.find((scene) => sceneId === scene.id);
    const currentShot = currentScene?.shots.find((shot) => shot.id === shotId);
    const deletedShotIdx = currentScene?.shots.findIndex((shot) => shot.id === shotId) ?? 0;

    if (!currentShot) return;

    const description = currentShot.description;
    if (selection) {
      const { anchor, focus } = selection;

      const minOffset = Math.min(anchor.offset, focus.offset);
      const maxOffset = Math.max(anchor.offset, focus.offset);

      if (minOffset === 0) {
        // @ts-ignore
        const selectedText = currentShot.prompt[0].children[0].text.slice(minOffset, maxOffset);
        // @ts-ignore
        const restText = currentShot.prompt[0].children[0].text.slice(maxOffset);

        const newShotInfo1: ShotInfoParams = createShotInfo(currentShot, selectedText);
        const newShot1 = createShot(newShotInfo1, deletedShotIdx);

        const newShotInfo2: ShotInfoParams = createShotInfo(currentShot, restText);
        const newShot2 = createShot(newShotInfo2, deletedShotIdx + 1);

        const filteredList = getFilteredList(sceneList, sceneId, currentShot.id);

        const newSceneList: TScene[] = getNewSceneList(
          filteredList,
          sceneId,
          [newShot1, newShot2],
          deletedShotIdx,
        );

        updateHistoryValue(ctx, newSceneList, "splitShotResourceOnTwo", {
          sceneId,
          shotIds: [shotId, newShot1.id, newShot2.id],
        });

        const newShotsList = newSceneList.find((scene) => scene.id === sceneId)?.shots;
        const shotsOrder = newShotsList?.map((shot) => shot.id) || [];

        await deleteShotResource({
          projectKey,
          shotId,
          sceneId,
          shotsOrder,
        });
        await createShotResource({
          projectKey,
          sceneId,
          shotsOrder,
          shotId: newShot1.id,
          shotInfo: newShotInfo1,
        });
        await createShotResource({
          projectKey,
          sceneId,
          shotsOrder,
          shotId: newShot2.id,
          shotInfo: newShotInfo2,
        });
      } else if (minOffset !== 0 && maxOffset >= description.length) {
        // @ts-ignore
        const selectedText = currentShot.prompt[0].children[0].text.slice(minOffset);
        // @ts-ignore
        const restText = currentShot.prompt[0].children[0].text.slice(0, minOffset);

        const newShotInfo1: ShotInfoParams = createShotInfo(currentShot, selectedText);
        const newShot1 = createShot(newShotInfo1, deletedShotIdx);

        const newShotInfo2: ShotInfoParams = createShotInfo(currentShot, restText);
        const newShot2 = createShot(newShotInfo2, deletedShotIdx + 1);

        const filteredList = getFilteredList(sceneList, sceneId, currentShot.id);
        const newSceneList = getNewSceneList(
          filteredList,
          sceneId,
          [newShot2, newShot1],
          deletedShotIdx,
        );

        updateHistoryValue(ctx, newSceneList, "splitShotResourceOnTwo", {
          sceneId,
          shotIds: [shotId, newShot2.id, newShot1.id],
        });

        const newShotsList = newSceneList.find((scene) => scene.id === sceneId)?.shots;
        const shotsOrder = newShotsList?.map((shot) => shot.id) || [];

        await deleteShotResource({
          projectKey,
          shotId,
          sceneId,
          shotsOrder,
        });
        await createShotResource({
          projectKey,
          sceneId,
          shotsOrder,
          shotId: newShot2.id,
          shotInfo: newShotInfo2,
        });
        await createShotResource({
          projectKey,
          sceneId,
          shotsOrder,
          shotId: newShot1.id,
          shotInfo: newShotInfo1,
        });
      }
      // select in the middle
      else {
        // @ts-ignore
        const selectedText = currentShot.prompt[0].children[0].text.slice(minOffset, maxOffset);
        // @ts-ignore
        const restTextFromBegin = currentShot.prompt[0].children[0].text.slice(0, minOffset);
        // @ts-ignore
        const restTextToEnd = currentShot.prompt[0].children[0].text.slice(maxOffset);

        const newShotInfo1: ShotInfoParams = createShotInfo(currentShot, restTextFromBegin);
        const newShot1 = createShot(newShotInfo1, deletedShotIdx);

        const newShotInfo2: ShotInfoParams = createShotInfo(currentShot, selectedText);
        const newShot2 = createShot(newShotInfo2, deletedShotIdx + 1);

        const newShotInfo3: ShotInfoParams = createShotInfo(currentShot, restTextToEnd);
        const newShot3 = createShot(newShotInfo3, deletedShotIdx + 2);

        const filteredList = getFilteredList(sceneList, sceneId, currentShot.id);

        const newSceneList = getNewSceneList(
          filteredList,
          sceneId,
          [newShot1, newShot2, newShot3],
          deletedShotIdx,
        );

        updateHistoryValue(ctx, newSceneList, "splitShotResourceOnThree", {
          sceneId,
          shotIds: [shotId, newShot1.id, newShot2.id, newShot3.id],
        });

        const newShotsList = newSceneList.find((scene) => scene.id === sceneId)?.shots;
        const shotsOrder = newShotsList?.map((shot) => shot.id) || [];

        await deleteShotResource({ projectKey, shotId, sceneId, shotsOrder });

        await createShotResource({
          projectKey,
          sceneId,
          shotsOrder,
          shotId: newShot1.id,
          shotInfo: newShotInfo1,
        });
        await createShotResource({
          projectKey,
          sceneId,
          shotsOrder,
          shotId: newShot2.id,
          shotInfo: newShotInfo2,
        });
        await createShotResource({
          projectKey,
          sceneId,
          shotsOrder,
          shotId: newShot3.id,
          shotInfo: newShotInfo3,
        });
      }
    }
  },
);
