import { Flex, Modal } from "antd";
import { FC } from "react";
import { IOpenModal } from "shared/hooks";
import { ThirdStepForm } from "./ThirdStepForm.tsx";

export const AddScriptModal: FC<IOpenModal> = ({ isOpen, toggleModal }) => {
  const onCloseModal = () => {
    toggleModal(false);
  };

  return (
    <Modal footer={[]} open={isOpen} onCancel={onCloseModal}>
      <Flex vertical className="gap-s">
        <ThirdStepForm closeModal={onCloseModal} isBriefOpen={false} />
      </Flex>
    </Modal>
  );
};
