import { Flex, FormInstance, Typography } from "antd";
import cn from "classnames";
import { FC, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import { GRAYSCALE_TOKEN } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";

import arrow from "assets/shared/arrow_left_24.svg";
import { DisableSubmitButton } from "shared/ui";

import "./RecoverPasswordWrapper.scss";

interface IRecoverPasswordWrapper {
  title: string;
  description: string;
  form: FormInstance;
  buttonText: string;
}

export const RecoverPasswordWrapper: FC<PropsWithChildren<IRecoverPasswordWrapper>> = ({
  children,
  title,
  description,
  form,
  buttonText,
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Flex vertical className="recover-password-wrapper gap-s">
      <Flex onClick={handleBack} className="recover-password-wrapper__back pointer">
        <img style={{ width: 20, height: 20 }} src={arrow} alt="back" />
      </Flex>
      <Flex vertical>
        <Typography.Text className={cn(TEXT_CLASSNAMES.Display70)}>{title}</Typography.Text>
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.Title30, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
        >
          {description}
        </Typography.Text>
      </Flex>
      <Flex vertical gap={8}>
        {children}
      </Flex>
      <DisableSubmitButton type="primary" form={form}>
        {buttonText}
      </DisableSubmitButton>
    </Flex>
  );
};
