import { useAtom, useUpdate } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC } from "react";

import { useSearchParams } from "react-router-dom";
import { addMoodboardsImagesCoordinates } from "@/shared/api/moodboard";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { MOODBOARD_TYPE_PARAM_NAME, MOODBORD_TYPE } from "shared/const/routes.ts";
import { MoodboardPath } from "shared/types/routes.ts";
import { Spinner } from "shared/ui";
import { getMoodboardAction, moodboardLoadingAtom } from "@/entities/moodboard";
import { MoodboardVisible } from "./MoodboardVisible";

export const MoodboardContent: FC = () => {
  const [searchParams] = useSearchParams();
  const moodboardType = searchParams.get(MOODBOARD_TYPE_PARAM_NAME) ?? MOODBORD_TYPE.structure;
  const { id } = useExtractParams<MoodboardPath>();
  const [isLoading] = useAtom(moodboardLoadingAtom);
  const [moodboardStatuses] = useAtom(getMoodboardAction.statusesAtom);

  useUpdate(
    async (ctx) => {
      const updatedImagesCoordinatesMoodboardList = ctx.get(getMoodboardAction.dataAtom);

      await addMoodboardsImagesCoordinates(
        id,
        updatedImagesCoordinatesMoodboardList.map((el) => ({ ...el.coordinates, id: el.id })),
      );
    },
    [moodboardType],
  );

  if (isLoading || moodboardStatuses.isPending) {
    return (
      <Flex className="full-height full-height" align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  return <MoodboardVisible />;
};
