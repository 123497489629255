import { useAction } from "@reatom/npm-react";
import { Button, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import upgrade from "assets/projects/upgrade.svg";
import { IOpenModal } from "shared/hooks";
import { useAccountWithAvatar } from "../../../account";
import { sessionAuthSubscriptionAction } from "../../index";

export const UpgradeModal: FC<IOpenModal> = ({ isOpen, toggleModal }) => {
  const navigate = useNavigate();
  const sessionAuthSubscription = useAction(sessionAuthSubscriptionAction);
  const { account } = useAccountWithAvatar();

  const handleUpgrade = () => {
    sessionAuthSubscription({ isGuest: false, email: account.email }); // ??
    navigate("/plan");
  };

  return (
    <Modal
      footer={
        <Flex justify="space-between" className="gap-xs" style={{ marginTop: 32 }}>
          <Button size="large" style={{ flexGrow: 1 }} onClick={() => toggleModal(false)}>
            Cancel
          </Button>
          <Button size="large" type="primary" style={{ flexGrow: 1 }} onClick={handleUpgrade}>
            Upgrade now
          </Button>
        </Flex>
      }
      open={isOpen}
      onCancel={() => toggleModal(false)}
    >
      <Flex vertical align="center">
        <img src={upgrade} width={160} height={160} alt="add member" />
        <Flex vertical className="gap-xxs" align="center">
          <Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>Upgrade Required</Typography.Text>
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.XsRegular, "text-center", COLOR_CLASSNAMES.TextSecondary)}
          >
            To create a project and access the full functionality of the product, please upgrade
            your subscription.
          </Typography.Text>
        </Flex>
      </Flex>
    </Modal>
  );
};
