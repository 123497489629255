import { useAtom } from "@reatom/npm-react";
import { Collapse, ConfigProvider, Flex } from "antd";

import { FC, useMemo, useRef, useState } from "react";

import { Comment as CommentType } from "@/shared/api/comments";

import { COLOR_NAMES } from "shared/const/color-names";
import { useOutsideClick } from "shared/hooks";
import { ActionRestrictor } from "shared/ui";
import { CommentCollapseState } from "../../lib";
import { activeTabAtom, getCommentsAction } from "../../model";
import { TextField } from "../text-field";
import { Comment } from "./Comment";

import "./Comment.scss";

type Props = CommentType & { currUserEmail?: string; projectKey: string };

export const CommentCollapse: FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { id, projectKey, currUserEmail, resolved } = props;
  const [activeKey, setActiveKey] = useState<CommentCollapseState>(0);
  const [comments] = useAtom(getCommentsAction.dataAtom);
  const [activeTab] = useAtom(activeTabAtom);

  useOutsideClick(ref, () => {
    setActiveKey(0);
  });

  const filteredReplies = useMemo(() => {
    const topLevelReplies = comments.filter((comment) => comment.parent_id === id);
    if (activeTab === "resolved") {
      return topLevelReplies.filter((c) => c.resolved);
    }
    return topLevelReplies;
  }, [comments, activeTab]);

  const repliesAmount = filteredReplies.length;
  const isActive = activeKey === 1;
  const headerBg = resolved
    ? `${COLOR_NAMES.MetalGray200} !important`
    : `${COLOR_NAMES.White100} !important`;

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            contentPadding: 0,
            headerBg,
            borderRadiusLG: 0,
            headerPadding: 0,
          },
        },
      }}
    >
      <Collapse
        ref={ref}
        ghost
        collapsible="icon"
        activeKey={activeKey}
        items={[
          {
            key: "1",
            label: (
              <Comment
                {...props}
                currUserEmail={currUserEmail}
                isActive={isActive}
                repliesAmount={repliesAmount}
                setActiveKey={setActiveKey}
              />
            ),
            children: (
              <Flex vertical>
                {filteredReplies.map((reply) => (
                  <Comment
                    currUserEmail={currUserEmail}
                    isActive={isActive}
                    projectKey={projectKey}
                    repliesAmount={repliesAmount}
                    key={reply.id}
                    {...reply}
                    setActiveKey={setActiveKey}
                  />
                ))}
                <ActionRestrictor show={!resolved}>
                  <div style={{ padding: "8px 16px 16px 16px" }}>
                    <TextField placeholder="Add a comment" parentId={id} />
                  </div>
                </ActionRestrictor>
              </Flex>
            ),
            className: "collapse-item",
            showArrow: false,
          },
        ]}
      />
    </ConfigProvider>
  );
};
