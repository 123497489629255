import { useAction } from "@reatom/npm-react";
import { Button, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";

import { ScriptPath } from "shared/types/routes.ts";
import deleteImg from "assets/shared/delete_gray.svg";
import { useOpenModal } from "shared/hooks";
import { deleteSceneAction } from "@/entities/script";

interface IDeleteSceneModal {
  sceneId: string;
}

export const DeleteSceneModal: FC<IDeleteSceneModal> = ({ sceneId }) => {
  const [open, toggleModal] = useOpenModal();
  const { id } = useExtractParams<ScriptPath>();
  const deleteScene = useAction(deleteSceneAction);

  const handleDeleteScene = async () => {
    await deleteScene({
      sceneId,
      projectKey: id,
    });
  };

  return (
    <>
      <Typography.Text
        className={cn(TEXT_CLASSNAMES.Subtitle10, "pointer")}
        role="button"
        onClick={() => toggleModal(true)}
        style={{ whiteSpace: "nowrap" }}
      >
        <img src={deleteImg} alt="delete" />
      </Typography.Text>
      <Modal
        open={open}
        width={590}
        onCancel={() => toggleModal(false)}
        footer={
          <Flex gap={8} justify="flex-end">
            <Button size="large" onClick={() => toggleModal(false)}>
              Cancel
            </Button>
            <Button size="large" type="primary" onClick={handleDeleteScene}>
              Yes, delete it
            </Button>
          </Flex>
        }
      >
        <Flex vertical className="gap-xxs">
          <Flex style={{ background: "transparent" }} align="center" className="gap-xxs">
            <Typography.Text className={TEXT_CLASSNAMES.Title50}>
              Are you sure you want to delete the entire scene?
            </Typography.Text>
          </Flex>
          <Typography.Text className={TEXT_CLASSNAMES.Body20}>
            All shots from this scene will also be deleted and cannot be returned
          </Typography.Text>
        </Flex>
      </Modal>
    </>
  );
};
