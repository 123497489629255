import { Flex, Typography } from "antd";
import { FC } from "react";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { isEmptyShot, TShowDetailsInfo } from "../../index";

import { ShowDetailsInfo } from "./ShowDetailsInfo";

import "./PopoverContent.scss";
export const PopoverContent: FC<TShowDetailsInfo> = (propsData) => {
  const { props, cameraMovement, shotSettings, cameraAngle } = propsData;

  if (!isEmptyShot(propsData)) {
    return (
      <Flex className="shot-details-info-popover__empty">
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
          No details in this shot
        </Typography.Text>
      </Flex>
    );
  }

  return (
    <ShowDetailsInfo
      props={props}
      shotSettings={shotSettings}
      cameraMovement={cameraMovement}
      cameraAngle={cameraAngle}
    />
  );
};
