import { Button, Flex, Progress, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { COLOR_CLASSNAMES, COLOR_NAMES, GRAYSCALE_TOKEN } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";

import "./Projects-trial-banner.scss";

interface ProjectsTrialBannerProps {
  leftDays: number;
  onUpgrade?: () => void;
}

export const ProjectsTrialBanner: FC<ProjectsTrialBannerProps> = ({ leftDays, onUpgrade }) => {
  const maxDays = 7;
  const minDays = 0;
  const progress = ((leftDays - minDays) / (maxDays - minDays)) * 100;

  return (
    <Flex vertical align="center" gap="middle" className="statistic__container gap-s">
      <Progress
        type="circle"
        percent={Math.max(0, Math.min(100, progress))}
        format={() => (
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.Title50, GRAYSCALE_TOKEN.TEXT_ICON.TITLE.className)}
          >
            {leftDays}
          </Typography.Text>
        )}
        strokeColor="linear-gradient(343deg, #70FF00 9.07%, #40E441 50.33%, #22D469 93.03%)"
        strokeWidth={9}
        size={90}
        trailColor={COLOR_NAMES.MetalGray300}
      />

      <Flex vertical align="center" className="gap-4">
        <Typography.Text className={TEXT_CLASSNAMES.Title30} style={{ textAlign: "center" }}>
          Only {leftDays} days left on your trial!
        </Typography.Text>

        <Typography.Text
          className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.Body10)}
          style={{ textAlign: "center" }}
        >
          You're unlocking powerful features—don’t let them disappear! Upgrade now to keep creating
          without limits and take your projects to the next level.
        </Typography.Text>
      </Flex>

      <Button type="primary" size="large" onClick={onUpgrade}>
        Upgrade Plan
      </Button>
    </Flex>
  );
};
