import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Input, InputRef, Tag, Typography, Space, Button } from "antd";
import cn from "classnames";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { GRAYSCALE_TOKEN } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import plus from "assets/shared/plus_square.svg";
import add from "assets/shot-selection/add.svg";
import close from "assets/shot-selection/close.svg";
import { ActionRestrictor, IconButtonWrapper } from "shared/ui";
import { useProjectType } from "@/entities/projects";
import { shotEditStateAtom } from "@/entities/shot-edit";
import { updateShotPropsActions } from "../../../model";

export const ShotPropsEdit: FC = () => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const updateShotProps = useAction(updateShotPropsActions);
  const [shotEditState] = useAtom(shotEditStateAtom);
  const propsArray = (shotEditState.props ?? "")?.split(",")?.filter((el) => el);
  const [currentProps, setCurrentProps] = useState(propsArray);
  const inputRef = useRef<InputRef>(null);
  const { isShare } = useProjectType();

  const toggleInput = () => {
    setInputVisible(!inputVisible);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && currentProps.indexOf(inputValue) === -1) {
      const newProps = [...currentProps, inputValue];
      const newPropsString = newProps?.join(",") ?? "";

      setCurrentProps(newProps);
      updateShotProps({ props: newPropsString });
    }
    setInputVisible(false);
    setInputValue("");
  };

  const onDelete = (value: string) => {
    const newProps = currentProps?.filter((el) => el !== value);
    const newPropsString = newProps?.join(",") ?? "";

    setCurrentProps(newProps);
    updateShotProps({ props: newPropsString });
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    setCurrentProps(propsArray);
  }, [propsArray?.length]);

  return (
    <>
      <div className="shot-details-info__divider" />
      <Flex vertical wrap="wrap" className="shot-details-info__row gap-xs">
        <Flex vertical className="gap-xxs">
          <Flex align="center" justify="space-between">
            <Typography.Text
              className={cn(GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className, TEXT_CLASSNAMES.Body10)}
            >
              Props
            </Typography.Text>
            <ActionRestrictor show={!isShare}>
              <Flex align="center" className="gap-4 pointer" onClick={toggleInput}>
                <img width={20} height={20} src={plus} alt="plus" />
                <Typography.Text className={TEXT_CLASSNAMES.Link10}>Add</Typography.Text>
              </Flex>
            </ActionRestrictor>
          </Flex>
          <Flex wrap="wrap" className="gap-xxs">
            {currentProps.map((tag) => (
              <Tag className="shot-details-info__tag flex-ctr-ctr gap-4" key={tag}>
                {tag}
                <ActionRestrictor show={!isShare}>
                  <img onClick={() => onDelete(tag)} className="pointer" src={close} alt="close" />
                </ActionRestrictor>
              </Tag>
            ))}
          </Flex>
          {inputVisible && (
            <Space.Compact style={{ width: "100%" }}>
              <Input
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={toggleInput}
                onPressEnter={handleInputConfirm}
              />
              <Button
                onClick={handleInputConfirm}
                icon={
                  <IconButtonWrapper>
                    <img style={{ width: 20, height: 20 }} src={add} alt="add" />
                  </IconButtonWrapper>
                }
              />
            </Space.Compact>
          )}
        </Flex>
      </Flex>
    </>
  );
};
