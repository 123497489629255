import { Flex, Typography } from "antd";
import { FC } from "react";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";

export const Label: FC = () => (
  <Flex vertical>
    <Typography.Text className={TEXT_CLASSNAMES.Title20}>All pages</Typography.Text>
    <Typography.Text className={TEXT_CLASSNAMES.Body10}>
      You can manage access to pages.
    </Typography.Text>
  </Flex>
);
