import { FC } from "react";
import { Flex, Typography } from "antd";
import timeImg from "assets/storyboard/time.svg";
import cn from "classnames";

import { SceneShotTagsProps } from "../model";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";

export const SceneShotTags: FC<SceneShotTagsProps> = ({ 
  location, 
  time, 
  shotCharacteristics, 
  type = "default",
  props,
  shotSettings,
  cameraAngle,
  cameraMovement,
}) => {
  const propsArray = (props ?? "").split(",")?.filter((el) => el);

  if (type === "props") {
    return (
      <Flex vertical className="scene-shot-grid__line scene-shot-grid__line__height gap-xxs">
        <Flex className="gap-xxs" wrap="wrap">
          {propsArray.map((tag, index) => (
            <Flex
              vertical
              className="scene-shot-grid__tag"
              style={{ height: 30 }}
              key={index.toString(36)}
            >
              <Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>{tag}</Typography.Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    );
  }

  if (type === "settings") {
    return (
      <Flex vertical className="scene-shot-grid__line scene-shot-grid__line__height gap-xxs">
        <Flex wrap="wrap" className="gap-xxs">
          {shotSettings.map((tag, index) => (
            <Flex
              style={{ height: 30 }}
              className="scene-shot-grid__tag"
              key={index.toString(36)}
            >
              <Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>{tag}</Typography.Text>
            </Flex>
          ))}
          {cameraAngle && (
            <Flex className="scene-shot-grid__tag" style={{ height: 30 }} key={cameraAngle}>
              <Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>
                {cameraAngle}
              </Typography.Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  }

  if (type === "movement") {
    return (
      <Flex vertical className="scene-shot-grid__line scene-shot-grid__line__height gap-xxs">
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{cameraMovement}</Typography.Text>
      </Flex>
    );
  }

  return (
    <Flex vertical className="scene-shot-grid__line gap-xxs">
      <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{location}</Typography.Text>
      <Flex className="gap-xxs" wrap="wrap">
        <Flex align="center" className="scene-shot-grid__tag gap-4">
          <img
            style={{ width: 16, height: 16 }}
            src={timeImg}
            className="image-contain"
            alt="time"
          />
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.MetalGray700)}
          >
            {`${time} sec`}
          </Typography.Text>
        </Flex>
        {shotCharacteristics.map((tag, index) => (
          <Flex className="scene-shot-grid__tag" key={index.toString(36)}>
            {tag}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};