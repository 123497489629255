import { useAction } from "@reatom/npm-react";
import { useDebounceFn } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { ChangeEvent, FC, useEffect, useRef } from "react";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { ScriptPath } from "shared/types/routes.ts";
import { useProjectType } from "@/entities/projects";
import { updateSceneTitleAction } from "@/entities/script";

import "./SceneTitle.scss";

type Props = {
  idx: number;
  sceneId: string;
  title: string;
};

export const SceneTitle: FC<Props> = ({ idx, sceneId, title }) => {
  const updateScene = useAction(updateSceneTitleAction);
  const { id } = useExtractParams<ScriptPath>();
  const titleRef = useRef<HTMLSpanElement>(null);
  const { isShare } = useProjectType();

  const { run } = useDebounceFn(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { innerText } = event.target;

      updateScene({
        projectKey: id,
        sceneId,
        title: innerText,
      });
    },
    { wait: 500 },
  );

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.innerText = title;
    }
  }, []);

  return (
    <Flex align="center" className="scene-title gap-4">
      <Typography.Text className={cn(TEXT_CLASSNAMES.Title20, COLOR_CLASSNAMES.TextPrimary)}>
        Scene {idx + 1}
        &nbsp; "
        <span
          ref={titleRef}
          className="scene-title__edit"
          role="textbox"
          contentEditable={!isShare}
          onInput={run}
        />
        "
      </Typography.Text>
    </Flex>
  );
};
