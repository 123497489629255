import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import { FC, useRef, useState } from "react";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { ScriptPath } from "shared/types/routes.ts";
import plus from "assets/shared/plus.svg";
import { useOpenModal } from "shared/hooks";
import { ActionRestrictor } from "shared/ui";
import { useProjectType } from "@/entities/projects";
import {
  activeSceneAtom,
  addShotToSceneAction,
  deleteShotAction,
  DeleteShotModal,
  TShot,
} from "@/entities/script";

import { DeleteSceneModal } from "./DeleteSceneModal.tsx";
import { SceneShotItem } from "./SceneShotItem.tsx";

import "./SceneShotOutline.scss";
import { useHover } from "ahooks";

interface IScene {
  shots: TShot[];
  sceneTitle: string;
  sceneId: string;
  sceneIdx: number;
  projectLastShotId: string;
}

export const SceneShotOutline: FC<IScene> = ({
  sceneTitle,
  shots,
  sceneId,
  sceneIdx,
  projectLastShotId,
}) => {
  const { id } = useExtractParams<ScriptPath>();
  const addShot = useAction(addShotToSceneAction);
  const deleteShot = useAction(deleteShotAction);
  const [open, toggleModal] = useOpenModal();
  const [activeShotId, setActiveShotId] = useState<string>("");
  const [isLastShot, setIsLastShot] = useState(false);
  const lastShotId = shots[shots.length - 1]?.id;
  const isEmptyScene = shots.length === 1;
  const { isShare } = useProjectType();
  const titleRef = useRef(null);
  const isHover = useHover(titleRef);
  const [activeScene, setActiveScene] = useAtom(activeSceneAtom);
  const isSceneActive = activeScene === sceneId;

  const handleAddShot = () => {
    addShot({
      projectKey: id,
      sceneId,
      shotId: lastShotId,
    });
  };

  const handleDeleteShot = async (shotId: string, description: string) => {
    const isContent = !!description.length;
    const isLast = projectLastShotId === shotId;

    if (isEmptyScene && isLast) {
      setIsLastShot(true);
      setActiveShotId(activeShotId);
      toggleModal(true);
    } else {
      if (isContent) {
        setActiveShotId(shotId);
        toggleModal(true);
      } else {
        await deleteShot({
          projectKey: id,
          shotId,
          sceneId,
        });
      }
    }
  };

  return (
    <Flex vertical className="scene">
      <Flex
        onClick={() => setActiveScene(sceneId)}
        ref={titleRef}
        align="center"
        justify="space-between"
        gap={4}
        className="scene__title"
      >
        <Typography.Text className={TEXT_CLASSNAMES.Link20} ellipsis>
          Scene {sceneIdx + 1} "{sceneTitle}"
        </Typography.Text>
        <ActionRestrictor show={!isShare && (isHover || isSceneActive)}>
          <DeleteSceneModal sceneId={sceneId} />
        </ActionRestrictor>
      </Flex>
      <Flex vertical className="gap-xxs scene__body">
        {shots.map((shot) => (
          <SceneShotItem
            key={shot.id}
            sceneId={sceneId}
            onDeleteShot={handleDeleteShot}
            {...shot}
          />
        ))}
        <DeleteShotModal
          sceneId={sceneId}
          shotId={activeShotId}
          toggleModal={toggleModal}
          isOpen={open}
          isLast={isLastShot}
        />
        <ActionRestrictor show={!isShare}>
          <Flex onClick={handleAddShot} align="center" className="gap-4 pointer">
            <img src={plus} alt="plus" className="scene__img" />
            <Typography.Text className={TEXT_CLASSNAMES.Link10}>Add shot</Typography.Text>
          </Flex>
        </ActionRestrictor>
      </Flex>
    </Flex>
  );
};
