import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { GRAYSCALE_TOKEN } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { CharacterInfoPath, ShotEditEditPath, StoryboardSidebarPath } from "shared/types/routes.ts";
import grayClose from "assets/shared/gray_close.svg";
import folder from "assets/shared/project.svg";
import { HeaderLayout, MenuItem } from "shared/ui";
import { TStoryboardRoutes } from "@/entities/storyboard";
import { ProjectBreadcrumbs } from "@/features/project-breadcrumbs";
import { STORYBOARD_NAVIGATION_SETTINGS } from "./lib";
import { useStoryboardNavigation } from "./model";
import { HeaderContent } from "./ui";

import "./StoryboardNavigation.scss";

const Header: FC = () => {
  const { id } = useExtractParams<StoryboardSidebarPath>();
  const characterParams = useExtractParams<CharacterInfoPath>();
  const shotEditParams = useExtractParams<ShotEditEditPath>();
  const navigate = useNavigate();

  const onBack = () => {
    navigate("/projects");
  };

  const {
    activePage,
    lastParam,
    possibleRoutes,
    projectType,
    canComment,
    account,
    accountLoading,
    projectName,
  } = useStoryboardNavigation({
    projectKey: id,
  });

  const { rightSidebarRoutes, SettingsComponent } = STORYBOARD_NAVIGATION_SETTINGS[activePage];

  const isRightSidebar = !!rightSidebarRoutes && !!rightSidebarRoutes.length;
  const isShare = projectType === "share";
  const isSettings = SettingsComponent !== null;

  return (
    <Flex vertical className="full-width">
      <HeaderContent account={account} loading={accountLoading}>
        <Flex
          align="center"
          gap={8}
          style={{
            height: "100%",
            background: GRAYSCALE_TOKEN.SURFACE.DEFAULT_LIGHT.color,
            padding: "0 12px",
          }}
        >
          <img style={{ width: 20, height: 20 }} src={folder} alt="folder" />
          <Typography.Paragraph
            style={{ maxWidth: 100, width: "100%" }}
            ellipsis={{ suffix: '"' }}
            className={cn(GRAYSCALE_TOKEN.TEXT_ICON.TITLE.className, TEXT_CLASSNAMES.Link10)}
          >
            Project "{projectName}
          </Typography.Paragraph>
          <img src={grayClose} alt="close" role="button" onClick={onBack} className="pointer" />
        </Flex>
      </HeaderContent>
      <ProjectBreadcrumbs projectType={projectType} />
      <Flex className="storyboard-navigation">
        <Flex className="storyboard-navigation__settings">
          {isSettings && <SettingsComponent />}
          {!isSettings && isRightSidebar && <div style={{ flex: 1 }} />}
        </Flex>
        {isRightSidebar && (
          <Flex align="center" className="storyboard-navigation__sidebar-header">
            {rightSidebarRoutes?.map((el, index) => {
              let link = el.url({ projectKey: id });

              if (characterParams.characterId) {
                link = el.url({ projectKey: id, characterId: characterParams.characterId });
              }

              if (shotEditParams.shotId && shotEditParams.sceneId) {
                link = el.url({
                  projectKey: id,
                  shotId: shotEditParams.shotId,
                  sceneId: shotEditParams.sceneId,
                });
              }

              const active = lastParam === el.routePath;
              const firstRenderActive =
                (lastParam === "" || possibleRoutes.includes(lastParam as TStoryboardRoutes)) &&
                index === 0;

              const isCommentPage = el.routePath === "comments";

              if (isShare && isCommentPage && !canComment) {
                return null;
              }

              return (
                <MenuItem
                  active={active || firstRenderActive}
                  key={index.toString(36)}
                  link={link}
                  title={el.title}
                />
              );
            })}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export const StoryboardNavigation: FC = () => {
  return (
    <HeaderLayout HeaderContent={Header}>
      <Outlet />
    </HeaderLayout>
  );
};
