import { useAtom } from "@reatom/npm-react";
import { Flex, Segmented } from "antd";
import { FC } from "react";

import {
  CHARACTERS_TITLE,
  CharacterTab,
  characterTabAtom,
  getCharactersAction,
  toCharacterList,
} from "@/entities/character";

import { CharacterButton } from "./CharacterButton";

import "./CharacterSettings.scss";

export const CharacterSettings: FC = () => {
  const [tab, setTab] = useAtom(characterTabAtom);
  const [characterList] = useAtom(getCharactersAction.dataAtom);

  const options = Object.entries(CHARACTERS_TITLE).map(([value, label]) => ({
    label,
    value,
  }));
  const characters = toCharacterList(
    tab,
    characterList.map((el) => el.id),
  );

  return (
    <Flex vertical className="character-settings gap-s full-height">
      <Segmented
        className="character-settings__tabs"
        options={options}
        onChange={(value) => setTab(value as CharacterTab)}
      />
      <Flex
        justify="center"
        align="flex-start"
        wrap="wrap"
        className="gap-xs character-settings__tabs--content"
      >
        {characters.map((character) => (
          <CharacterButton key={character.id} {...character} />
        ))}
      </Flex>
    </Flex>
  );
};
