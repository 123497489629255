import { PermissionGroup } from "../types/share.ts";

export type Option = {
  label: string;
  value: PermissionGroup;
};

export const SHARE_OPTIONS: Option[] = [
  {
    label: "can view",
    value: "view",
  },
  {
    label: "can comment",
    value: "comment",
  },
];
