import brush_16 from "assets/shared/brush_16.svg";
import { IconButtonWrapper } from "shared/ui";

export const BrushIcon = () => {
  return (
    <IconButtonWrapper>
      <img style={{ width: 16, height: 16 }} src={brush_16} alt="brush" />
    </IconButtonWrapper>
  );
};
