import { api } from "@/shared/api";
import {
  ProjectShareParams,
  CreateProjectParams,
  CreateProjectResult,
  TProjectResult,
  GetProjectResult,
} from "./types.ts";
import { TResponse } from "@/vite-env";

export const createProjectResource = (
  params: CreateProjectParams,
  controller?: AbortController
): TResponse<CreateProjectResult> => api.post("/projects", params, { signal: controller?.signal });

export const getAllProjectsResource = (): TResponse<TProjectResult> => api.get("/projects");

export const deleteProjectResource = (projectKey: string) => api.delete(`/projects/${projectKey}`);

export const getProjectResource = (
  projectKey: string,
  controller?: AbortController,
): TResponse<GetProjectResult> =>
  api.get(`/projects/${projectKey}`, { signal: controller?.signal });

export const updateProjectNameResource = (projectKey: string, newName: string) =>
  api.patch(`/projects/${projectKey}/${newName}`);

export const addThumbnailResource = (projectKey: string, formData: FormData): TResponse<string> =>
  api.post(`/projects/thumbnails/${projectKey}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const shareProjectResource = (params: ProjectShareParams) =>
  api.post("/projects/share", params);
