import { FC } from "react";
import undoIcon from "assets/character-info/undo.svg";
import { IconButtonWrapper } from "shared/ui";

export const UndoIcon: FC = () => {
  return (
    <IconButtonWrapper>
      <img src={undoIcon} alt="undo" style={{ width: 16, height: 16 }} />
    </IconButtonWrapper>
  );
};
