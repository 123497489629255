import { Button, Flex, message, Typography } from "antd";
import { FC, useEffect } from "react";
import ReactLassoSelect from "react-lasso-select";
import { TransformComponent } from "react-zoom-pan-pinch";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";

import { ShotEditPath } from "shared/types/routes.ts";
import emptyImage from "assets/shot-selection/empty-veriosns.svg";
import { useShotEditImageBehavior } from "../model";

import "./ShotEditImage.scss";

export const ShotEditImage: FC = () => {
  const { id, shotId, sceneId } = useExtractParams<ShotEditPath>();
  const [api, contextHolder] = message.useMessage();
  const {
    errorShot,
    regenerate,
    destroyKey,
    points,
    completePoints,
    zeroingPoints,
    imageUrl,
    canInpainting,
    isEmptyImage,
  } = useShotEditImageBehavior();

  useEffect(() => {
    if (errorShot?.name) {
      const onClose = () => {
        api.destroy();
      };

      api.error({
        content: (
          <Flex>
            <Flex style={{ width: 250 }} vertical gap={6} align="flex-start">
              <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>
                Something happened
              </Typography.Text>
              <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
                Please try again
              </Typography.Text>
            </Flex>
            <Flex align="center">
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  regenerate({ shotId, projectKey: id, sceneId });
                  onClose();
                }}
              >
                Regenerate
              </Button>
            </Flex>
          </Flex>
        ),
        className: "regeneration-error",
        duration: 5,
      });
    }
  }, [errorShot]);

  return (
    <Flex
      onClick={zeroingPoints}
      justify="center"
      align="center"
      className="full-height full-width flex-1"
    >
      {contextHolder}
      <Flex onClick={(event) => event.stopPropagation()} className="image-container">
        <TransformComponent wrapperClass="image-container">
          <ReactLassoSelect
            key={destroyKey}
            imageStyle={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            value={points}
            disabled={!canInpainting || isEmptyImage}
            src={imageUrl || emptyImage}
            onComplete={(value) => {
              completePoints(value);
            }}
          />
        </TransformComponent>
      </Flex>
    </Flex>
  );
};
