import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { PARTNERSHIP } from "shared/const/partnership.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";

import "./Partnership.scss";

export const Partnership: FC = () => {
  const { isTablet, isMobileXL } = useMediaQueries();

  const redirect = (link: string) => {
    const anchor = document.createElement("a");
    anchor.href = link;
    anchor.target = "_blank";
    anchor.click();
  };

  return (
    <Flex className="gap-l" vertical align="center" justify="center">
      <Typography.Text
        style={{ textTransform: "uppercase" }}
        className={cn(TEXT_CLASSNAMES.LPHeadlineH3, {
          [TEXT_CLASSNAMES.LPHeadlineH4]: isTablet,
          [TEXT_CLASSNAMES.LPHeadlineH5]: isMobileXL,
        })}
      >
        Partnership
      </Typography.Text>
      <Flex wrap="wrap" justify="center" className="gap-s">
        {PARTNERSHIP.filter((el) => el.isPartner).map(({ title, description, img, link }, index) => (
          <Flex
            onClick={() => redirect(link)}
            vertical={isTablet}
            align="center"
            key={index.toString(36)}
            className="partnership gap-s pointer"
          >
            <Flex justify={isTablet ? "center" : "start"} style={{ minWidth: 120 }}>
              <img src={img} alt={title} />
            </Flex>
            <Flex vertical gap={2}>
              <Typography.Text className={TEXT_CLASSNAMES.SmSemibold}>{title}</Typography.Text>
              <Typography.Text className={TEXT_CLASSNAMES.SmRegular}>{description}</Typography.Text>
            </Flex>
          </Flex>
        ))}
        {!isTablet && (
          <Flex
            style={{ opacity: 0 }}
            vertical={isTablet}
            align="center"
            key={PARTNERSHIP.length}
            className="partnership gap-s pointer"
          >
            <img src={""} alt={""} />
            <Flex vertical gap={2}>
              <Typography.Text className={TEXT_CLASSNAMES.SmSemibold}>{""}</Typography.Text>
              <Typography.Text className={TEXT_CLASSNAMES.SmRegular}>{""}</Typography.Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
