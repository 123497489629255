import { UserOutlined } from "@ant-design/icons";
import { useAction, useAtom } from "@reatom/npm-react";
import {
  Avatar,
  ConfigProvider,
  Flex,
  MenuProps,
  message,
  Table,
  TableProps,
  Typography,
} from "antd";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { COLOR_CLASSNAMES, COLOR_NAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { DropdownDots } from "shared/ui";
import {
  deleteTeamMemberAction,
  initTeamListAction,
  InviteNewMember,
  SearchTeamMember,
  searchTeamMemberAtom,
  teamIdAtom,
  teamListAtom,
} from "../../../../features/team-settings";
import { TEAM_MEMBER_ROLE_MAP, TTeamMember } from "../../../../features/team-settings/lib";

export const SettingsTeam: FC = () => {
  const [email, setEmail] = useState("");
  const [teamId] = useAtom(teamIdAtom);
  const [messageApi, contextHolder] = message.useMessage();
  const initTeams = useAction(initTeamListAction);
  const [teamList] = useAtom(teamListAtom);
  const [searchTeamMember] = useAtom(searchTeamMemberAtom);
  const deleteTeamMember = useAction(deleteTeamMemberAction);

  const isContainValue = (team: TTeamMember) => {
    const isContainEmail = team.email.toLowerCase().includes(searchTeamMember.toLowerCase());
    const isContainName = team.name.toLowerCase().includes(searchTeamMember.toLowerCase());

    return isContainEmail || isContainName;
  };

  const filteredTeamList = teamList.filter(isContainValue);

  const onClick = () => {
    console.log("==========>1", 1);
  };

  const onCopy = async () => {
    try {
      await navigator.clipboard.writeText(email);
      messageApi.open({
        type: "success",
        content: "Your email has been copied",
      });
    } catch (err) {
      messageApi.open({
        type: "error",
        content: "Oops, something went wrong",
      });
    }
  };

  const onDelete = () => {
    deleteTeamMember({ email, teamId });
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Flex onClick={onClick} align="center" className="gap-xxs">
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>See access</Typography.Text>
        </Flex>
      ),
    },
    {
      key: "2",
      label: (
        <Flex onClick={onCopy} align="center" className="gap-xxs">
          <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES)}>
            Copy email
          </Typography.Text>
        </Flex>
      ),
    },
    {
      key: "3",
      label: (
        <Flex onClick={onDelete} align="center" className="gap-xxs">
          <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES)}>
            Delete
          </Typography.Text>
        </Flex>
      ),
    },
  ];

  const columns: TableProps<TTeamMember>["columns"] = [
    {
      title: <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Name</Typography.Text>,
      key: "name",
      render: (teamMember: TTeamMember) => (
        <Flex align="center" className="gap-xxs">
          <Avatar
            shape="square"
            size={34}
            icon={
              teamMember.avatar ? <img src={teamMember.avatar} alt="avatar" /> : <UserOutlined />
            }
          />
          <Flex vertical>
            <Typography.Text className={TEXT_CLASSNAMES.XsBold}>{teamMember.name}</Typography.Text>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
            >
              {teamMember.email}
            </Typography.Text>
          </Flex>
        </Flex>
      ),
      width: 240,
    },
    {
      title: (
        <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Team permissions</Typography.Text>
      ),
      key: "teamPermissions",
      render: (teamMember: TTeamMember) => (
        <Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>
          {TEAM_MEMBER_ROLE_MAP[teamMember.role].title}
        </Typography.Text>
      ),
      width: 220,
    },
    {
      title: <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Project seat</Typography.Text>,
      key: "projectsCount",
      dataIndex: "projectsCount",
      render: (projectsCount) => (
        <Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>
          {projectsCount} Projects allowed
        </Typography.Text>
      ),
      width: 145,
    },
    {
      title: "",
      key: "actions",
      render: (data) => (
        <Flex justify="flex-end">
          <DropdownDots
            onMouseOver={(event) => {
              event.stopPropagation();
              setEmail(data.email);
            }}
            onClick={(event) => {
              event.stopPropagation();
              setEmail(data.email);
            }}
            style={{ background: COLOR_NAMES.MetalGray100 }}
            items={items}
          />
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    initTeams();
  }, []);

  return (
    <Flex vertical className="gap-s">
      {contextHolder}
      <Flex align="center" justify="space-between" className="full-width">
        <Flex className="gap-xs">
          <SearchTeamMember />
        </Flex>
        <InviteNewMember />
      </Flex>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: COLOR_NAMES.MetalGray100,
              colorBgContainer: COLOR_NAMES.MetalGray100,
            },
          },
        }}
      >
        <Table dataSource={filteredTeamList} columns={columns} pagination={false} />
      </ConfigProvider>
    </Flex>
  );
};
