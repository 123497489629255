import { Button, Flex, Skeleton, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";
import { PlanParams } from "../../lib";

type Props = {
  activePlanParams: PlanParams;
  onChange: (params: PlanParams) => void;
  loading: boolean;
  onTrial: () => void;
  fetching: boolean;
};

export const FreeTrialPlan: FC<Props> = ({
  loading,
  onChange,
  activePlanParams,
  onTrial,
  fetching,
}) => {
  const { isMobileL } = useMediaQueries();
  const isActive = activePlanParams.termType === "trial";

  if (loading) {
    return (
      <Flex className="plan-type">
        <Skeleton active paragraph={{ rows: 2 }} />
      </Flex>
    );
  }

  return (
    <Flex
      gap={8}
      vertical
      className={cn("plan-type cubic-animation", {
        "plan-type--active": isActive,
      })}
    >
      <Flex align={isMobileL ? "center" : "initial"}>
        <input
          id="trial"
          className="plan-type__input"
          type="radio"
          name="plan"
          onChange={() => onChange({ termType: "trial", interval: "month" })}
        />
        <span
          className={cn("plan-type__radio cubic-animation", {
            "plan-type__radio--active": isActive,
          })}
        />
        <Flex vertical className="plan-type__text">
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.MdSemibold, {
              [TEXT_CLASSNAMES.XsSemibold]: isMobileL,
            })}
          >
            7-day free trial
          </Typography.Text>
          {!isMobileL && (
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}
            >
              No credit card required
              <br />1 user, 3 500 symbols, 1 project, share & collaborate, image watermarks
            </Typography.Text>
          )}
        </Flex>
      </Flex>
      {isActive && (
        <Button
          className="xs-semibold"
          style={{
            minWidth: "max-content",
            textDecoration: "none",
            cursor: "pointer",
            zIndex: 10,
          }}
          type="primary"
          size="large"
          onClick={onTrial}
          loading={fetching}
        >
          Start free trial
        </Button>
      )}
    </Flex>
  );
};
