import { useAction, useAtom } from "@reatom/npm-react";
import { Form, message, Upload } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { RcFile } from "antd/es/upload/interface";
import {analyzeScriptAction, newProjectAtom, resetDataAction, uploadStatusAtom} from "./addNewProjectModel";
import {useExtractParams} from "@/shared/hooks/use-extract-params.ts";
import {ScriptPath} from "shared/types/routes.ts";
import {isObjectEmpty} from "shared/methods";
import {getFileType} from "@/shared/methods/get-file-type.ts";
import {addScriptToProjectAction} from "@/entities/script";
import {createProjectAction} from "@/entities/projects";

export const useBehavior = () => {
  const { id } = useExtractParams<ScriptPath>();
  const analyzeScript = useAction(analyzeScriptAction);
  const [analyzeStatuses] = useAtom(analyzeScriptAction.statusesAtom);
  const [uploadStatus, setUploadStatus] = useAtom(uploadStatusAtom);
  const uploadScript = useAction(addScriptToProjectAction);
  const createProject = useAction(createProjectAction);
  const [createProjectStatuses] = useAtom(createProjectAction.statusesAtom);
  const resetData = useAction(resetDataAction);

  const [form] = Form.useForm();
  const script: UploadChangeParam | null = Form.useWatch("script", { form });

  const [newProject, setNewProject] = useAtom(newProjectAtom);

  const briefing = Form.useWatch("briefing", { form });
  const getScriptIsEmpty = () => {
    return !script || isObjectEmpty(script) || !script.fileList?.length;
  };
  const isEmptyFields = !briefing && getScriptIsEmpty();

  const onChange = async () => {
    try {
      if (isEmptyFields) {
        await form.validateFields();
      }
    } catch (e) {
    }
  };

  const onRemove = () => {
    form.setFieldValue("script", null);
    setNewProject({
      ...newProject,
      script: null,
    });
  };

  const beforeUpload = async (file: RcFile) => {
    const isLt16M = (file?.size ?? 0) / 1024 / 1024 < Number(process.env.VITE_IMAGE_LOAD_LIMIT_MB);
    if (isLt16M) {
      if (file) {
        setUploadStatus("uploading")
        const formData = new FormData();
        const fileType = getFileType(file);
        formData.append("file", file);
        setNewProject({
          ...newProject,
          script: file,
        });
        try {
          await analyzeScript({ projectKey: id, formData, fileType });
          setUploadStatus("done")
        } catch (e) {
          setUploadStatus("error")
        }
      }
    } else {
      message.error(`File must be smaller than ${process.env.VITE_IMAGE_LOAD_LIMIT_MB}MB!`);
      return Upload.LIST_IGNORE;
    }

    return false;
  };

  return {
    onRemove,
    onChange,
    newProject,
    form,
    isEmptyFields,
    beforeUpload,
    analyzeStatuses,
    uploadStatus,
    uploadScript,
    createProject,
    createProjectStatuses,
    resetData
  };
};
