import { useAction, useAtom } from "@reatom/npm-react";
import { captureException, setUser } from "@sentry/react";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";

import {
  applyPromoCodeResource,
  createSetupIntentResource,
  subscribeCustomerResource,
  subscribeTrialCustomerResource,
} from "@/shared/api/payment";
import { getPromoCodeAction } from "@/entities/plan";
import { isGuestAtom } from "@/entities/viewer";

import { ApplyPromoCodeParams, PlanParams, sleep } from "../lib";
import { useStore } from "./useStore.ts";

export const useBehavior = () => {
  const navigate = useNavigate();
  const getPromoCode = useAction(getPromoCodeAction);

  const [planParams, setPlanParams] = useState<PlanParams>({
    termType: "trial",
    interval: "month",
  });
  const [loading, setLoading] = useState(false);
  const [isGuest] = useAtom(isGuestAtom);

  const {
    getPossibleSubscriptions,
    possibleSubscriptions,
    logout,
    promoCode,
    getSubscription,
    sessionAuth,
    subscription,
    callError,
    viewer,
    clearPromoCode,
  } = useStore();

  const onApplyPromoCode = async ({ userPromoCode, subscriptionId }: ApplyPromoCodeParams) => {
    let code = userPromoCode;

    if (userPromoCode === "free-blooper-tier") {
      code = "FREE-BLOOPER-TIER";
    }

    await getPromoCode(code as string, subscriptionId);
  };

  const startUsing = async (productId: number) => {
    try {
      setLoading(true);
      const interval = planParams.interval;

      await subscribeCustomerResource({
        interval,
        productId,
        promoCode: "",
      });

      if (promoCode?.percent_off) {
        if (promoCode.percent_off === 100) {
          await applyPromoCodeResource(promoCode.code);

          TagManager.dataLayer({
            dataLayer: {
              event: "start_subscription_100_percent_off_promo_code",
            },
          });

          navigate("/payment-success");
        } else {
          await applyPromoCodeResource(promoCode.code);

          navigate("/payment");
        }
      } else {
        navigate(`/promo-code/${productId}`);
      }
    } catch (e) {
      callError(e);

      setUser({
        email: viewer.email,
      });

      captureException(e, {
        tags: {
          feature: "start-subscription",
        },
        level: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const changePlanParams = (params: PlanParams) => {
    setPlanParams(params);
  };

  const startTrial = async () => {
    try {
      setLoading(true);
      await subscribeTrialCustomerResource().then(() => {
        TagManager.dataLayer({
          dataLayer: {
            event: "start_trial_subscription",
            trialEmail: viewer.email,
          },
        });
        sleep(1000);
      });

      await getSubscription();

      sessionAuth({ isGuest: false, email: viewer.email });

      navigate("/projects");
    } catch (e) {
      callError(e);

      setUser({
        email: viewer.email,
      });

      captureException(e, {
        tags: {
          feature: "start-trial-subscription",
        },
        level: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const startSubscriptionWithTrial = async (productId: number) => {
    try {
      setLoading(true);

      const stripePromise = loadStripe(process.env.VITE_STRIPE_KEY, { locale: "en" });
      localStorage.setItem("interval", planParams.interval);
      localStorage.setItem("blooper_subscription_id", productId.toString());
      localStorage.setItem("promocode", promoCode?.code ?? "");

      const params = {
        success_url: `${window.location.origin}/trial-move-success`,
        fail_url: `${window.location.origin}/trial-move-fail`,
      };

      const { data } = await createSetupIntentResource(params);
      localStorage.setItem("session_id", data.session_id);
      const stripe = await stripePromise;

      await stripe?.redirectToCheckout({
        sessionId: data.session_id,
      });

      TagManager.dataLayer({
        dataLayer: {
          event: "subscription",
          subscriptionAmount: "",
          subscriptionCurrency: "",
        },
      });
    } catch (e) {
      callError(e);

      setUser({
        email: viewer.email,
      });

      captureException(e, {
        tags: {
          service: "stripe",
          feature: "start-subscription-with-trial",
        },
        level: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        if (!isGuest) {
          await getSubscription();
        }
      } catch (e) {
        setUser({
          email: viewer.email,
        });

        captureException(e, {
          tags: {
            feature: "init-user-subscription",
          },
          level: "error",
        });
      } finally {
        getPossibleSubscriptions();
      }
    };

    init();

    return clearPromoCode;
  }, []);

  const isShowTrial = subscription?.trial_status === "no_trial" || subscription === null;

  return {
    planParams,
    loading,
    startUsing: subscription?.trial_status === "on_trial" ? startSubscriptionWithTrial : startUsing,
    changePlanParams,
    startTrial,
    logout,
    possibleSubscriptions,
    isShowTrial: !isGuest && isShowTrial,
    onApplyPromoCode,
  };
};
