import { TeamMemberPositionMap, TeamMemberRoleMap } from "./types";

export const TEAM_MEMBER_MAP: TeamMemberPositionMap = {
  assistant_director: {
    color: "",
    title: "Assistant director",
  },
  editor: {
    color: "",
    title: "Editor",
  },
  line_producer: {
    color: "",
    title: "Line producer",
  },
  screenwriter: {
    color: "",
    title: "Screenwriter",
  },
  sound_designer: {
    color: "",
    title: "Sound designer",
  },
  cinematographer: {
    color: "",
    title: "Cinematographer",
  },
  production_designer: {
    color: "",
    title: "Production designer",
  },
  costume_designer: {
    color: "",
    title: "Costume designer",
  },
  location_manager: {
    color: "",
    title: "Location manager",
  },
  casting_director: {
    color: "",
    title: "Casting director",
  },
  art_director: {
    color: "",
    title: "Art director",
  },
};

export const TEAM_MEMBER_ROLE_MAP: TeamMemberRoleMap = {
  admin: {
    color: "",
    title: "Admin",
  },
  collaborator: {
    color: "",
    title: "User",
  },
};
