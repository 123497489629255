import { useAtom } from "@reatom/npm-react";
import { Flex } from "antd";

import { FC } from "react";

import { getShotAction } from "@/entities/shot-edit";

import {
  ShotCameraAngleEdit,
  ShotDescriptionEdit,
  ShotPropsEdit,
  ShotCameraMovementEdit,
  ShotTitle,
  ShotLocation,
  ShotCharacters,
} from "./edit-components";

import "./ShotDetailsInfo.scss";

export const ShotDetailsInfo: FC = () => {
  const [shot] = useAtom(getShotAction.dataAtom);

  return (
    <Flex vertical className="shot-details-info">
      <ShotTitle title={shot?.title ?? ""} />
      <ShotLocation
        location={shot?.location}
        time={shot?.time}
        characteristics={shot?.characteristics}
      />
      <ShotDescriptionEdit />
      <ShotPropsEdit />
      <ShotCameraAngleEdit />
      <ShotCameraMovementEdit />
      <ShotCharacters />
    </Flex>
  );
};
