import cn from "classnames";
import { FC, HTMLAttributes } from "react";

import "./Spinner.scss";

interface ISpinner extends HTMLAttributes<HTMLDivElement> {
  isOverlay?: boolean;
}

export const Spinner: FC<ISpinner> = ({ isOverlay, ...props }) => (
  <>
    <div
      className={cn("spinner-overlay", {
        "spinner-overlay__open": isOverlay,
      })}
    />
    <span
      {...props}
      className={cn("spinner", props.className, {
        spinner__overlay: isOverlay,
      })}
    />
  </>
);
