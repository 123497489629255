import { Collapse, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import arrow from "assets/shared/arrow_left_24.svg";
import { IOpenModal } from "shared/hooks";

import { ExpandIcon } from "./ExpandIcon.tsx";
import { Label } from "./Label.tsx";
import { PagesToggles } from "./PagesToggle.tsx";
import { ShareProject } from "./ShareProject.tsx";

import "./ShareProjectModal.scss";

type Props = IOpenModal & {
  projectKey: string;
  toggleManageTeam: (value?: boolean) => void;
};

export const InviteGuestModal: FC<Props> = ({
  isOpen,
  toggleModal,
  projectKey,
  toggleManageTeam,
}) => {
  const onBack = () => {
    toggleModal(false);
    toggleManageTeam(true);
  };

  return (
    <Modal footer={[]} open={isOpen} onCancel={() => toggleModal(false)}>
      <Flex vertical className="share-modal gap-m">
        <Flex vertical className="gap-xxs">
          <Flex className="gap-xs" align="center">
            <div role="button" onClick={onBack} className="navigation-button pointer">
              <img src={arrow} alt="arrow left" />
            </div>
            <Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>
              Invite guest to project
            </Typography.Text>
          </Flex>
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}
          >
            You can invite anyone to the project and they will have access to view your project. You
            can remove guest from the project at any time.
          </Typography.Text>
        </Flex>
        <Flex vertical className="gap-xxs">
          <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Share pages</Typography.Text>
          <Collapse
            expandIconPosition="end"
            className="collapse-share"
            expandIcon={() => <ExpandIcon />}
            size="large"
            items={[
              {
                key: "1",
                label: <Label />,
                children: <PagesToggles />,
              },
            ]}
          />
        </Flex>
        <ShareProject projectKey={projectKey} />
      </Flex>
    </Modal>
  );
};
