import { useAction, useAtom } from "@reatom/npm-react";
import { Typography, notification, Flex } from "antd";
import { FC } from "react";

import { SHARE_OPTIONS } from "shared/const/share.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { PermissionGroup } from "shared/types/share.ts";
import { InviteEmail, InviteMemberFieldType } from "shared/ui";
import { shareProjectAction } from "@/entities/projects";
import { activeSharingPagesAtom, isSomeAtom } from "../model";
import { NotificationError } from "@/assets/components/NotificationError";

type Props = {
  projectKey: string;
};

export const ShareProject: FC<Props> = ({ projectKey }) => {
  const [api, contextHolder] = notification.useNotification();
  const [sharingPages] = useAtom(activeSharingPagesAtom);
  const [isButtonDisabled] = useAtom(isSomeAtom);
  const shareProject = useAction(shareProjectAction);

  const onShare = async (params: InviteMemberFieldType) => {
    try {
      await shareProject({
        projectShareParams: sharingPages,
        projectKey,
        userEmail: params.email,
        permissionGroup: params.select as PermissionGroup,
      });

      api.success({
        message: (
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
            Invitation was sent
          </Typography.Text>
        ),
        placement: "top",
        closeIcon: null,
        className: "scene-added-notification",
        style: { width: 225 },
      });
    } catch (e) {
      api.warning({
        message: (
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
            This user has already been invited to the project.
          </Typography.Text>
        ),
        placement: "top",
        closeIcon: null,
        className: "scene-added-notification",
        style: { width: 300 },
        icon: <NotificationError />,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Flex vertical className="gap-xxs">
        <InviteEmail
          title=""
          isButtonDisabled={isButtonDisabled}
          options={SHARE_OPTIONS}
          onFinish={onShare}
          placeholder="Permission"
        />
      </Flex>
    </>
  );
};
