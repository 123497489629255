import { FC } from "react";
import { Flex, Popover, Typography } from "antd";
import closeEye from "assets/storyboard/close-eye.svg";
import eye from "assets/storyboard/eye.svg";
import { EditButton } from "@/entities/storyboard";
import { useShotStoryboard } from "@/entities/storyboard";

import { PopoverContent } from "../PopoverContent";
import { SceneShotDetailsProps } from "../model";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";

export const SceneShotDetails: FC<SceneShotDetailsProps> = ({ 
  id, 
  sceneId, 
  isShowDetails, 
  setIsShowDetails,
  cameraAngle,
  props,
  cameraMovement,
  shotSettings,
}) => {
  const { onRedirect } = useShotStoryboard();

  return (
    <Flex style={{ padding: 16 }} className="gap-xxs">
      <Popover
        placement="topLeft"
        content={
          <PopoverContent
            cameraAngle={cameraAngle}
            props={props}
            cameraMovement={cameraMovement}
            shotSettings={shotSettings}
          />
        }
        trigger="click"
        open={isShowDetails}
        onOpenChange={(open) => setIsShowDetails(open)}
      >
        <Flex
          onClick={() => setIsShowDetails(!isShowDetails)}
          role="button"
          justify="space-between"
          align="center"
          className="scene-shot-grid__details gap-4 pointer"
        >
          <img
            src={isShowDetails ? closeEye : eye}
            alt={isShowDetails ? "Hide details" : "Show details"}
          />
          <span className={TEXT_CLASSNAMES.XsRegular}>
            {isShowDetails ? "Hide details" : "Show details"}
          </span>
        </Flex>
      </Popover>
      <EditButton isActive onClick={() => onRedirect({ shotId: id, sceneId })}>
        <Typography className={TEXT_CLASSNAMES.XsRegular}>Edit shot</Typography>
      </EditButton>
    </Flex>
  );
};