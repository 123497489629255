import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import ReactGA from "react-ga4";

import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";
import blooperExamplePDF from "assets/blooper-example.pdf";
import pitch from "assets/use-cases/video/pitch.png";
import preview from "assets/use-cases/video/preview.png";
import storyboard from "assets/use-cases/video/storyboard.png";
import themes from "assets/use-cases/video/themes.png";
import { useToTop } from "shared/hooks";
import { LandingContent, TrialButton } from "shared/ui";
import {
  UseCaseBlock,
  UseCaseContent,
  UseCaseFooter,
  UseCaseTitle,
  UseCaseWrapper,
} from "@/entities/use-cases";
import { Header } from "../../widgets/header";

import "./UseCaseVideo.scss";

export const UseCaseVideo: FC = () => {
  useToTop();
  const { isMobileXL, isLaptopM } = useMediaQueries();
  const onButtonClick = () => {
    const link = document.createElement("a");
    link.href = blooperExamplePDF;
    link.download = "Blooper example.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    ReactGA.event({
      category: "pdf download",
      action: "download pdf from use case storyboard",
    });
  };

  return (
    <Flex vertical>
      <LandingContent>
        <Header />
      </LandingContent>
      <UseCaseTitle
        isComingSoon
        description="Polish your ideas and share a ready-to-present deck in minutes"
        italicHeaderText="Ad Campaigns"
        headerText="Create Custom Pitch Decks for"
        isBrItalic
        style={{ maxWidth: 690 }}
      />
      <UseCaseBlock
        previewImg={preview}
        title="Movie pitch deck"
        description={
          <Flex vertical className="gap-xs">
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextWhitePrimary, {
                [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
                [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
              })}
            >
              <ul
                className="list-white"
                style={{ display: "flex", flexDirection: "column", gap: 4 }}
              >
                <li>
                  Create polished pitch decks faster by selecting pre-production materials,
                  adjusting layouts, and customizing colours.
                </li>
                <li>
                  Save time, streamline approvals, and focus on delivering more projects with
                  clarity and confidence.
                </li>
              </ul>
            </Typography.Text>
          </Flex>
        }
      />
      <UseCaseWrapper>
        <Flex className="gap-m">
          <UseCaseContent
            flex={1}
            title="Storyboard views"
            description="Explore your storyboard from multiple perspectives—shot lists, tiled layouts, and more."
          >
            <Flex vertical className="gap-m">
              <TrialButton onClick={onButtonClick} text="View example PDF" />
              <img src={storyboard} alt="storyboard" className="image-contain video-block-img__1" />
            </Flex>
          </UseCaseContent>
        </Flex>
        <Flex vertical={isMobileXL} className="gap-m">
          <UseCaseContent
            flex={1}
            title="Different themes and preview"
            description={
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.MdRegular, "text-left", {
                  [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
                  [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
                })}
              >
                <ul
                  className="list-black"
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <li>
                    Easily adapt the theme of your pitch deck to align with your project’s vision
                    and style, ensuring that it is tailored to your needs.
                  </li>
                </ul>
              </Typography.Text>
            }
          >
            <img src={themes} alt="themes" className="image-contain video-block-img__2" />
          </UseCaseContent>
          <UseCaseContent
            id="pitch"
            flex={1}
            title="Pitch deck customization"
            description={
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.MdRegular, "text-left", {
                  [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
                  [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
                })}
              >
                <ul
                  className="list-black"
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <li>
                    Select the specific pre-production documents to include in your pitch deck and
                    customize the color scheme to create a presentation that truly reflects your
                    project's identity.
                  </li>
                </ul>
              </Typography.Text>
            }
          >
            <img src={pitch} alt="pitch" className="image-contain video-block-img__3" />
          </UseCaseContent>
        </Flex>
      </UseCaseWrapper>
      <UseCaseFooter />
    </Flex>
  );
};
