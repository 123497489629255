import { Button, Flex } from "antd";
import { FC } from "react";

type Props = {
  onGenerate: () => void;
  disabled: boolean;
};

export const Generate: FC<Props> = ({ onGenerate, disabled }) => {
  return (
    <Flex style={{ padding: 16 }} className="full-width">
      <Button
        disabled={disabled}
        type="primary"
        className="flex-ctr-ctr full-width"
        onClick={onGenerate}
        size="large"
      >
        Generate
      </Button>
    </Flex>
  );
};
