import { useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { useState } from "react";
import { useProjectType } from "@/entities/projects";
import { getSceneListAction, sceneListAtom } from "@/entities/script";
import { SceneTitle } from "../scene-title";
import { Shot } from "../shot";
import { ShotSkeleton } from "../shot-skeleton";

const fakeSkeletons = [...Array(10)];

export const SceneList = () => {
  const [status] = useAtom(getSceneListAction.statusesAtom);
  const [sceneList] = useAtom(sceneListAtom);
  const { isShare } = useProjectType();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  const lastShots = sceneList[sceneList.length - 1]?.shots;
  const lastId = lastShots?.[lastShots.length - 1]?.id;

  if (status.isPending) return fakeSkeletons.map((el) => <ShotSkeleton key={el} />);

  return sceneList.map((scene) => (
    <Flex vertical className="highlight-the-shots__scene" key={scene.id}>
      <SceneTitle sceneId={scene.id} idx={scene.idx} title={scene.title} />
      {scene.shots.map((shot) => (
        <section
          key={`${shot.id}-${shot.description}`}
          id={`${shot.id}`}
          style={{ marginBottom: isShare ? 32 : 0 }}
        >
          <Shot
            key={`${shot.id}-${shot.description}`}
            sceneId={scene.id}
            shotId={shot.id}
            color={shot.color}
            title={shot.title}
            content={shot.prompt}
            shotIdx={shot.idx}
            isAddButtonDisabled={isAddButtonDisabled}
            setIsAddButtonDisabled={setIsAddButtonDisabled}
            sceneName={scene.title}
            projectLastShotId={lastId}
            shotsLength={lastShots.length}
            description={shot.description}
          />
        </section>
      ))}
    </Flex>
  ));
};
