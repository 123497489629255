import { Flex, Typography } from "antd";
import { FC, MouseEvent } from "react";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";

interface Props {
  onClick: () => void;
}

export const UnresolveComment: FC<Props> = ({ onClick }) => {
  const onLabelClick = (callback: () => void) => (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    callback();
  };
  return (
    <Flex onClick={onLabelClick(onClick)} align="center" className="gap-xxs full-width">
      <Typography.Text className={TEXT_CLASSNAMES.Body10}>Mark as unresolved</Typography.Text>
    </Flex>
  );
};
