import { useAction, useAtom } from "@reatom/npm-react";
import { Switch } from "antd";
import { FC } from "react";
import { isAllAtom, toggleAllAction } from "../model";

export const ExpandIcon: FC = () => {
  const [isAll] = useAtom(isAllAtom);
  const toggleAll = useAction(toggleAllAction);

  return <Switch checked={isAll} onChange={toggleAll} />;
};
