import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { DragEvent, FC } from "react";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { MoodboardPath } from "shared/types/routes.ts";
import emptyMoodboard from "assets/moodboard/empty-moodboard.svg";
import addImage from "assets/shared/add_image.svg";
import { useOpenModal } from "shared/hooks";
import { emptyClick } from "shared/methods";
import { IconButtonWrapper } from "shared/ui";
import { DRAGGABLE_IMAGE_ID, isDroppableAtom } from "@/entities/inspiration-board";
import { dropImageFromSidebarsAction, moodboardLoadingAtom } from "@/entities/moodboard";
import { useProjectType } from "@/entities/projects";
import { AddImageMoodboard } from "../../add-image-moodboard";

import "./EmptyMoodboard.scss";

export const EmptyMoodboard: FC = () => {
  const params = useExtractParams<MoodboardPath>();

  const [isOpenAddImageModal, toggleAddImageModal] = useOpenModal();
  const dropImageFromSidebars = useAction(dropImageFromSidebarsAction);
  const { isShare } = useProjectType();

  const [isDroppable, toggleDroppable] = useAtom(isDroppableAtom);
  const [_, setIsLoading] = useAtom(moodboardLoadingAtom);

  const handleDrop = async (event: DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
    const id = event.dataTransfer.getData(DRAGGABLE_IMAGE_ID);
    setIsLoading(true);

    if (id) {
      await dropImageFromSidebars({ dropId: id, id: params.id });
    } else {
      const filesLength = event.dataTransfer.files.length;
      for (let i = 0; i < filesLength; i++) {
        const file = event.dataTransfer.files.item(i);
        const formData = new FormData();
        if (file) {
          formData.append("images", file);

          // const newId = await dropImage(params.id, formData, imageFromSearchBoard.coordinates);
          // addToMoodBoard({ ...imageFromInspirationBoard, id: newId ?? imageFromInspirationBoard.id });

          console.log("==========>file", file);
        }
      }
    }

    setIsLoading(false);
    toggleDroppable(false);
  };

  const enableDropping = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    toggleDroppable(true);
  };

  return (
    <Flex className="empty-moodboard" vertical align="center" justify="center">
      <Flex
        onDragOver={isShare ? emptyClick : enableDropping}
        onDrop={isShare ? emptyClick : handleDrop}
        vertical
        align="center"
        className={cn("gap-xxs", {
          "empty-moodboard__dropping": isDroppable,
          "non-pointer-events": isShare,
        })}
      >
        <img
          draggable={false}
          className="empty-moodboard__image"
          src={emptyMoodboard}
          alt="empty moodboard"
        />
        {!isShare && (
          <>
            <Typography.Text className={TEXT_CLASSNAMES.Display60}>
              Your Moodboard is empty
            </Typography.Text>
            <Flex className="empty-moodboard__content" vertical justify="center" align="center">
              <Typography.Text className={TEXT_CLASSNAMES.Body30}>
                To fill it, drag pictures from the inspiration board or add image from your computer
              </Typography.Text>
            </Flex>
          </>
        )}

        <Flex justify="center" align="center" className="gap-xs">
          {!isShare && (
            <>
              <Button
                className="empty-moodboard__button"
                type="primary"
                size="middle"
                icon={
                  <IconButtonWrapper>
                    <img style={{ width: 20, height: 20 }} src={addImage} alt="add image" />
                  </IconButtonWrapper>
                }
                onClick={() => toggleAddImageModal()}
              >
                <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Add Image</Typography.Text>
              </Button>
              {/* <Button
                className="empty-moodboard__button"
                type="primary"
                icon={<img src={addDescription} alt="Add Brief" />}
                onClick={() => toggleAddBriefModal()}
              >
                <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Add Brief</Typography.Text>
              </Button> */}
            </>
          )}
        </Flex>
      </Flex>
      {/* <AddBriefMoodboard isOpen={isOpenAddBriefnModal} toggleModal={toggleAddBriefModal} /> */}
      <AddImageMoodboard isOpen={isOpenAddImageModal} toggleModal={toggleAddImageModal} />
    </Flex>
  );
};
