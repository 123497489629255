import { ConfigProvider } from "antd";
import { FC, PropsWithChildren } from "react";
import {
  COLOR_NAMES,
  ERROR_TOKEN,
  GRAYSCALE_TOKEN,
  PRIMARY_TOKEN,
  WARNING_TOKEN,
} from "shared/const/color-names";

export const AntDProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            fontFamily: "Avenir",
            fontSize: 20,
            colorText: COLOR_NAMES.TextPrimary,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            lineHeight: "26px",

            colorLink: GRAYSCALE_TOKEN.TEXT_ICON.TITLE.color,
            colorLinkHover: GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.color,
            colorLinkActive: GRAYSCALE_TOKEN.TEXT_ICON.TITLE.color,

            colorSuccessText: PRIMARY_TOKEN.TEXT_ICON.LINK_LABEL.color,

            colorWarningText: WARNING_TOKEN.TEXT_ICON.LINK_LABEL.color,

            colorSecondaryText: GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.color,

            colorError: ERROR_TOKEN.TEXT_ICON.LINK_LABEL.color,
          },
          Button: {
            defaultBg: "transparent",
            fontFamily: "Avenir",

            // default
            defaultHoverColor: PRIMARY_TOKEN.TEXT_ICON.LINK_LABEL.color,
            defaultHoverBorderColor: GRAYSCALE_TOKEN.BORDER.DEFAULT_DARK.color,

            // primary
            colorPrimary: PRIMARY_TOKEN.SURFACE.DEFAULT.color,
            colorPrimaryHover: PRIMARY_TOKEN.SURFACE.DEFAULT_LIGHT.color,
            colorPrimaryActive: PRIMARY_TOKEN.SURFACE.DEFAULT_DARK.color,
            primaryColor: GRAYSCALE_TOKEN.TEXT_ICON.TITLE.color,

            // big button
            controlHeightLG: 36,
            contentFontSizeLG: 14,
            contentLineHeightLG: 20,

            // medium button
            controlHeight: 36,
            contentLineHeight: 14,
            contentFontSize: 20,

            // small button
            controlHeightSM: 24,
            contentFontSizeSM: 12,
            borderRadiusSM: 6,
            paddingBlockSM: 10,
            paddingInlineSM: 8,

            // algorithm: true,
          },
          Input: {
            paddingBlock: 8,
            paddingInline: 16,
            activeBorderColor: COLOR_NAMES.BrandGreen500,
            hoverBorderColor: COLOR_NAMES.BrandGreen400,
            borderRadius: 8,
            colorBorder: COLOR_NAMES.TextTertiary,
            activeBg: "transparent",
            colorBgContainer: "transparent",
            // @ts-ignore
            lineHeight: "18px",
          },
          Tabs: {
            itemSelectedColor: COLOR_NAMES.TextPrimary,
            itemColor: COLOR_NAMES.TextSecondary,
            itemHoverColor: COLOR_NAMES.BrandGreen500,
            inkBarColor: COLOR_NAMES.BrandGreen500,
            titleFontSize: 12,
            horizontalItemPadding: "8px 12px",
            horizontalItemGutter: 8,
          },
          Form: {
            itemMarginBottom: 0,
          },
          Upload: {
            colorPrimaryHover: COLOR_NAMES.BrandGreen600,
          },
          Steps: {
            // finishIconBorderColor: COLOR_NAMES.TextBrand,
            colorPrimary: COLOR_NAMES.BrandGreen500,
          },
          Progress: {
            defaultColor: COLOR_NAMES.TextBrand,
            colorSuccess: COLOR_NAMES.TextBrand,
            marginXS: 0,
          },
          Select: {
            colorPrimary: COLOR_NAMES.BrandGreen500,
            optionSelectedBg: COLOR_NAMES.BrandGreen100,
            colorPrimaryHover: COLOR_NAMES.BrandGreen400,
            colorText: COLOR_NAMES.TextPrimary,
            colorTextPlaceholder: COLOR_NAMES.TextTertiary,
            fontFamily: "Avenir",
            colorBgContainer: "transparent",
            controlHeight: 36,
            colorBorder: COLOR_NAMES.MetalGray400,
          },
          Radio: {
            colorPrimary: COLOR_NAMES.BrandGreen500,
            algorithm: true,
            controlHeight: 36,
          },
          Modal: {
            contentBg: COLOR_NAMES.White100,
          },
          Popover: {
            colorBgElevated: COLOR_NAMES.MetalGray50,
          },
          Tag: {
            defaultBg: GRAYSCALE_TOKEN.SURFACE.DEFAULT_LIGHT.color,
            borderRadiusSM: 16,
            marginXS: 0,
            colorText: GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.color,
            fontFamily: "Avenir",
            colorBorder: "transparent",
          },
          Table: {
            borderColor: COLOR_NAMES.MetalGray300,
            headerBg: COLOR_NAMES.BackgroundWhite,
            headerColor: COLOR_NAMES.TextSecondary,
            fontSize: 13,
            fontFamily: "Avenir",
            fontWeightStrong: 500,
          },
          Menu: {
            colorSplit: COLOR_NAMES.MetalGray400,
            horizontalItemSelectedColor: COLOR_NAMES.BrandGreen600,
          },
          Divider: {
            colorSplit: COLOR_NAMES.MetalGray300,
          },
          Switch: {
            colorPrimary: COLOR_NAMES.BrandGreen500,
            colorPrimaryHover: COLOR_NAMES.BrandGreen400,
          },
          Segmented: {
            itemSelectedBg: GRAYSCALE_TOKEN.SURFACE.DEFAULT_LIGHT.color,
            trackPadding: 2,
            trackBg: GRAYSCALE_TOKEN.SURFACE.DEFAULT.color,

            borderRadiusSM: 6,
            controlHeight: 20,
            fontSize: 12,
          },
          Empty: {
            colorTextDescription: COLOR_NAMES.MetalGray400,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
