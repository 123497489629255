import { ProjectPermission } from "@/shared/api/project";
import { PermissionPage, PermissionGroup, TPages } from "shared/types/share.ts";

const addToPermissionsArray = (
  page: PermissionPage,
  permissionGroup: PermissionGroup,
): ProjectPermission[] => {
  if (permissionGroup === "view") {
    return [
      {
        permission_group_name: "view",
        permission_page: page,
      },
    ];
  }

  if (permissionGroup === "comment") {
    return [
      {
        permission_group_name: "view",
        permission_page: page,
      },
      {
        permission_group_name: "comment",
        permission_page: page,
      },
    ];
  }

  return [];
};

export const getPermissionParams = (params: TPages, permissionName: PermissionGroup) => {
  let permissions: ProjectPermission[] = [];

  if (params.storyboards) {
    permissions = [...permissions, ...addToPermissionsArray("storyboards", permissionName)];
  }

  if (params.script) {
    permissions = [...permissions, ...addToPermissionsArray("script", permissionName)];
  }

  if (params.characters) {
    permissions = [...permissions, ...addToPermissionsArray("characters", permissionName)];
  }

  if (params.moodboard) {
    permissions = [...permissions, ...addToPermissionsArray("moodboard", permissionName)];
  }

  return permissions;
};
