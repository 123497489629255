export const TEXT_CLASSNAMES = {
  LPHeadlineH1: "lp-headline-h1",
  LPHeadlineH2: "lp-headline-h2",
  LPHeadlineH3: "lp-headline-h3",
  LPHeadlineH4: "lp-headline-h4",
  LPHeadlineH5: "lp-headline-h5",

  LgRegular: "lg-regular",
  MdRegular: "md-regular",
  SmRegular: "sm-regular",
  XsRegular: "xs-regular",

  XxsRegular: "xxs-regular",

  LgLight: "lg-light",
  MdLight: "md-light",
  SmLight: "sm-light",
  XsLight: "xs-light",
  XxsLight: "xxs-light",

  LgSemibold: "lg-semibold",
  MdSemibold: "md-semibold",
  SmSemibold: "sm-semibold",
  XsSemibold: "xs-semibold",
  XxsSemibold: "xxs-semibold",

  LgBold: "lg-bold",
  MdBold: "md-bold",
  SmBold: "sm-bold",
  XsBold: "xs-bold",
  XxsBold: "xxs-bold",

  HeadlineH1: "headline-h1",
  HeadlineH2: "headline-h2",
  HeadlineH3: "headline-h3",
  HeadlineH4: "headline-h4",

  Display100: "display-100",
  Display90: "display-90",
  Display80: "display-80",
  Display70: "display-70",
  Display60: "display-60",

  Title50: "title-50",
  Title40: "title-40",
  Title30: "title-30",
  Title20: "title-20",
  Title10: "title-10",

  Link50: "link-50",
  Link40: "link-40",
  Link30: "link-30",
  Link20: "link-20",
  Link10: "link-10",

  Subtitle50: "subtitle-50",
  Subtitle40: "subtitle-40",
  Subtitle30: "subtitle-30",
  Subtitle20: "subtitle-20",
  Subtitle10: "subtitle-10",

  Body50: "body-50",
  Body40: "body-40",
  Body30: "body-30",
  Body20: "body-20",
  Body10: "body-10",

  Caption50: "caption-50",
  Caption40: "caption-40",
  Caption30: "caption-30",
  Caption20: "caption-20",
  Caption10: "caption-10",
};
