import { useSize } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { BackgroundPreLogin, LoginWrapper } from "@/entities/viewer";

const text =
  "\n" +
  "\n" +
  "\n" +
  "This Acceptable Use Policy is a part of the Terms and Conditions (<https://blooper.ai/terms-and-conditions>) and describes what you can do and what you cannot do using our service. Please read this document carefully to know how to use Blooper in a proper way.\n" +
  "\n" +
  "\n" +
  "# **You should:**\n" +
  "\n" +
  "1. comply with the Terms and Conditions (https\\://blooper.ai/terms-and-conditions), including this Acceptable Use Policy, and comply with any law applicable to you;\n" +
  "\n" +
  "2. take reasonable measures to protect your account; keep your login and password safe; \n" +
  "\n" +
  "3. access our service only through the interface provided on [www.blooper.ai](https://blooper.ai/).\n" +
  "\n" +
  "\n" +
  "# **You shouldn’t:**\n" +
  "\n" +
  "1. upload to the service any unlawful or offensive content;\n" +
  "\n" +
  "2. give an access to your account to any person;\n" +
  "\n" +
  "3. attempt to gain unauthorized access to service or to other user accounts;\n" +
  "\n" +
  "4. attempt to disrupt or overwhelm the service by sending unreasonable requests;\n" +
  "\n" +
  "5. attempt to reverse engineer, decompile, hack, disable, interfere with, disassemble, modify, copy, translate, or disrupt the features, functionality, integrity, or performance of the service; \n" +
  "\n" +
  "6. transmit malware or other harmful software or host phishing pages;\n" +
  "\n" +
  "7. use the service to violate the privacy of other users, including posting private and confidential information of other users, without their express permission;\n" +
  "\n" +
  "8. use the Services for consumer purposes.\n" +
  "\n" +
  "\n" +
  "# **Contacts**\n" +
  "\n" +
  "For any legal questions, or questions regarding Acceptable Use Policy please contact us at <legal@blooper.ai>.\n" +
  "\n" +
  "For service support and for other questions you may contact us at <info@blooper.ai>.\n";

export const AcceptableUsePolicy: FC = () => {
  const size = useSize(() => document.querySelector(".login-wrapper"));

  return (
    <BackgroundPreLogin>
      <LoginWrapper isChildrenFullHeight title="" questionText="" actionText="" action={() => {}}>
        <Flex vertical gap={8}>
          <Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH4)}>
            Acceptable Use Policy - Blooper
          </Typography.Text>
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}
          >
            Last Updated: 28.10.2024
          </Typography.Text>
        </Flex>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            overflowY: "scroll",
            width: "100%",
            height: "100%",
            maxHeight: (size?.height ?? 0) - 120,
            marginTop: 24,
          }}
        >
          <ReactMarkdown children={text} />
        </div>
      </LoginWrapper>
    </BackgroundPreLogin>
  );
};
