import { FC } from "react";

import { useAccountWithAvatar } from "@/entities/account";
import { SettingsForm } from "./SettingsForm";
import { SettingsProfileSkeleton } from "./SettingsProfileSkeleton";

export const SettingsProfile: FC = () => {
  const { loading } = useAccountWithAvatar();

  if (loading) {
    return <SettingsProfileSkeleton />;
  }

  return <SettingsForm />;
};
