import facebook from "assets/shared/facebook.svg";
import instagram from "assets/shared/instagram.svg";
import linkedin from "assets/shared/linkedin.svg";
import twitter from "assets/shared/twitter.svg";

export const SOCIAL_MEDIA_LIST = [
  {
    link: "https://www.instagram.com/blooper.ai",
    icon: instagram,
    alt: "instagram",
  },
  {
    link: "https://www.linkedin.com/company/blooper-ai/",
    icon: linkedin,
    alt: "linkedin",
  },
  {
    link: "https://www.facebook.com/blooperai",
    icon: facebook,
    alt: "facebook",
  },
  {
    link: "https://x.com/blooper_ai",
    icon: twitter,
    alt: "x",
  },
];

export const INFO_LIST = [
  {
    title: "Platform",
    links: [
      {
        text: "Team - About Us",
        link: "/team",
        icon: null,
      },
      {
        text: "Pricing",
        link: "/pricing",
        icon: null,
      },
      {
        text: "News",
        link: "/news",
        icon: null,
      },
      {
        text: "Contact",
        link: "/contacts",
        icon: null,
      },
    ],
  },
  {
    title: "Use cases",
    links: [
      {
        text: "Script breakdowns",
        link: "/use-case-script",
        icon: null,
      },
      {
        text: "Storyboard generation",
        link: "/use-case-storyboard",
        icon: null,
      },
      {
        text: "Pitch deck generation",
        link: "/use-case-video",
        icon: null,
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        text: "Terms of Service",
        link: "/terms-of-conditions",
        icon: null,
      },
      {
        text: "Privacy Policy",
        link: "/privacy-policy",
        icon: null,
      },
      {
        text: "Cookie Policy",
        link: "/cookie-policy",
        icon: null,
      },
      {
        text: "Acceptable Use Policy",
        link: "/acceptable-use-policy",
        icon: null,
      },
    ],
  },
  // {
  // 	title: "Contact Us",
  // 	links: [
  // 		{
  // 			text: "Blooper AG Zugerstrasse 32 6340 Baar Switzerland",
  // 			link: "/contacts",
  // 			icon: null
  // 		},
  // 		{
  // 			text: "info@blooper.ai",
  // 			link: "/contacts",
  // 			icon: null
  // 		}
  // 	]
  // }
];
