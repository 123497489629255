import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, MenuProps, Tooltip, Typography } from "antd";
import cn from "classnames";
import { FC, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { ROUTES } from "shared/const/routes.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { getDisplayedUserName } from "shared/methods/getDisplayedUserName.ts";
import { ProjectType } from "shared/types/page.ts";
import { ScriptPath } from "shared/types/routes.ts";

import deleteStory from "assets/projects/delete.svg";
import edit from "assets/projects/edit.svg";
import emptyStoryboard from "assets/projects/empty-storyboard.svg";
import leave from "assets/projects/leave.svg";
import share from "assets/projects/share.svg";
import team from "assets/projects/team.svg";
import thumbnail from "assets/projects/thumbnail.svg";
import { useOpenModal } from "shared/hooks";
import { generateUrl } from "shared/methods";
import { DropdownDots, Spinner } from "shared/ui";
import { useAccountWithAvatar } from "@/entities/account";
import {
  deleteProjectByIdAction,
  DeleteProjectModal,
  leaveProjectByIdAction,
  renameProjectByIdAction,
  RenameProjectModal,
  ThumbnailProjectModal,
  LeaveProjectModal,
  uploadThumbnailLoadingKeyAtom,
} from "@/entities/projects";
import { ManageTeam as ManageTeamModal } from "../../../../features/manage-team";
import { ShareProjectModal, InviteGuestModal } from "../../../../features/share-project";
import { ProjectCardBottomInfo } from "./ProjectCardBottomInfo.tsx";

import "./ProjectCard.scss";

interface ProjectCard {
  img?: string;
  date: string;
  projectName: string;
  projectKey: string;
  commentsCount: number;
  sharedAccessCount: number;
  projectType: ProjectType;
}

export const ProjectCard: FC<ProjectCard> = ({
  img,
  projectKey,
  date,
  commentsCount,
  sharedAccessCount,
  projectName,
  projectType,
}) => {
  const navigate = useNavigate();
  const [editOpen, toggleEditOpen] = useOpenModal();
  const [deleteOpen, toggleDeleteOpen] = useOpenModal();
  const [leaveOpen, toggleLeaveOpen] = useOpenModal();
  const [thumbnailOpen, toggleThumbnail] = useOpenModal();
  const [shareOpen, toggleShare] = useOpenModal();
  const [inviteGuestOpen, toggleInviteGuest] = useOpenModal();
  const [manageTeamOpen, toggleManageTeam] = useOpenModal();
  const { account } = useAccountWithAvatar();

  const { first_name: fn, last_name: ln, username, email, avatar } = account;
  const [loadingKey] = useAtom(uploadThumbnailLoadingKeyAtom);

  const isShare = projectType === "share";

  const handleRedirect = () => {
    navigate(generateUrl<ScriptPath>(ROUTES.SCRIPT.fullPath, { id: projectKey }));
  };

  const deleteProject = useAction(deleteProjectByIdAction);
  const renameProject = useAction(renameProjectByIdAction);
  const leaveProject = useAction(leaveProjectByIdAction);

  const onRename = (oldName: string, newName: string) => {
    renameProject({ projectKey, oldName, newName });
    toggleEditOpen(false);
  };

  const onDelete = async () => {
    await deleteProject(projectKey);
    toggleDeleteOpen(false);
  };

  const onLeave = async () => {
    await leaveProject(projectKey);
    toggleLeaveOpen(false);
  };

  const onLabelClick = (callback: () => void) => (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    callback();
  };

  const onShare = () => {
    toggleShare(true);
  };

  const onManage = () => {
    toggleManageTeam(true);
  };

  const ownItems: MenuProps["items"] = [
    {
      key: `${projectKey}_rename`,
      label: (
        <Flex
          onClick={onLabelClick(() => {
            toggleEditOpen(true);
          })}
          align="center"
          className="gap-xxs full-width"
        >
          <img src={edit} alt="rename" />
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Rename</Typography.Text>
        </Flex>
      ),
    },
    {
      key: `${projectKey}_thumbnail`,
      label: (
        <Flex
          onClick={onLabelClick(() => {
            toggleThumbnail(true);
          })}
          align="center"
          className="gap-xxs full-width"
        >
          <img src={thumbnail} alt="thumbnail" />
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Set a thumbnail</Typography.Text>
        </Flex>
      ),
    },
    {
      key: `${projectKey}_share`,
      label: (
        <Flex
          onClick={onLabelClick(() => {
            toggleShare(true);
          })}
          align="center"
          className="gap-xxs full-width"
        >
          <img src={share} alt="share" />
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Share</Typography.Text>
        </Flex>
      ),
    },
    {
      key: `${projectKey}_team`,
      label: (
        <Flex onClick={onLabelClick(onManage)} align="center" className="gap-xxs full-width">
          <img src={team} alt="team" />
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Manage team</Typography.Text>
        </Flex>
      ),
    },
    {
      key: `${projectKey}_delete`,
      label: (
        <Flex
          style={{ zIndex: 100 }}
          onClick={onLabelClick(() => toggleDeleteOpen(true))}
          align="center"
          className="gap-xxs full-width"
        >
          <img src={deleteStory} alt="delete" />
          <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.Orange600)}>
            Delete
          </Typography.Text>
        </Flex>
      ),
    },
  ];

  const sharedItems: MenuProps["items"] = [
    {
      key: `${projectKey}_leave`,
      label: (
        <Flex
          style={{ zIndex: 100 }}
          onClick={onLabelClick(() => toggleLeaveOpen(true))}
          align="center"
          className="gap-xxs full-width"
        >
          <img src={leave} alt="leave" />
          <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.Orange600)}>
            Leave
          </Typography.Text>
        </Flex>
      ),
    },
  ];

  const created = new Date(date);
  const dateCreated = `Created:  ${created.toDateString()}`;

  return (
    <>
      <Flex vertical className="your-storyboard">
        <Flex
          vertical
          align="center"
          justify="center"
          className="your-storyboard__top gap-xs pointer"
          onClick={handleRedirect}
        >
          <Flex justify="center" align="center" className="your-storyboard__background">
            {loadingKey === projectKey ? (
              <Spinner />
            ) : (
              <>
                {!!img && (
                  <img
                    className="your-storyboard__img"
                    height={216}
                    src={`${img}?no-cache=${Date.now()}`}
                    alt="storyboard"
                  />
                )}

                {!img && (
                  <img
                    className="your-storyboard__img-empty"
                    height={116}
                    src={emptyStoryboard}
                    alt="storyboard"
                  />
                )}
              </>
            )}
          </Flex>
          <Flex
            align="center"
            justify="space-between"
            className="full-width your-storyboard__actions"
          >
            <Flex vertical>
              <Tooltip arrow placement="topLeft" title={projectName}>
                <Typography.Paragraph
                  style={{ width: 250 }}
                  ellipsis={true}
                  className={cn(TEXT_CLASSNAMES.SmSemibold, COLOR_CLASSNAMES.TextPrimary)}
                >
                  {projectName}
                </Typography.Paragraph>
              </Tooltip>
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextPrimary)}
              >
                {isShare ? "Shared project" : dateCreated}
              </Typography.Text>
            </Flex>
            <DropdownDots items={isShare ? sharedItems : ownItems} />
          </Flex>
        </Flex>
        <ProjectCardBottomInfo
          commentsCount={commentsCount}
          sharedAccessCount={sharedAccessCount}
          onShare={onShare}
          onManage={onManage}
          isShare={isShare}
        />
      </Flex>
      {editOpen && (
        <RenameProjectModal
          onRename={onRename}
          projectName={projectName}
          isOpen={editOpen}
          toggleModal={toggleEditOpen}
        />
      )}
      {deleteOpen && (
        <DeleteProjectModal
          projectName={projectName}
          onDelete={onDelete}
          isOpen={deleteOpen}
          toggleModal={toggleDeleteOpen}
        />
      )}
      {leaveOpen && (
        <LeaveProjectModal isOpen={leaveOpen} toggleModal={toggleLeaveOpen} onLeave={onLeave} />
      )}
      {thumbnailOpen && (
        <ThumbnailProjectModal
          isOpen={thumbnailOpen}
          toggleModal={toggleThumbnail}
          projectKey={projectKey}
        />
      )}
      {manageTeamOpen && (
        <ManageTeamModal
          projectKey={projectKey}
          isManageTeamOpen={manageTeamOpen}
          toggleManageTeam={toggleManageTeam}
          toggleInviteGuest={toggleInviteGuest}
          owner={{ name: getDisplayedUserName({ fn, ln, username, email }), avatar }}
          sharedAccessCount={sharedAccessCount}
        />
      )}
      {shareOpen && (
        <ShareProjectModal projectKey={projectKey} toggleModal={toggleShare} isOpen={shareOpen} />
      )}
      {inviteGuestOpen && (
        <InviteGuestModal
          isOpen={inviteGuestOpen}
          toggleModal={toggleInviteGuest}
          toggleManageTeam={toggleManageTeam}
          projectKey={projectKey}
        />
      )}
    </>
  );
};
