import { useAction, useAtom } from "@reatom/npm-react";

import { DragEvent, FC } from "react";
import { Gallery } from "react-grid-gallery";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { MoodboardPath } from "shared/types/routes.ts";
import { DRAGGABLE_IMAGE_ID } from "@/entities/inspiration-board";
import { dropImageFromSidebarsAction, getMoodboardAction } from "@/entities/moodboard";
import { DeleteOverlay } from "./DeleteOverlay";

import "./StructureMoodboard.scss";

export const StructureMoodboard: FC = () => {
  const { id } = useExtractParams<MoodboardPath>();
  const [moodBoardList] = useAtom(getMoodboardAction.dataAtom);
  const dropImageFromSidebars = useAction(dropImageFromSidebarsAction);
  const images = moodBoardList.map((image) => ({
    src: image.url,
    width: image.coordinates.w,
    height: image.coordinates.h,
    alt: image.id,
    customOverlay: <DeleteOverlay imageId={image.id} />,
  }));

  const enableDropping = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = async (event: DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
    const dropId = event.dataTransfer.getData(DRAGGABLE_IMAGE_ID);

    if (dropId) {
      await dropImageFromSidebars({ dropId, id });
    } else {
      const filesLength = event.dataTransfer.files.length;
      for (let i = 0; i < filesLength; i++) {
        const file = event.dataTransfer.files.item(i);
        const formData = new FormData();
        if (file) {
          formData.append("images", file);

          // const newId = await dropImage(params.id, formData, imageFromSearchBoard.coordinates);
          // addToMoodBoard({ ...imageFromInspirationBoard, id: newId ?? imageFromInspirationBoard.id });

          console.log("==========>file", file);
        }
      }
    }
  };

  return (
    <div
      onDragOver={enableDropping}
      onDrop={handleDrop}
      className="full-width full-height structure-moodboard"
      style={{
        overflowY: "auto",
      }}
    >
      <Gallery rowHeight={300} margin={5} images={images} enableImageSelection={false} />
    </div>
  );
};
