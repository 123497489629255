import { reatomAsync, withDataAtom, withErrorAtom, withStatusesAtom } from "@reatom/async";
import { getShotImagesVersionsResource, GetShotImageVersionParams } from "@/shared/api/storyboard";
import { getImgSize } from "shared/methods";
import { callErrorAction } from "@/entities/notification";

export const initShotsVersionsAction = reatomAsync(
  async (_ctx, params: GetShotImageVersionParams) => {
    const { data } = await getShotImagesVersionsResource({
      shotId: params.shotId,
      sceneId: params.sceneId,
      projectKey: params.projectKey,
    });

    const imagesPromises = data.map(async (image) => {
      const { width, height } = await getImgSize(image.bw_image_url_watermarked_compressed);

      return {
        ...image,
        width,
        height,
      };
    });

    const images = await Promise.all(imagesPromises);

    return images;
  },
  "initShotsVersions",
).pipe(
  withDataAtom([], (_ctx, images) => images),
  withStatusesAtom(),
  withErrorAtom(callErrorAction),
);
