import { ExtractParams } from "../types/routes.ts";

export function generateUrl<T extends string>(path: T, params: ExtractParams<T>): string {
  let result: string = path;

  for (const key in params) {
    result = result.replace(`:${key}`, String(params[key]));
  }

  return result;
}
