import { atom, AtomMut } from "@reatom/core";
import { useAtom } from "@reatom/npm-react";
import { withLocalStorage } from "@reatom/persist-web-storage";
import { Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { GRAYSCALE_TOKEN } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";

import redesignIcon from "assets/projects/redesign.svg";

const redesignAtom = atom<boolean>(true).pipe(withLocalStorage("redesign")) as AtomMut<boolean>;

export const RedesignModal: FC = () => {
  const [isOpen, toggleModal] = useAtom(redesignAtom);

  return (
    <Modal open={isOpen} onCancel={() => toggleModal(false)} footer={[]}>
      <Flex vertical justify="center" align="center" className="text-center">
        <img
          style={{ width: 200, height: 200, marginBottom: 8 }}
          src={redesignIcon}
          alt="redesign"
        />
        <Typography.Text className={TEXT_CLASSNAMES.Title50}>Fresh New Look!</Typography.Text>
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.Body20, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
        >
          We've redesigned our interface to make your experience smoother, faster, and more
          intuitive. Explore the new design and enjoy an even better workflow!
        </Typography.Text>
      </Flex>
    </Modal>
  );
};
