import { useState } from "react";

export type ToggleModal = (value?: boolean) => void;
type TUseOpenModal = [isOpen: boolean, toggleModal: ToggleModal];

export interface IOpenModal {
  isOpen: boolean;
  toggleModal: ToggleModal;
}

export function useOpenModal(): TUseOpenModal {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const toggleModal = (value?: boolean) => {
    setIsOpenModal((prevIsOpenModal) => value ?? !prevIsOpenModal);
  };

  return [isOpenModal, toggleModal];
}
