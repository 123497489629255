import { Button, Flex } from "antd";
import { FC } from "react";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ROUTES } from "shared/const/routes.ts";
import { ProjectType } from "shared/types/page.ts";
import {
  CharacterPath,
  MoodboardPath,
  ScriptPath,
  StoryboardPath,
  StoryboardSidebarPath,
} from "shared/types/routes.ts";
import arrow from "assets/shared/arrow_right.svg";

import characterDesign from "assets/storyboard/character-design.svg";
import moodBoard from "assets/storyboard/mood-board.svg";
import shareIcon from "assets/storyboard/share.svg";
import storyBoard from "assets/storyboard/story-board.svg";
import textUpload from "assets/storyboard/text-upload.svg";
import { generateUrl } from "shared/methods";
import { ActionRestrictor, IconButtonWrapper, MenuItem } from "shared/ui";
import { ShareProjectModal } from "../../share-project";
import { useBehaviour } from "../model";

import "./ProjectBreadcrumbs.scss";

type Props = {
  projectType: ProjectType;
};

export const ProjectBreadcrumbs: FC<Props> = ({ projectType }) => {
  const { id } = useExtractParams<StoryboardSidebarPath>();
  const {
    canViewCharacters,
    canViewStoryboards,
    canViewScript,
    canViewMoodboard,
    canCommentCharacters,
    canCommentMoodboard,
    canCommentScript,
    canCommentStoryboards,
    activeStep,
    shareOpen,
    onShare,
  } = useBehaviour({ projectKey: id });

  const isOwn = projectType === "own";

  return (
    <Flex align="center" justify="space-between" className="full-width gap-xxs project-breadcrumbs">
      <Flex align="center" className="gap-xxs">
        <ActionRestrictor show={isOwn || canViewScript || canCommentScript}>
          <Flex align="center" className="gap-xxs">
            <MenuItem
              title="Script"
              img={textUpload}
              active={activeStep === "script"}
              link={generateUrl<ScriptPath>(ROUTES.SCRIPT.fullPath, { id })}
            />
            <img className="project-breadcrumbs__arrow" src={arrow} alt="arrow" />
          </Flex>
        </ActionRestrictor>
        <ActionRestrictor show={isOwn || canViewMoodboard || canCommentMoodboard}>
          <Flex align="center" className="gap-xxs">
            <MenuItem
              title="Moodboard"
              img={moodBoard}
              active={activeStep === "moodboard"}
              link={generateUrl<MoodboardPath>(ROUTES.MOODBOARD.fullPath, {
                id,
              })}
            />
            <img className="project-breadcrumbs__arrow" src={arrow} alt="arrow" />
          </Flex>
        </ActionRestrictor>
        <ActionRestrictor show={isOwn || canViewCharacters || canCommentCharacters}>
          <Flex align="center" className="gap-xxs">
            <MenuItem
              title="Character Design"
              img={characterDesign}
              active={activeStep === "character"}
              link={generateUrl<CharacterPath>(ROUTES.CHARACTER.fullPath, { id })}
            />
            <img className="project-breadcrumbs__arrow" src={arrow} alt="arrow" />
          </Flex>
        </ActionRestrictor>
        <ActionRestrictor show={isOwn || canViewStoryboards || canCommentStoryboards}>
          <Flex align="center" className="gap-xxs">
            <MenuItem
              title="Storyboard"
              img={storyBoard}
              active={activeStep === "storyboard"}
              link={generateUrl<StoryboardPath>(ROUTES.STORYBOARD.fullPath, { id })}
            />
          </Flex>
        </ActionRestrictor>
      </Flex>
      <Flex>
        <ActionRestrictor show={isOwn}>
          <Button
            onClick={onShare}
            size="small"
            icon={
              <IconButtonWrapper>
                <img src={shareIcon} style={{ width: 16, height: 16 }} alt="share" />
              </IconButtonWrapper>
            }
          >
            Share
          </Button>
        </ActionRestrictor>
      </Flex>
      {shareOpen && <ShareProjectModal projectKey={id} toggleModal={onShare} isOpen={shareOpen} />}
    </Flex>
  );
};
