import uploadIcon from "assets/moodboard/upload.svg";
import { IconButtonWrapper } from "shared/ui";

export const UploadIcon = () => {
  return (
    <IconButtonWrapper>
      <img style={{ width: 16, height: 16 }} src={uploadIcon} alt="upload" />
    </IconButtonWrapper>
  );
};
