import { useAction } from "@reatom/npm-react";
import { MenuItemType, SubMenuType } from "antd/es/menu/interface";
import { FC, RefObject } from "react";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { StoryboardSidebarPath } from "shared/types/routes";
import { DropdownDots } from "shared/ui";
import { CommentPermissions } from "../../lib";
import { toggleResolveCommentAction } from "../../model";
import { DeleteMessage } from "./DeleteMessage";
import { DeleteThread } from "./DeleteThread";
import { ResolveComment } from "./ResolveComment";
import { UnresolveComment } from "./UnresolveCommen";

type MyItemType = MenuItemType | SubMenuType;
interface CommentActionsProps {
  permissions: CommentPermissions;
  commentRef: RefObject<HTMLElement>;
  commentId: string;
  onDeleteThreadModalOpen: () => void;
  onDeleteMessageModalOpen: () => void;
}

export const CommentActions: FC<CommentActionsProps> = ({
  commentRef,
  commentId,
  onDeleteThreadModalOpen,
  onDeleteMessageModalOpen,
  permissions,
}) => {
  const { id } = useExtractParams<StoryboardSidebarPath>();
  const toggleResolveComment = useAction(toggleResolveCommentAction);
  const permissionKeys = Object.keys(permissions) as Array<keyof typeof permissions>;
  const allowedPermissions = permissionKeys.filter((permission) => permissions[permission]);

  const handleToggleResolveComment = (value: boolean) => {
    toggleResolveComment({
      project_key: id,
      comment_id: commentId,
      resolved: value,
    });
  };

  const items: MyItemType[] = [
    {
      key: "canResolve",
      label: <ResolveComment key={"canResolve"} onClick={() => handleToggleResolveComment(true)} />,
    },
    {
      key: "canUnresolve",
      label: (
        <UnresolveComment key={"canUnresolve"} onClick={() => handleToggleResolveComment(false)} />
      ),
    },
    {
      key: "canDeleteThread",
      label: <DeleteThread key={"canDeleteThread"} onClick={onDeleteThreadModalOpen} />,
    },
    {
      key: "canDeleteMessage",
      label: <DeleteMessage key={"canDeleteMessage"} onClick={onDeleteMessageModalOpen} />,
    },
  ];

  return (
    <DropdownDots
      className="comment-dropdown-dots"
      items={items.filter((el) => allowedPermissions.includes(el.key as keyof CommentPermissions))}
      getPopupContainer={() => commentRef.current!}
    />
  );
};
