import { UserOutlined } from "@ant-design/icons";
import { useAction, useAtom } from "@reatom/npm-react";
import { Avatar, Empty, Flex, Skeleton, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";

import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";

import { PermissionGroup } from "shared/types/share";
import add from "assets/projects/add.svg";
import { ToggleModal } from "shared/hooks";
import { Guest } from "shared/ui";
import {
  GuestModal,
  changePermissionAction,
  getGuestsAction,
  removeGuestAction,
} from "@/entities/guest";

import "./ManageTeam.scss";

interface IManageTeam {
  projectKey: string;
  toggleManageTeam: ToggleModal;
  toggleInviteGuest: ToggleModal;
  isManageTeamOpen: boolean;
  sharedAccessCount: number;
  owner: {
    avatar?: string;
    name: string;
  };
}

export const ManageTeam: FC<IManageTeam> = ({
  projectKey,
  owner,
  isManageTeamOpen,
  toggleManageTeam,
  toggleInviteGuest,
  sharedAccessCount,
}) => {
  const getGuests = useAction(getGuestsAction);
  const [guests] = useAtom(getGuestsAction.dataAtom);
  const [guestsStatus] = useAtom(getGuestsAction.statusesAtom);
  const removeGuest = useAction(removeGuestAction);
  const changePermission = useAction(changePermissionAction);
  const { name, avatar } = owner;

  useEffect(() => {
    getGuests({ projectKey });
  }, [projectKey]);

  const handleOpenShare = () => {
    toggleManageTeam(false);
    toggleInviteGuest(true);
  };

  const handleRemove = (email: string) => {
    removeGuest({ projectKey, userEmail: email });
  };

  const handleChangePermission = (permission: PermissionGroup, email: string) => {
    changePermission({
      user_email: email,
      permission_group_name: permission,
      project_key: projectKey,
    });
  };

  return (
    <GuestModal isOpen={isManageTeamOpen} toggleModal={toggleManageTeam}>
      <Flex className="team-owner" justify="space-between" align="center">
        <Flex className="gap-xxs">
          <Flex className="gap-xxs" align="center">
            {avatar ? (
              <img className="home-left-sidebar__avatar" src={avatar} alt="avatar" />
            ) : (
              <Avatar style={{ minWidth: 32 }} shape="square" size={32} icon={<UserOutlined />} />
            )}
          </Flex>
          <Flex vertical>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextPrimary)}
            >
              {name}
            </Typography.Text>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
            >
              Director
            </Typography.Text>
          </Flex>
        </Flex>
        <Flex className="team-owner__role" align="center">
          <Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextPrimary)}>
            Owner
          </Typography.Text>
        </Flex>
      </Flex>
      <Flex vertical className="guests">
        <Flex className="guests__header" justify="space-between" align="center">
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
          >
            Project Guests
          </Typography.Text>
          <Flex role="button" gap={2} className="pointer" align="center" onClick={handleOpenShare}>
            <img src={add} alt="add" />
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.MetalGray900)}
            >
              Invite
            </Typography.Text>
          </Flex>
        </Flex>
        {guestsStatus.isPending ? (
          <>
            {[...Array(sharedAccessCount || 1)].map((_, index) => (
              <Skeleton
                key={index.toString(36)}
                style={{ padding: 8 }}
                active
                paragraph={{ rows: 1 }}
              />
            ))}
          </>
        ) : (
          <>
            {!guests.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {guests.map((guest) => (
              <Guest
                key={guest.email}
                name={guest.name}
                email={guest.email}
                permissionGroup={guest.permissionGroup}
                onRemove={handleRemove}
                onChangePermission={handleChangePermission}
              />
            ))}
          </>
        )}
      </Flex>
    </GuestModal>
  );
};
