import { Button, Flex, Input, Typography } from "antd";
import { FC } from "react";

import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import arrow from "assets/shared/arrow_left_16.svg";

import { useBehavior } from "../model";
import { InpaintingBanner } from "./banner";

export const ShotEditSidebar: FC = () => {
  const { loading, onBack, isActiveButton, onGenerate, onChange, shotId } = useBehavior();

  return (
    <Flex
      style={{
        padding: "16px 16px 0 16px",
      }}
      vertical
      className="gap-xxs full-width"
    >
      <Flex vertical className="gap-xs full-width">
        <Flex align="center" className="gap-xxs">
          <Button
            onClick={onBack}
            style={{ padding: 0, height: 24, width: 24 }}
            className="flex-ctr-ctr"
          >
            <img src={arrow} alt={arrow} />
          </Button>
          <Typography.Text disabled={loading} className={TEXT_CLASSNAMES.Subtitle10}>
            Back
          </Typography.Text>
        </Flex>
        <Input.TextArea
          onChange={onChange}
          placeholder="Describe the object you want to appear in place of the area you selected out."
          rows={3}
        />
        <Button
          loading={loading}
          onClick={onGenerate}
          disabled={!isActiveButton}
          type="primary"
          size="large"
        >
          Generate
        </Button>
      </Flex>
      <InpaintingBanner shotId={shotId} />
    </Flex>
  );
};
