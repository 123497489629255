import { Flex, Typography } from "antd";
import { FC } from "react";

import { TransformWrapper } from "react-zoom-pan-pinch";
import { DotTyping } from "shared/ui";

import { useBehavior } from "../model";
import { ShotEditImage } from "./ShotEditImage.tsx";

import "./ShotEdit.scss";

export const ShotEdit: FC = () => {
  const { loading } = useBehavior();

  if (loading) {
    return (
      <Flex className="full-width full-height">
        <Flex vertical className="shot-selection-editor full-width gap-s">
          <Flex gap={24} align="center" justify="center" className="full-height full-width">
            <DotTyping />
            <Typography.Text>Generating</Typography.Text>
          </Flex>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex className="full-width full-height">
      <TransformWrapper disabled>
        <Flex vertical className="shot-selection-editor full-width gap-s">
          <ShotEditImage />
        </Flex>
      </TransformWrapper>
    </Flex>
  );
};
