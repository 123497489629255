import { useAction, useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { MOODBOARD_TYPE_PARAM_NAME, MOODBORD_TYPE } from "shared/const/routes.ts";
import { MoodboardPath } from "shared/types/routes.ts";
import { useUpdateTitle } from "shared/hooks";
import { moodBoardStatusAtom, getMoodboardAction } from "@/entities/moodboard";
import { MoodboardContent } from "./MoodboardContent";

import "./Moodboard.scss";

export const Moodboard: FC = () => {
  const [searchParams] = useSearchParams();
  const moodboardType = searchParams.get(MOODBOARD_TYPE_PARAM_NAME) ?? MOODBORD_TYPE.structure;
  const { id } = useExtractParams<MoodboardPath>();
  const [moodBoardStatus] = useAtom(moodBoardStatusAtom);
  const initMoodboard = useAction(getMoodboardAction);
  const abortMoodboard = useAction(getMoodboardAction.abort);
  const isMoodBoardEmpty = moodBoardStatus === "empty";

  useUpdateTitle();

  useEffect(() => {
    initMoodboard(id);

    return () => {
      abortMoodboard();
    };
  }, [moodboardType]);

  return (
    <Flex className="full-width full-height">
      <Flex vertical className={cn("moodboard", "gap-s", { moodboard__full: !isMoodBoardEmpty })}>
        <MoodboardContent />
      </Flex>
    </Flex>
  );
};
