import { useAtom } from "@reatom/npm-react";
import { accountWithAvatarModel } from "@/entities/account";

export const useAccountWithAvatar = () => {
  const [account] = useAtom(accountWithAvatarModel.resource.dataAtom);
  const [pending] = useAtom(accountWithAvatarModel.resource.pendingAtom);

  return {
    account,
    loading: pending > 0,
  };
};
