import {useEffect, useState} from "react";
import {TStoryboardRoutes} from "@/entities/storyboard";

export const useCurrentPage = () => {
	const possibleRoutes: TStoryboardRoutes[] = [
		"storyboard",
		"script",
		"character-info",
		"moodboard",
		"shot-edit",
		"character",
	];

	const [activePage, setActivePage] = useState<TStoryboardRoutes>("script");
	const [lastParam, setLastParam] = useState<string>("");

	useEffect(() => {
		const pathnameArray = location.pathname.split("/");
		const routeName = pathnameArray[pathnameArray.length - 1] as TStoryboardRoutes;
		const secondRouteName = pathnameArray[pathnameArray.length - 2] as TStoryboardRoutes;

		setLastParam(routeName);

		if (possibleRoutes.includes(routeName)) {
			setActivePage(routeName);
			return;
		}

		if (possibleRoutes.includes(secondRouteName)) {
			setActivePage(secondRouteName);
		}
	}, [location.pathname]);

	return {
		activePage,
		lastParam,
		possibleRoutes
	}
}
