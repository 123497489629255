import { useHover } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useRef } from "react";

import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import deleteImg from "assets/shared/delete_gray.svg";
import { ActionRestrictor } from "shared/ui";
import { useProjectType } from "@/entities/projects";
import {TShot, useShotActiveHash} from "@/entities/script";
import { ColorShotSelection } from "../../color-shot-select";

interface ISceneShotItem extends TShot {
  sceneId: string;
  onDeleteShot: (id: string, description: string) => void;
}

export const SceneShotItem: FC<ISceneShotItem> = ({
  onDeleteShot,
  sceneId,
  title,
  color,
  id,
  idx,
  description,
}) => {
  const elementRef = useRef(null);
  const isHover = useHover(elementRef);
  const { isShare } = useProjectType();
  const { activeHash, setActiveHash } = useShotActiveHash();

  const isActive = activeHash === id;

  return (
    <Flex
      onClick={() => setActiveHash(id)}
      ref={elementRef}
      align="center"
      justify="space-between"
      className={cn("scene__shot pointer", {
        "scene__shot--hover": isHover,
        "scene__shot--active": isActive,
      })}
    >
      <Flex align="center" className="gap-xxs">
        <ColorShotSelection placement="left" defaultColor={color} shotId={id} sceneId={sceneId} />
        <Typography.Text
          style={{ whiteSpace: "nowrap", maxWidth: 250 }}
          ellipsis
          className={TEXT_CLASSNAMES.Subtitle10}
        >
          Shot {idx + 1} {!!title.length && `"${title}"`}
        </Typography.Text>
      </Flex>
      <ActionRestrictor show={!isShare && (isHover || isActive)}>
        <div role="button" style={{ height: 16 }} onClick={() => onDeleteShot(id, description)}>
          <img className="scene__shot--delete" src={deleteImg} height={16} alt="delete" />
        </div>
      </ActionRestrictor>
    </Flex>
  );
};
