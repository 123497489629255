import { COLOR_NAMES } from "shared/const/color-names.ts";

export const colors = (values: boolean[]) => {
  switch (values.length) {
    case 0:
      return "";
    case 1:
      return COLOR_NAMES.Red500;
    case 2:
      return COLOR_NAMES.Orange500;
    case 3:
      return COLOR_NAMES.BrandGreen500;
    default:
      return COLOR_NAMES.BrandGreen500;
  }
};

export const percent = (values: boolean[]) => {
  const val = colors(values);

  switch (val) {
    case COLOR_NAMES.Red500:
      return 20;
    case COLOR_NAMES.Orange500:
      return 60;
    case COLOR_NAMES.BrandGreen500:
      return 100;
    default:
      return 0;
  }
};
