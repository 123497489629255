import { useAction } from "@reatom/npm-react";
import Konva from "konva";
import { KonvaEventObject, Node, NodeConfig } from "konva/lib/Node";
import { FC, MutableRefObject, useEffect, useRef } from "react";
import { Group, Image } from "react-konva";
import { useParams } from "react-router-dom";
import { getNewCoordinatesAfterTransform, getPosition, toBorder } from "@/features/full-moodboard";
import {
  addGroupButtonAction,
  changeMoodboardButtonPositionAction,
  toggleMoodboardButtonActiveAction,
  updateMoodboardImagesPositionAction,
} from "@/entities/moodboard";
import { TMoodBoardImageCoordinate } from "@/shared/api/moodboard";

type Props = {
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex?: number;
  onHover: (event: KonvaEventObject<globalThis.MouseEvent, Node<NodeConfig>>, id: string) => void;
  isSelected: boolean;
  onSelect: (state: boolean) => void;
  isHovered: boolean;
  image: HTMLImageElement;
  transformerRef: MutableRefObject<null | Konva.Transformer>;
  parentWidth: number;
  parentHeight: number;
  id: string;
};

export const CustomKonvaImage: FC<Props> = ({
  x,
  y,
  width,
  parentWidth,
  onHover,
  parentHeight,
  height,
  image,
  onSelect,
  isSelected,
  transformerRef,
  id,
  zIndex,
}) => {
  const params = useParams();

  const imageRef = useRef<null | Konva.Image>(null);
  const groupRef = useRef<null | Konva.Group>(null);

  const updateLayoutPosition = useAction(updateMoodboardImagesPositionAction);
  const changeButtonPosition = useAction(changeMoodboardButtonPositionAction);
  const toggleButtonActive = useAction(toggleMoodboardButtonActiveAction);
  const addGroup = useAction(addGroupButtonAction);

  const updateImageCoordinates = (e: any) => {
    const { x, y } = e.target.getAbsolutePosition();

    const imageCoordinates: TMoodBoardImageCoordinate = {
      x,
      y,
      w: width,
      h: height,
      minW: zIndex ?? 0,
      minH: 0,
    };
    changeButtonPosition({
      x,
      y,
      name: id,
      isLocked: false,
      isActive: false,
      group: groupRef.current,
      width,
    });

    // @ts-ignore
    if (params.id) {
      updateLayoutPosition(imageCoordinates, id);
    }

    toggleButtonActive(id);
  };

  const onTransformerEnd = () => {
    const imageCoordinates = getNewCoordinatesAfterTransform(imageRef);

    const { x, y } = getPosition(transformerRef);
    changeButtonPosition({
      x,
      y,
      name: id,
      isLocked: false,
      isActive: false,
      group: groupRef.current,
      width: imageCoordinates.w,
    });

    if (params.id) {
      updateLayoutPosition(imageCoordinates, id);
    }

    toggleButtonActive(id);
  };

  useEffect(() => {
    addGroup(id, groupRef.current);
  }, []);

  return (
    <Group
      ref={groupRef}
      height={height}
      width={width}
      // x={x}
      // y={y}
      name={id}
      draggable
      onDragMove={() => toBorder(transformerRef, parentWidth, parentHeight)}
      onDragStart={() => {
        if (imageRef.current) {
          transformerRef.current?.nodes([imageRef.current]);
          toggleButtonActive(id, false);
        }
      }}
      onDragEnd={updateImageCoordinates}
      onMouseEnter={(e) => {
        if (isSelected) return;
        onHover(e, id);
      }}
    >
      <Image
        onTransformEnd={onTransformerEnd}
        onTransformStart={() => toggleButtonActive(id, false)}
        image={image}
        x={x}
        y={y}
        onMouseOver={(e) => {
          if (isSelected) return;
          onHover(e, id);
        }}
        onClick={(e) => {
          onHover(e, id);
          onSelect(true);
          transformerRef.current?.moveToTop();
        }}
        onMouseDown={(e) => {
          onHover(e, id);
        }}
        ref={imageRef}
        draggable
        width={width}
        height={height}
        cornerRadius={8}
        zIndex={zIndex}
      />
    </Group>
  );
};
