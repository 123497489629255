import { Descendant } from "slate";
import { v4 as uuidv4 } from "uuid";
import { ScriptShot, ShotColor, ScriptScene, ShotInfoParams } from "@/shared/api/script";
import { circularIterator, insert } from "shared/methods";
import { SceneHistoryRequest, TScene, TShot } from "../lib";

export const sortShots = (shotsInfo: ScriptShot, shotsOrder: string[]): TShot[] => {
  const colors: ShotColor[] = ["violet", "green", "blue", "red", "orange"];
  const shots = Object.entries(shotsInfo).map(([shotId, shot]) => {
    return {
      id: shotId,
      idx: shotsOrder.findIndex((order) => order === shotId),
      title: shot.title,
      prompt:
        typeof shot.prompt === "string"
          ? ([{ type: "paragraph", children: [{ text: shot.prompt }] }] as Descendant[])
          : Array.isArray(shot.prompt)
            ? shot.prompt
            : [],
      color: colors.includes(shot.color) ? shot.color : (randomColor.next().value as ShotColor),
      description: shot.description,
    };
  });

  return shots.sort((shotA, shotB) => shotA.idx - shotB.idx);
};

export const sortScenes = (scenes: ScriptScene, orders: string[]): TScene[] => {
  const sceneList = Object.entries(scenes);

  const sceneListUpdated: TScene[] = sceneList.map(([id, scene]) => ({
    id,
    title: scene.title,
    idx: orders.findIndex((order) => order === id),
    shots: sortShots(scene.shots_info, scene.shots_order),
    locations: scene.location_options,
    selectedLocation: scene.location_options?.[0] || "",
  }));

  return sceneListUpdated.sort((sceneA, sceneB) => sceneA.idx - sceneB.idx);
};

type TInsertShotsParams = {
  newShots: TShot[];
  initialShots: TShot[];
  lastShotIndex: number;
};

export const insertShots = (params: TInsertShotsParams, orders: string[]) => {
  const insertedShots = insert<TShot>(
    params.lastShotIndex + 1,
    [...params.newShots],
    params.initialShots,
  );
  const updatedShots = insertedShots.map((shot) => ({
    ...shot,
    idx: orders.findIndex((order) => order === shot.id),
  }));

  return updatedShots.sort((shotA, shotB) => shotA.idx - shotB.idx);
};

const colors: ShotColor[] = ["violet", "green", "blue", "red", "orange"];
const colorIterator = (elements: ShotColor[]) => {
  return ((items) => {
    const randomColor = circularIterator<ShotColor>(items);

    return randomColor;
  })(elements);
};

export const randomColor = colorIterator(colors);

export const dumpIndex = (step: number, arr: Array<SceneHistoryRequest>) => {
  let index =
    step > 0
      ? step - 1 // move forward
      : arr.length + step; // move backward
  if (index >= arr.length - 1) {
    index = arr.length - 1;
  }
  if (index < 0) {
    index = 0;
  }
  return index;
};

export const split = (step: number, targetArr: Array<SceneHistoryRequest>) => {
  const index = dumpIndex(step, targetArr);
  const type = step === 1 ? ("redo" as const) : ("undo" as const);
  return {
    _current: targetArr[index],
    _before: targetArr.slice(0, index),
    _after: targetArr.slice(index + 1),
    _type: type,
  };
};

export const getScriptLength = (list: TScene[]) => {
  let length = 0;

  list.forEach((scene) => {
    scene.shots.forEach((shot) => {
      length += shot.description.length;
    });
  });

  return length;
};

export const createShotInfo = (shot: TShot, text: string): ShotInfoParams => {
  return {
    title: shot.title,
    color: shot.color,
    description: text,
    prompt: [{ type: "paragraph" as const, children: [{ text }] }] as Descendant[],
  };
};

export const createShot = (shotIfo: ShotInfoParams, idx: number) => {
  const id = uuidv4();
  return { ...shotIfo, id, idx };
};

export const getFilteredList = (sceneList: TScene[], sceneId: string, shotId: string) => {
  return sceneList.map((scene) => {
    if (scene.id === sceneId) {
      return {
        ...scene,
        shots: scene.shots
          ?.filter((shot) => shot.id !== shotId)
          .map((insertedShot, insertedShotIdx) => ({
            ...insertedShot,
            idx: insertedShotIdx,
          })),
      };
    }
    return scene;
  });
};

export const getNewSceneList = (
  filteredList: TScene[],
  sceneId: string,
  newShots: TShot[],
  deletedShotIdx: number,
) => {
  return filteredList.map((scene) => {
    if (scene.id === sceneId) {
      const params = {
        newShots,
        lastShotIndex: deletedShotIdx,
        initialShots: scene.shots,
      };

      const shotsOrder = insert<string>(
        deletedShotIdx,
        newShots.map((shot) => shot.id),
        scene.shots?.map((shot) => shot.id) ?? [],
      );

      const updatedShots = insertShots(params, shotsOrder);

      return {
        ...scene,
        shots: updatedShots,
      };
    }

    return {
      ...scene,
    };
  });
};
