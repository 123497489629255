export function base64FileHeaderMapper(fileBase64: string) {
  const fileHeader = new Map();

  // get the first 3 char of base64
  fileHeader.set("/9j", "JPG");
  fileHeader.set("iVB", "PNG");
  fileHeader.set("Qk0", "BMP");
  fileHeader.set("SUk", "TIFF");
  fileHeader.set("JVB", "PDF");
  fileHeader.set("UEs", "OFD");

  let res = "";

  fileHeader.forEach((v, k) => {
    if (k === fileBase64.substr(0, 3)) {
      res = v;
    }
  });

  // if file is not supported
  if (res === "") {
    res = "unknown file";
  }

  // return map value
  return res;
}
