import { useAction, useAtom } from "@reatom/npm-react";
import { captureException } from "@sentry/react";
import { Flex } from "antd";
import { FC, PropsWithChildren, useEffect, useState } from "react";

import { Spinner } from "shared/ui";
import { isGuestAtom, viewerModel } from "../../index";
import { getCredential } from "../../lib/utils.ts";

export const PermissionWrapper: FC<PropsWithChildren> = ({ children }) => {
  const viewer = getCredential();
  const getSubscription = useAction(viewerModel.viewerSubscription);
  const getPermission = useAction(viewerModel.viewerPermission);
  const [loading, setLoading] = useState(false);
  const [isGuest] = useAtom(isGuestAtom);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const isAuth = viewer?.data?.isAuth;

        if (isAuth) {
          if (!isGuest) {
            await getSubscription();
          }
          await getPermission();
        }
      } catch (e) {
        captureException(e, {
          tags: {
            feature: "init-user-data",
          },
          level: "warning",
        });
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  if (loading) {
    return (
      <Flex align="center" justify="center" className="full-width full-height">
        <Spinner isOverlay />
      </Flex>
    );
  }

  return <>{children}</>;
};
