import { Button, Flex, Typography } from "antd";
import { FC } from "react";

import { TEXT_CLASSNAMES } from "shared/const/text-classnames";

import description from "assets/description/empty-description.svg";
import upload from "assets/description/upload.svg";
import { useOpenModal } from "shared/hooks";
import { AddScriptModal } from "../../add-new-project";

import "./EmptyDescription.scss";

export const EmptyDescription: FC = () => {
  const [isOpen, toggleModal] = useOpenModal();

  const handleUploadScript = () => {
    toggleModal(true);
  };

  return (
    <Flex vertical align="center" justify="center" className="empty-description gap-m full-height">
      <img src={description} alt="description" className="empty-description__img" />
      <Flex vertical className="gap-s">
        <Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>Add script</Typography.Text>
        <Flex vertical>
          <Typography.Text className={TEXT_CLASSNAMES.SmRegular}>
            Upload your script and split it into shots
          </Typography.Text>
          <Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>or</Typography.Text>
          <Typography.Text className={TEXT_CLASSNAMES.SmRegular}>
            Write a short description and we will generate it for you
          </Typography.Text>
        </Flex>
      </Flex>
      <Flex align="center" justify="center" className="gap-xs">
        <Button
          onClick={handleUploadScript}
          className="flex-ctr-ctr"
          icon={<img src={upload} alt="upload" />}
          type="primary"
        >
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Upload script</Typography.Text>
        </Button>
      </Flex>
      <AddScriptModal toggleModal={toggleModal} isOpen={isOpen} />
    </Flex>
  );
};
