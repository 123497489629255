import { useAtom } from "@reatom/npm-react";
import * as Sentry from "@sentry/react";
import { setUser } from "@sentry/react";
import { useEffect } from "react";
import { createBrowserRouter, Navigate, useRouteError } from "react-router-dom";

import { ROUTES } from "shared/const/routes.ts";
import { ArchiveBoard } from "@/entities/archive-board";
import { InspirationBoard } from "@/entities/inspiration-board";
import { SearchBoard } from "@/entities/search-board";
import { viewerAtom } from "@/entities/viewer";
import { CharacterSettings } from "../features/character-settings";
import { CommentsScriptOutline } from "../features/comments-script-outline";
import { ShotDetails } from "../features/shot-details";
import { ShotEditSidebar } from "../features/shot-edit-sidebar";
import { ShotOutline } from "../features/shot-outline";
import { ShotVersions } from "../features/shot-versions";
import { LandingLayout } from "../widgets/landing-layout";
import {
  StoryboardNavigation,
  ProjectsNavigation,
  SettingsNavigation,
} from "../widgets/navigation";
import { StoryboardMainSidebar, StoryboardRightSidebar } from "../widgets/storyboard-right-sidebar";
import { BlooperExample } from "./blooper-example";
import { Character } from "./character";
import { CharacterInfo } from "./character-info";
import { Contacts } from "./contacts";
import { CreateNewPassword } from "./create-new-password";
import { AcceptableUsePolicy, CookiePolicy, PrivacyPolicy, TermsConditions } from "./documents";
import { EnterTheCode } from "./enter-the-code";
import { DesktopOnly, Error404, Error500 } from "./errors";
import { GuestInvitation } from "./guest-invitation";
import { Invitation } from "./invitation";
import { LandingTeam } from "./landing-team";
import { Login } from "./login";
import { Moodboard } from "./moodboard";
import { News, NewsContent } from "./news";
import { Payment, UpgradePlan } from "./payment";
import { AlreadySubscribed, PaymentSuccess } from "./payment-success";
import { Plan } from "./plan";
import { Pricing } from "./pricing";
import { Product } from "./product";
import { Projects } from "./projects";
import { PromoCode } from "./promo-code";
import { RecoverPassword } from "./recover-password";
import { Register } from "./register";
import { AuthRoute, CustomerRoute, PaymentRoute, PrivateRoute, PublicRoute } from "./routes.tsx";
import { Script } from "./script";
import {
  SettingsAccount,
  SettingsBilling,
  SettingsPreferences,
  SettingsProfile,
  SettingsTeam,
} from "./settings";
import { ShotEdit } from "./shot-edit";
import { Storyboard } from "./storyboard";
import { SuccessPasswordChanged } from "./success-password-changed";
import { TeamManage } from "./team-manage";
import { TrialMoveSuccess } from "./trial-move";
import { UseCaseScript } from "./use-case-script";
import { UseCaseStoryboard } from "./use-case-storyboard";
import { UseCaseVideo } from "./use-case-video";
import { SuccessEmail, Verify } from "./verification";

function ErrorBoundary() {
  const error = useRouteError() as Error;
  const [viewer] = useAtom(viewerAtom);

  useEffect(() => {
    setUser({
      email: viewer.email,
    });

    Sentry.captureException(error, {
      tags: {
        feature: "runtime-error",
      },
      level: "warning",
    });
  }, [error]);

  return <Error500 />;
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "",
        element: <PublicRoute component={Product} />,
      },
      {
        path: "blooper-example",
        element: <PublicRoute component={BlooperExample} />,
      },
      {
        path: "use-case-video",
        element: <PublicRoute component={UseCaseVideo} />,
      },
      {
        path: "use-case-storyboard",
        element: <PublicRoute component={UseCaseStoryboard} />,
      },
      {
        path: "use-case-script",
        element: <PublicRoute component={UseCaseScript} />,
      },
      {
        path: "contacts",
        element: <PublicRoute component={Contacts} />,
      },
      {
        path: "team",
        element: <PublicRoute component={LandingTeam} />,
      },
      {
        path: "pricing",
        element: <PublicRoute component={Pricing} />,
      },
      {
        path: "news",
        element: <PublicRoute component={News} />,
        children: [
          {
            path: ":id",
            element: <PublicRoute component={NewsContent} />,
          },
          {
            path: "",
            element: <PublicRoute component={NewsContent} />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.STORYBOARD_SIDEBAR.routePath,
    element: <PrivateRoute component={StoryboardNavigation} />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: ROUTES.SCRIPT.routePath,
        element: <StoryboardRightSidebar isFirstComments={false} Page={Script} />,
        children: [
          {
            path: "",
            element: <ShotOutline />,
          },
          {
            path: ROUTES.SCRIPT_SHOT_OUTLINE.routePath,
            element: <ShotOutline />,
          },
          {
            path: ROUTES.SCRIPT_COMMENTS.routePath,
            element: <CommentsScriptOutline />,
          },
        ],
      },
      {
        path: ROUTES.MOODBOARD.routePath,
        element: <StoryboardRightSidebar isFirstComments={false} Page={Moodboard} />,
        children: [
          {
            path: "",
            element: <InspirationBoard />,
          },
          {
            path: ROUTES.MOODBORD_INSPIRATION_BOARD.routePath,
            element: <InspirationBoard />,
          },
          {
            path: ROUTES.MOODBORD_SEARCH_BOARD.routePath,
            element: <SearchBoard />,
          },
          {
            path: ROUTES.MOODBORD_ARCHIVE_BOARD.routePath,
            element: <ArchiveBoard />,
          },
          {
            path: ROUTES.MOODBORD_COMMENTS.routePath,
            element: <CommentsScriptOutline />,
          },
        ],
      },
      {
        path: ROUTES.CHARACTER.routePath,
        element: <StoryboardRightSidebar isFirstComments={true} Page={Character} />,
        children: [
          {
            path: "",
            element: <CommentsScriptOutline />,
          },
          {
            path: ROUTES.CHARACTER_COMMENTS.routePath,
            element: <CommentsScriptOutline />,
          },
        ],
      },
      {
        path: ROUTES.CHARACTER_INFO.routePath,
        element: <StoryboardRightSidebar isFirstComments={false} Page={CharacterInfo} />,
        children: [
          {
            path: "",
            element: <CharacterSettings />,
          },
          {
            path: ROUTES.CHARACTER_INFO_SETTINGS.routePath,
            element: <CharacterSettings />,
          },
          {
            path: ROUTES.CHARACTER_INFO_COMMENTS.routePath,
            element: <CommentsScriptOutline />,
          },
        ],
      },
      {
        path: ROUTES.STORYBOARD.routePath,
        element: <StoryboardMainSidebar isFirstComments={false} Page={Storyboard} />,
      },
      {
        path: ROUTES.SHOT_EDIT.routePath,
        element: <StoryboardRightSidebar isFirstComments={false} Page={ShotEdit} />,
        children: [
          {
            path: "",
            element: <ShotDetails />,
          },
          {
            path: ROUTES.SHOT_EDIT_DETAILS.routePath,
            element: <ShotDetails />,
          },
          {
            path: ROUTES.SHOT_EDIT_EDIT.routePath,
            element: <ShotEditSidebar />,
          },
          {
            path: ROUTES.SHOT_EDIT_VERSIONS.routePath,
            element: <ShotVersions />,
          },
          {
            path: ROUTES.SHOT_EDIT_COMMENTS.routePath,
            element: <CommentsScriptOutline />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.PROJECTS.routePath,
    element: <PrivateRoute component={ProjectsNavigation} />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },
  {
    path: ROUTES.TEAM_MANAGE.routePath,
    element: <PrivateRoute component={ProjectsNavigation} />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "",
        element: <CustomerRoute component={TeamManage} />,
      },
    ],
  },
  {
    path: ROUTES.SETTINGS.routePath,
    element: <PrivateRoute component={SettingsNavigation} />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: ROUTES.SETTINGS_PROFILE.routePath,
        element: <SettingsProfile />,
      },
      {
        path: ROUTES.SETTINGS_ACCOUNT.routePath,
        element: <SettingsAccount />,
      },
      {
        path: ROUTES.SETTINGS_MEMBERS.routePath,
        element: <CustomerRoute component={SettingsTeam} />,
      },
      {
        path: ROUTES.SETTINGS_PREFERENCES.routePath,
        element: <CustomerRoute component={SettingsPreferences} />,
      },
      {
        path: ROUTES.SETTINGS_BILLING.routePath,
        element: <CustomerRoute component={SettingsBilling} />,
      },
    ],
  },
  {
    path: "/main",
    element: <ProjectsNavigation />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "projects",
        element: <Navigate to={ROUTES.PROJECTS.fullPath} />,
      },
    ],
  },
  {
    path: "/upgrade-plan",
    element: <PrivateRoute component={() => <CustomerRoute component={UpgradePlan} />} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/login",
    element: <AuthRoute component={Login} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/register",
    element: <AuthRoute component={Register} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: ROUTES.SUCCESS_EMAIL.routePath,
    element: <AuthRoute component={SuccessEmail} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/trial-move-success",
    element: <PublicRoute component={TrialMoveSuccess} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/trial-move-fail",
    element: <PublicRoute component={() => <Navigate to="/plan" />} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/plan",
    element: <PaymentRoute component={Plan} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/payment-success",
    element: <PaymentRoute component={PaymentSuccess} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/already-subscribed",
    element: <PaymentRoute component={AlreadySubscribed} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/payment",
    element: <PaymentRoute component={Payment} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: ROUTES.PROMO_CODE.routePath,
    element: <PaymentRoute component={PromoCode} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/auth/verify",
    element: <AuthRoute component={Verify} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/auth/guest/simple_sign_up",
    element: <GuestInvitation />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/auth/verify/invitation",
    element: <Invitation />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/recover-password",
    element: <AuthRoute component={RecoverPassword} />,
    errorElement: <ErrorBoundary />,
  },
  {
    // TODO fix mobile version
    path: ROUTES.ENTER_THE_CODE.routePath,
    element: <AuthRoute component={EnterTheCode} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: ROUTES.CREATE_NEW_PASSWORD.routePath,
    element: <AuthRoute component={CreateNewPassword} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/password-success-changed",
    element: <AuthRoute component={SuccessPasswordChanged} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/404",
    element: <PublicRoute component={Error404} />,
  },
  {
    path: "/500",
    element: <PublicRoute component={Error500} />,
  },
  {
    path: "/desktop-only",
    element: <PublicRoute component={DesktopOnly} />,
  },
  {
    path: "/privacy-policy",
    element: <PublicRoute component={PrivacyPolicy} />,
  },
  {
    path: "/terms-of-conditions",
    element: <PublicRoute component={TermsConditions} />,
  },
  {
    path: "/cookie-policy",
    element: <PublicRoute component={CookiePolicy} />,
  },
  {
    path: "/acceptable-use-policy",
    element: <PublicRoute component={AcceptableUsePolicy} />,
  },
  {
    path: "*",
    element: <Error404 />,
  },
]);
