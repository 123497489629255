import structureIcon from "assets/moodboard/structure.svg";
import { IconButtonWrapper } from "shared/ui";

export const StructureIcon = () => {
  return (
    <IconButtonWrapper>
      <img style={{ width: 16, height: 16 }} src={structureIcon} alt="structure" />
    </IconButtonWrapper>
  );
};
