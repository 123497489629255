import { TeamResult } from "@/shared/api/teams";
import { TTeamMember } from "./types";

export const toTeamMemberList = (data: TeamResult, projectsCount: number): TTeamMember[] => {
  let members: TTeamMember[] = [];

  Object.entries(data).forEach(([id, teamMembers]) => {
    members = teamMembers.map((member) => {
      const email = Object.keys(member)[0];

      return {
        id,
        email,
        projectsCount,
        role: member[email].user_role,
        position: member[email].user_position,
        name: "",
        avatar: "",
      };
    });
  });

  return members;
};

export const getTeamId = (teams: TeamResult): string => {
  const teamId = Object.keys(teams)[0];

  return teamId;
};
