import { usePermission } from "@/shared/hooks/use-permission.ts";
import { PermissionPage } from "shared/types/share.ts";
import { useAccountWithAvatar } from "@/entities/account";
import { useProjectTypePermission } from "@/entities/projects";
import {useCurrentPage} from "@/shared/hooks/use-current-page";

type Params = {
  projectKey: string;
};

export const useStoryboardNavigation = ({ projectKey }: Params) => {
  const { activePage, lastParam, possibleRoutes } = useCurrentPage();
  const { can: canComment } = usePermission({
    page: activePage as PermissionPage,
    group: "comment",
    projectKey,
  });

  const { account, loading: accountLoading } = useAccountWithAvatar();
  const { projectType, projectName } = useProjectTypePermission({
    projectKey,
    email: account.email,
  });

  return {
    activePage,
    lastParam,
    possibleRoutes,
    projectType,
    canComment,
    account,
    accountLoading,
    projectName,
  };
};
