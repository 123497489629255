import { ComponentType } from "react";
import contentImg1 from "assets/news/content-1.png";
import contentImg2 from "assets/news/content-2.png";
import contentImg3 from "assets/news/content-3.svg";
import sidebar1 from "assets/news/sidebar-1.jpg";
import sidebar2 from "assets/news/sidebar-2.svg";
import sidebar3 from "assets/news/sidebar-3.svg";
import { WhatsNewInBlooper } from "../../../features/news-pages";

type MDType = "markdown";
type ReactType = "react";

type MdNews = {
  id: string;
  type: string;
  date: number;
  title: string;
  sidebarImg: string;
  contentImg: string;
  dataType: MDType;
  contentUrl: string;
};

type ComponentNews = {
  id: string;
  type: string;
  date: number;
  title: string;
  sidebarImg: string;
  contentImg: string;
  dataType: ReactType;
  contentComponent: ComponentType<any>;
};

type News = MdNews | ComponentNews;

export const NEWS: News[] = [
  {
    id: "blooper-launches",
    type: "Launch news",
    date: 1737133031233,
    title: "A Smarter Way to Streamline Pre-Production",
    sidebarImg: sidebar1,
    contentImg: contentImg1,
    dataType: "markdown" as MDType,
    contentUrl:
      "# **Blooper Launches: A Smarter Way to Streamline Pre-Production**\n" +
      "\n" +
      "Blooper, the new AI-powered pre-production platform, is here to transform the way creators and managers approach video production. Built **by creators for creators**, Blooper simplifies the entire pre-production process—from storyboarding and location scouting to script breakdowns and team collaboration.\n" +
      "\n" +
      "**Designed for efficiency and creativity**  \n" +
      "Blooper streamlines workflows, saves valuable time and resources, and eliminates the typical challenges of pre-production. Whether you're working on a commercial, a campaign, or a video project, Blooper ensures your team stays aligned and on track.\n" +
      "\n" +
      '**"As a director myself, I understand the challenges of managing pre-production—wasted time, delays, and inflexible workflows. Blooper was built to eliminate these problems. It allows teams to streamline their work, focus more on creativity, and deliver projects faster and smarter,"** says **Arseniy Seroka**, CEO and Founder of Blooper.\n' +
      "\n" +
      "Blooper isn't just for filmmakers—it’s a game-changer for **advertising professionals and managers** looking to maximize their output and efficiency.\n" +
      "\n" +
      '**"Blooper is an essential tool for managers. It helps track team activities, generate clear reports, and streamline collaboration—all in one place. By optimizing workflows, it allows advertising teams to run more campaigns, deliver them faster, and manage budgets more effectively,"** adds **Kamilla Saifulina**, Head of Marketing.\n' +
      "\n" +
      "---\n" +
      "\n" +
      "## **The Benefits of Blooper for Advertising Teams**\n" +
      "\n" +
      "🔹 **Save Time**  \n" +
      "Automate repetitive tasks, speed up pre-production, and stay on schedule. Spend less time on manual work and focus on creativity.\n" +
      "\n" +
      "🔹 **Save Your Budget**  \n" +
      "Avoid wasted resources and reduce unexpected costs by keeping everything organized and optimized.\n" +
      "\n" +
      "🔹 **Ensure Transparency**  \n" +
      "Communicate with your team directly within the platform, track every activity, and make the process fully visible to all stakeholders.\n" +
      "\n" +
      "🔹 **Drive Accountability**  \n" +
      "Easily generate detailed reports and updates to monitor progress and share results with clients or internal teams.\n" +
      "\n" +
      "🔹 **Stay Flexible**  \n" +
      "Make last-minute adjustments to scripts, storyboards, or shot lists without delays—ensuring your project stays agile and adaptable.\n" +
      "\n" +
      "🔹 **Scale Faster**  \n" +
      "Streamline workflows so you can manage multiple campaigns simultaneously, pitch more projects, and deliver faster results.\n" +
      "\n" +
      "---\n" +
      "\n" +
      "Blooper is more than a tool—it’s a smarter way to **plan, collaborate, and create.** Ready to streamline your next project?",
  },
  {
    id: "blooper-at-the-european-film",
    date: 1737139967841,
    contentImg: contentImg2,
    sidebarImg: sidebar2,
    type: "Event news",
    title: "Blooper at the European Film Awards 2024: Shaping the Future of Pre-Production",
    dataType: "markdown" as MDType,
    contentUrl:
      "# **Blooper at the European Film Awards 2024: Shaping the Future of Pre-Production**\n" +
      "\n" +
      "At Blooper, we believe that collaboration and open dialogue are essential to building tools that truly serve video creators. That’s why we were thrilled to see Michel Perez, our Chief Product Officer, take the stage at the European Film Awards 2024 in Lucerne to share insights on the future of pre-production and connect with industry professionals.\n" +
      "\n" +
      "---\n" +
      "\n" +
      "### **Why This Matters** \n" +
      "\n" +
      "### As a product designed by creators for creators, Blooper aims to streamline the pre-production process—addressing challenges that every team faces, from missed deadlines to chaotic workflows. Events like these allow us to have direct conversations with the people we’re building for: advertising professionals, filmmakers, and content creators.\n" +
      "\n" +
      "It was truly an honor for our team to participate in such a significant event and connect with so many talented individuals who inspire us every day. Hearing first-hand about their challenges and ideas fuels our commitment to making Blooper the go-to tool for video creators worldwide.\n" +
      "\n" +
      "---\n" +
      "\n" +
      "### **What We Shared**\n" +
      "\n" +
      "Michel’s session focused on:\n" +
      "\n" +
      "* How AI-driven tools can solve pre-production pain points, like managing storyboards, breaking down scripts, and scouting locations.  \n" +
      "* The importance of collaboration in modern workflows, and how streamlined communication within a single platform eliminates confusion and increases transparency for all team members.  \n" +
      "* How Blooper helps video creators—including advertisers—save time and resources, improve team alignment, and participate in more tenders and campaigns faster.\n" +
      "\n" +
      "---\n" +
      "\n" +
      "### **Looking Ahead**\n" +
      "\n" +
      "We’re not stopping here. This event was just the beginning, and we’re excited to continue participating in industry conversations that shape the future of video production. Stay tuned—we’ll be sharing more insights, updates, and opportunities to connect with us at future events.\n" +
      "\n" +
      "\n",
  },
  {
    id: "whats-new-in-blooper",
    title: "What’s New in Blooper?",
    date: 1743103222866,
    contentImg: contentImg3,
    sidebarImg: sidebar3,
    type: "Launch news",
    dataType: "react" as ReactType,
    contentComponent: WhatsNewInBlooper,
  },
].reverse();
