import { Flex, Form, Input, Typography } from "antd";
import { AxiosError } from "axios";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { resetPasswordResource } from "@/shared/api/auth";
import { REG_EXP } from "shared/const/reg-exp";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { validatePassword } from "shared/validation";
import { BackgroundPreLogin, LoginWrapper, RecoverPasswordWrapper } from "@/entities/viewer";
import { Chip } from "./Chip";

type DataType = {
  password: string;
  confirmPassword: string;
};

export const CreateNewPassword: FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [error, setError] = useState<null | string>(null);

  const password = Form.useWatch("password", { form, preserve: true });

  const onFinish = async (data: DataType) => {
    try {
      await resetPasswordResource(data.password);
      navigate("/password-success-changed");
    } catch (e) {
      const err = e as AxiosError;

      // @ts-ignore
      setError(err.response?.data?.message ?? "Something went wrong. Please try again");
    }
  };

  const validatedPassword = validatePassword(password);

  return (
    <BackgroundPreLogin>
      <LoginWrapper title={""} questionText={""} actionText={""} action={() => {}}>
        <Form form={form} onFinish={onFinish}>
          <RecoverPasswordWrapper
            title="Create a new password"
            description="Your new password must be different from the previous password you used"
            form={form}
            buttonText="Change password"
          >
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "" },
                { min: 8, message: "" },
                { pattern: REG_EXP.upperCaseLetter, message: "" },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 8 }}
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                { required: true, message: "Please input your confirm password" },
                { min: 8, message: "" },
                { pattern: REG_EXP.upperCaseLetter, message: "" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The new password that you entered do not match!"),
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
            <Flex wrap="wrap" className="gap-4">
              <Chip title="at least 8 characters" isSuccess={validatedPassword.eightCharacters} />
              <Chip title="1 lowercase letter" isSuccess={validatedPassword.lowerCaseLetter} />
              <Chip title="1 capital letter" isSuccess={validatedPassword.upperCaseLetter} />
            </Flex>
            {error && (
              <Typography.Text type="danger" className={TEXT_CLASSNAMES.Body20}>
                {error}
              </Typography.Text>
            )}
          </RecoverPasswordWrapper>
        </Form>
      </LoginWrapper>
    </BackgroundPreLogin>
  );
};
