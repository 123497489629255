import { FC } from "react";
import redoIcon from "assets/shared/arrow_left_16.svg";
import { IconButtonWrapper } from "shared/ui";

export const RedoIcon: FC = () => {
  return (
    <IconButtonWrapper>
      <img style={{ rotate: "180deg" }} src={redoIcon} alt="redo" />
    </IconButtonWrapper>
  );
};
