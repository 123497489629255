import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";

import "./MenuItem.scss";

type Props = {
  img?: string;
  title: string;
  link: string;
  active?: boolean;
  disabled?: boolean;
};

export const MenuItem: FC<Props> = ({ img, title, active, disabled, link }) => {
  const navigate = useNavigate();

  const onRedirect = () => {
    if (!disabled) {
      navigate(link);
    }
  };

  return (
    <Flex
      onClick={onRedirect}
      role="button"
      className={cn("menu-item", {
        "menu-item--active": active,
        "non-pointer-events": disabled,
        pointer: !disabled,
      })}
    >
      {!!img && (
        <img
          className={cn("menu-item__img", {
            "menu-item__img--active": active,
            "menu-item__img--disabled": disabled,
          })}
          src={img}
          alt={title}
        />
      )}
      <Typography.Text
        className={cn(TEXT_CLASSNAMES.Subtitle10, {
          [TEXT_CLASSNAMES.Link10]: active,
          [TEXT_CLASSNAMES.Subtitle10]: disabled,
        })}
      >
        {title}
      </Typography.Text>
    </Flex>
  );
};
