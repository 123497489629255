import { useAtom } from "@reatom/npm-react";
import { ConfigProvider, Flex, Input, InputRef, Typography } from "antd";
import cn from "classnames";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { GRAYSCALE_TOKEN, PRIMARY_TOKEN } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import pencil from "assets/shot-selection/pencil.svg";
import { useOutsideClick } from "shared/hooks";
import { ActionRestrictor } from "shared/ui";
import { useProjectType } from "@/entities/projects";
import { shotEditStateAtom } from "@/entities/shot-edit";

type Props = {
  title: string;
  editKey: "camera_movement" | "shot_description" | "dialog";
};

export const EditInput: FC<Props> = ({ title, editKey }) => {
  const { isShare } = useProjectType();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const ref = useRef(null);
  const inputRef = useRef<InputRef>(null);
  const [editState, setEditState] = useAtom(shotEditStateAtom);

  const onOpen = () => {
    setIsEdit(true);
  };

  const onClose = () => {
    setIsEdit(false);
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setEditState({
      ...editState,
      [editKey]: event.target.value,
    });
  };

  useOutsideClick(ref, onClose);

  useEffect(() => {
    if (isEdit) {
      inputRef.current?.focus();
    }
  }, [isEdit]);

  return (
    <Flex ref={ref} vertical className="gap-xxs">
      <Flex align="center" justify="space-between">
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.Body10, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
        >
          {title}
        </Typography.Text>
        <ActionRestrictor show={!isShare}>
          <Flex className="pointer" align="center" role="button" onClick={onOpen}>
            <img src={pencil} alt="pencil" />
            <Typography.Text className={TEXT_CLASSNAMES.Link10}>Edit</Typography.Text>
          </Flex>
        </ActionRestrictor>
      </Flex>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              hoverBorderColor: PRIMARY_TOKEN.BORDER.DEFAULT.color,
              activeBorderColor: PRIMARY_TOKEN.BORDER.DEFAULT_DARK.color,
            },
          },
        }}
      >
        {isEdit ? (
          <Input.TextArea ref={inputRef} rows={4} onChange={onChange} value={editState[editKey]} />
        ) : (
          <Typography.Text className={TEXT_CLASSNAMES.Body20}>{editState[editKey]}</Typography.Text>
        )}
      </ConfigProvider>
    </Flex>
  );
};
