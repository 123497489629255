import { useAction, useAtom } from "@reatom/npm-react";
import { ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ShotEditPath } from "shared/types/routes.ts";
import {
  generateInpaintingAction,
  inpaintingDescriptionAtom,
  inpaintingPointsAtom,
} from "@/entities/inpainting";
import { getShotAction } from "@/entities/shot-edit";
import { toBlackMask } from "../lib";

export const useBehavior = () => {
  const navigate = useNavigate();
  const { shotId, id } = useExtractParams<ShotEditPath>();
  const [points, setPoints] = useAtom(inpaintingPointsAtom);
  const [description, setDescription] = useAtom(inpaintingDescriptionAtom);
  const [shot] = useAtom(getShotAction.dataAtom);
  const generateInpainting = useAction(generateInpaintingAction);
  const [inpaintingStatuses] = useAtom(generateInpaintingAction.statusesAtom);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const onGenerate = () => {
    const baseImage = shot?.image_url_compressed ?? "";

    toBlackMask(baseImage, points, async (canvas) => {
      try {
        const base64Mask = canvas.toDataURL();

        await generateInpainting({
          shotId,
          description,
          base64Mask,
          baseImage,
          projectKey: id,
          originalDescription: shot?.description ?? "",
        });
      } finally {
        setPoints([]);
      }
    });
  };

  const isActiveButton = !!description.length && !!points.length;

  const onBack = () => {
    navigate(-1);
  };

  return {
    isActiveButton,
    onBack,
    onGenerate,
    onChange,
    loading: inpaintingStatuses.isPending,
    shotId,
  };
};
