import {
  FULL_HD,
  LAPTOP_LARGE,
  LAPTOP_MEDIUM,
  LAPTOP_SMALL,
  MOBILE_EXTRA_LARGE,
  MOBILE_LARGE,
  MOBILE_MEDIUM,
  MOBILE_SMALL,
  TABLET,
  WIDESCREEN,
} from "../const/media-queries.ts";
import { useWindowSize } from "./use-window-size.ts";

type TUseWindowSize = {
  is4K: boolean;
  isFullHd: boolean;
  isLaptopL: boolean;
  isLaptopM: boolean;
  isLaptopS: boolean;
  isTablet: boolean;
  isMobileL: boolean;
  isMobileM: boolean;
  isMobileS: boolean;
  isMobileXL: boolean;
};

export function useMediaQueries(): TUseWindowSize {
  const { width } = useWindowSize();

  const is4K = width <= WIDESCREEN;
  const isFullHd = width <= FULL_HD;
  const isLaptopL = width <= LAPTOP_LARGE;
  const isLaptopM = width <= LAPTOP_MEDIUM;
  const isLaptopS = width <= LAPTOP_SMALL;
  const isTablet = width <= TABLET;
  const isMobileL = width <= MOBILE_LARGE;
  const isMobileS = width <= MOBILE_SMALL;
  const isMobileM = width <= MOBILE_MEDIUM;
  const isMobileXL = width <= MOBILE_EXTRA_LARGE;

  return {
    is4K,
    isFullHd,
    isLaptopL,
    isLaptopM,
    isLaptopS,
    isTablet,
    isMobileL,
    isMobileS,
    isMobileM,
    isMobileXL,
  };
}
