import { useAction } from "@reatom/npm-react";
import { Button, Flex, Modal, Typography } from "antd";
import { FC } from "react";

import { deleteSceneAction, deleteShotAction } from "@/entities/script";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";

import { IOpenModal } from "shared/hooks";
import { ScriptPath } from "shared/types/routes.ts";

interface IDeleteSceneModal extends IOpenModal {
  sceneId: string;
  shotId: string;
  isLast: boolean;
}

export const DeleteShotModal: FC<IDeleteSceneModal> = ({
  sceneId,
  shotId,
  toggleModal,
  isOpen,
  isLast,
}) => {
  const { id } = useExtractParams<ScriptPath>();

  const deleteShot = useAction(deleteShotAction);
  const deleteScene = useAction(deleteSceneAction);

  const onClose = () => {
    toggleModal(false);
  };

  const handleDeleteShot = async () => {
    await deleteShot({
      projectKey: id,
      shotId,
      sceneId,
    });
    onClose();
  };

  const handleDeleteScene = async () => {
    await deleteScene({
      projectKey: id,
      sceneId,
    });
    onClose();
  };

  const title = isLast
    ? "Are you sure you want to delete the last shot with scene?"
    : "Are you sure you want to delete the shot?";

  const description = isLast
    ? "All content from this scene will be deleted"
    : "All content from this shot will be deleted";

  return (
    <>
      <Modal
        open={isOpen}
        width={590}
        onCancel={onClose}
        footer={
          <Flex gap={8} justify="flex-end">
            <Button size="large" onClick={onClose}>
              Cancel
            </Button>
            <Button type="primary" onClick={isLast ? handleDeleteScene : handleDeleteShot}>
              <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
                Yes, delete it
              </Typography.Text>
            </Button>
          </Flex>
        }
      >
        <Flex vertical className="gap-xxs">
          <Flex style={{ background: "transparent" }} align="center" className="gap-xxs">
            <Typography.Text className={TEXT_CLASSNAMES.Title50}>{title}</Typography.Text>
          </Flex>
          <Typography.Text className={TEXT_CLASSNAMES.Body20}>{description}</Typography.Text>
        </Flex>
      </Modal>
    </>
  );
};
