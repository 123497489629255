import { reatomAsync, withAbort, withStatusesAtom } from "@reatom/async";
import { action, atom } from "@reatom/core";
import { withReset } from "@reatom/lens";
import { UploadFile } from "antd";
import { TNewProject } from "@/entities/projects";
import { AnalyzeScriptParams, analyzeScriptResource } from "@/shared/api/script";

export type TStep = "1" | "2" | "3";
export const stepAtom = atom<TStep>("1", "stepAtom").pipe(withReset());
export const newProjectAtom = atom<TNewProject>(
  {
    name: null,
    briefing: null,
    script: null,
    country: null,
    city: null,
  },
  "newProjectAtom",
).pipe(withReset());
export const uploadStatusAtom = atom<UploadFile["status"]>("done", "uploadStatusAtom").pipe(
  withReset(),
);

export const resetDataAction = action((ctx) => {
  stepAtom.reset(ctx);
  newProjectAtom.reset(ctx);
});

export const analyzeScriptAction = reatomAsync(
  async (ctx, params: AnalyzeScriptParams) => {
    uploadStatusAtom(ctx, "uploading");
    return await analyzeScriptResource(params, ctx.controller);
  },
  {
    onFulfill: (ctx) => {
      uploadStatusAtom(ctx, "done");
    },
    onReject: (ctx) => {
      uploadStatusAtom(ctx, "error");
    },
  },
).pipe(withAbort(), withStatusesAtom());
