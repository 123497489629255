import { useAtom } from "@reatom/npm-react";
import { captureException, setUser } from "@sentry/react";
import { Flex } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Interval, moveFromTrialResource, updateTrialPaymentDetails } from "@/shared/api/payment";
import { Spinner } from "shared/ui";
import { viewerAtom } from "@/entities/viewer";

export const TrialMoveSuccess = () => {
  const navigate = useNavigate();
  const [viewer] = useAtom(viewerAtom);

  useEffect(() => {
    const init = async () => {
      try {
        const interval = localStorage.getItem("interval");
        const blooperSubscriptionId = localStorage.getItem("blooper_subscription_id");
        const promocode = localStorage.getItem("promocode");

        const session = localStorage.getItem("session_id");
        await updateTrialPaymentDetails({ sessionId: session ?? "" });
        await moveFromTrialResource({
          interval: (interval as Interval) ?? "month",
          blooper_subscription_id: blooperSubscriptionId ? Number(blooperSubscriptionId) : 1,
          promocode: promocode ?? "",
        });

        navigate("/payment-success");
      } catch (e) {
        setUser({
          email: viewer.email,
        });
        captureException(e, {
          tags: {
            feature: "trial-move",
          },
          level: "error",
        });
      }
    };

    init();
  }, []);

  return (
    <Flex>
      <Spinner isOverlay={true} />
    </Flex>
  );
};
