import { Button, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { GRAYSCALE_TOKEN } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { IOpenModal } from "shared/hooks";

import "./MoodboardDeleteModal.scss";

interface IMoodboardDeleteModal extends IOpenModal {
  deleteMoodboard: () => void;
}

export const MoodboardDeleteModal: FC<IMoodboardDeleteModal> = ({
  isOpen,
  toggleModal,
  deleteMoodboard,
}) => {
  const handleCancel = () => {
    toggleModal();
  };
  const handleOk = () => {
    deleteMoodboard();
    toggleModal();
  };

  return (
    <Modal
      width={325}
      open={isOpen}
      onCancel={handleCancel}
      footer={[
        <Flex className="full-width" gap={8} justify="end">
          <Button style={{ width: 70 }} size="small" key="back" onClick={handleCancel}>
            Cancel
          </Button>
          <Button style={{ width: 70 }} type="primary" size="small" key="back" onClick={handleOk}>
            Delete
          </Button>
        </Flex>,
      ]}
    >
      <Flex className="moodboard-delete-modal" vertical>
        <Typography.Text
          className={cn(
            "moodboard-delete-modal__title",
            TEXT_CLASSNAMES.Link20,
            GRAYSCALE_TOKEN.TEXT_ICON.TITLE.className,
          )}
        >
          Are you sure you want to delete all <br /> images on the moodboard?
        </Typography.Text>
        <Typography.Text
          className={cn(
            "moodboard-delete-modal__description",
            TEXT_CLASSNAMES.Body10,
            GRAYSCALE_TOKEN.TEXT_ICON.TITLE.className,
          )}
        >
          All images will be moved to the archive
        </Typography.Text>
      </Flex>
    </Modal>
  );
};
