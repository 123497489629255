import { atom, AtomMut } from "@reatom/core";
import { useAction, useAtom } from "@reatom/npm-react";
import { withSessionStorage } from "@reatom/persist-web-storage";
import { useDebounceFn, useInterval } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { GRAYSCALE_TOKEN } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { SuccessEmailPath } from "shared/types/routes.ts";
import {
  BackgroundPreLogin,
  LoginWrapper,
  logoutAction,
  resendLinkAction,
} from "@/entities/viewer";

const countAtom = atom<number>(60).pipe(withSessionStorage("resendCount")) as AtomMut<number>;
const isSendAtom = atom<boolean>(false).pipe(withSessionStorage("isSendLink")) as AtomMut<boolean>;

export const SuccessEmail: FC = () => {
  const { email } = useExtractParams<SuccessEmailPath>();
  const logout = useAction(logoutAction);
  const resendLink = useAction(resendLinkAction);
  const [count, setCount] = useAtom(countAtom);
  const [isSend, setIsSend] = useAtom(isSendAtom);

  const { run: onResendLink } = useDebounceFn(
    () => {
      resendLink(email);
      setCount(60);
      setIsSend(true);
    },
    {
      wait: 500,
    },
  );

  const onLogout = () => {
    logout();
  };

  useInterval(() => {
    if (count > 0) {
      setCount(count - 1);
    }
  }, 1000);

  const isActive = !isSend || (isSend && count <= 0);

  return (
    <BackgroundPreLogin>
      <LoginWrapper
        action={() => {}}
        title="Verification"
        questionText=""
        actionText=""
        style={{
          textAlign: "left",
        }}
      >
        <Flex vertical gap={16}>
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.Body40, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
          >
            We sent an verification link to {email}
          </Typography.Text>
          <Flex vertical>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.Body40, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
            >
              Didn't receive the email?{" "}
              <Typography.Link
                underline
                onClick={onResendLink}
                type="secondary"
                className={TEXT_CLASSNAMES.Body40}
              >
                Resend verification link
              </Typography.Link>{" "}
              {!isActive && (
                <Typography.Text
                  className={cn(
                    TEXT_CLASSNAMES.Body40,
                    GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className,
                  )}
                >
                  ({count}) sec
                </Typography.Text>
              )}
            </Typography.Text>
            <Typography
              className={cn(TEXT_CLASSNAMES.Body40, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
            >
              <Typography.Link
                onClick={onLogout}
                underline
                type="secondary"
                className={TEXT_CLASSNAMES.Body40}
              >
                Sign out
              </Typography.Link>{" "}
              to use a different account
            </Typography>
          </Flex>
        </Flex>
      </LoginWrapper>
    </BackgroundPreLogin>
  );
};
