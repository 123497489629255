import { useAction, useAtom } from "@reatom/npm-react";
import { useEffect } from "react";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ShotEditPath } from "shared/types/routes.ts";
import { generateInpaintingAction } from "@/entities/inpainting";
import {
  getShotAction,
  getShotsOrderAction,
  saveShotChangesAction,
  shotEditingImageUploadLoadingAtom,
} from "@/entities/shot-edit";
import {
  generateImageAction,
  generateImageAtom,
  regenerateImageAction,
  regenerateImageAtom
} from "@/entities/storyboard";

export const useBehavior = () => {
  const { id, shotId, sceneId } = useExtractParams<ShotEditPath>();

  const initShot = useAction(getShotAction);
  const abortShot = useAction(getShotAction.abort);

  const [generateImage] = useAtom(generateImageAtom);
  const [regenerateImage] = useAtom(regenerateImageAtom);
  const [regenerateImageStatus] = useAtom(regenerateImageAction.statusesAtom);
  const [generateImageStatus] = useAtom(generateImageAction.statusesAtom);
  const [shotEditingImageUploadLoading] = useAtom(shotEditingImageUploadLoadingAtom);
  const [saveShotStatus] = useAtom(saveShotChangesAction.statusesAtom);
  const [inpaitingStatuses] = useAtom(generateInpaintingAction.statusesAtom);
  const [editShotStatuses] = useAtom(getShotAction.statusesAtom);
  const [shotsOrderStatuses] = useAtom(getShotsOrderAction.statusesAtom);

  const loading =
    regenerateImageStatus.isPending ||
    saveShotStatus.isPending ||
    generateImageStatus.isPending ||
    editShotStatuses.isPending ||
    shotsOrderStatuses.isPending ||
    inpaitingStatuses.isPending ||
    shotEditingImageUploadLoading ||
    (generateImage?.[shotId]?.pending ?? false) ||
    (regenerateImage?.[shotId]?.pending ?? false);

  useEffect(() => {
    initShot({ projectKey: id, sceneId, shotId });

    return () => {
      abortShot();
    };
  }, [id, shotId]);

  return {
    loading,
  };
};
