import { REG_EXP } from "../const/reg-exp.ts";
import { TFeedbackMessage } from "../types/validation.ts";

export const validateEmail = (email: string): TFeedbackMessage => {
  const regExp = REG_EXP.email;

  const containEmail = regExp.test(email) ? "success" : "validating";

  return email?.length ? containEmail : "";
};
