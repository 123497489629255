import { Button, Flex } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ROUTES } from "shared/const/routes.ts";
import { ScriptPath, ShotEditPath } from "shared/types/routes.ts";
import { generateUrl } from "shared/methods";
import { EditInput } from "./EditInput.tsx";

export const ShotDescriptionEdit: FC = () => {
  const { id, shotId } = useExtractParams<ShotEditPath>();
  const navigate = useNavigate();

  const goToScript = () => {
    if (shotId) {
      const url = generateUrl<ScriptPath>(ROUTES.SCRIPT.fullPath, { id });

      navigate(`${url}#${shotId}`, { state: { from: "shot-editing" } });
    }
  };

  return (
    <>
      <div className="shot-details-info__divider" />
      <Flex vertical wrap="wrap" className="shot-details-info__row gap-xxs">
        <Flex vertical className="gap-xs">
          <EditInput title="Description" editKey="shot_description" />
          <EditInput title="Dialogue" editKey="dialog" />
        </Flex>
        <Button size="large" onClick={goToScript}>
          Show in the script
        </Button>
      </Flex>
    </>
  );
};
