import { useAction, useAtom } from "@reatom/npm-react";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setCustomImageResource } from "@/shared/api/storyboard";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ROUTES } from "shared/const/routes.ts";
import { ShotEditPath, StoryboardPath } from "shared/types/routes.ts";
import { downloadImage, generateUrl } from "shared/methods";
import { callErrorAction } from "@/entities/notification";
import {
  getShotAction,
  getShotsOrderAction,
  shotEditingImageUploadLoadingAtom,
  updateShotAction,
} from "@/entities/shot-edit";

export const useBehaviour = () => {
  const { shotId, id, sceneId } = useExtractParams<ShotEditPath>();
  const navigate = useNavigate();

  const [shotsOrder] = useAtom(getShotsOrderAction.dataAtom);
  const [shot] = useAtom(getShotAction.dataAtom);
  const [__, setFileLoading] = useState(false);
  const [_, setShotEditingImageUploadLoading] = useAtom(shotEditingImageUploadLoadingAtom);
  const catchError = useAction(callErrorAction);
  const updateShot = useAction(updateShotAction);
  const currentShot = shotsOrder.find((shot) => shot.shot_id === shotId);

  const onDownload = async () => {
    const currentShot = shotsOrder.find((shot) => shot.shot_id === shotId);

    if (shot?.image_url && currentShot) {
      await downloadImage(
        shot.image_url,
        `Scene ${currentShot.scene_ix + 1}. Shot ${currentShot.shot_ix + 1}`,
      );
    }
  };

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setFileLoading(true);
      setShotEditingImageUploadLoading(true);

      const formData = new FormData();
      const file = event.target?.files?.[0];
      formData.append("file", file ?? "");
      const { data } = await setCustomImageResource({ projectKey: id, shotId, sceneId }, formData);
      updateShot(data);
    } catch (e) {
      catchError(e);
    } finally {
      setFileLoading(false);
      setShotEditingImageUploadLoading(false);
    }
  };

  const handleBack = () => {
    navigate(generateUrl<StoryboardPath>(ROUTES.STORYBOARD.fullPath, { id }));
  };

  return {
    onDownload,
    handleUpload,
    handleBack,
    currentShot,
  };
};
