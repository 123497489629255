import { useAction, useAtom } from "@reatom/npm-react";
import { useKeyPress } from "ahooks";
import { useEffect, useState } from "react";
import { Point } from "react-lasso-select/lib/helpers";
import { useLocation } from "react-router-dom";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ROUTES } from "shared/const/routes.ts";
import { ShotEditEditPath } from "shared/types/routes.ts";
import { blobToBase64 } from "shared/methods";
import { bannerShownAtom, inpaintingPointsAtom } from "@/entities/inpainting";
import { getShotAction } from "@/entities/shot-edit";
import { regenerateImageAction } from "@/entities/storyboard";

export const useShotEditImageBehavior = () => {
  const { shotId } = useExtractParams<ShotEditEditPath>();
  const { pathname } = useLocation();

  const [destroyKey, setDestroyKey] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [points, setPoints] = useAtom(inpaintingPointsAtom);
  const [shot] = useAtom(getShotAction.dataAtom);
  const [errorShot] = useAtom(regenerateImageAction.errorAtom);
  const [_, setBannerShown] = useAtom(bannerShownAtom);

  const regenerate = useAction(regenerateImageAction);

  const lastRoutes = pathname.split("/");
  const lastRoute = lastRoutes[lastRoutes.length - 1];
  const isEmptyImage = !shot?.image_url_compressed;
  const canInpainting = lastRoute === ROUTES.SHOT_EDIT_EDIT.routePath;

  const zeroingPoints = () => {
    setPoints([]);
    setDestroyKey(Math.random().toString());
  };

  const completePoints = (value: Point[]) => {
    setPoints(value);
    setBannerShown((prev) => ({
      ...prev,
      [shotId]: true,
    }));
  };

  useEffect(() => {
    const init = () => {
      if (!isEmptyImage) {
        fetch(shot?.image_url_compressed)
          .then((res) => res.blob())
          .then((res) =>
            blobToBase64(res, (_base64, blob) => {
              const localUrl = window.URL.createObjectURL(blob);
              setImageUrl(localUrl);
            }),
          );
      } else {
        setImageUrl("");
      }
    };

    init();
  }, [shot?.image_url_compressed, shotId]);

  useKeyPress(["esc"], zeroingPoints);

  return {
    destroyKey,
    errorShot,
    regenerate,
    points,
    completePoints,
    imageUrl,
    canInpainting,
    zeroingPoints,
    isEmptyImage,
  };
};
