import { Layout } from "antd";
import { FC, PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import { GRAYSCALE_TOKEN } from "shared/const/color-names.ts";
import {useProjectType} from "@/entities/projects";
import {usePermission} from "@/shared/hooks/use-permission.ts";
import {PermissionPage} from "shared/types/share.ts";
import {useCurrentPage} from "@/shared/hooks/use-current-page.ts";
import {useExtractParams} from "@/shared/hooks/use-extract-params.ts";
import {StoryboardSidebarPath} from "shared/types/routes.ts";

type Props = {
  Page: FC<PropsWithChildren<unknown>>;
  isFirstComments: boolean;
};

export const StoryboardRightSidebar: FC<Props> = ({ Page, isFirstComments }) => {
  const { id: projectKey } = useExtractParams<StoryboardSidebarPath>();
  const { activePage, lastParam } = useCurrentPage();
  const { isShare } = useProjectType();
  const { cannot: cannotComment } = usePermission({
    page: activePage as PermissionPage,
    group: "comment",
    projectKey,
  });

  const notEnoughPernission = isShare && cannotComment;

  const notRenderSidebar = (isFirstComments && notEnoughPernission) || (lastParam === "comments" && notEnoughPernission);

  return (
    <Layout>
      <Layout.Content>
        <Page />
      </Layout.Content>
      {!notRenderSidebar && (
        <Layout.Sider
          width={370}
          style={{
            boxSizing: "border-box",
            background: GRAYSCALE_TOKEN.SURFACE.DEFAULT.color,
            borderLeft: "1px solid var(--grayscale-border-default-light)",
          }}
        >
          <Outlet />
        </Layout.Sider>
      )}
    </Layout>
  );
};

export const StoryboardMainSidebar: FC<Props> = ({ Page }) => {
  return (
    <Layout>
      <Layout.Content>
        <Page />
      </Layout.Content>
    </Layout>
  );
};
