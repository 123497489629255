import { Layout } from "antd";
import { FC, PropsWithChildren } from "react";
import { GRAYSCALE_TOKEN } from "../../const/color-names.ts";

type Props = {
  HeaderContent: FC<PropsWithChildren<unknown>>;
};

export const HeaderLayout: FC<PropsWithChildren<Props>> = ({ children, HeaderContent }) => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Layout.Header
        style={{
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
          background: GRAYSCALE_TOKEN.SURFACE.DEFAULT.color,
          padding: 0,
          height: "auto",
        }}
      >
        <HeaderContent />
      </Layout.Header>
      {children}
    </Layout>
  );
};
