import { Flex, Skeleton } from "antd";
import { FC, PropsWithChildren } from "react";

import "./DraggableBoard.scss";

type Props = {
  isLoading: boolean;
};

export const DraggableBoard: FC<PropsWithChildren<Props>> = ({ children, isLoading }) => {
  if (isLoading) {
    return <BoardLoading />;
  }

  return (
    <Flex wrap="wrap" className="draggable-board gap-xxs">
      {children}
    </Flex>
  );
};

export const BoardLoading = () => {
  return (
    <Flex
      className="full-width full-height draggable-board--loading gap-xs"
      justify="space-between"
      wrap
    >
      <Flex vertical style={{ width: "calc(50% - 8px)" }} className="full-height gap-xs ">
        <Skeleton.Node className="skeleton-image-210" active />
        <Skeleton.Node className="skeleton-image-280" active />
        <Skeleton.Node className="skeleton-image-320" active />
      </Flex>
      <Flex vertical style={{ width: "calc(50% - 8px)" }} className="full-height gap-xs ">
        <Skeleton.Node className="skeleton-image-120" active />
        <Skeleton.Node className="skeleton-image-250" active />
        <Skeleton.Node className="skeleton-image-100" active />
        <Skeleton.Node className="skeleton-image-320" active />
      </Flex>
    </Flex>
  );
};
