import gridIcon from "assets/storyboard/grid.svg";
import { IconButtonWrapper } from "shared/ui";

export const GridIcon = () => {
  return (
    <IconButtonWrapper>
      <img alt="grid icon" src={gridIcon} style={{ width: 16, height: 16 }} />
    </IconButtonWrapper>
  );
};
