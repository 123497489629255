import { FC } from "react";
import listIcon from "assets/storyboard/list.svg";
import { IconButtonWrapper } from "shared/ui";

export const ListIcon: FC = () => {
  return (
    <IconButtonWrapper>
      <img src={listIcon} alt="list" style={{ width: 16, height: 16 }} />
    </IconButtonWrapper>
  );
};
