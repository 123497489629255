import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useAtom} from "@reatom/npm-react";
import {shotActiveHash} from "@/entities/script";

export const useShotActiveHash = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [activeHash, setActiveHashState] = useAtom(shotActiveHash);

	useEffect(() => {
		if (!location.hash) return;

		setActiveHashState(location.hash.slice(1));
	}, [])

	const setActiveHash = (shotId: string) => {
		navigate(`${location.pathname}#${shotId}`, { replace: true });
		setActiveHashState(shotId);
	};

	return {
		activeHash,
		setActiveHash
	}
};
