import { useSize } from "ahooks";
import { Flex } from "antd";
import { FC, PropsWithChildren, useRef } from "react";

import "./BackgroundPreLogin.scss";

export const BackgroundPreLogin: FC<PropsWithChildren> = ({ children }) => {
  const ref = useRef(null);
  const size = useSize(ref);

  return (
    <Flex ref={ref} justify="center" align="center" className="background-pre-login">
      <div className="background-pre-login__content">
        <div
          className="triangle"
          style={{
            borderWidth: `0 0 ${size?.height ?? 0}px ${size?.width ?? 0}px`,
          }}
        />
        <div className="gradient">
          <div className="radial one"></div>
          <div className="radial two"></div>
          <div className="radial three"></div>
        </div>
      </div>
      <Flex
        style={{ zIndex: 100 }}
        justify="center"
        align="center"
        className="full-width full-height"
      >
        {children}
      </Flex>
    </Flex>
  );
};
