import { useAction, useAtom } from "@reatom/npm-react";
import { useKeyPress } from "ahooks";
import { Flex, Skeleton, Typography } from "antd";
import { FC, useEffect, useRef } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ROUTES } from "shared/const/routes.ts";
import { ScriptPath } from "shared/types/routes.ts";
import { useUpdateTitle } from "shared/hooks";
import { generateUrl } from "shared/methods";
import { getProjectByNameAction, useProjectType } from "@/entities/projects";
import {
  backAction,
  forwardAction,
  getSceneListAction, useShotActiveHash,
} from "@/entities/script";
import { editingShotAtom } from "../index";
import { SceneList } from "./scene-list";

import "./HighlightScript.scss";

export const HighlightScript: FC = () => {
  const { id } = useExtractParams<ScriptPath>();
  const navigate = useNavigate();
  const [status] = useAtom(getSceneListAction.statusesAtom);
  const location = useLocation();
  const [editingShot] = useAtom(editingShotAtom);
  const historyBack = useAction(backAction);
  const historyForward = useAction(forwardAction);
  const descriptionElementRef = useRef<HTMLDivElement | null>(null);
  const { activeHash } = useShotActiveHash();
  const fromEdit = useRef(false);
  const { isShare } = useProjectType();
  const [project] = useAtom(getProjectByNameAction.dataAtom);

  useUpdateTitle(project?.name);

  useKeyPress(
    ["ctrl.z", "meta.z"],
    () => {
      if (!editingShot && !isShare) {
        historyBack(id);
      }
    },
    { exactMatch: true },
  );

  useKeyPress(["shift.ctrl.z", "shift.meta.z"], () => {
    if (!editingShot && !isShare) {
      historyForward(id);
    }
  });

  useEffect(() => {
    if (location.state?.from === "shot-editing") {
      fromEdit.current = true;
    } else {
      fromEdit.current = false;
    }
  }, []);

  useEffect(() => {
    if (!descriptionElementRef.current) return;

    if (!activeHash && !location.hash) return;

    const anchorId = activeHash || location.hash.slice(1);

    const element = descriptionElementRef.current.querySelector(`[id="${anchorId}"]`);

    if (!fromEdit.current) {
      const url = generateUrl<ScriptPath>(ROUTES.SCRIPT.fullPath, { id });
      navigate(`${url}#${anchorId}`);
    }

    element?.scrollIntoView({ behavior: "smooth" });

    return () => {
      fromEdit.current = false;
    };
  }, [activeHash]);

  return (
    <Flex ref={descriptionElementRef} vertical className="highlight-the-shots full-height gap-xs">
      {status.isPending ? (
        <Flex justify="center">
          <Skeleton style={{ width: "50%" }} active paragraph={false} />
        </Flex>
      ) : (
        <Typography.Text className="text-center highlight-the-shots__title">
          “{project?.name}”
        </Typography.Text>
      )}
      <Flex vertical className="gap-s">
        <SceneList />
      </Flex>
    </Flex>
  );
};
