import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, message, Modal, Typography, Upload, UploadFile } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import imageModal from "assets/moodboard/image-modal.svg";
import cn from "classnames";
import { FC } from "react";

import { addImageToMoodboardAction, getImageSizeFromBlob } from "@/entities/moodboard";
import { TMoodBoardImageCoordinate } from "@/shared/api/moodboard";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import uploadImage from "assets/shared/plus_load.svg";
import { GRAYSCALE_TOKEN, PRIMARY_TOKEN } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { IOpenModal } from "shared/hooks";
import { MoodboardPath } from "shared/types/routes.ts";
import { imageListAtom } from "../model";

import "./AddImageMoodboard.scss";

export const AddImageMoodboard: FC<IOpenModal> = ({ isOpen, toggleModal }) => {
  const { id } = useExtractParams<MoodboardPath>();
  const [imageList, setImageList] = useAtom(imageListAtom);
  const sendImages = useAction(addImageToMoodboardAction);
  const handleOk = async () => {
    const formData = new FormData();

    const data = imageList.map(async (image) => {
      if (image?.originFileObj) {
        const arrayBuffer = await image.originFileObj
          .arrayBuffer()
          .then((arrayBuffer) => arrayBuffer);

        if (arrayBuffer) {
          const blob = new Blob([new Uint8Array(arrayBuffer)], { type: image.type });

          const { w, h, minW, minH } = await getImageSizeFromBlob(blob);

          formData.append("images", image.originFileObj);
          setImageList([]);

          return {
            w,
            h,
            minW,
            minH,
            x: 0,
            y: 0,
          };
        }

        return null;
      }

      return null;
    });

    const coordinatesPromises = await Promise.all(data);
    const coordinates = coordinatesPromises.filter((el) => el !== null);

    sendImages(id, formData, coordinates as TMoodBoardImageCoordinate[]);

    toggleModal();
  };

  const handleCancel = () => {
    toggleModal();
    setImageList([]);
  };

  const onChange = (info: UploadChangeParam) => {
    setImageList([...info.fileList]);
  };

  const beforeUpload = (file: UploadFile) => {
    const updatedList = [...imageList, file];
    const filesSize = updatedList.reduce((acc, cur) => acc + (cur?.size ?? 0), 0);
    const isLt16M = filesSize / 1024 / 1024 < Number(process.env.VITE_IMAGE_LOAD_LIMIT_MB);

    if (isLt16M) {
      setImageList([...imageList, file]);
    } else {
      message.error(`All files must be smaller than ${process.env.VITE_IMAGE_LOAD_LIMIT_MB}MB!`);
      return Upload.LIST_IGNORE;
    }

    return false;
  };

  const onRemove = (file: UploadFile) => {
    const index = imageList.indexOf(file);
    const newFileList = imageList.slice();

    newFileList.splice(index, 1);

    setImageList(newFileList);
  };

  return (
    <Modal
      width={420}
      open={isOpen}
      onCancel={handleCancel}
      footer={[
        <Flex gap={8} className="full-width add-image-moodboard__footer">
          <Button
            htmlType="button"
            size="middle"
            className="flex-1"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            disabled={!imageList.length}
            htmlType="button"
            type="primary"
            className="flex-1"
            size="middle"
            key="add"
            onClick={handleOk}
          >
            Add
          </Button>
        </Flex>,
      ]}
    >
      <Flex className="add-image-moodboard" vertical align="center">
        <img src={imageModal} alt="image upload" />
        <Typography.Text className={cn("add-image-moodboard__title", TEXT_CLASSNAMES.Title50)}>
          Upload your images
        </Typography.Text>
        <Typography.Text className={cn("add-image-moodboard__description", TEXT_CLASSNAMES.Body20)}>
          Upload your pictures and they will appear in the moodboard
        </Typography.Text>
        <Upload.Dragger
          name="file"
          multiple
          onChange={onChange}
          className="add-image-moodboard__upload"
          fileList={imageList}
          beforeUpload={beforeUpload}
          onRemove={onRemove}
          listType="picture"
          accept=".png, .jpg, .jpeg, .webp"
        >
          <p className="ant-upload-drag-icon">
            <img src={uploadImage} alt="upload image" />
          </p>
          <Typography.Text className={TEXT_CLASSNAMES.Body20}>
            Drag and drop to upload, or{" "}
            <Typography.Text
              underline
              className={cn(TEXT_CLASSNAMES.Link20, PRIMARY_TOKEN.TEXT_ICON.LINK_LABEL.className)}
            >
              browse
            </Typography.Text>
          </Typography.Text>
          <Typography.Paragraph
            className={cn(TEXT_CLASSNAMES.Body10, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
          >
            Supports: PNG, JPG
          </Typography.Paragraph>
        </Upload.Dragger>
      </Flex>
    </Modal>
  );
};
