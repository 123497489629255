import { useAction, useAtom } from "@reatom/npm-react";
import { useDebounceFn } from "ahooks";
import { Flex, Input } from "antd";
import { ChangeEvent, FC } from "react";

import clear from "assets/shared/allow_clear.svg";
import search from "assets/shared/search.svg";

import searchPreview from "assets/shared/search_preview.svg";
import { getEmbeddingsAction, searchBoardViewAtom } from "../index";

export const SearchBoardAutocomplete: FC = () => {
  const getEmbeddings = useAction(getEmbeddingsAction);
  const [_searchBoardView, setSearchBoardView] = useAtom(searchBoardViewAtom);
  const { run } = useDebounceFn(
    (event: ChangeEvent<HTMLInputElement>) => {
      getEmbeddings(event.target.value);
    },
    { wait: 700 },
  );

  return (
    <Input
      placeholder="Search"
      prefix={<img src={search} alt="search" />}
      allowClear={{ clearIcon: <img src={clear} alt="clear" /> }}
      addonAfter={
        <Flex onClick={() => setSearchBoardView("image")} className="search-board__addon-after">
          <img src={searchPreview} alt="search preview" />
        </Flex>
      }
      onChange={run}
    />
  );
};
