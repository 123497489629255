import { captureException } from "@sentry/react";

export const urlToBlob = async (url: string) => {
  try {
    const res = await fetch(url);
    const blob = await res.blob();

    return blob;
  } catch (e) {
    captureException(e, {
      tags: {
        feature: "utils-url-to-blob",
      },
      level: "log",
    });
  }
};
