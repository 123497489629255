import { useAction, useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC, useEffect } from "react";

import { UploadImageResult } from "@/shared/api/storyboard";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ShotEditPath } from "shared/types/routes.ts";
import { ActionRestrictor } from "shared/ui";
import { useProjectType } from "@/entities/projects";
import {
  getShotAction,
  getShotsOrderAction,
  isShotSaveChangeAtom,
  saveShotChangesAction,
  shotEditStateAtom,
  updateShotAction,
} from "@/entities/shot-edit";
import {
  generateImageAction,
  regenerateImageAction,
  updateRegenerateImageAction,
} from "@/entities/storyboard";
import { Generate, Regenerate, Save } from "./buttons";
import { ShotDetailsInfo } from "./shot-details-info";

export const ShotDetails: FC = () => {
  const { id, shotId, sceneId } = useExtractParams<ShotEditPath>();

  const regenerateImage = useAction(regenerateImageAction);
  const generateImage = useAction(generateImageAction);
  const updateRegenerateImage = useAction(updateRegenerateImageAction);
  const updateShot = useAction(updateShotAction);
  const saveShotChanges = useAction(saveShotChangesAction);
  const getShotsOrder = useAction(getShotsOrderAction);

  const [shot] = useAtom(getShotAction.dataAtom);
  const [shotEditState] = useAtom(shotEditStateAtom);
  const [regenerateImagePending] = useAtom(regenerateImageAction.statusesAtom);
  const [generateImagePending] = useAtom(generateImageAction.statusesAtom);
  const [shotsOrder] = useAtom(getShotsOrderAction.dataAtom);
  const [isShotSaveChange] = useAtom(isShotSaveChangeAtom);
  const isImg = !!shot?.image_url_compressed;
  const { isShare } = useProjectType();

  const currentShot = shotsOrder.find((shot) => shot.shot_id === shotId);

  const onRegenerate = async () => {
    updateRegenerateImage({ shotId, loading: true, image: null, error: false });
    const data = await regenerateImage({ shotId, projectKey: id, sceneId });
    const params: UploadImageResult = {
      bw_shot_image: data?.image_url ?? "",
      bw_shot_image_compressed: data?.image_url_compressed ?? "",
      bw_shot_image_watermarked: data?.image_url ?? "",
      bw_shot_image_watermarked_compressed: data?.image_url_compressed ?? "",
      id: 0,
    };

    updateShot(params);
    updateRegenerateImage({
      shotId,
      loading: false,
      image: data?.image_url_compressed ?? null,
      error: false,
    });
  };

  const onGenerate = async () => {
    updateRegenerateImage({ shotId, loading: true, image: null, error: false });
    const data = await generateImage({ shotId, projectKey: id, sceneId });
    const params: UploadImageResult = {
      bw_shot_image: data?.image_url ?? "",
      bw_shot_image_compressed: data?.image_url_compressed ?? "",
      bw_shot_image_watermarked: data?.image_url ?? "",
      bw_shot_image_watermarked_compressed: data?.image_url_compressed ?? "",
      id: 0,
    };

    updateShot(params);
    updateRegenerateImage({
      shotId,
      loading: false,
      image: shot?.image_url_compressed ?? null,
      error: false,
    });
  };

  const onSave = () => {
    saveShotChanges({
      projectKey: id,
      shotId,
      sceneId,
      shot_info: shotEditState,
      shots_order: shotsOrder.filter((el) => el.scene_id === sceneId).map((el) => el.shot_id),
    });
  };

  const isGenerate = !isImg && !isShotSaveChange;
  const isRegenerate = isImg && !isShotSaveChange;
  const isSave = isShotSaveChange;

  useEffect(() => {
    getShotsOrder(id);
  }, [id]);

  if (!currentShot) {
    return null;
  }

  return (
    <Flex vertical justify="space-between" className="full-height full-width">
      <Flex
        style={{ overflowY: "scroll" }}
        vertical
        justify="space-between"
        className="full-width gap-xs"
      >
        <Flex vertical className="gap-xs">
          <ShotDetailsInfo />
        </Flex>
      </Flex>
      <Flex style={{ paddingTop: 8 }} vertical className="gap-xs">
        <ActionRestrictor show={!isShare}>
          {isRegenerate && (
            <Regenerate onRegenerate={onRegenerate} disabled={regenerateImagePending.isPending} />
          )}
          {isGenerate && (
            <Generate onGenerate={onGenerate} disabled={generateImagePending.isPending} />
          )}
          {isSave && <Save onSave={onSave} disabled={false} />}
        </ActionRestrictor>
      </Flex>
    </Flex>
  );
};
