import { UserOutlined } from "@ant-design/icons";
import { useHover } from "ahooks";
import { Avatar, Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useMemo, useRef } from "react";
import { Comment as CommentType } from "@/shared/api/comments";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { getDisplayedUserName } from "shared/methods/getDisplayedUserName";
import { useOpenModal } from "shared/hooks";
import { timeAgo } from "shared/methods";

import { ActionRestrictor } from "shared/ui";
import { CommentCollapseState } from "../../lib";
import { getPermissions } from "../../lib/utils";
import { CommentActions } from "./CommentActions";

import { DeleteMessageModal } from "./DeleteMessageModal";
import { DeleteThreadModal } from "./DeleteThreadModal";
import { Replies } from "./Replies";

import "./Comment.scss";

interface CommentProps extends CommentType {
  currUserEmail?: string;
  projectKey: string;
  repliesAmount: number;
  setActiveKey: React.Dispatch<React.SetStateAction<CommentCollapseState>>;
  isActive: boolean;
}

export const Comment: FC<CommentProps> = ({
  avatar,
  username,
  email,
  first_name,
  last_name,
  resolved,
  created_at,
  message,
  id,
  currUserEmail,
  parent_id,
  repliesAmount,
  setActiveKey,
  isActive,
}) => {
  const [deleteThreadModalOpen, toggleDeleteThreadModalOpen] = useOpenModal();
  const [deleteMessageModalOpen, toggleDeleteMessageModalOpen] = useOpenModal();

  const commentRef = useRef<HTMLDivElement>(null);
  const isHover = useHover(commentRef);

  const memoUserName = useMemo(() => {
    return getDisplayedUserName({ fn: first_name, kn: last_name, username, email });
  }, [username, email, first_name, last_name]);

  const ago = () => {
    const date = new Date(created_at);
    return timeAgo(date);
  };

  const permissions = getPermissions({
    resolved,
    currUserEmail,
    commentUserEmail: email,
    parentId: parent_id,
  });

  const showCommentActions = isHover || (isActive && !parent_id);

  return (
    <Flex
      ref={commentRef}
      id={id}
      vertical
      className={cn({ "comment--active": resolved }, "comment gap-xxs")}
    >
      <Flex justify="space-between" align="center">
        <Flex align="center" className="gap-4">
          <Flex align="center" className="gap-xxs">
            <Avatar
              size={24}
              icon={avatar ? <img src={avatar} alt="avatar" /> : <UserOutlined />}
            />
            {/* eslint-disable-next-line camelcase */}
            <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>{memoUserName}</Typography.Text>
          </Flex>
          {/* eslint-disable-next-line camelcase */}
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
          >
            {ago()}
          </Typography.Text>
        </Flex>
        <ActionRestrictor show={showCommentActions}>
          <CommentActions
            onDeleteThreadModalOpen={() => toggleDeleteThreadModalOpen(true)}
            onDeleteMessageModalOpen={() => toggleDeleteMessageModalOpen(true)}
            commentId={id}
            commentRef={commentRef}
            permissions={permissions}
          />
        </ActionRestrictor>
      </Flex>
      <Flex>
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{message}</Typography.Text>
      </Flex>
      <ActionRestrictor show={parent_id === null}>
        <Flex
          align="center"
          role="button"
          className="gap-4 pointer"
          onClick={() => setActiveKey((prev) => (prev === 0 ? 1 : 0))}
        >
          <Replies amount={repliesAmount} isActive={isActive} isResolved={resolved} />
        </Flex>
      </ActionRestrictor>
      {deleteThreadModalOpen && (
        <DeleteThreadModal
          isOpen={deleteThreadModalOpen}
          toggleModal={toggleDeleteThreadModalOpen}
          commentId={id}
        />
      )}
      {deleteMessageModalOpen && (
        <DeleteMessageModal
          isOpen={deleteMessageModalOpen}
          toggleModal={toggleDeleteMessageModalOpen}
          commentId={id}
        />
      )}
    </Flex>
  );
};
