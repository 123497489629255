import {
  reatomAsync,
  withAbort,
  // withCache,
  withDataAtom,
  withErrorAtom,
  withStatusesAtom,
} from "@reatom/async";
import { action, atom } from "@reatom/core";
import { AxiosError } from "axios";
import Konva from "konva";
import { Vector2d } from "konva/lib/types";
import { getRandomCoordinatesInRectangle } from "@/features/full-moodboard";
import { RandomCenterDropImagePosition } from "@/features/full-moodboard/lib/types";
import { getArchiveBoardAction, removeFromArchiveBoardAction } from "@/entities/archive-board";
import {
  getInspirationImagesAction,
  removeFromInspirationBoardAction,
} from "@/entities/inspiration-board";
import { Attrs, TMoodBoardStatus } from "@/entities/moodboard/lib";
import { callErrorAction } from "@/entities/notification";
import { removeFromSearchBoardAction, searchBoardListAtom } from "@/entities/search-board";
import {
  addMoodboardImages,
  addMoodboardsImagesCoordinates,
  deleteMoodboardImages,
  getMoodboardsResource,
  TMoodboardImage,
  TMoodBoardImageCoordinate,
} from "@/shared/api/moodboard";
import { urlToBlob } from "@/shared/methods/url-to-blob.ts";
import { getImgSize } from "shared/methods";

export const moodboardLoadingAtom = atom<boolean>(false);

export const moodBoardStatusAtom = atom<TMoodBoardStatus>((ctx) => {
  const list = ctx.spy(getMoodboardAction.dataAtom);

  return list.length > 0 ? "full" : "empty";
}, "moodBoardStatusAtom");
export const centerMoodboardPositionAtom = atom<null | RandomCenterDropImagePosition>(
  null,
  "centerMoodboardPositionAtom",
);

export const getMoodboardAction = reatomAsync(async (ctx, id: string) => {
  const { data } = await getMoodboardsResource(id, ctx.controller);

  const imagesPromises: Promise<TMoodboardImage>[] = data.images
    .filter((img) => !img.archived)
    .map(async (image) => {
      const { width, height } = await getImgSize(image.url);

      if (image.coordinates.w === 0 || image.coordinates.h === 0) {
        image.coordinates.w = width;
        image.coordinates.h = height;
        image.coordinates.minW = width;
        image.coordinates.minH = height;
      }

      return image;
    });

  const images = await Promise.all(imagesPromises);

  return images;
}).pipe(
  // withCache({ swr: { shouldFulfill: false } }),
  withDataAtom([], (_ctx, images) => images),
  withStatusesAtom(),
  withErrorAtom(),
  withAbort(),
);

export const shadowMoodboardButtonsAtom = atom<Attrs[]>([], "shadowMoodboardButtonsAtom");
getMoodboardAction.dataAtom.onChange((ctx, newState) => {
  const oldButtons = ctx.get(shadowMoodboardButtonsAtom);
  const buttons: Attrs[] = newState.map((el, index) => ({
    x: el.coordinates.x,
    y: el.coordinates.y,
    width: el.coordinates.w,
    isLocked: false,
    isActive: false,
    name: el.id,
    group: oldButtons[index]?.group ?? null,
  }));

  shadowMoodboardButtonsAtom(ctx, buttons);
});

export const changeMoodboardButtonPositionAction = action((ctx, params: Attrs) => {
  const buttons = ctx.get(shadowMoodboardButtonsAtom);
  const updatedButtons = buttons.map((button) => (button.name === params.name ? params : button));

  shadowMoodboardButtonsAtom(ctx, updatedButtons);
}, "chnageMoodboardButtonPositionAction");

export const toggleMoodboardButtonActiveAction = action((ctx, id: string, isActive?: boolean) => {
  const buttons = ctx.get(shadowMoodboardButtonsAtom);
  const updatedButtons = buttons.map((button) => ({
    ...button,
    isActive: typeof isActive === "boolean" ? isActive : button.name === id,
  }));

  shadowMoodboardButtonsAtom(ctx, updatedButtons);
}, "changeMoodboardButtonActiveAction");

export const addGroupButtonAction = action((ctx, id: string, group: Konva.Group | null) => {
  const buttons = ctx.get(shadowMoodboardButtonsAtom);

  const updatedButtons = buttons.map((button) => ({
    ...button,
    group: button.name === id ? group : button.group,
  }));

  shadowMoodboardButtonsAtom(ctx, updatedButtons);
});

export const addImageToMoodboardAction = action(
  async (ctx, id: string, formData: FormData, coordinates: TMoodBoardImageCoordinate[]) => {
    try {
      moodboardLoadingAtom(ctx, true);
      const { data } = await addMoodboardImages(id, formData);

      await addMoodboardsImagesCoordinates(
        id,
        Object.keys(data).map((id, index) => ({
          ...coordinates[index],
          id,
        })),
      );
      await getMoodboardAction(ctx, id);
    } catch (err) {
      moodboardLoadingAtom(ctx, false);
      callErrorAction(ctx, err as AxiosError, true);
    }
  },
);

export const dropImageToMoodboardAction = action(
  async (ctx, id: string, formData: FormData, coordinates: TMoodBoardImageCoordinate) => {
    try {
      const { data } = await addMoodboardImages(id, formData);

      const newId = Object.keys(data)[0];
      const image = {
        ...coordinates,
        id: newId,
      };

      await addMoodboardsImagesCoordinates(id, [image]);

      return newId;
    } catch (err) {
      callErrorAction(ctx, err as AxiosError, true);
    }
  },
);

export const updateMoodboardImagesPositionAction = action(
  async (ctx, imageCoordinate: TMoodBoardImageCoordinate, id: string) => {
    const list = ctx.get(getMoodboardAction.dataAtom);

    const newImages: TMoodboardImage[] = list.map((image) => {
      if (image.id === id) {
        return {
          ...image,
          coordinates: imageCoordinate,
        };
      }

      return image;
    });

    getMoodboardAction.dataAtom(ctx, newImages);
  },
  "updateMoodboardImagesPosition",
);

export const clearMoodBoardAction = action(async (ctx, id) => {
  try {
    moodboardLoadingAtom(ctx, true);
    const imageIdList = ctx
      .get(getMoodboardAction.dataAtom)
      .filter((img) => !img.archived)
      .map((image) => image.id);

    await deleteMoodboardImages(id, imageIdList);
    await getMoodboardAction(ctx, id);
  } catch (err) {
    moodboardLoadingAtom(ctx, false);
    callErrorAction(ctx, err as AxiosError, true);
  }
});

export const removeFromMoodBoardAction = action(
  async (ctx, moodboardId: string, imageId: string) => {
    try {
      getMoodboardAction.dataAtom(
        ctx,
        ctx.get(getMoodboardAction.dataAtom).filter((item) => item.id !== imageId),
      );

      await deleteMoodboardImages(moodboardId, [imageId]);
    } catch (err) {
      callErrorAction(ctx, err as AxiosError, true);
    }
  },
);

export const addToMoodBoardAction = action((ctx, image: TMoodboardImage) => {
  getMoodboardAction.dataAtom(ctx, [...ctx.get(getMoodboardAction.dataAtom), image]);
});

type Params = {
  id: string;
  dropId: string;
  position?: Vector2d | null;
};

type DropImage = {
  image: TMoodboardImage;
} & Params;

export const toDropImage = action(async (ctx, params: DropImage) => {
  const imageBlob = await urlToBlob(params.image.url);
  const file = new File([imageBlob!], params.dropId);
  const formData = new FormData();
  formData.append("images", file);

  const centerMoodboardPosition = ctx.get(centerMoodboardPositionAtom);

  let randomX, randomY;

  if (centerMoodboardPosition) {
    const { centerCoordinates, width, height } = centerMoodboardPosition;
    const { w: imageW, h: imageH } = params.image.coordinates;
    ({ x: randomX, y: randomY } = getRandomCoordinatesInRectangle(
      centerCoordinates[0],
      centerCoordinates[1],
      width,
      height,
      imageW,
      imageH,
    ));
  }

  const newId = await dropImageToMoodboardAction(ctx, params.id as string, formData, {
    ...params.image.coordinates,
    x: params?.position?.x ?? randomX ?? 0,
    y: params?.position?.y ?? randomY ?? 0,
    minW: 0,
  });

  const searchParams = new URL(window.location.href).searchParams;
  const moodboardType = searchParams.get("grid");

  const paramsImageCoordinateX =
    moodboardType === "structure" || !moodboardType ? (randomX ?? 0) : params.image.coordinates.x;
  const paramsImageCoordinateY =
    moodboardType === "structure" || !moodboardType ? (randomY ?? 0) : params.image.coordinates.y;

  addToMoodBoardAction(ctx, {
    ...params.image,
    id: newId ?? params.image.id,
    coordinates: {
      ...params.image.coordinates,
      x: params?.position?.x ?? paramsImageCoordinateX ?? randomX ?? 0,
      y: params?.position?.y ?? paramsImageCoordinateY ?? randomY ?? 0,
      minW: 0,
    },
  });
});

export const dropImageFromSidebarsAction = action(async (ctx, params: Params) => {
  const inspirationBoardList = ctx.get(getInspirationImagesAction.dataAtom);
  const searchBoardList = ctx.get(searchBoardListAtom);
  const archiveBoardList = ctx.get(getArchiveBoardAction.dataAtom);

  const imageFromInspirationBoard = inspirationBoardList.find((item) => item.id === params.dropId);
  const imageFromSearchBoard = searchBoardList.find((item) => item.id === params.dropId);
  const imageFromArchiveBoard = archiveBoardList.find((item) => item.id === params.dropId);

  if (params.id) {
    if (imageFromArchiveBoard) {
      removeFromArchiveBoardAction(ctx, params.dropId);

      await toDropImage(ctx, {
        ...params,
        image: imageFromArchiveBoard,
      });
    }

    if (imageFromSearchBoard) {
      removeFromSearchBoardAction(ctx, params.dropId);

      await toDropImage(ctx, {
        ...params,
        image: imageFromSearchBoard,
      });
    }

    if (imageFromInspirationBoard) {
      removeFromInspirationBoardAction(ctx, params.dropId);
      await toDropImage(ctx, {
        ...params,
        image: imageFromInspirationBoard,
      });
    }
  }
});
