import { api } from "@/shared/api";

import {
  AddTeamMemberDTO,
  CreateTeamDTO,
  DeleteTeamMemberDTO,
  Member,
  TeamResult,
} from "./types.ts";
import { TResponse } from "@/vite-env";

export const getTeamsResource = (): TResponse<TeamResult> => api.get("/teams");

export const createTeamResource = (dto: CreateTeamDTO): TResponse<TeamResult> =>
  api.post("/teams", dto);

export const addToTeamResource = (teamId: string, dto: AddTeamMemberDTO) =>
  api.put(`/teams/${teamId}`, dto);
export const inviteToTeamResource = (teamId: string, dto: AddTeamMemberDTO) =>
  api.post(`/teams/${teamId}/invite`, dto);

export const updateTeamMemberResource = (teamId: string, dto: Member) =>
  api.patch(`/teams/${teamId}`, dto);

export const getTeamByIdResource = (teamId: string) => api.get(`/teams/${teamId}`);

export const deleteTeamByIdResource = (teamId: string) => api.delete(`/teams/${teamId}`);

export const deleteTeamMemberResource = (teamMember: DeleteTeamMemberDTO) =>
  api({
    method: "DELETE",
    url: `/teams/${teamMember.teamId}`,
    data: {
      email: teamMember.email,
    },
  });
