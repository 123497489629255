import { ConfigProvider, Flex, Segmented, Typography } from "antd";
import cn from "classnames";
import { FC, useMemo, useState } from "react";

import { COLOR_CLASSNAMES, COLOR_NAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import check from "assets/landing/check.svg";
import plus from "assets/landing/plus.svg";
import { useOpenModal, useToTop } from "shared/hooks";
// import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { LandingContent, PageContainer, PageHeader, TrialButton } from "shared/ui";
import { UseCaseFooter } from "@/entities/use-cases";
import { RequestAccessModal } from "@/features/request-access-modal";
import { Header } from "@/widgets/header";
// import line from "assets/landing/pricing-line.svg";

import "./Pricing.scss";

type Currency = "$" | "€" | "£";

type Intent = "single" | "business" | "enterprise";

type Amount = Record<Intent, Record<Currency, string>>;

export const Pricing: FC = () => {
  // const { isLaptopS, isTablet } = useMediaQueries();
  const [open, toggleOpen] = useOpenModal();
  const [currency, setCurrency] = useState<Currency>("€");

  useToTop();

  const amount: Amount = {
    single: {
      $: "52",
      "€": "49",
      "£": "41",
    },
    business: {
      $: "219",
      "€": "199",
      "£": "179",
    },
    enterprise: {
      $: "Contact us",
      "€": "Contact us",
      "£": "Contact us",
    },
  };

  const pricing = useMemo(() => {
    return [
      {
        title: "Single User",
        priceText: `${currency}${amount.single[currency]}`,
        interval: "/per month",
        options: [
          "1 user",
          "5 projects maximum",
          "Short scripts (10000 characters max)",
          "Watermarks",
        ],
        additionalOptions: [],
        link: "",
        isContact: false,
        buttonText: "Start free trial",
      },
      {
        title: "Business",
        priceText: `${currency}${amount.business[currency]}`,
        interval: "/per month",
        options: ["20 users", "Unlimited projects", "Watermarks (option)"],
        additionalOptions: [
          "Custom characters",
          "Collaboration features",
          "Movie pitch",
          "Premium support",
        ],
        link: "",
        isContact: true,
        buttonText: "Contact us",
      },
      {
        title: "Enterprise",
        priceText: amount.enterprise[currency],
        interval: "",
        options: ["Unlimited users", "Unlimited projects", "No watermarks"],
        additionalOptions: [
          "On-premise deployment option",
          "Custom model training",
          "Supports multiple SAML configurations",
          "Integration with data loss prevention and offline backup providers",
          "HIPAA-compliant messaging and file collaboration",
        ],
        link: "",
        isContact: true,
        buttonText: "Contact us",
      },
    ];
  }, [currency]);

  return (
    <Flex align="center" justify="center" vertical className="contacts">
      <div style={{ background: COLOR_NAMES.BackgroundWhite, width: "100%" }}>
        <LandingContent>
          <Header />
        </LandingContent>
      </div>
      <PageHeader headerText="Pricing" italicHeaderText="plans" />
      <PageContainer>
        <Flex justify="center">
          <ConfigProvider
            theme={{
              components: {
                Segmented: {
                  itemSelectedBg: COLOR_NAMES.MetalGray50,
                },
              },
            }}
          >
            <Segmented
              className="xs-regular custom-segmented"
              size="large"
              value={currency}
              onChange={setCurrency}
              options={[
                {
                  label: "€ EUR",
                  value: "€",
                },
                {
                  label: "$ USD",
                  value: "$",
                },
                {
                  label: "£ GBP",
                  value: "£",
                },
              ]}
              style={{
                border: "1px solid #EAEAEC",
                background: "transparent",
                height: "min-content",
                padding: "4px",
              }}
            />
          </ConfigProvider>
        </Flex>
        <Flex wrap="wrap" justify="center" className="full-width" gap={16}>
          {pricing.map((price, index) => (
            <Flex
              flex={1}
              justify="space-between"
              vertical
              gap={32}
              key={index.toString(36)}
              className={cn("pricing-block", {
                "pricing-block--active": !!price.link,
              })}
            >
              <Flex gap={16} vertical>
                <Typography.Text
                  className={cn(COLOR_CLASSNAMES.TextPrimary, TEXT_CLASSNAMES.SmSemibold)}
                >
                  {price.title}
                </Typography.Text>
                <Flex align="center">
                  <Typography.Text
                    className={cn(COLOR_CLASSNAMES.TextPrimary, TEXT_CLASSNAMES.LPHeadlineH3)}
                  >
                    {price.priceText}
                  </Typography.Text>
                  {!!price.interval && (
                    <Typography.Text
                      className={cn(COLOR_CLASSNAMES.TextPrimary, TEXT_CLASSNAMES.XsRegular)}
                    >
                      {price.interval}
                    </Typography.Text>
                  )}
                </Flex>
                <div style={{ width: "100%", height: 1, background: COLOR_NAMES.MetalGray400 }} />
                <Flex gap={8} vertical>
                  {price.options.map((el, index) => (
                    <Flex gap={8} align="center" key={index.toString(36)}>
                      <img style={{ width: 24, height: 24 }} src={check} alt="check" />
                      <Typography.Text
                        className={cn(COLOR_CLASSNAMES.TextPrimary, TEXT_CLASSNAMES.SmRegular)}
                      >
                        {el}
                      </Typography.Text>
                    </Flex>
                  ))}
                  {!!price.additionalOptions.length && (
                    <>
                      <img
                        style={{ width: 24, height: 24, margin: "0 auto" }}
                        src={plus}
                        alt="plus"
                      />
                      {price.additionalOptions.map((el) => (
                        <Flex gap={8} align="center" key={index.toString(36)}>
                          <img style={{ width: 24, height: 24 }} src={check} alt="check" />
                          <Typography.Text
                            className={cn(COLOR_CLASSNAMES.TextPrimary, TEXT_CLASSNAMES.SmRegular)}
                          >
                            {el}
                          </Typography.Text>
                        </Flex>
                      ))}
                    </>
                  )}
                </Flex>
              </Flex>
              <TrialButton
                fullWidth
                text={price.buttonText}
                onClick={
                  price.isContact
                    ? () => {
                        if (price.isContact) {
                          toggleOpen(true);
                        }
                      }
                    : undefined
                }
              />
            </Flex>
          ))}
        </Flex>
      </PageContainer>
      <RequestAccessModal isOpen={open} toggleModal={toggleOpen} />
      <div className="full-width">
        <UseCaseFooter />
      </div>
    </Flex>
  );
};
