import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, HTMLAttributes, PropsWithChildren } from "react";

import { TEXT_CLASSNAMES } from "shared/const/text-classnames";

import "./SettingsWrapper.scss";

interface ISettingsWrapper extends HTMLAttributes<HTMLDivElement> {
  title: string;
  description: string;
}

export const SettingsWrapper: FC<PropsWithChildren<ISettingsWrapper>> = ({
  children,
  title,
  description,
  className,
  ...props
}) => {
  return (
    <Flex vertical className={cn("settings-wrapper", className)} {...props}>
      <Flex vertical>
        <Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>{title}</Typography.Text>
        <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{description}</Typography.Text>
      </Flex>
      {children}
    </Flex>
  );
};
