import useCountdown from "ahooks/lib/useCountDown";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useCallback, useEffect, useState } from "react";
import { TEXT_CLASSNAMES } from "../../../const/text-classnames";
import { DotWindmill } from "../../../ui";
import { TEXTS } from "../lib";

import "./TextLoader.scss";

type Props = {
  isOverlay?: boolean;
  time: number;
};

export const TextLoader: FC<Props> = ({ isOverlay, time }) => {
  const [counter, setCounter] = useState(Math.random());
  const [countdown] = useCountdown({ leftTime: time });
  const [dots, setDots] = useState(".");

  const randomText = useCallback(() => {
    const random = TEXTS[Math.floor(counter * TEXTS.length)];

    return random;
  }, [counter]);

  useEffect(() => {
    const timeout = setInterval(() => {
      setCounter(Math.random());
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev === "." ? ".." : prev === ".." ? "..." : "."));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const minutes = Math.floor(countdown / (60 * 1000));
  const seconds = Math.floor((countdown % (60 * 1000)) / 1000);

  const displayTime =
    countdown > 0 ? `Estimated time: ${minutes}m ${seconds}s` : `Finishing generation${dots}`;

  return (
    <>
      <div
        className={cn("text-loader-overlay", {
          "text-loader-overlay__open": isOverlay,
        })}
      />
      <Flex vertical justify="center" align="center" className="text-loader gap-xs">
        <DotWindmill />
        <Flex vertical className="text-center">
          <Typography.Text className={TEXT_CLASSNAMES.SmRegular}>
            Storyboard generation may take a while. {displayTime}
            <br /> In the meantime, {randomText()}
          </Typography.Text>
        </Flex>
      </Flex>
    </>
  );
};
