import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";
import composition from "assets/use-cases/storyboard/composition.png";
import location from "assets/use-cases/storyboard/location.png";
import moodboard from "assets/use-cases/storyboard/moodboard.png";
import preview from "assets/use-cases/storyboard/preview.png";
import storyboard from "assets/use-cases/storyboard/storyboard.png";
import { useToTop } from "shared/hooks";
import { LandingContent } from "shared/ui";
import {
  UseCaseBlock,
  UseCaseContent,
  UseCaseFooter,
  UseCaseTitle,
  UseCaseWrapper,
} from "@/entities/use-cases";
import { Header } from "../../widgets/header";

import "./UseCaseStoryboard.scss";

export const UseCaseStoryboard: FC = () => {
  const { isMobileXL, isLaptopM } = useMediaQueries();
  useToTop();

  return (
    <Flex vertical>
      <LandingContent>
        <Header />
      </LandingContent>
      <UseCaseTitle
        isComingSoon={false}
        description="With Blooper, directors and producers bring their artistic vision to life through seamless planning and storytelling"
        italicHeaderText="with AI"
        headerText="Convert Scripts to Storyboards Instantly "
        style={{ maxWidth: 540 }}
      />
      <UseCaseBlock
        previewImg={preview}
        title="Detailed Storyboard"
        description={
          <Flex vertical className="gap-xs">
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextWhitePrimary, {
                [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
                [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
              })}
            >
              Blooper takes care of all sequencing and continuity rules for shot composition.
            </Typography.Text>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextWhitePrimary, {
                [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
                [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
              })}
            >
              For every visualisation, we do:
            </Typography.Text>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextWhitePrimary, {
                [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
                [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
              })}
            >
              <ul
                className="list-white"
                style={{ display: "flex", flexDirection: "column", gap: 4 }}
              >
                <li>Shot type</li>
                <li>Camera angle</li>
                <li>Scene details</li>
                <li>List of props</li>
                <li>Equipment predictions</li>
                <li>Location suggestions</li>
              </ul>
            </Typography.Text>
          </Flex>
        }
      />
      <UseCaseWrapper>
        <Flex vertical={isMobileXL} className="gap-m">
          <UseCaseContent
            id="moodboard"
            flex={1}
            title="Props Planning Made Easy"
            description={
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.MdRegular, "text-left", {
                  [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
                  [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
                })}
              >
                <ul
                  className="list-black"
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <li>
                    Blooper identifies essential props, costumes, and set elements from your script.
                  </li>
                  <li>
                    From furniture to costumes, every detail is listed and organized—saving time,
                    reducing costs, and ensuring your scene looks exactly as envisioned.
                  </li>
                </ul>
              </Typography.Text>
            }
          >
            <img
              src={moodboard}
              alt="moodboard"
              className="storyboard-block-img__1 image-contain"
            />
          </UseCaseContent>
          <UseCaseContent
            id="storyboard"
            flex={1}
            title="Storyboard Generation Made Effortless"
            description={
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.MdRegular, "text-left", {
                  [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
                  [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
                })}
              >
                <ul
                  className="list-black"
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <li>
                    Visualize each scene with AI precision, ensuring every shot, angle, and
                    transition aligns with your vision.
                  </li>
                  <li>
                    Save time, reduce manual work, and keep your team aligned before filming begins.
                  </li>
                </ul>
              </Typography.Text>
            }
          >
            <img
              src={storyboard}
              alt="storyboard"
              className="storyboard-block-img__2 image-contain"
            />
          </UseCaseContent>
        </Flex>
        <Flex vertical={isMobileXL} className="gap-m">
          <UseCaseContent
            flex={1}
            title="Smart Shot Compositions"
            description={
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.MdRegular, "text-left", {
                  [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
                  [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
                })}
              >
                <ul
                  className="list-black"
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <li>
                    Forget about mistakes in rules of composition. Blooper takes care of everything
                    for you.
                  </li>
                  <li>Adjust camera angles and framing to align with your creative vision.</li>
                  <li>
                    It’s possible to change the professional parameters of the shots, and we will
                    reflect that in generation.
                  </li>
                </ul>
              </Typography.Text>
            }
          >
            <img
              src={composition}
              alt="composition"
              className="storyboard-block-img__3 image-contain"
            />
          </UseCaseContent>
          <UseCaseContent
            id="location"
            flex={1}
            title="Scout locations"
            description={
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.MdRegular, "text-left", {
                  [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
                  [TEXT_CLASSNAMES.XsRegular]: isMobileXL,
                })}
              >
                <ul
                  className="list-black"
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <li>
                    No more endless searches for the right spot. Blooper suggests tailored venues
                    that match your scene's mood and requirements.
                  </li>
                  <li>We automatically find venues in the city you provide for your script.</li>
                </ul>
              </Typography.Text>
            }
          >
            <img
              style={{ height: "auto" }}
              src={location}
              alt="location"
              className="storyboard-block-img__3 image-contain"
            />
          </UseCaseContent>
        </Flex>
      </UseCaseWrapper>
      <UseCaseFooter />
    </Flex>
  );
};
