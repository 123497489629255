import { useAction, useAtom } from "@reatom/npm-react";
import { RefObject, useEffect, useMemo } from "react";
import { Comment } from "@/shared/api/comments";
import {
  activeTabAtom,
  addCommentToBottomAction,
  deleteCommentAction,
  getCommentsAction,
} from "./commentsScriptOutline";

type UseBehavior = {
  id: string;
  latestMessage: MessageEvent<any> | undefined;
  commentsRef: RefObject<HTMLElement>;
};

export const useBehavior = ({ id, latestMessage, commentsRef }: UseBehavior) => {
  const getComments = useAction(getCommentsAction);
  const deleteComment = useAction(deleteCommentAction);
  const addCommentToBottom = useAction(addCommentToBottomAction);
  const [comments] = useAtom(getCommentsAction.dataAtom);
  const [activeTab, setActiveTab] = useAtom(activeTabAtom);

  useEffect(() => {
    getComments(id);
  }, []);

  useEffect(() => {
    if (latestMessage?.data) {
      const data = JSON.parse(latestMessage?.data) as Comment;
      const { action_type, project_key, id } = data;
      if (action_type === "delete_comment") {
        deleteComment({ project_id: project_key, comment_id: id });
      } else if (action_type === undefined) {
        addCommentToBottom(data);
      }
    }
  }, [latestMessage]);

  useEffect(() => {
    if (!commentsRef.current) return;
    const lastComment = commentsRef.current.querySelector(".comment:last-child");
    lastComment?.scrollIntoView({ behavior: "smooth" });
  }, [latestMessage]);

  const filteredComments = useMemo(() => {
    const topLevelComments = comments.filter((c) => c.parent_id === null);
    if (activeTab === "resolved") {
      return topLevelComments.filter((c) => c.resolved);
    }
    if (activeTab === "unresolved") {
      return topLevelComments.filter((c) => !c.resolved);
    }
    return topLevelComments;
  }, [comments, activeTab]);

  return { filteredComments, setActiveTab, activeTab };
};
