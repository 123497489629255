import { useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC } from "react";

import { useUpdateTitle } from "shared/hooks";
import { UpgradeModal, getTrialEndAtom } from "@/entities/viewer";
import { AddNewProject } from "../../../features/add-new-project";
import { EmptyProject } from "../../../features/empty-project";
import { useBehaviour } from "../model";
import { NewProjectCard } from "./new-project-card";
import { ProjectCard, ProjectCardSkeleton } from "./project-card";
import { ProjectsTrialBanner } from "./projects-trial-banner";
import { RedesignModal } from "./RedesignModal";

import "./Projects.scss";

export const Projects: FC = () => {
  const {
    projects,
    toggleModal,
    toggleUpgradeModalModal,
    isUpgradeModalOpen,
    isOpen,
    loading,
    onUpgrade,
  } = useBehaviour();

  useUpdateTitle();

  const isProject = !!projects.length;
  const [{ days, trialStatus }] = useAtom(getTrialEndAtom);

  return (
    <Flex className="projects full-width full-height gap-m" justify="space-between" align="start">
      <Flex vertical className="gap-s full-width full-height">
        {loading && !isProject && (
          <Flex wrap="wrap" className="gap-s">
            <ProjectCardSkeleton key={1} />
            <ProjectCardSkeleton key={2} />
            <ProjectCardSkeleton key={3} />
          </Flex>
        )}
        {!isProject && !loading && (
          <Flex align="center" justify="center" className="full-width full-height">
            <EmptyProject />
          </Flex>
        )}
        {isProject && (
          <Flex wrap="wrap" className="gap-s">
            {projects.map((project) => (
              <ProjectCard key={project.projectKey} {...project} />
            ))}
            <NewProjectCard
              projects={projects}
              toggleModal={toggleModal}
              toggleUpgradeModalModal={toggleUpgradeModalModal}
            />
          </Flex>
        )}
        <AddNewProject isOpen={isOpen} toggleModal={toggleModal} />
        <UpgradeModal isOpen={isUpgradeModalOpen} toggleModal={toggleUpgradeModalModal} />
        <RedesignModal />
      </Flex>

      {/* Добавить нормальное определение оставшихся дней */}
      {trialStatus === "on_trial" && <ProjectsTrialBanner onUpgrade={onUpgrade} leftDays={days} />}
    </Flex>
  );
};
