import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";
import circle from "assets/product/circle.svg";
import { TrialButton } from "shared/ui";

export const Title: FC = () => {
  const { isLaptopM, isLaptopS, isMobileXL } = useMediaQueries();

  return (
    <Flex vertical justify="center" className="product-content__title gap-xs text-center">
      <img src={circle} className="product-content__title--circle" />
      <Typography.Text
        className={cn("text-center", TEXT_CLASSNAMES.LPHeadlineH1, COLOR_CLASSNAMES.TextPrimary, {
          [TEXT_CLASSNAMES.LPHeadlineH2]: isLaptopM,
          [TEXT_CLASSNAMES.LPHeadlineH3]: isLaptopS,
          [TEXT_CLASSNAMES.LPHeadlineH4]: isMobileXL,
        })}
      >
        <span className="product-content__title--italic">Pre-Production</span> <br />
        Assistant and Management
      </Typography.Text>
      <Typography.Text
        style={{ textTransform: "none" }}
        className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextPrimary, {
          [TEXT_CLASSNAMES.SmRegular]: isLaptopM,
          [TEXT_CLASSNAMES.XsRegular]: isLaptopS,
        })}
      >
        Scripts turn into storyboards. Every scene is seamlessly planned. {!isMobileXL && <br />}
        Locations are found in seconds. Teams work as one. {!isMobileXL && <br />}
        All in one place. {!isMobileXL && <br />}
      </Typography.Text>
      <Flex justify="center" style={{ marginTop: 16 }}>
        <TrialButton />
      </Flex>
    </Flex>
  );
};
