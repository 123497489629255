import { useAction, useAtom } from "@reatom/npm-react";
import { useKeyPress } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect, useRef, useState } from "react";

import { ShotColor } from "@/shared/api/script";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { ScriptPath } from "shared/types/routes.ts";
import deleteImg from "assets/shared/delete.svg";
import { useOpenModal } from "shared/hooks";
import { useProjectType } from "@/entities/projects";
import {
  deleteShotAction,
  DeleteShotModal,
  styleSettingsOutlineAtom,
} from "@/entities/script";

import "./ShotTitle.scss";

type Props = {
  color: ShotColor;
  isHover: boolean;
  isActive: boolean;
  shotIdx: number;
  title: string;
  shotId: string;
  sceneId: string;
  isContent: boolean;
  sceneName: string;
  shotsLength: number;
  projectLastShotId: string;
};

export const ShotTitle: FC<Props> = ({
  color,
  isHover,
  isActive,
  shotIdx,
  title,
  shotId,
  sceneId,
  isContent,
  sceneName,
  shotsLength,
  projectLastShotId,
}) => {
  const { id } = useExtractParams<ScriptPath>();

  const [open, toggleModal] = useOpenModal();
  const titleRef = useRef<HTMLSpanElement>(null);
  const deleteShot = useAction(deleteShotAction);
  const isEmptyScene = shotsLength === 1;
  const [isLastShot, setIsLastShot] = useState(false);
  const { isShare } = useProjectType();
  const [outlined] = useAtom(styleSettingsOutlineAtom);

  const handleDelete = () => {
    const isLast = projectLastShotId === shotId;

    if (isEmptyScene && isLast) {
      setIsLastShot(true);
      toggleModal(true);
    } else {
      if (isContent) {
        toggleModal(true);
      } else {
        deleteShot({
          projectKey: id,
          shotId,
          sceneId,
        });
      }
    }
  };

  useKeyPress(
    "enter",
    () => {
      if (titleRef.current) {
        titleRef.current.innerText = titleRef.current.innerText.trim();
      }
    },
    {
      target: titleRef,
    },
  );

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.innerText = title;
    }
  }, []);

  const isOutlined = outlined[id];
  const notOutlinedHover = isHover && !isOutlined;
  const notOutlinedActive = isActive && !isOutlined;

  return (
    <Flex>
      <Flex
        align="center"
        className={cn(
          "shot__title cubic-animation gap-4",

          {
            [`shot__title__${color}`]: isOutlined,
            "shot__title--hover": isHover,
            "shot__title--active": isActive,
            "shot__title--hover-noColor": notOutlinedHover,
            "shot__title--active-noColor": notOutlinedActive,
          },
        )}
      >
        <Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextPrimary)}>
          Scene: {sceneName} Shot: {shotIdx + 1}
        </Typography.Text>
      </Flex>
      {!isShare && (
        <Flex
          align="center"
          className={cn("shot__actions", {
            [`shot__actions__${color}`]: isOutlined && isOutlined,
            "shot__actions--hover": isHover,
            "shot__actions--active": isActive,
            "shot__actions--hover-noColor": notOutlinedHover,
            "shot__actions--active-noColor": notOutlinedActive,
          })}
        >
          <Flex
            onClick={handleDelete}
            className="gap-xxs pointer shot__actions__button cubic-animation"
          >
            <img src={deleteImg} alt="delete" />
            <Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>Delete</Typography.Text>
          </Flex>
        </Flex>
      )}
      <DeleteShotModal
        sceneId={sceneId}
        shotId={shotId}
        toggleModal={toggleModal}
        isOpen={open}
        isLast={isLastShot}
      />
    </Flex>
  );
};
