import { Flex } from "antd";
import { FC, useRef, useEffect, useState } from "react";
import { useMediaQueries } from "shared/hooks/use-media-queries.ts";
import poster from "assets/product/poster.png";
import videoMOV from "assets/video/video.mov";
import videoMP4 from "assets/video/video.mp4";
import { Title } from "./Title.tsx";

import "./ProductStart.scss";

type VideoStart = {
  src: string;
  type: string;
};

export const ProductStart: FC = () => {
  const { isLaptopS } = useMediaQueries();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoSrc, setVideoSrc] = useState<VideoStart>({
    src: videoMP4,
    type: "video/mp4",
  });

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    if (!video.canPlayType("video/mp4")) {
      setVideoSrc({
        src: videoMOV,
        type: "video/quicktime",
      });
      return;
    }

    const handleError = () => {
      setVideoSrc({
        src: videoMOV,
        type: "video/quicktime",
      });
    };

    video.addEventListener("error", handleError);

    return () => {
      video.removeEventListener("error", handleError);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const startVideo = () => {
      video?.play();
    };

    video.addEventListener("loadeddata", startVideo);

    return () => {
      video.removeEventListener("loadeddata", startVideo);
    };
  }, [videoSrc]);

  return (
    <Flex className="product-start">
      <Flex vertical={isLaptopS} className="product-start__content">
        <Flex justify="center" vertical className="product-content">
          <Flex className="product-content__wrapper">
            <Title />
          </Flex>
        </Flex>
        <Flex className="product-preview">
          <video
            ref={videoRef}
            className="product-preview__img image-contain"
            poster={poster}
            loop
            muted
            autoPlay
            playsInline
            preload="auto"
          >
            <source type={videoSrc.type} src={videoSrc.src} />
          </video>
        </Flex>
      </Flex>
    </Flex>
  );
};
