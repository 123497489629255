import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import close from "assets/shared/close.svg";
import { cameraData } from "./const.ts";
import "./Content.scss";

type Props = {
  hide: () => void;
  active: string;
  onChange: (value: string) => void;
};

export const Content: FC<Props> = ({ hide, active, onChange }) => {
  const cameraAngles = Object.keys(cameraData);

  return (
    <Flex vertical className="gap-xs">
      <Flex justify="space-between" className="full-width">
        <Typography.Text className={TEXT_CLASSNAMES.SmSemibold}>Camera angle</Typography.Text>
        <img src={close} alt="close" onClick={hide} className="pointer" />
      </Flex>
      <Flex className="gap-xxs">
        {cameraAngles.map((key) => (
          <Flex
            align="center"
            justify="center"
            vertical
            className={cn("choose-elem gap-xs pointer", {
              "choose-elem--active": active === key,
            })}
            onClick={() => onChange(key)}
            key={key}
          >
            <img src={cameraData[key].img} alt={cameraData[key].key} />
            <Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, "text-center")}>
              {cameraData[key].title}
            </Typography.Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
