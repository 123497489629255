import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { ROUTES } from "shared/const/routes.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { CharacterInfoPath, CharacterPath } from "shared/types/routes.ts";
import { useOpenModal } from "shared/hooks";
import { generateUrl } from "shared/methods";
import {
  activeCharacterAtom,
  getCharactersAction,
  getNextCharacterId,
  getPrevCharacterId,
  UnsavedChangesModal,
  updateCharacterAction,
} from "@/entities/character";
import { RedoIcon, UndoIcon, BackIcon } from "./icons";

export const CharacterInfoSettings = () => {
  const { id, characterId } = useExtractParams<CharacterInfoPath>();
  const navigate = useNavigate();
  const [openModal, toggleOpenModal] = useOpenModal();

  const [activeCharacter] = useAtom(activeCharacterAtom);
  const [characterList] = useAtom(getCharactersAction.dataAtom);
  const currentCharacter = characterList.find((character) => character.id === characterId);
  const currentCharacterIndex = characterList.findIndex(
    (character) => character.id === characterId,
  );
  const updateCharacter = useAction(updateCharacterAction);

  const changeSelected = !activeCharacter || activeCharacter === characterId;

  const onPrev = () => {
    const prevId = getPrevCharacterId(characterList, characterId);

    navigate(
      generateUrl<CharacterInfoPath>(ROUTES.CHARACTER_INFO.fullPath, {
        id,
        characterId: prevId!,
      }),
    );
  };

  const onNext = () => {
    const nextId = getNextCharacterId(characterList, characterId);

    navigate(
      generateUrl<CharacterInfoPath>(ROUTES.CHARACTER_INFO.fullPath, {
        id,
        characterId: nextId!,
      }),
    );
  };

  const onSelect = async () => {
    if (id && currentCharacter && activeCharacter) {
      await updateCharacter(id, {
        appearance_key: activeCharacter,
        name: currentCharacter.name,
        new_name: currentCharacter.name,
      });
      navigate(generateUrl<CharacterPath>(ROUTES.CHARACTER.fullPath, { id }));
    }
  };

  const handleBack = () => {
    navigate(generateUrl<CharacterPath>(ROUTES.CHARACTER.fullPath, { id }));
  };

  return (
    <Flex className="gap-xxs full-width" align="center" justify="space-between">
      <Button onClick={handleBack} size="small" icon={<BackIcon />}>
        Back to characters
      </Button>
      <Flex align="center" className="gap-4">
        <Button size="small" icon={<UndoIcon />} onClick={onPrev} />
        <Typography.Text className={TEXT_CLASSNAMES.Link10}>
          {currentCharacterIndex + 1} character - {currentCharacter?.name}
        </Typography.Text>
        <Button size="small" icon={<RedoIcon />} onClick={onNext} />
      </Flex>
      {!changeSelected && (
        <Button onClick={onSelect} size="small" type="primary">
          Select
        </Button>
      )}
      {changeSelected && <div style={{ width: 50 }} />}
      <UnsavedChangesModal toggleModal={toggleOpenModal} isOpen={openModal} onSave={onSelect} />
    </Flex>
  );
};
