import { useAtom } from "@reatom/npm-react";
import { Flex, Input, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { COLOR_CLASSNAMES, COLOR_NAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";

import { promoErrorAtom, userInputPromoCodeAtom } from "../model";

export const PromotionalCode: FC = () => {
  const [error] = useAtom(promoErrorAtom);
  const [_, setUserPromoCode] = useAtom(userInputPromoCodeAtom);

  return (
    <Flex vertical gap={16}>
      <Flex vertical className="full-width gap-xs">
        <Flex gap={8} vertical align="flex-start">
          <Input
            onChange={(event) => setUserPromoCode(event.target.value.trim())}
            placeholder="Enter code here"
            style={{
              borderColor: error ? COLOR_NAMES.Orange500 : undefined,
              height: 38,
            }}
          />
          <Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.Orange500)}>
            {error}
          </Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
