import amazingActive from "assets/feedback/amazing_active.svg";
import amazingInactive from "assets/feedback/amazing_inactive.svg";
import awfulActive from "assets/feedback/awful_active.svg";
import awfulInactive from "assets/feedback/awful_inactive.svg";
import badActive from "assets/feedback/bad_active.svg";
import badInactive from "assets/feedback/bad_inactive.svg";
import goodActive from "assets/feedback/good_active.svg";
import goodInactive from "assets/feedback/good_inactive.svg";
import mediumActive from "assets/feedback/medium_active.svg";
import mediumInactive from "assets/feedback/medium_inactive.svg";
import { FeedbackMap } from "./types";

export const FEEDBACK_GRADE: FeedbackMap = {
  awful: {
    title: "Awful",
    inActiveImg: awfulInactive,
    activeImg: awfulActive,
  },
  bad: {
    title: "Bad",
    inActiveImg: badInactive,
    activeImg: badActive,
  },
  medium: {
    title: "Okay",
    inActiveImg: mediumInactive,
    activeImg: mediumActive,
  },
  good: {
    title: "Good",
    inActiveImg: goodInactive,
    activeImg: goodActive,
  },
  amazing: {
    title: "Amazing",
    inActiveImg: amazingInactive,
    activeImg: amazingActive,
  },
};
