import { useSize } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { BackgroundPreLogin, LoginWrapper } from "@/entities/viewer";

const text =
  "\n" +
  "\n" +
  "\n" +
  "\n" +
  "# **Data Controller and Processors**\n" +
  "\n" +
  "We are your personal data controllers – that means that we determine the purposes and means of the processing of your personal data. The legal basis for each category of personal data is indicated in the respective sections of this Policy.\n" +
  "\n" +
  "\n" +
  "# **Personal Data**\n" +
  "\n" +
  "Personal data [means](https://eur-lex.europa.eu/eli/reg/2016/679/oj) any information related to an identified or identifiable natural person. You may find more details about personal data definition in Article 4 and Recital 30 of the [Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 (GDPR)](https://eur-lex.europa.eu/eli/reg/2016/679/oj).\n" +
  "\n" +
  "We collect the following personal data you provide us while you use our service:\n" +
  "\n" +
  "\n" +
  "## **_Data necessary for our service_**\n" +
  "\n" +
  "After your registration in Blooper, in connection with the contract (by contract we mean Terms and Conditions: https\\://blooper.ai/terms-and-conditions), collecting and processing your personal data listed here is necessary for the performance of such contract:\n" +
  "\n" +
  "**1. Account information.** Using our service, you may provide us information about your name, email, address, phone number, domain.\n" +
  "\n" +
  "**How do we use it:** your account information is used by us in order to provide you with our service. \n" +
  "\n" +
  "**2. Contact information.** Your email, address and phone number.\n" +
  "\n" +
  "**How do we use it:** we may use this information to contact you or to answer on your request. We may also contact you to inform you about significant updates to our services. \n" +
  "\n" +
  "**3. Usage data.** We collect some default information about usage of our service: log data (including its date and time), IP address, browser type, some browser settings and browser plugins, device information (your screen resolution, operating system, device settings etc.).\n" +
  "\n" +
  "**How do we use it:** site may work differently depending on your device. We use this information in order to make our service work as designed on your device.\n" +
  "\n" +
  "We do not knowingly collect any other personal data except stated above, so we encourage you not to provide any other personal data except the stated above if it is not related to our service. Please do not provide sensitive personal information.\n" +
  "\n" +
  "\n" +
  "## **_Mailing_**\n" +
  "\n" +
  "If you gave us your consent to receive our emails, we will contact you from time to time using the email you provided during registration on our service. We may send you information about recent updates of our service or some other marketing materials. If you would like to unsubscribe from our newsletter, you may contact us or use the link in our emails.\n" +
  "\n" +
  "\n" +
  "## **_Statistical data_**\n" +
  "\n" +
  "Before you register in our service we will process only statistical data (which is not personal data in itself). We do not collect any of your personal data before your registration on our service.\n" +
  "\n" +
  "\n" +
  "# **Personal Data Disclosure**\n" +
  "\n" +
  "We will not disclose or share any of your personal data with third parties except:\n" +
  "\n" +
  "1. It is necessary for the performance of the contract:\n" +
  "\n" +
  "- with our employees or contractors who are responsible for contacting you;\n" +
  "\n" +
  "- if you make payment, your name, email and billing details will be shared with a third-party payment gateway;\n" +
  "\n" +
  "- with a successor in case of transferring the rights.\n" +
  "\n" +
  "2. Based on your consent:\n" +
  "\n" +
  "- if you give us your consent to receive emails from us, we will share your email with The Rocket Science Group LLC d/b/a Mailchimp to include you into the distribution list in order to send you emails. You may find more information about Mailchimp GDPR compliance [here](https://mailchimp.com/gdpr/) and learn their [Privacy Policy](https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts) here;\n" +
  "\n" +
  "3. If disclosure is required by law or court order.\n" +
  "\n" +
  "We do not sell your personal data.\n" +
  "\n" +
  "\n" +
  "# **Personal Data Storage and Transfer**\n" +
  "\n" +
  "Your personal data is stored in the Netherlands. We do not knowingly transfer your personal data into other countries. \n" +
  "\n" +
  "If you contact us via email, your message will be stored on Google servers which may be located in various countries, so your data can be transferred to other countries outside the EU, but Google is using the [European Commission’s Standard Contractual Clauses (SCCs)](https://support.google.com/adspolicy/answer/10042247?hl=en). Our email server supports TLS encryption to ensure the security of sending and receiving emails. However, to take advantage of it, your email service provider needs to support it too.\n" +
  "\n" +
  "\n" +
  "# **Third Party Links**\n" +
  "\n" +
  "This website may contain links to third party websites, which are governed by their own privacy policies. We are not responsible for the content or privacy of third party websites, so we encourage you to check third parties’ privacy and security policies before providing them with any information.\n" +
  "\n" +
  "\n" +
  "# **Third Party Services**\n" +
  "\n" +
  "## **_Google Analytics_**\n" +
  "\n" +
  "We use Google Analytics to get a better understanding of how people use our website. It allows us to collect various statistical data, such as the number of users visiting and their geographic distribution.\n" +
  "\n" +
  "We do not share any of this data with Google or other third-parties and we do not use[ Remarketing or Advertising Reporting Features](https://support.google.com/analytics/answer/2444872). Neither do we use[ User-ID](https://support.google.com/analytics/answer/3123662). Also, we configured Google Analytics to[ anonymise your IP address](https://support.google.com/analytics/answer/2763052).\n" +
  "\n" +
  "We believe this configuration restricts the information collected to the bare minimum required for us to obtain meaningful statistical data. Neither we, nor other parties use it for targeting ads or any equally evil marketing purposes.\n" +
  "\n" +
  "You may opt-out of making your site activity available to Google Analytics by using the[ Google Analytics Opt-out Add-on](https://tools.google.com/dlpage/gaoptout).\n" +
  "\n" +
  "\n" +
  "## **_Hubspot_**\n" +
  "\n" +
  "HubSpot acts as a controller of your personal data when you sign up for our products and services, visit our websites or interact with us through other channels. HubSpot uses this data for a variety of purposes, including to provide our products and services to you, contact you about relevant content, and improve and promote our products and services. To learn more about Hubspot please visit [Hubspot Privacy Policy](https://legal.hubspot.com/privacy-policy).\n" +
  "\n" +
  "\n" +
  "# **Children**\n" +
  "\n" +
  "Our website is not directed at children under 18, and we do not knowingly collect personal data from children under 18. If a child under the age of 18 has provided us with personal data, the child’s parent or guardian may contact us and request that such information be deleted.\n" +
  "\n" +
  "\n" +
  "# **Your Data Protection Rights**\n" +
  "\n" +
  "[General Data Protection Regulation](https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679) (Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016) provides data protection rights for each user. Here they are:\n" +
  "\n" +
  "[Right to access](https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679#d1e2513-1-1) – you have the right to request from us a confirmation as to whether or not any personal data concerning you has been processed. You also can request a copy of your personal data that we hold.\n" +
  "\n" +
  "[Right to rectification](https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679#d1e2599-1-1) – you have the right to request us to correct your personal data you believe is inaccurate or incomplete.\n" +
  "\n" +
  "[Right to erasure](https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679#d1e2606-1-1) – in certain circumstances, you can ask for your personal data we hold to be erased.\n" +
  "\n" +
  "[Right to restriction of processing](https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679#d1e2700-1-1) – in certain circumstances, you can request us to limit the way we use your personal data.\n" +
  "\n" +
  "[Right to data portability](https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679#d1e2753-1-1) – in certain circumstances, you can request us to transfer your personal data to another company.\n" +
  "\n" +
  "[Right to object](https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679#d1e2803-1-1) – you have the right to challenge certain types of processing, such as direct marketing.\n" +
  "\n" +
  "\n" +
  "# **Updating and Definitions**\n" +
  "\n" +
  "We may update this Privacy Policy by posting the updated Privacy Policy on this page: https\\://blooper.ai/privacy-policy. You should check this page from time to time. The effective date of this Privacy Policy is stated at the top.\n" +
  "\n" +
  "We may use some definitions from our Terms and Conditions (https\\://blooper.ai/terms-and-conditions). Please make sure to review this document.\n" +
  "\n" +
  "\n" +
  "# **Cookies**\n" +
  "\n" +
  "We use cookies (including advertising cookies) on the site. To know more about what cookies are and how we use them please visit our Cookie Policy page (https\\://blooper.ai/cookie-policy).\n" +
  "\n" +
  "\n" +
  "# **Do Not Track**\n" +
  "\n" +
  "We do not support the Do Not Track browser option, however we respect all terms of this Privacy Policy.\n" +
  "\n" +
  "\n" +
  "# **Contact**\n" +
  "\n" +
  "If you would like to access, erase, update or rectify any of your personal data that we hold or exercise any other of your data protection rights, please contact us:\n" +
  "\n" +
  "<legal@blooper.ai>\n" +
  "\n" +
  "**Blooper AG**\n" +
  "\n" +
  "Zugerstrasse 32\n" +
  "\n" +
  "6340 Baar\n" +
  "\n" +
  "Switzerland\n" +
  "\n" +
  "\n" +
  "# **Data Protection Authority**\n" +
  "\n" +
  "If you wish to lodge a complaint, you may contact [Federal Data Protection and Information Commissioner (FDPIC)](https://www.edoeb.admin.ch/edoeb/en/home.html):\n" +
  "\n" +
  "Federal Data Protection and Information Commissioner (FDPIC)\n" +
  "\n" +
  "T +41 (0)58 462 43 95, F +41 (0)58 465 99 96\n" +
  "\n" +
  "[www.edoeb.admin.ch](https://d.docs.live.net/e8ebaf242109f944/%D0%9D%D0%BE%D0%B2%D0%B0%D1%8F%20%D0%BF%D0%B0%D0%BF%D0%BA%D0%B0%20\\(2\\)/Blooper/www.edoeb.admin.ch)\n" +
  "\n" +
  "Feldeggweg 1\n" +
  "\n" +
  "CH - 3003 Berne\n" +
  "\n" +
  "You may also file a complaint on FDPIC’s website [here](https://www.edoeb.admin.ch/edoeb/en/home/adresse.html).\n";

export const PrivacyPolicy: FC = () => {
  const size = useSize(() => document.querySelector(".login-wrapper"));

  return (
    <BackgroundPreLogin>
      <LoginWrapper isChildrenFullHeight title="" questionText="" actionText="" action={() => {}}>
        <Flex vertical gap={8}>
          <Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH4)}>
            Privacy Policy - Blooper
          </Typography.Text>
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}
          >
            Last Updated: 28.10.2024
          </Typography.Text>
        </Flex>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            overflowY: "scroll",
            width: "100%",
            height: "100%",
            maxHeight: (size?.height ?? 0) - 120,
            marginTop: 24,
          }}
        >
          <ReactMarkdown children={text} />
        </div>
      </LoginWrapper>
    </BackgroundPreLogin>
  );
};
