import { api } from "@/shared/api";
import {
  GenerateInpaintingParams,
  GenerateInpaintingResult,
  UploadMaskParams,
  UploadMaskResult,
} from "./types.ts";
import { TResponse } from "@/vite-env";

export const uploadMaskResource = (params: UploadMaskParams): TResponse<UploadMaskResult> =>
  api.post("/inpainting/upload_to_s3", params);

export const generateInpaintingResource = (
  params: GenerateInpaintingParams,
): TResponse<GenerateInpaintingResult> => api.post("/inpainting/get", params);
