import { action, atom } from "@reatom/core";
import { AxiosResponse } from "axios";
import { teamIdAtom } from "@/features/team-settings";
import { callErrorAction } from "@/entities/notification";
import { Member, updateTeamMemberResource } from "@/shared/api/teams";

export const isOpenChangeTeamPermissionsModalAtom = atom(
  false,
  "isOpenChangeTeamPermissionsModalAtom",
);

export const openChangeTeamPermissionsModalAction = action((ctx) => {
  isOpenChangeTeamPermissionsModalAtom(ctx, true);
});

export const currentTeamMember = atom<Member | null>(null, "currentTeamMember");

export const setCurrentTeamMemberAction = action((ctx, member: Member | null) => {
  currentTeamMember(ctx, member);
});

export const changeTeamMemberPermissionsAction = action(async (ctx, member: Member) => {
  try {
    const teamId = ctx.get(teamIdAtom);
    await updateTeamMemberResource(teamId, member);
  } catch (err) {
    callErrorAction(ctx, err as AxiosResponse);
  }
});
