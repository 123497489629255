import { useAtom } from "@reatom/npm-react";
import { ComponentType, FC, useEffect } from "react";
import ReactGA from "react-ga4";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { Navigate, useLocation } from "react-router-dom";
import { NOT_RELEASE } from "shared/const/release.ts";
import { mobileCheck } from "shared/methods";
import { CookieBanner, DesktopAccess } from "shared/ui";
import { ErrorWrapper, SuccessWrapper } from "@/entities/notification/ui";
import { TrialBanner } from "@/entities/plan";
import { isGuestAtom } from "@/entities/viewer";
import { getCredential } from "@/entities/viewer/lib/utils.ts";

interface IRoute {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ComponentType<any>;
}

export const AuthRoute: FC<IRoute> = ({ component: Component }) => {
  const viewer = getCredential();
  const isAuth = viewer?.data?.isAuth;

  if (isAuth) {
    return <Navigate to="/projects" />;
  }

  return (
    <ErrorWrapper>
      <Component />
      <CookieBanner />
    </ErrorWrapper>
  );
};

export const PrivateRoute: FC<IRoute> = ({ component: Component }) => {
  const { setPathPageView, setTrackPageView } = useTrackingCode();
  const { pathname } = useLocation();
  const viewer = getCredential();
  const isAuth = viewer?.data?.isAuth;
  const isMobile = mobileCheck();

  useEffect(() => {
    if (isAuth) {
      setPathPageView(pathname);
      setTrackPageView();
    }
  }, []);

  if (isMobile && !NOT_RELEASE) {
    return <Navigate to="/desktop-only" />;
  }

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  return (
    <ErrorWrapper>
      <SuccessWrapper>
        <Component />
        <CookieBanner />
        <TrialBanner />
      </SuccessWrapper>
    </ErrorWrapper>
  );
};

export const PublicRoute: FC<IRoute> = ({ component: Component }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: pathname });
  }, [pathname]);

  return (
    <>
      <Component />
      <CookieBanner />
      <DesktopAccess />
    </>
  );
};

export const PaymentRoute: FC<IRoute> = ({ component: Component }) => {
  const viewer = getCredential();
  const isAuth = viewer?.data?.isSubscriptionAuth;

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  return (
    <ErrorWrapper>
      <Component />
    </ErrorWrapper>
  );
};

export const CustomerRoute: FC<IRoute> = ({ component: Component }) => {
  const viewer = getCredential();
  const [isGuest] = useAtom(isGuestAtom);
  const isAuth = viewer?.data?.isAuth;

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  if (isGuest) {
    return <Navigate to="/projects" />;
  }

  return <Component />;
};
