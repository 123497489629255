import {
  reatomAsync,
  withAbort,
  withCache,
  withDataAtom,
  withErrorAtom,
  withStatusesAtom,
} from "@reatom/async";
import { action, atom } from "@reatom/core";

import { isShallowEqual } from "@reatom/utils";
import { callErrorAction } from "@/entities/notification";
import {
  getOrderedShotsResource,
  GetShotParams,
  GetShotResult,
  getStoryboardShotResource,
  UpdateShotInfo,
  UpdateShotParams,
  updateStoryboardShotResource,
  UploadImageResult,
} from "@/shared/api/storyboard";

export const shotEditStateAtom = atom<UpdateShotInfo>({
  title: "",
  shot_description: "",
  location: "",
  dialog: "",
  time: 0,
  camera_movement: "",
  camera_angle: "",
  props: "",
});

export const shotEditingImageUploadLoadingAtom = atom<boolean>(
  false,
  "shotEditingImageUploadLoading",
);

export const getShotsOrderAction = reatomAsync((ctx, projectKey: string) =>
  getOrderedShotsResource(projectKey, ctx.controller),
).pipe(
  withDataAtom([], (_, res) => res.data.shots),
  withStatusesAtom(),
  withErrorAtom((ctx, err) => callErrorAction(ctx, err)),
  withCache(),
  withAbort(),
);

export const getShotAction = reatomAsync((ctx, params: GetShotParams) =>
  getStoryboardShotResource(params, ctx.controller),
).pipe(
  withDataAtom({} as GetShotResult, (_ctx, res) => res.data),
  withStatusesAtom(),
  withErrorAtom((ctx, err) => callErrorAction(ctx, err)),
  withAbort(),
);

getShotAction.dataAtom.onChange((ctx, newState) => {
  shotEditStateAtom(ctx, {
    title: newState.title,
    shot_description: newState.shot_description,
    location: newState.location,
    dialog: newState.dialog,
    time: newState.time,
    camera_movement: newState.camera_movement,
    camera_angle: newState.camera_angle,
    props: newState.props,
  });
});

export const isShotSaveChangeAtom = atom<boolean>((ctx) => {
  const shot = ctx.spy(getShotAction.dataAtom);
  const shotEditState = ctx.spy(shotEditStateAtom);

  const shotInfo: UpdateShotInfo = {
    title: shot.title,
    shot_description: shot.shot_description,
    location: shot.location,
    dialog: shot.dialog,
    time: shot.time,
    camera_movement: shot.camera_movement,
    camera_angle: shot.camera_angle,
    props: shot.props,
  };

  return !isShallowEqual(shotInfo, shotEditState);
});

export const updateShotAction = action((ctx, params: UploadImageResult) => {
  const shot = ctx.get(getShotAction.dataAtom);

  if (shot) {
    getShotAction.dataAtom(ctx, {
      ...shot,
      image_url_compressed: params.bw_shot_image_watermarked_compressed,
      image_url: params.bw_shot_image_compressed,
    });
  }
});

export const saveShotChangesAction = reatomAsync(
  async (_ctx, params: UpdateShotParams) => {
    await updateStoryboardShotResource(params);

    return params;
  },
  {
    onFulfill: (ctx, params) => {
      const shot = ctx.get(getShotAction.dataAtom);
      getShotAction.dataAtom(ctx, {
        ...shot,
        ...params.shot_info,
      });
    },
  },
).pipe(withStatusesAtom(), withErrorAtom(callErrorAction));
