import { reatomAsync, withStatusesAtom } from "@reatom/async";
import { atom, AtomMut } from "@reatom/core";
import { withSessionStorage } from "@reatom/persist-web-storage";
import { Point } from "react-lasso-select/lib/helpers";
import { getShotAction } from "@/entities/shot-edit";
import { generateInpaintingResource, uploadMaskResource } from "@/shared/api/inpaintng";
import { BannerShow, GenerateInpaintingParams } from "../lib";

export const inpaintingDescriptionAtom = atom<string>("", "inpaintingDescriptionAtom");

export const inpaintingPointsAtom = atom<Point[]>([], "inpaintingPointsAtom");

export const bannerShownAtom = atom<BannerShow>({}, "bannerShown").pipe(
  withSessionStorage("inpaintingBannerShown"),
) as AtomMut<BannerShow>;

export const generateInpaintingAction = reatomAsync(
  async (_, params: GenerateInpaintingParams) => {
    const base64Mask = params.base64Mask.replace(/^data:image\/[a-z]+;base64,/, "");

    const { data } = await uploadMaskResource({
      base64_string: base64Mask,
      project_key: params.projectKey,
    });

    const { data: imageVersion } = await generateInpaintingResource({
      shot_id: params.shotId,
      project_key: params.projectKey,
      description: params.description,
      original_description: params.originalDescription,
      base_image: params.baseImage,
      mask_image: data.image_url,
    });

    return imageVersion;
  },
  {
    onFulfill: (ctx, imageVersion) => {
      const shot = ctx.get(getShotAction.dataAtom);
      if (shot) {
        getShotAction.dataAtom(ctx, {
          ...shot,
          image_url_compressed: imageVersion.image_url_compressed,
          image_url: imageVersion.image_url,
        });
      }
    },
  },
).pipe(withStatusesAtom());
