import { Flex, Typography } from "antd";
import cn from "classnames";
import { ButtonHTMLAttributes, FC } from "react";

import { createSearchParams, useNavigate } from "react-router-dom";
import { COLOR_CLASSNAMES } from "../../const/color-names.ts";
import { TEXT_CLASSNAMES } from "../../const/text-classnames.ts";

import "./TrialButton.scss";

type Props = {
  fullWidth?: boolean;
  justify?: "flex-start" | "center";
  text?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const TrialButton: FC<Props> = ({
  fullWidth,
  onClick,
  justify = "center",
  text = "Start free trial",
  ...props
}) => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const from = params.get("user_redirect_from");

  const login = () => {
    if (from) {
      navigate({
        pathname: "/login",
        search: createSearchParams({
          user_redirect_from: from ?? "",
        }).toString(),
      });
    } else {
      navigate("/login");
    }
  };

  return (
    <Flex
      justify={justify}
      className={cn({
        "full-width": fullWidth,
      })}
    >
      <button
        className={cn("trial-button button-grad", {
          "full-width": fullWidth,
        })}
        onClick={onClick ?? login}
        {...props}
      >
        <Typography.Text className={cn(TEXT_CLASSNAMES.XsSemibold, COLOR_CLASSNAMES.TextPrimary)}>
          {text}
        </Typography.Text>
      </button>
    </Flex>
  );
};
