import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { titles } from "shared/const/titles.ts";

export const useUpdateTitle = (projectName?: string) => {
  const { pathname } = useLocation();
  const specialPaths = ["script", "storyboard"];

  const titleMemo = useMemo(() => {
    for (const title in titles) {
      const name = titles[title as keyof typeof titles];

      if (pathname.includes(title)) {
        if (specialPaths.includes(title)) {
          return !projectName ? "Blooper" : `${projectName} | ${name}`;
        } else {
          return name;
        }
      }
    }

    return "Blooper";
  }, [projectName]);

  useEffect(() => {
    document.title = titleMemo;
  }, [location, titleMemo]);
};
