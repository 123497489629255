import { useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { Currency, Interval } from "@/shared/api/payment";
import { COLOR_CLASSNAMES } from "shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";

import { useMediaQueries } from "shared/hooks/use-media-queries.ts";
import { promoCodeAtom, PromotionalCode, userInputPromoCodeAtom } from "@/entities/plan";
import { ApplyPromoCodeParams, CURRENCY, INTERVAL, PlanParams } from "../../lib";

import "./PlanType.scss";

type Props = {
  activePlanParams: PlanParams | null;
  name: string;
  productId: string;
  title: string;
  description: string;
  onChange: (params: PlanParams, value: string) => void;
  amount: number;
  interval: Interval;
  currency: Currency;
  onSubscription: (productId: number) => void;
  onApplyPromoCode: (params: ApplyPromoCodeParams) => void;
  fetching: boolean;
};

export const PlanType: FC<Props> = ({
  title,
  amount,
  activePlanParams,
  onChange,
  name,
  interval,
  description,
  currency,
  onSubscription,
  productId,
  onApplyPromoCode,
  fetching,
}) => {
  const [promoCode] = useAtom(promoCodeAtom);
  const [userPromoCode] = useAtom(userInputPromoCodeAtom);
  const isDiscount = !!promoCode;
  const { isMobileL } = useMediaQueries();

  const priceText = `${CURRENCY[currency]}/${amount.toFixed(2)} ${INTERVAL[interval]}`;
  const discountPrice = isDiscount
    ? (amount - amount * ((promoCode?.percent_off as number) / 100)).toFixed(2)
    : "";

  const discountText = isDiscount
    ? `${CURRENCY[currency]}${discountPrice}/${INTERVAL[interval]}`
    : "";

  const changeValue = { interval, termType: productId };

  const isActive =
    activePlanParams?.termType === productId && activePlanParams?.interval === interval;

  const onPromoCode = () => {
    onApplyPromoCode({ subscriptionId: productId, userPromoCode: userPromoCode as string });
  };

  return (
    <Flex
      gap={8}
      vertical
      className={cn("plan-type cubic-animation", {
        "plan-type--active": isActive,
      })}
    >
      <Flex align={isMobileL ? "center" : "initial"}>
        {isDiscount && (
          <Flex justify="center" align="center" className="plan-type__discount">
            <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
              -{promoCode?.percent_off}% discount promocode
            </Typography.Text>
          </Flex>
        )}
        <input
          id={`${name}-${productId}`}
          className="plan-type__input"
          type="radio"
          name={name}
          onChange={() => onChange(changeValue, productId)}
        />
        <span
          className={cn("plan-type__radio cubic-animation", {
            "plan-type__radio--active": isActive,
          })}
        />
        <Flex vertical className="plan-type__text">
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.MdSemibold, {
              [TEXT_CLASSNAMES.XsSemibold]: isMobileL,
            })}
          >
            {title}
          </Typography.Text>
          {!isMobileL && (
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}
            >
              1 user, up to 10 000 symbols, 5 projects, share & collaborate, image watermarks
            </Typography.Text>
          )}
        </Flex>
        {isMobileL && <div style={{ flex: 1 }} />}
        <Flex vertical>
          <Flex className="gap-xxs" align="center">
            {isDiscount && (
              <Typography.Text
                className={TEXT_CLASSNAMES.SmSemibold}
                style={{
                  minWidth: "max-content",
                }}
              >
                {discountText}
              </Typography.Text>
            )}
            <Typography.Text
              style={{
                minWidth: "max-content",
                textDecoration: isDiscount ? "line-through" : "none",
                cursor: "pointer",
                zIndex: 0,
              }}
              className={cn(TEXT_CLASSNAMES.SmSemibold, {
                [COLOR_CLASSNAMES.TextSecondary]: isDiscount,
                [TEXT_CLASSNAMES.XsRegular]: isDiscount,
                [TEXT_CLASSNAMES.SmSemibold]: isMobileL,
              })}
            >
              {priceText}
            </Typography.Text>
          </Flex>
        </Flex>
      </Flex>
      {isActive && (
        <Flex
          style={{
            minWidth: "max-content",
            textDecoration: "none",
            cursor: "pointer",
            zIndex: 10,
          }}
          vertical
          gap={16}
        >
          <PromotionalCode />
          <Flex className="full-width" gap={16}>
            <Button className="full-width" loading={fetching} size="large" onClick={onPromoCode}>
              Apply promo code
            </Button>
            <Button
              className="full-width"
              loading={fetching}
              type="primary"
              size="large"
              onClick={() => onSubscription(Number(productId))}
            >
              Continue
            </Button>
          </Flex>
        </Flex>
      )}
      {isMobileL && (
        <Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>
          {description}
        </Typography.Text>
      )}
    </Flex>
  );
};
