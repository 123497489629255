import { Button, Flex, Segmented } from "antd";
import { FC } from "react";

import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { MOODBORD_TYPE } from "shared/const/routes.ts";
import { MoodboardPath, MoodboardType } from "shared/types/routes.ts";
import { MoodboardDeleteModal } from "@/entities/moodboard";
import { AddImageMoodboard } from "@/features/add-image-moodboard";
import { uesBehavior } from "../model";
import { DownloadIcon, KonvaIcon, StructureIcon, TrashIcon, UploadIcon } from "./icons";
import {ActionRestrictor} from "shared/ui";
import {useProjectType} from "@/entities/projects";
import {NOT_RELEASE} from "shared/const/release.ts";

import "./MoodboardSegmented.scss";

export const MoodboardSettings: FC = () => {
  const { id } = useExtractParams<MoodboardPath>();
  const { isShare } = useProjectType();
  const {
    onChangeMoodboardType,
    toggleDeleteModal,
    isOpenDeleteModal,
    isOpenAddImage,
    toggleAddImage,
    deleteMoodBoard,
    moodboardType,
  } = uesBehavior({ projectKey: id });

  return (
    <Flex gap={8}>
      <Segmented
        onChange={onChangeMoodboardType}
        className="segmented-border moodboard-segmented"
        size="small"
        value={moodboardType as MoodboardType}
        options={[
          { value: MOODBORD_TYPE.chaos, icon: <KonvaIcon /> },
          { value: MOODBORD_TYPE.structure, icon: <StructureIcon /> },
        ]}
      />
      {NOT_RELEASE && (
        <Button icon={<DownloadIcon />} size="small">
          Download
        </Button>
      )}
      <ActionRestrictor show={!isShare}>
        <Button onClick={() => toggleAddImage()} icon={<UploadIcon />} size="small">
          Upload
        </Button>
      </ActionRestrictor>
      <ActionRestrictor show={!isShare}>
        <Button onClick={() => toggleDeleteModal()} icon={<TrashIcon />} size="small" />
      </ActionRestrictor>

      <MoodboardDeleteModal
        isOpen={isOpenDeleteModal}
        toggleModal={toggleDeleteModal}
        deleteMoodboard={deleteMoodBoard}
      />
      <AddImageMoodboard isOpen={isOpenAddImage} toggleModal={toggleAddImage} />
    </Flex>
  );
};
