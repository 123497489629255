import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Input, InputRef, Space, Tag, Typography } from "antd";
import add from "assets/shot-selection/add.svg";
import close from "assets/shot-selection/close.svg";
import cn from "classnames";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";

import { Gallery } from "react-grid-gallery";
import { useProjectType } from "@/entities/projects";
import { useExtractParams } from "@/shared/hooks/use-extract-params";
import { GRAYSCALE_TOKEN } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { MoodboardPath } from "shared/types/routes.ts";
import arrow from "assets/shared/arrow_black_down.svg";

import {
  ActionRestrictor,
  DraggableBoard,
  DraggableImage,
  DraggableOverlay,
  IconButtonWrapper,
} from "shared/ui";
import { DRAGGABLE_IMAGE_ID } from "../lib";
import {
  getInspirationImagesAction,
  getInspirationTagsAction,
  isShowMoreActiveAtom,
  queryTagsAtom,
  updateMoodboardTagsAction
} from "../model";

import "./InspirationBoard.scss";

const SHOW_TAGS = 7;

export const InspirationBoard: FC = () => {
  const { id } = useExtractParams<MoodboardPath>();
  const { isShare } = useProjectType();

  const [inputValue, setInputValue] = useState("");

  const getInspirationTags = useAction(getInspirationTagsAction);
  const abortInspirationTags = useAction(getInspirationTagsAction.abort);

  const updateMoodboardTags = useAction(updateMoodboardTagsAction);

  const [inspirationImages] = useAtom(getInspirationImagesAction.dataAtom);
  const [tags, setTags] = useAtom(getInspirationTagsAction.dataAtom);
  const [isShowMore, setIsShowMore] = useAtom(isShowMoreActiveAtom);

  const [_, setQueryTags] = useAtom(queryTagsAtom);

  const [imagesStatuses] = useAtom(getInspirationImagesAction.statusesAtom);
  const [tagsStatuses] = useAtom(getInspirationTagsAction.statusesAtom);

  const inputRef = useRef<InputRef>(null);
  const tagsRef = useRef<HTMLDivElement>(null);

  const images = inspirationImages.map((image) => ({
    src: image.url,
    width: image.coordinates.w,
    height: image.coordinates.h,
    alt: image.id,
    customOverlay: <DraggableOverlay />,
  }));

  const handleRegenerate = () => {
    setQueryTags(tags);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue) {
      const newTags = [inputValue, ...tags];
      setTags(newTags);
      setInputValue("");
      updateMoodboardTags({ id, tags: newTags })
      return;
    }

    setInputValue("");
  };

  const onDelete = (value: string) => {
    const newTags = tags?.filter((el) => el !== value);
    setTags(newTags);
  };

  useEffect(() => {
    getInspirationTags(id);

    return () => {
      abortInspirationTags();
    };
  }, []);

  const empty = inspirationImages.length === 0;

  return (
    <Flex vertical className="inspiration-board gap-s">
      <DraggableBoard isLoading={imagesStatuses.isPending || tagsStatuses.isPending}>
        {empty ? (
          <Flex vertical className="gap-xs full-width" align="center">
            <Flex vertical className="gap-xxs">
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.Title20, GRAYSCALE_TOKEN.TEXT_ICON.BODY.className)}
              >
                Inspiration board is empty
              </Typography.Text>
              <Typography.Text
                className={cn(TEXT_CLASSNAMES.Body10, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
              >
                Add the tags, and we'll generate an inspiration board tailored to your vision.
              </Typography.Text>
            </Flex>
          </Flex>
        ) : (
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.Body10, GRAYSCALE_TOKEN.TEXT_ICON.SUBTITLE.className)}
          >
            The images were generated based on your script. Drag the pictures you like onto the
            moodboard.
          </Typography.Text>
        )}
        <Flex vertical className="gap-xxs" style={{ width: "100%" }} ref={tagsRef}>
          <Flex wrap="wrap" className="gap-xxs">
            {tags.slice(0, isShowMore ? tags.length : SHOW_TAGS).map((tag) => (
              <Tag className="shot-details-info__tag flex-ctr-ctr gap-4" key={tag}>
                {tag}
                <ActionRestrictor show={!isShare}>
                  <img onClick={() => onDelete(tag)} className="pointer" src={close} alt="close" />
                </ActionRestrictor>
              </Tag>
            ))}
            <Flex gap={4} onClick={() => setIsShowMore(!isShowMore)} className="pointer" align="center">
              {!isShowMore && <Typography.Text className={TEXT_CLASSNAMES.Link10}>View more</Typography.Text>}
              {isShowMore && <Typography.Text className={TEXT_CLASSNAMES.Link10}>View less</Typography.Text>}
              <img className="cubic-animation" style={{ width: 16, height: 16, transform: isShowMore ? "" : "rotate(180deg)" }} src={arrow} alt="arrow" />
            </Flex>
          </Flex>
          <ActionRestrictor show={!isShare}>
            <Space.Compact style={{ width: "100%" }}>
              <Input
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onPressEnter={handleInputConfirm}
              />
              <Button
                onClick={handleInputConfirm}
                icon={
                  <IconButtonWrapper>
                    <img style={{ width: 20, height: 20 }} src={add} alt="add" />
                  </IconButtonWrapper>
                }
              />
            </Space.Compact>
          </ActionRestrictor>
        </Flex>

        <ActionRestrictor show={!isShare}>
          <Button
            onClick={handleRegenerate}
            size="large"
            htmlType="button"
            className="flex-1"
            disabled={tags.length === 0}
          >
            {empty ? "Generate" : "Regenerate"}
          </Button>
        </ActionRestrictor>

        <Gallery
          margin={3}
          rowHeight={165}
          images={images}
          // @ts-ignore
          thumbnailImageComponent={(props) => (
            <DraggableImage draggableImageId={DRAGGABLE_IMAGE_ID} {...props} />
          )}
          enableImageSelection={false}
        />
      </DraggableBoard>
    </Flex>
  );
};
