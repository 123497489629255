import { Flex, Skeleton } from "antd";

export const CharacterCardSkeleton = () => {
  return (
    <Flex gap={8} vertical className="character-card">
      <Skeleton.Input style={{ height: 26 }} active />
      <Flex className="full-height full-width">
        <Skeleton.Image style={{ width: 270, height: "100%" }} active />
      </Flex>
    </Flex>
  );
};
