import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, notification, Skeleton, Typography } from "antd";
import { FC, useEffect } from "react";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames.ts";
import { ScriptPath } from "shared/types/routes.ts";
import plus from "assets/shared/plus.svg";
import { getProjectByNameAction, useProjectType } from "@/entities/projects";
import {addSceneAction, addScriptToProjectAction, getSceneListAction, sceneListAtom} from "@/entities/script";
import { SceneShotOutline } from "./SceneShotOutline.tsx";

import "./ShotOutline.scss";

const ShotOutlineSkeleton: FC = () => {
  return [...Array(2)].map((_, i) => {
    return (
      <Flex key={i} vertical className="scene">
        <Flex className="scene__title scene__title--skeleton">
          <Skeleton active style={{ width: "60%" }} paragraph={false} />
        </Flex>
        <Flex vertical className="scene__body gap-xxs">
          <Skeleton style={{ width: "100%", height: 24 }} active paragraph={false} />
          <Skeleton style={{ width: "100%", height: 24 }} active paragraph={false} />
          <Skeleton style={{ width: "100%", height: 24 }} active paragraph={false} />
          <Skeleton style={{ width: "100%", height: 24 }} active paragraph={false} />
        </Flex>
      </Flex>
    );
  })
}

const ShotOutlineContent: FC = () => {
  const { id } = useExtractParams<ScriptPath>();
  const { isShare } = useProjectType();
  const getProject = useAction(getProjectByNameAction);
  const addScene = useAction(addSceneAction);
  const [{ isPending }] = useAtom(getSceneListAction.statusesAtom);

  const [sceneList] = useAtom(sceneListAtom);
  const [project] = useAtom(getProjectByNameAction.dataAtom);
  const [api, contextHolder] = notification.useNotification();

  const lastShots = sceneList[sceneList.length - 1]?.shots;
  const lastId = lastShots?.[lastShots.length - 1]?.id;

  const handleAddScene = async () => {
    if (project) {
      await addScene(id, { country: project.country ?? "", city: project.city ?? "" });

      api.success({
        message: (
          <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
            New scene was added
          </Typography.Text>
        ),
        placement: "top",
        closeIcon: null,
        className: "scene-added-notification",
        style: { width: 225 },
      });
    }
  };

  useEffect(() => {
    getProject(id);
  }, []);

  return (
    <Flex vertical className="shot-outline">
      {contextHolder}
      <Flex flex={1} vertical className="gap-xs shot-outline__content">
        {isPending
          ? <ShotOutlineSkeleton />
          : sceneList.map((scene) => (
              <SceneShotOutline
                sceneId={scene.id}
                sceneIdx={scene.idx}
                sceneTitle={scene.title}
                key={scene.id}
                shots={scene.shots}
                projectLastShotId={lastId}
              />
            ))}
      </Flex>
      {!isShare && (
        <>
          <div style={{ height: 72 }} />
          <Flex flex={0} className="shot-outline__button">
            <Flex className="shot-outline__button--content">
              <Button
                onClick={handleAddScene}
                className="full-width flex-ctr-ctr gap-xxs"
                type="primary"
                size="large"
              >
                <img src={plus} alt="plus" className="shot-outline__img" />
                Add a new scene
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export const ShotOutline: FC = () => {
  const [uploadScriptStatuses] = useAtom(addScriptToProjectAction.statusesAtom);

  if (!uploadScriptStatuses.isPending) {
    return <ShotOutlineContent />
  }

  return (
    <Flex vertical className="shot-outline">
      <Flex flex={1} vertical className="gap-xs shot-outline__content">
        <ShotOutlineSkeleton />
      </Flex>
    </Flex>
  );
};

