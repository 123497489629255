import { useAction, useAtom } from "@reatom/npm-react";
import { Button, ConfigProvider, Flex, Typography } from "antd";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  generateImageAtom,
  getStoryboardAction,
  getStoryboardStatusAction,
  SceneHeader,
  SceneShotGrid,
  storyboardEstimatedTimeAtom,
} from "@/entities/storyboard";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { COLOR_NAMES } from "shared/const/color-names";
import { ROUTES } from "shared/const/routes.ts";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { generateUrl } from "shared/methods";
import { ScriptPath, StoryboardPath } from "shared/types/routes.ts";
import { TextLoader } from "shared/ui";

import emptyStoryboard from "assets/storyboard/empty-storyboard.svg";
import { CollapsedScene } from "./CollapsedScene";

import "./StoryboardView.scss";

export const StoryboardView: FC = () => {
  const { id } = useExtractParams<StoryboardPath>();
  const navigate = useNavigate();
  const [_, setGenerateImage] = useAtom(generateImageAtom);
  const [estimatedTime] = useAtom(storyboardEstimatedTimeAtom);

  const getStoryboard = useAction(getStoryboardAction);
  const abortStoryboard = useAction(getStoryboardAction.abort);

  const getStoryboardStatus = useAction(getStoryboardStatusAction);
  const abortStoryboardStatus = useAction(getStoryboardStatusAction.abort);

  // const [storyboardStatus] = useAtom(getStoryboardStatusAction.dataAtom);
  // const [storyboardStatusStatuses] = useAtom(getStoryboardStatusAction.statusesAtom);
  const [storyboardList] = useAtom(getStoryboardAction.dataAtom);
  const [statuses] = useAtom(getStoryboardAction.statusesAtom);

  const onRedirect = () => {
    navigate(generateUrl<ScriptPath>(ROUTES.SCRIPT.fullPath, { id }));
  };

  useEffect(() => {
    getStoryboardStatus(id);
    getStoryboard(id);

    return () => {
      setGenerateImage({});
      abortStoryboard();
      abortStoryboardStatus();
    };
  }, []);

  // if (storyboardStatusStatuses.isPending) {
  //   return <Typography.Text>Statuses...</Typography.Text>;
  // }

  if (statuses.isPending) {
    return <TextLoader time={estimatedTime} />;
  }

  // if (statuses.isPending && !storyboardStatus) {
  //   return <Typography.Text>Loading...</Typography.Text>;
  // }

  return (
    <Flex vertical className="storyboard-view full-width gap-xs">
      {!storyboardList.length && (
        <Flex vertical align="center" justify="center" className="gap-s full-height">
          <img className="storyboard-view__image" src={emptyStoryboard} alt="Empty Storyboard" />
          <Flex vertical className="text-center gap-xxs">
            <Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>
              You haven't created storyboard yet
            </Typography.Text>
            <Typography.Text className={TEXT_CLASSNAMES.SmRegular}>
              Start creating script and storyboards
            </Typography.Text>
          </Flex>
          <Button type="primary" onClick={onRedirect}>
            <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Script</Typography.Text>
          </Button>
        </Flex>
      )}

      <Flex vertical className="gap-xs">
        <ConfigProvider
          theme={{
            components: {
              Collapse: {
                headerBg: COLOR_NAMES.MetalGray50,
                contentBg: COLOR_NAMES.MetalGray50,
                contentPadding: "16px 24px",
                headerPadding: "16px 24px",
              },
            },
          }}
        >
          {storyboardList.map((scene) => (
            <CollapsedScene scene={scene} key={scene.id} />
          ))}
        </ConfigProvider>
      </Flex>
    </Flex>
  );
};

export const RenderTable: FC = () => {
  const [storyboardList] = useAtom(getStoryboardAction.dataAtom);

  return (
    <Flex id="virtual-grid-storyboard" vertical gap={16}>
      {storyboardList.map((scene) => (
        <Flex
          key={scene.idx}
          vertical
          style={{ background: "#F4F5F5", border: "1px solid #d9d9d9", borderRadius: 16 }}
        >
          <Flex style={{ padding: "16px 24px" }}>
            <SceneHeader
              scenesOrder={scene.scenesOrder}
              sceneId={scene.id}
              shotsOrder={scene.shots.map((shot) => shot.id)}
              locations={scene.locations}
              activeLocation="Cafe San Pietro"
              idx={scene.idx}
              title={scene.title}
              shotLength={scene.shots.length}
              projectLocation={scene.projectLocation}
            />
          </Flex>
          <Flex
            wrap="wrap"
            className="gap-xs shots"
            style={{ padding: "16px 24px", borderTop: "1px solid #d9d9d9 " }}
          >
            {scene.shots.map((shot) => (
              <SceneShotGrid
                renderType="export"
                key={shot.id}
                style={{ minWidth: 300, maxWidth: 280 }}
                imageStyle={{ objectFit: "contain" }}
                imageContainerStyle={{ height: 220 }}
                sceneId={scene.id}
                shotsOrder={scene.shots.map((shot) => shot.id)}
                {...shot}
              />
            ))}
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};
