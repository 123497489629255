import { AxiosResponse } from "axios";
import { api } from "@/shared/api";

import {
  AnalyzeScriptParams,
  AnalyzeScriptResult,
  CreateSceneParams,
  CreateShotParams,
  DeleteSceneParams,
  DeleteShotParams,
  ScriptResult,
  UpdateSceneParams,
  UpdateShotParams,
  UploadScriptParams,
} from "./types";
import { TResponse } from "@/vite-env";

export const uploadScriptResource = (
  params: UploadScriptParams,
  controller?: AbortController,
): TResponse<ScriptResult> =>
  api.post(`/scripts/${params.projectKey}/generate`, params.formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "File-Type": params.fileType,
    },
    signal: controller?.signal,
  });

export const analyzeScriptResource = (
  params: AnalyzeScriptParams,
  controller?: AbortController,
): TResponse<AnalyzeScriptResult> =>
  api.post(`/scripts/${params.projectKey}/analyze_script`, params.formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "File-Type": params.fileType,
    },
    signal: controller?.signal,
  });

export const getScriptResource = (
  projectKey: string,
  controller?: AbortController,
): TResponse<ScriptResult> => api.get(`/scripts/${projectKey}`, { signal: controller?.signal });

export const createSceneResource = (params: CreateSceneParams): Promise<AxiosResponse<unknown>> =>
  api.post(`/storyboards/${params.projectKey}/scenes`, {
    scene_info: params.sceneInfo,
    scenes_order: params.scenesOrder,
  });

export const deleteSceneResource = (params: DeleteSceneParams): Promise<unknown> =>
  api.delete(`/storyboards/${params.projectKey}/scenes`, {
    data: {
      scene_info: params.sceneInfo,
      scenes_order: params.scenesOrder,
    },
  });

export const updateSceneResource = (params: UpdateSceneParams): Promise<AxiosResponse<unknown>> =>
  api.put(`/storyboards/${params.projectKey}/scenes`, {
    scene_info: params.sceneInfo,
    scenes_order: params.scenesOrder,
  });

export const createShotResource = (params: CreateShotParams): Promise<AxiosResponse<unknown>> =>
  api.post(`/scripts/${params.projectKey}/scenes/${params.sceneId}/shots/${params.shotId}`, {
    shot_info: params.shotInfo,
    shots_order: params.shotsOrder,
  });

export const updateShotResource = (params: UpdateShotParams): Promise<AxiosResponse<unknown>> =>
  api.patch(`/scripts/${params.projectKey}/scenes/${params.sceneId}/shots/${params.shotId}`, {
    shot_info: params.shotInfo,
    shots_order: params.shotsOrder,
  });

export const deleteShotResource = (params: DeleteShotParams): Promise<AxiosResponse<unknown>> =>
  api.delete(`/storyboards/${params.projectKey}/scenes/${params.sceneId}/shots/${params.shotId}`, {
    data: {
      shots_order: params.shotsOrder,
    },
  });
