import { Flex, Tag, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { COLOR_CLASSNAMES, COLOR_NAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { TShowDetailsInfo } from "../../index";

export const ShowDetailsInfo: FC<TShowDetailsInfo> = ({
  props,
  cameraMovement,
  shotSettings,
  cameraAngle,
}) => {
  const propsArray = (props ?? "").split(",")?.filter((el) => el);

  return (
    <Flex vertical className="gap-xs" style={{ maxWidth: 330 }}>
      {!!propsArray.length && (
        <Flex vertical className="gap-xxs">
          <Typography.Text
            className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
          >
            Props
          </Typography.Text>
          <Flex className="gap-xxs" wrap="wrap">
            {propsArray.map((tag, index) => (
              <Tag key={index.toString(36)}>{tag}</Tag>
            ))}
          </Flex>
          {(!!shotSettings.length || !!cameraAngle || !!cameraMovement) && (
            <div style={{ width: "100%", height: 1, background: COLOR_NAMES.MetalGray200 }} />
          )}
        </Flex>
      )}
      {(!!shotSettings.length || !!cameraAngle) && (
        <>
          <Flex vertical className="gap-xxs">
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
            >
              Shot settings
            </Typography.Text>
            <Flex wrap="wrap" className="gap-xxs">
              {shotSettings.map((tag, index) => (
                <Tag key={index.toString(36)}>{tag}</Tag>
              ))}
              <Tag key={cameraAngle}>{cameraAngle}</Tag>
            </Flex>
          </Flex>
          {!!cameraMovement && (
            <div style={{ width: "100%", height: 1, background: COLOR_NAMES.MetalGray200 }} />
          )}
        </>
      )}
      {!!cameraMovement && (
        <>
          <Flex vertical className="gap-xxs">
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
            >
              Camera movement
            </Typography.Text>
            <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
              {cameraMovement}
            </Typography.Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};
