import { Flex } from "antd";
import { FC } from "react";
import { useToTop } from "shared/hooks";
import { Header } from "../../widgets/header";
import { FAQ, ProductShot, ProductSimplify, ProductStart, ProductSteps, TrustedBy } from "./ui";

import "./Product.scss";

export const Product: FC = () => {
  useToTop();

  return (
    <Flex className="product" vertical>
      <Flex className="product__header">
        <Header />
      </Flex>
      <ProductStart />
      <ProductShot />
      <TrustedBy />
      <ProductSteps />
      <ProductSimplify />
      <FAQ />
    </Flex>
  );
};
