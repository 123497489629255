import { useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { Comment } from "@/shared/api/comments";
import { useExtractParams } from "@/shared/hooks/use-extract-params.ts";
import { COLOR_CLASSNAMES } from "shared/const/color-names";
import { TEXT_CLASSNAMES } from "shared/const/text-classnames";
import { StoryboardSidebarPath } from "shared/types/routes";
import emptyComments from "assets/comments/empty.svg";
import { Spinner } from "shared/ui";
import { useAccountWithAvatar } from "@/entities/account";
import { getCommentsAction } from "../model";
import { CommentCollapse } from "./comment";

interface Props {
  filteredComments: Comment[];
}

export const RenderComments: FC<Props> = ({ filteredComments }) => {
  const { id } = useExtractParams<StoryboardSidebarPath>();
  const [commentsStatus] = useAtom(getCommentsAction.statusesAtom);
  const { account } = useAccountWithAvatar();

  if (commentsStatus.isPending) {
    return (
      <Flex vertical align="center" justify="center" className="full-height gap-xs">
        <Spinner />
      </Flex>
    );
  }
  if (!filteredComments.length) {
    return (
      <Flex vertical align="center" justify="center">
        <img width={180} src={emptyComments} alt="comments" />
        <Typography.Text className={cn(TEXT_CLASSNAMES.XsSemibold, COLOR_CLASSNAMES.TextSecondary)}>
          No comments yet
        </Typography.Text>
      </Flex>
    );
  }
  return filteredComments.map((comment) => {
    return (
      <CommentCollapse
        key={`${comment.id}-filteredComments`}
        {...comment}
        currUserEmail={account.email}
        projectKey={id!}
      />
    );
  });
};
