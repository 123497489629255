import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useState } from "react";
import { COLOR_NAMES } from "../../const/color-names.ts";
import { TEXT_CLASSNAMES } from "../../const/text-classnames.ts";
import { useMediaQueries } from "../../hooks/use-media-queries.ts";

import "./CookieBanner.scss";

export const CookieBanner: FC = () => {
  const { isMobileL } = useMediaQueries();
  const [cookieBanner, setCookieBanner] = useState(localStorage.getItem("cookie-banner"));

  const removeCookieBanner = () => {
    localStorage.setItem("cookie-banner", "false");
    setCookieBanner("false");
  };

  const isActive = cookieBanner !== "false";

  return (
    <Flex
      align="center"
      justify="center"
      className={cn("cookie-banner", {
        "cookie-banner--active": isActive,
      })}
    >
      <Flex
        align="center"
        justify="center"
        className={cn("cookie-banner__content gap-m", {
          "gap-xs": isMobileL,
        })}
      >
        <Typography.Text
          className={cn(TEXT_CLASSNAMES.XsRegular, {
            [TEXT_CLASSNAMES.XxsRegular]: isMobileL,
          })}
        >
          We use cookies to improve your browsing experience. By clicking “Accept all”, you agree to
          use of cookies.{" "}
          <a
            style={{
              color: COLOR_NAMES.BrandGreen500,
            }}
            target="_blank"
            href="/cookie-policy"
          >
            Cookie Policy
          </a>
        </Typography.Text>
        <Flex
          vertical={isMobileL}
          align="center"
          justify="center"
          className={cn("gap-m", {
            "gap-xs": isMobileL,
          })}
        >
          <Button type="primary" onClick={removeCookieBanner}>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XsRegular, {
                [TEXT_CLASSNAMES.XxsRegular]: isMobileL,
              })}
            >
              Accept all
            </Typography.Text>
          </Button>
          <Button onClick={removeCookieBanner}>
            <Typography.Text
              className={cn(TEXT_CLASSNAMES.XsRegular, {
                [TEXT_CLASSNAMES.XxsRegular]: isMobileL,
              })}
            >
              Decline
            </Typography.Text>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
