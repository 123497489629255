import { api } from "@/shared/api";
import { Comment, CreateComment, ResolveComment } from "./types.ts";
import { TResponse } from "@/vite-env";

export const getCommentsResource = (id: string): TResponse<Comment[]> => api.get(`/comments/${id}`);

export const getCommentsNestedResource = (id: string) => api.get(`/comments/${id}/nested`);

export const createCommentResource = (params: CreateComment) => api.post("/comments", params);

export const resolveCommentResource = (params: ResolveComment) =>
  api.patch("/comments/resolved_mark", params);

export const deleteCommentResource = (id: string, commentId: string) =>
  api.delete(`/comments/${id}/${commentId}`);
