import { useAtom } from "@reatom/npm-react";
import { Avatar, Flex } from "antd";
import { FC } from "react";
import { ShotCharacter } from "@/shared/api/storyboard";
import { toCharacter } from "@/shared/methods/to-character.ts";
import { getShotAction } from "@/entities/shot-edit";

export const ShotCharacters: FC = () => {
  const [shot] = useAtom(getShotAction.dataAtom);

  const characters = shot?.characters?.reduce(
    (acc, cur) => {
      return {
        [cur.character_key]: cur,
        ...acc,
      };
    },
    {} as Record<string, ShotCharacter>,
  );

  return (
    <>
      {!!Object.values(characters ?? {})?.length && (
        <>
          <div className="shot-details-info__divider" />
          <Flex wrap="wrap" className="shot-details-info__row gap-xs">
            {Object.values(characters ?? {})?.map((character, index) => (
              <Avatar
                key={index.toString(36)}
                size={46}
                icon={<img src={toCharacter(character.character_key, "2")} alt="character" />}
              />
            ))}
          </Flex>
        </>
      )}
    </>
  );
};
