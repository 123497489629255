import { ROUTES } from "shared/const/routes.ts";
import {
  ArchiveBoardPath,
  CharacterCommentsPath,
  CharacterInfoCommentsPath,
  CharacterInfoSettingsPath,
  InspirationBoardPath,
  MoodboardCommentsPath,
  ScriptCommentsPath,
  ScriptShotOutlinePath,
  SearchBoardPath,
  ShotEditCommentsPath,
  ShotEditDetailsPath,
  ShotEditEditPath,
  ShotEditVersionsPath,
} from "shared/types/routes.ts";
import { generateUrl } from "shared/methods";
import { TStoryboardRoutes } from "@/entities/storyboard";
import {
  MoodboardSettings,
  StoryboardSettings,
  ScriptSettings,
  CharacterInfoSettings,
  ShotEditSettings,
} from "../ui";
import { CharacterInfoURL, Settings, ShotEditURL, URL } from "./types.ts";

export const STORYBOARD_NAVIGATION_SETTINGS: Record<TStoryboardRoutes, Settings> = {
  script: {
    rightSidebarRoutes: [
      {
        title: "Shot outline",
        url: (params: URL) =>
          generateUrl<ScriptShotOutlinePath>(ROUTES.SCRIPT_SHOT_OUTLINE.fullPath, {
            id: params.projectKey,
          }),
        routePath: ROUTES.SCRIPT_SHOT_OUTLINE.routePath,
      },
      {
        title: "Comments",
        url: (params: URL) =>
          generateUrl<ScriptCommentsPath>(ROUTES.SCRIPT_COMMENTS.fullPath, {
            id: params.projectKey,
          }),
        routePath: ROUTES.SCRIPT_COMMENTS.routePath,
      },
    ],
    SettingsComponent: ScriptSettings,
  },
  moodboard: {
    SettingsComponent: MoodboardSettings,
    rightSidebarRoutes: [
      {
        title: "Inspiration board",
        url: (params: URL) =>
          generateUrl<InspirationBoardPath>(ROUTES.MOODBORD_INSPIRATION_BOARD.fullPath, {
            id: params.projectKey,
          }),
        routePath: ROUTES.MOODBORD_INSPIRATION_BOARD.routePath,
      },
      {
        title: "Search",
        url: (params: URL) =>
          generateUrl<SearchBoardPath>(ROUTES.MOODBORD_SEARCH_BOARD.fullPath, {
            id: params.projectKey,
          }),
        routePath: ROUTES.MOODBORD_SEARCH_BOARD.routePath,
      },
      {
        title: "Archive",
        url: (params: URL) =>
          generateUrl<ArchiveBoardPath>(ROUTES.MOODBORD_ARCHIVE_BOARD.fullPath, {
            id: params.projectKey,
          }),
        routePath: ROUTES.MOODBORD_ARCHIVE_BOARD.routePath,
      },
      {
        title: "Comments",
        url: (params: URL) =>
          generateUrl<MoodboardCommentsPath>(ROUTES.MOODBORD_COMMENTS.fullPath, {
            id: params.projectKey,
          }),
        routePath: ROUTES.MOODBORD_COMMENTS.routePath,
      },
    ],
  },
  character: {
    SettingsComponent: null,
    rightSidebarRoutes: [
      {
        title: "Comments",
        url: (params: URL) =>
          generateUrl<CharacterCommentsPath>(ROUTES.CHARACTER_COMMENTS.fullPath, {
            id: params.projectKey,
          }),
        routePath: ROUTES.CHARACTER_COMMENTS.routePath,
      },
    ],
  },
  "character-info": {
    SettingsComponent: CharacterInfoSettings,
    rightSidebarRoutes: [
      {
        title: "Character settings",
        url: (params: URL) =>
          generateUrl<CharacterInfoSettingsPath>(ROUTES.CHARACTER_INFO_SETTINGS.fullPath, {
            id: params.projectKey,
            characterId: (params as CharacterInfoURL).characterId,
          }),
        routePath: ROUTES.CHARACTER_INFO_SETTINGS.routePath,
      },
      {
        title: "Comments",
        url: (params: URL) =>
          generateUrl<CharacterInfoCommentsPath>(ROUTES.CHARACTER_INFO_COMMENTS.fullPath, {
            id: params.projectKey,
            characterId: (params as CharacterInfoURL).characterId,
          }),
        routePath: ROUTES.CHARACTER_INFO_COMMENTS.routePath,
      },
    ],
  },
  storyboard: {
    SettingsComponent: StoryboardSettings,
  },
  "shot-edit": {
    SettingsComponent: ShotEditSettings,
    rightSidebarRoutes: [
      {
        title: "Details",
        url: (params: URL) =>
          generateUrl<ShotEditDetailsPath>(ROUTES.SHOT_EDIT_DETAILS.fullPath, {
            id: params.projectKey,
            shotId: (params as ShotEditURL).shotId,
            sceneId: (params as ShotEditURL).sceneId,
          }),
        routePath: ROUTES.SHOT_EDIT_DETAILS.routePath,
      },
      {
        title: "Edit",
        url: (params: URL) =>
          generateUrl<ShotEditEditPath>(ROUTES.SHOT_EDIT_EDIT.fullPath, {
            id: params.projectKey,
            shotId: (params as ShotEditURL).shotId,
            sceneId: (params as ShotEditURL).sceneId,
          }),
        routePath: ROUTES.SHOT_EDIT_EDIT.routePath,
      },
      {
        title: "Versions",
        url: (params: URL) =>
          generateUrl<ShotEditVersionsPath>(ROUTES.SHOT_EDIT_VERSIONS.fullPath, {
            id: params.projectKey,
            shotId: (params as ShotEditURL).shotId,
            sceneId: (params as ShotEditURL).sceneId,
          }),
        routePath: ROUTES.SHOT_EDIT_VERSIONS.routePath,
      },
      {
        title: "Comments",
        url: (params: URL) =>
          generateUrl<ShotEditCommentsPath>(ROUTES.SHOT_EDIT_COMMENTS.fullPath, {
            id: params.projectKey,
            shotId: (params as ShotEditURL).shotId,
            sceneId: (params as ShotEditURL).sceneId,
          }),
        routePath: ROUTES.SHOT_EDIT_COMMENTS.routePath,
      },
    ],
  },
};
